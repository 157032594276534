import React, { useEffect, useState } from "react";
import "./Tabspopulardata.css";
import { Col, Form, Modal, Row } from "react-bootstrap";
import Popularsearches from "./Popularsearches";
import Companieshiring from "./Companieshiring";
import Trendingsearche from "./Trendingsearche";
import Trendingjobs from "./Trendingjobs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSocket } from "../../context/SocketProvider";
import Popularcities from "./Popularcities";
import turntologo from "../../assets/images/turntoexplorelogo.png";
import historyclose from "./../../Component/icontopng/historyclose.png";
import deletepng from "./../../Component/icontopng/deleteapppng.png";
import bottomarrow from "../../Component/icontopng/bottomarrow.png"
import { useAuth } from "../../context/AuthProvider";
import {
  PostDeleteAllHistory,
  PostDeleteNotInterstedHistory,
  getSearchHistory,
} from "../../Api/Api";
import RecentHistory from "../../Component/SearchHistroy/RecentHistory";
import NotIntersted from "../../Component/SearchHistroy/NotIntersted";
import { Store } from "react-notifications-component";

const Tabspopulardata = (props) => {
  const [currentLocation, setCurrentLocation] = useState({});
  const { socket } = useSocket();
  const { AuthData, isAuthenticated } = useAuth();

  const [searchdata, setsearchdata] = useState([]);
  const [trendingJobs, settrendingJobs] = useState([]);
  const [trendingSearches, settrendingSearches] = useState([]);
  const [popularCompanies, setpopularCompanies] = useState([]);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [dropDownSelectMenu, setDropDownSelectMenu] =
    useState("Companies Hiring");
  const initialDropDownSelectMenuData = [
    "Companies Hiring",
    "Popular Cities",
  ];

  if (isAuthenticated && AuthData.role === "user") {
    initialDropDownSelectMenuData.push("Recent Search", "Not Interested");
  }

  const [dropDownSelectMenuData, setDropDownSelectMenuData] = useState(
    initialDropDownSelectMenuData
  );

  const history = useHistory();

  // for getting current location
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCurrentLocation(location);
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );
  }, []);
  const CloseDropDownModal = () => {
    setIsOpenDropdown(false);
  };
 
// set explore data
  useEffect(() => {
    const url = window.location.href;
    if (url.includes("/jobmap")) {
      var exploredata = JSON.parse(localStorage.getItem("exploredata"));
      if (exploredata?.trending_companies.length) {
        setpopularCompanies(exploredata?.trending_companies);
      }

      if (exploredata?.trending_jobs.length) {
        settrendingJobs(exploredata?.trending_jobs);
      }

      if (exploredata?.trending_search.length) {
        settrendingSearches(exploredata?.trending_search);
      }

      if (exploredata?.popular_search.length) {
        setsearchdata(exploredata?.popular_search);
      }
    } else {
      var explorecurrentlocationdata = JSON.parse(
        localStorage.getItem("explorecurrentlocationdata")
      );
    }
    if (explorecurrentlocationdata?.trending_companies.length) {
      setpopularCompanies(explorecurrentlocationdata.trending_companies);
    }

    if (explorecurrentlocationdata?.trending_jobs.length) {
      settrendingJobs(explorecurrentlocationdata.trending_jobs);
    }

    if (explorecurrentlocationdata?.trending_search.length) {
      settrendingSearches(explorecurrentlocationdata.trending_search);
    }

    if (explorecurrentlocationdata?.popular_search.length) {
      setsearchdata(explorecurrentlocationdata.popular_search);
    }
  }, [history]);

  const popularCities = [
    {
      cityname: "New York City, New York",
      cityvalue: "New York, NY, USA",
      latitude: 40.7127753,
      longitude: -74.0059728,
    },
    {
      cityname: "Los Angeles, California",
      cityvalue: "Los Angeles, CA, USA",
      latitude: 34.0549076,
      longitude: -118.242643,
    },
    {
      cityname: "Chicago, Illinois",
      cityvalue: "Chicago, IL, USA",
      latitude: 41.8781136,
      longitude: -87.6297982,
    },
    {
      cityname: "Houston, Texas",
      cityvalue: "Houston, TX, USA",
      latitude: 29.7604267,
      longitude: -95.3698028,
    },
    {
      cityname: "Phoenix, Arizona",
      cityvalue: "Phoenix, AZ, USA",
      latitude: 33.4483771,
      longitude: -112.0740373,
    },
    {
      cityname: "Philadelphia, Pennsylvania",
      cityvalue: "Philadelphia, PA, USA",
      latitude: 39.9525839,
      longitude: -75.1652215,
    },
    {
      cityname: "San Antonio, Texas",
      cityvalue: "San Antonio, TX, USA",
      latitude: 29.4251905,
      longitude: -98.4945922,
    },
    {
      cityname: "San Diego, California",
      cityvalue: "San Diego, CA, USA",
      latitude: 32.715738,
      longitude: -117.1610838,
    },
    {
      cityname: "Dallas, Texas",
      cityvalue: "Dallas, TX, USA",
      latitude: 32.7766642,
      longitude: -96.79698789999999,
    },
    {
      cityname: "San Jose, California",
      cityvalue: "San Jose, CA, USA",
      latitude: 37.33874,
      longitude: -121.8852525,
    },
    {
      cityname: "Austin, Texas",
      cityvalue: "Austin, TX, USA",
      latitude: 30.267153,
      longitude: -97.7430608,
    },
    {
      cityname: "Jacksonville, Florida",
      cityvalue: "Jacksonville, FL, USA",
      latitude: 30.3321838,
      longitude: -81.65565099999999,
    },
    {
      cityname: "Fort Worth, Texas",
      cityvalue: "Fort Worth, TX, USA",
      latitude: 32.7554883,
      longitude: -97.3307658,
    },
    {
      cityname: "Columbus, Ohio",
      cityvalue: "Columbus, OH, USA",
      latitude: 39.9611755,
      longitude: -82.99879419999999,
    },
    {
      cityname: "Charlotte, North Carolina",
      cityvalue: "Charlotte, NC, USA",
      latitude: 35.2270869,
      longitude: -80.8431267,
    },
    {
      cityname: "San Francisco, California",
      cityvalue: "San Francisco, CA, USA",
      latitude: 37.7749295,
      longitude: -122.4194155,
    },
    {
      cityname: "Indianapolis, Indiana",
      cityvalue: "Indianapolis, IN, USA",
      latitude: 39.768403,
      longitude: -86.158068,
    },
    {
      cityname: "Seattle, Washington",
      cityvalue: "Seattle, WA, USA",
      latitude: 47.6061389,
      longitude: -122.3328481,
    },
    {
      cityname: "Denver, Colorado",
      cityvalue: "Denver, CO, USA",
      latitude: 39.7392358,
      longitude: -104.990251,
    },
    {
      cityname: "Washington, D.C.",
      cityvalue: "Washington, DC, USA",
      latitude: 38.9071923,
      longitude: -77.0368707,
    },
    {
      cityname: "Boston, Massachusetts",
      cityvalue: "Boston, MA, USA",
      latitude: 42.3600825,
      longitude: -71.0588801,
    },
    {
      cityname: "El Paso, Texas",
      cityvalue: "El Paso, TX, USA",
      latitude: 31.7618778,
      longitude: -106.4850217,
    },
    {
      cityname: "Nashville, Tennessee",
      cityvalue: "Nashville, TN, USA",
      latitude: 36.1626638,
      longitude: -86.7816016,
    },
    {
      cityname: "Detroit, Michigan",
      cityvalue: "Detroit, MI, USA",
      latitude: 42.331427,
      longitude: -83.0457538,
    },
    {
      cityname: "Memphis, Tennessee",
      cityvalue: "Memphis, TN, USA",
      latitude: 35.1495343,
      longitude: -90.0489801,
    },
    {
      cityname: "Portland, Oregon",
      cityvalue: "Portland, OR, USA",
      latitude: 45.515232,
      longitude: -122.6783853,
    },
    {
      cityname: "Oklahoma City, Oklahoma",
      cityvalue: "Oklahoma City, OK, USA",
      latitude: 35.4675602,
      longitude: -97.5164276,
    },
    {
      cityname: "Las Vegas, Nevada",
      cityvalue: "Las Vegas, NV, USA",
      latitude: 36.171563,
      longitude: -115.1391009,
    },
    {
      cityname: "Louisville, Kentucky",
      cityvalue: "Louisville, KY, USA",
      latitude: 38.2526647,
      longitude: -85.7584557,
    },
    {
      cityname: "Baltimore, Maryland",
      cityvalue: "Baltimore, MD, USA",
      latitude: 39.2903848,
      longitude: -76.6121893,
    },
    {
      cityname: "Birmingham, Alabama",
      cityvalue: "Birmingham, AL, USA",
      latitude: 33.5206608,
      longitude: -86.80249,
  },
  {
      cityname: "Montgomery, Alabama",
      cityvalue: "Montgomery, AL, USA",
      latitude: 32.3668052,
      longitude: -86.2999689,
  },
  {
      cityname: "Mobile, Alabama",
      cityvalue: "Mobile, AL, USA",
      latitude: 30.6953657,
      longitude: -88.0398912,
  },
  {
      cityname: "Anchorage, Alaska",
      cityvalue: "Anchorage, AK, USA",
      latitude: 61.2180556,
      longitude: -149.9002778,
  },
  {
      cityname: "Juneau, Alaska",
      cityvalue: "Juneau, AK, USA",
      latitude: 58.3019444,
      longitude: -134.4197222,
  },
  {
      cityname: "Fairbanks, Alaska",
      cityvalue: "Fairbanks, AK, USA",
      latitude: 64.8377778,
      longitude: -147.7163889,
  },
  {
      cityname: "Phoenix, Arizona",
      cityvalue: "Phoenix, AZ, USA",
      latitude: 33.4483771,
      longitude: -112.0740373,
  },
  {
      cityname: "Tucson, Arizona",
      cityvalue: "Tucson, AZ, USA",
      latitude: 32.2226066,
      longitude: -110.9747108,
  },
  {
      cityname: "Flagstaff, Arizona",
      cityvalue: "Flagstaff, AZ, USA",
      latitude: 35.1982836,
      longitude: -111.651302,
  },
  {
      cityname: "Little Rock, Arkansas",
      cityvalue: "Little Rock, AR, USA",
      latitude: 34.7464809,
      longitude: -92.2895948,
  },
  {
      cityname: "Fayetteville, Arkansas",
      cityvalue: "Fayetteville, AR, USA",
      latitude: 36.0625795,
      longitude: -94.1574263,
  },
  {
      cityname: "Fort Smith, Arkansas",
      cityvalue: "Fort Smith, AR, USA",
      latitude: 35.3859242,
      longitude: -94.3985475,
  },
  {
      cityname: "Los Angeles, California",
      cityvalue: "Los Angeles, CA, USA",
      latitude: 34.052235,
      longitude: -118.243683,
  },
  {
      cityname: "San Diego, California",
      cityvalue: "San Diego, CA, USA",
      latitude: 32.715736,
      longitude: -117.161087,
  },
  {
      cityname: "San Jose, California",
      cityvalue: "San Jose, CA, USA",
      latitude: 37.3382082,
      longitude: -121.8863286,
  },
  {
      cityname: "San Francisco, California",
      cityvalue: "San Francisco, CA, USA",
      latitude: 37.7749295,
      longitude: -122.4194155,
  },
  {
      cityname: "Sacramento, California",
      cityvalue: "Sacramento, CA, USA",
      latitude: 38.5815719,
      longitude: -121.4943996,
  },
  {
      cityname: "Denver, Colorado",
      cityvalue: "Denver, CO, USA",
      latitude: 39.7392358,
      longitude: -104.990251,
  },
  {
      cityname: "Colorado Springs, Colorado",
      cityvalue: "Colorado Springs, CO, USA",
      latitude: 38.8338816,
      longitude: -104.8213634,
  },
  {
      cityname: "Boulder, Colorado",
      cityvalue: "Boulder, CO, USA",
      latitude: 40.0149856,
      longitude: -105.2705456,
  },
  {
      cityname: "Bridgeport, Connecticut",
      cityvalue: "Bridgeport, CT, USA",
      latitude: 41.1865478,
      longitude: -73.1951767,
  },
  {
      cityname: "New Haven, Connecticut",
      cityvalue: "New Haven, CT, USA",
      latitude: 41.308274,
      longitude: -72.9278835,
  },
  {
      cityname: "Stamford, Connecticut",
      cityvalue: "Stamford, CT, USA",
      latitude: 41.0534302,
      longitude: -73.5387341,
  },
  {
      cityname: "Wilmington, Delaware",
      cityvalue: "Wilmington, DE, USA",
      latitude: 39.7390721,
      longitude: -75.5397878,
  },
  {
      cityname: "Dover, Delaware",
      cityvalue: "Dover, DE, USA",
      latitude: 39.158168,
      longitude: -75.5243682,
  },
  {
      cityname: "Jacksonville, Florida",
      cityvalue: "Jacksonville, FL, USA",
      latitude: 30.3321838,
      longitude: -81.655651,
  },
  {
      cityname: "Miami, Florida",
      cityvalue: "Miami, FL, USA",
      latitude: 25.7616798,
      longitude: -80.1917902,
  },
  {
      cityname: "Tampa, Florida",
      cityvalue: "Tampa, FL, USA",
      latitude: 27.950575,
      longitude: -82.4571776,
  },
  {
      cityname: "Orlando, Florida",
      cityvalue: "Orlando, FL, USA",
      latitude: 28.5383355,
      longitude: -81.3792365,
  },
  {
      cityname: "St. Petersburg, Florida",
      cityvalue: "St. Petersburg, FL, USA",
      latitude: 27.7676008,
      longitude: -82.6402915,
  },
  {
      cityname: "Atlanta, Georgia",
      cityvalue: "Atlanta, GA, USA",
      latitude: 33.7490987,
      longitude: -84.3901849,
  },
  {
      cityname: "Augusta, Georgia",
      cityvalue: "Augusta, GA, USA",
      latitude: 33.4709714,
      longitude: -81.9748429,
  },
  {
      cityname: "Savannah, Georgia",
      cityvalue: "Savannah, GA, USA",
      latitude: 32.0808989,
      longitude: -81.091203,
  },

  {
    cityname: "Honolulu, Hawaii",
    cityvalue: "Honolulu, HI, USA",
    latitude: 21.3069,
    longitude: -157.8583,
  },
  {
    cityname: "Hilo, Hawaii",
    cityvalue: "Hilo, HI, USA",
    latitude: 19.7071,
    longitude: -155.0884,
  },
  {
    cityname: "Boise, Idaho",
    cityvalue: "Boise, ID, USA",
    latitude: 43.6150,
    longitude: -116.2023,
  },
  {
    cityname: "Idaho Falls, Idaho",
    cityvalue: "Idaho Falls, ID, USA",
    latitude: 43.4917,
    longitude: -112.0333,
  },
  {
    cityname: "Chicago, Illinois",
    cityvalue: "Chicago, IL, USA",
    latitude: 41.8781,
    longitude: -87.6298,
  },
  {
    cityname: "Aurora, Illinois",
    cityvalue: "Aurora, IL, USA",
    latitude: 41.7606,
    longitude: -88.3201,
  },
  {
    cityname: "Rockford, Illinois",
    cityvalue: "Rockford, IL, USA",
    latitude: 42.2711,
    longitude: -89.0937,
  },
  {
    cityname: "Indianapolis, Indiana",
    cityvalue: "Indianapolis, IN, USA",
    latitude: 39.7684,
    longitude: -86.1581,
  },
  {
    cityname: "Fort Wayne, Indiana",
    cityvalue: "Fort Wayne, IN, USA",
    latitude: 41.0793,
    longitude: -85.1394,
  },
  {
    cityname: "Evansville, Indiana",
    cityvalue: "Evansville, IN, USA",
    latitude: 37.9716,
    longitude: -87.5711,
  },
  {
    cityname: "Des Moines, Iowa",
    cityvalue: "Des Moines, IA, USA",
    latitude: 41.5868,
    longitude: -93.6250,
  },
  {
    cityname: "Cedar Rapids, Iowa",
    cityvalue: "Cedar Rapids, IA, USA",
    latitude: 41.9779,
    longitude: -91.6656,
  },
  {
    cityname: "Davenport, Iowa",
    cityvalue: "Davenport, IA, USA",
    latitude: 41.5236,
    longitude: -90.5776,
  },
  {
    cityname: "Kansas City, Kansas",
    cityvalue: "Kansas City, KS, USA",
    latitude: 39.0997,
    longitude: -94.5786,
  },
  {
    cityname: "Wichita, Kansas",
    cityvalue: "Wichita, KS, USA",
    latitude: 37.6872,
    longitude: -97.3301,
  },
  {
    cityname: "Topeka, Kansas",
    cityvalue: "Topeka, KS, USA",
    latitude: 39.0473,
    longitude: -95.6752,
  },
  {
    cityname: "Louisville, Kentucky",
    cityvalue: "Louisville, KY, USA",
    latitude: 38.2527,
    longitude: -85.7585,
  },
  {
    cityname: "Lexington, Kentucky",
    cityvalue: "Lexington, KY, USA",
    latitude: 38.0406,
    longitude: -84.5037,
  },
  {
    cityname: "Bowling Green, Kentucky",
    cityvalue: "Bowling Green, KY, USA",
    latitude: 36.9685,
    longitude: -86.4808,
  },
  {
    cityname: "New Orleans, Louisiana",
    cityvalue: "New Orleans, LA, USA",
    latitude: 29.9511,
    longitude: -90.0715,
  },
  {
    cityname: "Baton Rouge, Louisiana",
    cityvalue: "Baton Rouge, LA, USA",
    latitude: 30.4515,
    longitude: -91.1871,
  },
  {
    cityname: "Shreveport, Louisiana",
    cityvalue: "Shreveport, LA, USA",
    latitude: 32.5252,
    longitude: -93.7502,
  },
  {
    cityname: "Portland, Maine",
    cityvalue: "Portland, ME, USA",
    latitude: 43.6591,
    longitude: -70.2568,
  },
  {
    cityname: "Bangor, Maine",
    cityvalue: "Bangor, ME, USA",
    latitude: 44.8016,
    longitude: -68.7712,
  },
  {
    cityname: "Baltimore, Maryland",
    cityvalue: "Baltimore, MD, USA",
    latitude: 39.2904,
    longitude: -76.6122,
  },
  {
    cityname: "Frederick, Maryland",
    cityvalue: "Frederick, MD, USA",
    latitude: 39.4142,
    longitude: -77.4105,
  },
  {
    cityname: "Gaithersburg, Maryland",
    cityvalue: "Gaithersburg, MD, USA",
    latitude: 39.1434,
    longitude: -77.2014,
  },

  {
      cityname: "Boston, Massachusetts",
      cityvalue: "Boston, MA, USA",
      latitude: 42.3601,
      longitude: -71.0589,
  },
  {
      cityname: "Worcester, Massachusetts",
      cityvalue: "Worcester, MA, USA",
      latitude: 42.2626,
      longitude: -71.8023,
  },
  {
      cityname: "Springfield, Massachusetts",
      cityvalue: "Springfield, MA, USA",
      latitude: 42.1015,
      longitude: -72.5898,
  },
  {
      cityname: "Detroit, Michigan",
      cityvalue: "Detroit, MI, USA",
      latitude: 42.3314,
      longitude: -83.0458,
  },
  {
      cityname: "Grand Rapids, Michigan",
      cityvalue: "Grand Rapids, MI, USA",
      latitude: 42.9634,
      longitude: -85.6681,
  },
  {
      cityname: "Ann Arbor, Michigan",
      cityvalue: "Ann Arbor, MI, USA",
      latitude: 42.2808,
      longitude: -83.7430,
  },
  {
      cityname: "Minneapolis, Minnesota",
      cityvalue: "Minneapolis, MN, USA",
      latitude: 44.9778,
      longitude: -93.2650,
  },
  {
      cityname: "St. Paul, Minnesota",
      cityvalue: "St. Paul, MN, USA",
      latitude: 44.9537,
      longitude: -93.0900,
  },
  {
      cityname: "Duluth, Minnesota",
      cityvalue: "Duluth, MN, USA",
      latitude: 46.7867,
      longitude: -92.1005,
  },
  {
      cityname: "Jackson, Mississippi",
      cityvalue: "Jackson, MS, USA",
      latitude: 32.2988,
      longitude: -90.1848,
  },
  {
      cityname: "Gulfport, Mississippi",
      cityvalue: "Gulfport, MS, USA",
      latitude: 30.3674,
      longitude: -89.0928,
  },
  {
      cityname: "Kansas City, Missouri",
      cityvalue: "Kansas City, MO, USA",
      latitude: 39.0997,
      longitude: -94.5786,
  },
  {
      cityname: "St. Louis, Missouri",
      cityvalue: "St. Louis, MO, USA",
      latitude: 38.6270,
      longitude: -90.1994,
  },
  {
      cityname: "Springfield, Missouri",
      cityvalue: "Springfield, MO, USA",
      latitude: 37.2080,
      longitude: -93.2923,
  },
  {
      cityname: "Billings, Montana",
      cityvalue: "Billings, MT, USA",
      latitude: 45.7833,
      longitude: -108.5007,
  },
  {
      cityname: "Missoula, Montana",
      cityvalue: "Missoula, MT, USA",
      latitude: 46.8787,
      longitude: -113.9966,
  },
  {
      cityname: "Omaha, Nebraska",
      cityvalue: "Omaha, NE, USA",
      latitude: 41.2565,
      longitude: -95.9345,
  },
  {
      cityname: "Lincoln, Nebraska",
      cityvalue: "Lincoln, NE, USA",
      latitude: 40.8136,
      longitude: -96.7026,
  },
  {
      cityname: "Las Vegas, Nevada",
      cityvalue: "Las Vegas, NV, USA",
      latitude: 36.1699,
      longitude: -115.1398,
  },
  {
      cityname: "Reno, Nevada",
      cityvalue: "Reno, NV, USA",
      latitude: 39.5296,
      longitude: -119.8138,
  },
  {
      cityname: "Manchester, New Hampshire",
      cityvalue: "Manchester, NH, USA",
      latitude: 42.9956,
      longitude: -71.4548,
  },
  {
      cityname: "Nashua, New Hampshire",
      cityvalue: "Nashua, NH, USA",
      latitude: 42.7654,
      longitude: -71.4676,
  },
  {
      cityname: "Newark, New Jersey",
      cityvalue: "Newark, NJ, USA",
      latitude: 40.7357,
      longitude: -74.1724,
  },
  {
      cityname: "Jersey City, New Jersey",
      cityvalue: "Jersey City, NJ, USA",
      latitude: 40.7178,
      longitude: -74.0431,
  },
  {
      cityname: "Paterson, New Jersey",
      cityvalue: "Paterson, NJ, USA",
      latitude: 40.9168,
      longitude: -74.1718,
  },

  {
      cityname: "Albuquerque, New Mexico",
      cityvalue: "Albuquerque, NM, USA",
      latitude: 35.0843859,
      longitude: -106.650422,
  },
  {
      cityname: "Santa Fe, New Mexico",
      cityvalue: "Santa Fe, NM, USA",
      latitude: 35.6869752,
      longitude: -105.937799,
  },
  {
      cityname: "New York City, New York",
      cityvalue: "New York, NY, USA",
      latitude: 40.7127753,
      longitude: -74.0059728,
  },
  {
      cityname: "Buffalo, New York",
      cityvalue: "Buffalo, NY, USA",
      latitude: 42.8864468,
      longitude: -78.8783689,
  },
  {
      cityname: "Rochester, New York",
      cityvalue: "Rochester, NY, USA",
      latitude: 43.1565779,
      longitude: -77.6088465,
  },
  {
      cityname: "Charlotte, North Carolina",
      cityvalue: "Charlotte, NC, USA",
      latitude: 35.2270869,
      longitude: -80.8431267,
  },
  {
      cityname: "Raleigh, North Carolina",
      cityvalue: "Raleigh, NC, USA",
      latitude: 35.7795897,
      longitude: -78.6381787,
  },
  {
      cityname: "Greensboro, North Carolina",
      cityvalue: "Greensboro, NC, USA",
      latitude: 36.0726354,
      longitude: -79.7919754,
  },
  {
      cityname: "Fargo, North Dakota",
      cityvalue: "Fargo, ND, USA",
      latitude: 46.8771863,
      longitude: -96.7898034,
  },
  {
      cityname: "Bismarck, North Dakota",
      cityvalue: "Bismarck, ND, USA",
      latitude: 46.8083268,
      longitude: -100.7837392,
  },
  {
      cityname: "Columbus, Ohio",
      cityvalue: "Columbus, OH, USA",
      latitude: 39.9611755,
      longitude: -82.9987942,
  },
  {
      cityname: "Cleveland, Ohio",
      cityvalue: "Cleveland, OH, USA",
      latitude: 41.49932,
      longitude: -81.6943605,
  },
  {
      cityname: "Cincinnati, Ohio",
      cityvalue: "Cincinnati, OH, USA",
      latitude: 39.1031182,
      longitude: -84.5120196,
  },
  {
      cityname: "Oklahoma City, Oklahoma",
      cityvalue: "Oklahoma City, OK, USA",
      latitude: 35.4675602,
      longitude: -97.5164276,
  },
  {
      cityname: "Tulsa, Oklahoma",
      cityvalue: "Tulsa, OK, USA",
      latitude: 36.154,
      longitude: -95.9928,
  },
  {
      cityname: "Portland, Oregon",
      cityvalue: "Portland, OR, USA",
      latitude: 45.5051064,
      longitude: -122.6750261,
  },
  {
      cityname: "Eugene, Oregon",
      cityvalue: "Eugene, OR, USA",
      latitude: 44.0520691,
      longitude: -123.0867536,
  },
  {
      cityname: "Philadelphia, Pennsylvania",
      cityvalue: "Philadelphia, PA, USA",
      latitude: 39.9525839,
      longitude: -75.1652215,
  },
  {
      cityname: "Pittsburgh, Pennsylvania",
      cityvalue: "Pittsburgh, PA, USA",
      latitude: 40.4406248,
      longitude: -79.9958864,
  },
  {
      cityname: "Allentown, Pennsylvania",
      cityvalue: "Allentown, PA, USA",
      latitude: 40.6084305,
      longitude: -75.4901833,
  },
  {
      cityname: "Providence, Rhode Island",
      cityvalue: "Providence, RI, USA",
      latitude: 41.8239891,
      longitude: -71.4128343,
  },
  {
      cityname: "Columbia, South Carolina",
      cityvalue: "Columbia, SC, USA",
      latitude: 34.0007104,
      longitude: -81.0348144,
  },
  {
      cityname: "Charleston, South Carolina",
      cityvalue: "Charleston, SC, USA",
      latitude: 32.7764749,
      longitude: -79.9310512,
  },

  {
    cityname: "Sioux Falls, South Dakota",
    cityvalue: "Sioux Falls, SD, USA",
    latitude: 43.5445959,
    longitude: -96.7311034,
  },
  {
    cityname: "Rapid City, South Dakota",
    cityvalue: "Rapid City, SD, USA",
    latitude: 44.0805434,
    longitude: -103.2310153,
  },
  {
    cityname: "Nashville, Tennessee",
    cityvalue: "Nashville, TN, USA",
    latitude: 36.1626638,
    longitude: -86.7816016,
  },
  {
    cityname: "Memphis, Tennessee",
    cityvalue: "Memphis, TN, USA",
    latitude: 35.1495328,
    longitude: -90.0489811,
  },
  {
    cityname: "Knoxville, Tennessee",
    cityvalue: "Knoxville, TN, USA",
    latitude: 35.9606384,
    longitude: -83.9207392,
  },
  {
    cityname: "Houston, Texas",
    cityvalue: "Houston, TX, USA",
    latitude: 29.7604267,
    longitude: -95.3698028,
  },
  {
    cityname: "San Antonio, Texas",
    cityvalue: "San Antonio, TX, USA",
    latitude: 29.4241219,
    longitude: -98.4936282,
  },
  {
    cityname: "Dallas, Texas",
    cityvalue: "Dallas, TX, USA",
    latitude: 32.7766642,
    longitude: -96.7969879,
  },
  {
    cityname: "Austin, Texas",
    cityvalue: "Austin, TX, USA",
    latitude: 30.267153,
    longitude: -97.7430608,
  },
  {
    cityname: "Salt Lake City, Utah",
    cityvalue: "Salt Lake City, UT, USA",
    latitude: 40.7607793,
    longitude: -111.8910474,
  },
  {
    cityname: "Burlington, Vermont",
    cityvalue: "Burlington, VT, USA",
    latitude: 44.4758825,
    longitude: -73.21207199999999,
  },
  {
    cityname: "Virginia Beach, Virginia",
    cityvalue: "Virginia Beach, VA, USA",
    latitude: 36.8529263,
    longitude: -75.97798499999999,
  },
  {
    cityname: "Richmond, Virginia",
    cityvalue: "Richmond, VA, USA",
    latitude: 37.5407246,
    longitude: -77.4360481,
  },
  {
    cityname: "Seattle, Washington",
    cityvalue: "Seattle, WA, USA",
    latitude: 47.6062095,
    longitude: -122.3320708,
  },
  {
    cityname: "Spokane, Washington",
    cityvalue: "Spokane, WA, USA",
    latitude: 47.6587802,
    longitude: -117.4260466,
  },
  {
    cityname: "Charleston, West Virginia",
    cityvalue: "Charleston, WV, USA",
    latitude: 38.3498195,
    longitude: -81.6326234,
  },
  {
    cityname: "Milwaukee, Wisconsin",
    cityvalue: "Milwaukee, WI, USA",
    latitude: 43.0389025,
    longitude: -87.9064736,
  },
  {
    cityname: "Madison, Wisconsin",
    cityvalue: "Madison, WI, USA",
    latitude: 43.0730517,
    longitude: -89.4012302,
  },
  {
    cityname: "Cheyenne, Wyoming",
    cityvalue: "Cheyenne, WY, USA",
    latitude: 41.1399814,
    longitude: -104.8202462,
  }
  ];

  const [keyword, setKeyword] = useState([]);

  // get recent location
  const GetLocationrecent = () => {
    getSearchHistory()
      .then(async function (response) {
        setKeyword(response.data?.data?.recentSearches);
      })
      .catch(function (error) {
      });
  };
  const [filterText, setFilterText] = useState("");
  const filteredData = keyword.filter((item) => {
    return item.keyword?.toLowerCase().includes(filterText?.toLowerCase());
  });

  // delete history
  const DeleteAllHistory = () => {
    PostDeleteAllHistory()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
       
        GetLocationrecent();

        Store.addNotification({
          title: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
      })
      .catch(function (error) {
       
      });
  };

  const [notintersted, setNotIntersted] = useState([]);

  // delete not intersetd history
  const DeleteNotInterstedHistory = () => {
    PostDeleteNotInterstedHistory()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
       
        GetLocationrecent();

        Store.addNotification({
          title: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
       
      })
      .catch(function (error) {
      
      });
  };

  return (
    <>
     
      <div className="terms-container3 shadow newoffcanverallserch">
        <div onClick={props.exploroffcanvasclose}>
          <img
            src={historyclose}
            alt="close"
            className="cursor-pointer reportclosepng tabspopulardatacss"
          />
        </div>
        <div className="ofcanvsdroupdownsearch">
          <Form.Group className="shadow">
            <button
              name="distance"
              className="form-control custom-icon-select"
              id="select-location"
              value={dropDownSelectMenu}
              onClick={() => {
                setIsOpenDropdown(true);
              }}
            >
              <div className="newcssofdashother">{dropDownSelectMenu} <img src={bottomarrow} alt="select" className="setdroupdownwidthexploere"/></div>
            </button>
          </Form.Group>

          {dropDownSelectMenu == "Recent Search" ? (
            <div
              className="d-flex align-items-center cursor-pointer "
              onClick={DeleteAllHistory}
            >
              {" "}
              <img
                src={deletepng}
                alt="delete"
                className=" newrecentsearchhistory"
              />
            </div>
          ) : (
            ""
          )}
          {dropDownSelectMenu == "Not Interested" ? (
            <div
              className="d-flex align-items-center cursor-pointer "
              onClick={DeleteNotInterstedHistory}
            >
              {" "}
              <img
                src={deletepng}
                alt="delete"
                className=" newrecentsearchhistory"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {dropDownSelectMenu == "Popular Searches" && (
          <Popularsearches searchdata={searchdata} />
        )}
        {dropDownSelectMenu == "Companies Hiring" && (
          <Companieshiring popularCompanies={popularCompanies} />
        )}
        {dropDownSelectMenu == "Trending Searches" && (
          <Trendingsearche trendingSearches={trendingSearches} />
        )}
        {dropDownSelectMenu == "Trending Jobs" && (
          <Trendingjobs trendingJobs={trendingJobs} />
        )}
        {dropDownSelectMenu == "Popular Cities" && (
          <Popularcities popularCities={popularCities} />
        )}

        {dropDownSelectMenu == "Recent Search" && (
          <RecentHistory
            handleCloseHistory={props.handleCloseHistory}
            setSearch={props.setSearch}
            setSearchValue={props.setSearchValue}
            setLatitude={props.setLatitude}
            setLongitude={props.setLongitude}
            SetLocationGetAutoComplet={props.SetLocationGetAutoComplet}
            setLatitudeValue={props.setLatitudeValue}
            setLongitudeValue={props.setLongitudeValue}
            keyword={keyword}
          />
        )}
        {dropDownSelectMenu == "Not Interested" && (
          <NotIntersted notintersted={notintersted} />
        )}

        <div className="offcanvasbootamtuntologoset">
          <img src={turntologo} alt="turn-to" className="contactsuslogoimg" />
        </div>
      </div>

      <Modal show={isOpenDropdown} onHide={CloseDropDownModal} centered  backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title className="model_side">
            <div>Explorer</div>
            <img
              src={historyclose}
              alt="close"
              className="close_model reportclosepng"
              onClick={CloseDropDownModal}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0 addnewmodalsetpixelpadding">
          <Form>
            <Form.Group className="mt-1 mb-4">
              <Row>
                {dropDownSelectMenuData.map((item) => {
                  return (
                    <Col md={6}>
                      <Form.Group controlId="location">
                        {/* <Form.Label>Location Type</Form.Label> */}
                        <div className="d-flex">
                          <div className="mx-1 form-check">
                            <label className="form-check-label text-muted">
                              <input
                                type="radio"
                                checked={dropDownSelectMenu == item}
                                className="form-check-input"
                                value={dropDownSelectMenu}
                                id="posted_on"
                                name="posted_on"
                                onChange={(e) => {
                                  setDropDownSelectMenu(item);
                                  CloseDropDownModal()
                                }}
                              />
                              <i className="input-helper"></i>
                              <div className="date">{item}</div>
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  );
                })}
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Tabspopulardata;
