import Reaact, { useState } from "react";
import "./NewAddPage.css";
import { Col} from "react-bootstrap";
import { BsDot } from "react-icons/bs";

const DMCA = () => {
  return (
    <>
      <div>
        {/* first section */}
        <div className="user-profile">
          <div className="terms-container marginundersmallscreen">
            <Col lg={12} className="p-0 d-flex justify-content-center"></Col>
            <h1 className="termofuse text-center">
              {" "}
              Turn-To DMCA & Copyright Policy
            </h1>
            <hr></hr>

            <div className="mt-3 mb-3 text-justify">
              <b>
                Turn-To operates in compliance with the Digital Millennium
                Copyright Act ("DMCA") of 1998. If you hold a copyright or are
                authorized to act on behalf of one, or if you believe that your
                copyrighted work has been infringed upon by someone through the
                Turn-To service, please send a DMCA notice to our designated
                agent at the details provided below.
              </b>
            </div>

            <div>
              <h5>
                <b>DMCA Notice Requirements:</b>
              </h5>
              <div className="mt-3 mb-3 text-justify">
                To file a notice of infringement with us, you must provide a
                written communication that includes the following:
              </div>
              <div className="mt-2 text-justify">
                1. Identification of the copyrighted work claimed to have been
                infringed.
              </div>
              <div className="mt-2 text-justify">
                2. A description of where the infringing material is located on
                the Turn-To platform, with enough detail that we may find it on
                the service.
              </div>
              <div className="mt-2 text-justify">
                3. Your contact information, including name, address, telephone
                number, and email address.
              </div>
              <div className="mt-2 text-justify">
                4. A statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law.
              </div>
              <div className="mt-2 text-justify">
                5. A statement by you, made under penalty of perjury, that the
                above information in your notice is accurate and that you are
                the copyright owner or authorized to act on the copyright
                owner's behalf.
              </div>
              <div className="mt-2 text-justify">
                6. Your physical or electronic signature.
              </div>
              <div className="mt-3 text-justify">
                <b> Email your DMCA notice to:</b>{" "}
                <a href={"https://gmail.com"} target="_blank">
                  dmca@turn-to.com
                </a>
              </div>

              <h5 className="mb-2 mt-3 fontsizeh5">
                <BsDot size={25} />
                <b> Turn-To's Response to DMCA Notices</b>
              </h5>

              <div>
                <div className="text-justify">
                  Upon receipt of a proper DMCA notice, Turn-To will respond
                  expeditiously to remove, or disable access to, the material
                  claimed to be infringing. Furthermore, in appropriate
                  circumstances, Turn-To may also terminate accounts of users
                  who are deemed to be repeat infringers.
                </div>
              </div>

              <h5 className="mt-2">
                <b>Trademark Takedown Requests:</b>
              </h5>
              <div className="mt-3 mb-3 text-justify">
                If you believe that content on Turn-To infringes upon your
                trademark, please submit a comprehensive takedown request
                including:
              </div>
              <div className="mt-2 text-justify">
                1. A detailed description of the trademark and a description if
                it’s a logo.
              </div>
              <div className="mt-2 text-justify">
                2. The countries where the trademark is registered.
              </div>
              <div className="mt-2 text-justify">
                3. The registration number(s) of the trademark.
              </div>
              <div className="mt-2 text-justify">
                4. Scanned copies or screenshots of the trademark registration
                certificate(s).
              </div>
              <div className="mt-2 text-justify">
                5. A declaration that you believe in good faith that the use is
                unauthorized and the notice is accurate, and under penalty of
                perjury, that you are authorized to act on behalf of the owner
                of the trademark rights.
              </div>
              <div className="mt-3 text-justify">
                <b> Send your trademark takedown requests to:</b>{" "}
                <a href={"https://gmail.com"} target="_blank">
                  dmca@turn-to.com
                </a>
              </div>

              <h5 className="mb-2 mt-3 fontsizeh5">
                <BsDot size={25} />
                <b> Repeat Infringer Policy:</b>
              </h5>

              <div>
                <div className="text-justify">
                  Turn-To will terminate the accounts of repeat infringers in
                  accordance with the DMCA. We reserve the right to terminate
                  access to our service and/or terminate the accounts of users
                  at our sole discretion, potentially without prior notice, who
                  infringe the intellectual property rights of others.
                </div>
              </div>

              <div className="mt-3 text-justify">
                <h5>
                  <b>Designated Agent:</b>
                </h5>
                <div className="mt-2 mb-3 text-justify">
                  Our designated agent to receive notifications of claimed
                  infringement is:
                </div>
                <div>
                  <b>Name:</b> Turn-To
                </div>
                <div>
                  <b>Email:</b>{" "}
                  <a href={"https://gmail.com"} target="_blank">
                    dmca@turn-to.com
                  </a>
                </div>
                <div>
                  <b>Mailing Address:</b> dmca@turn-to.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DMCA;
