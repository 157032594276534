import React, { useEffect, useRef, useState } from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Feedback from "../../Component/Footer-Page/Feedback";


function Footer() {
  const [show, setShow] = useState(false);
  const offcanvasRef = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    function handleClickOutside(event) {
      if (offcanvasRef.current && !offcanvasRef.current.contains(event.target)) {
        handleClose();
      }
    }

    
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [offcanvasRef]);

  
  return (
    <>
    <footer className="footer footer_width">
      <div className="footer_size">
        <div className="footer_data">
          <div className="d-flex flex-wrap">
            <Link to="/privacy-and-cookies" className="blackcolor mx-2">
              {" "}
              Privacy Policy
            </Link>
            {/* <Link to="/cookies-policy" className="blackcolor mx-2">
              {" "}
              Cookies Policy
            </Link> */}
            <Link to="/legal" className="blackcolor mx-2">
              {" "}
              Legal
            </Link>
            {/* <Link to="/disclaimer" className="blackcolor mx-2">
              {" "}
              Disclaimer
            </Link>
            <Link to="/terms-and-conditions" className="blackcolor mx-2">
              {" "}
              Terms and Conditions
            </Link>
            <Link to="/refundPolicy" className="blackcolor mx-2">
              {" "}
              Refund Policy
            </Link>
            <Link to="/trademarks" className="blackcolor mx-2">
              {" "}
              Trademarks
            </Link> */}

            {/* <Link to="/coppa" className="blackcolor mx-2">
              {" "}
              COPPA Compliance Information
            </Link> */}
            <Link to="/help" className="blackcolor mx-2">
              {" "}
              Help
            </Link>
            <div  className="blackcolor mx-2 cursor-pointer" onClick={handleShow}>
              {" "}
              Feedback
            </div>
            <Link to="/about" className="blackcolor mx-2">
              {" "}
              About
            </Link>
            {/* <Link to="/welcome" className="blackcolor mx-2">
              {" "}
              Welcome
            </Link> */}
          </div>
          <div>
            <span className="blackcolor adddarkblacksetcopy mx-2">
              Copyright -{" "}
              <a href="/" target="_blank" rel="noopener noreferrer">
                Turn-to{" "}
              </a>
              {new Date().getFullYear()}
            </span>
          </div>
        </div>
        {/* <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Free <a href="https://www.bootstrapdash.com/react-admin-templates/" target="_blank" rel="noopener noreferrer"> react admin </a> templates from BootstrapDash.com.  </span> */}
      </div>
    </footer>
    <div className={show ? "offcanvas-footer open" : "offcanvas-footer"}>
    <Offcanvas
                backdrop={false}
                target={offcanvasRef.current}
                show={show}
                onHide={handleClose}
                placement="end"
                scroll={true}
                className="footer_offcanvas"
              >
                <Offcanvas.Body>
                 
                    <div className="" ref={offcanvasRef}>
                      <Feedback
                         setShow={setShow}
                      />
                    </div>
                 
                </Offcanvas.Body>
              </Offcanvas>
              </div>
    </>
  );
}

export default Footer;
