import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Modal,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useSocket } from "../../../../../context/SocketProvider";

import { MdOutlineClose } from "react-icons/md";
import footerpagepng from "../../../../../Component/icontopng/footerpagepng.png";

import { Store } from "react-notifications-component";
import Avatar from "react-avatar";
import editdatapng from "../../../../../Component/icontopng/editdata.png";
import deletecarddatapng from "../../../../../Component/icontopng/deletecarddata.png";
import MyButton from "../../common/Button/MyButton";
import addcarddata from "../../../../../Component/icontopng/addcarddata.png";
import Swal from "sweetalert2";
import Tooltip from "react-tooltip-lite";

const Awards = (props) => {
  const [show, setShow] = useState(false);
  const [awardsAllList, setAwardsAllList] = useState([]);
  const [awardsAllUserAwrds, setAwardsAllUserAwrds] = useState([]);
  const [selectAwards, setSelectAwards] = useState("");
  const [iscontactLoading, setContactIsLoading] = useState(false);

  const [awardsModalshow , setawardsModalshow] = useState(false);
  const [errAward , setErrAward] = useState(false);
  const [chechawardkist, setchechawardkist] = useState(false);

  const [Id, setId] = useState("");

  const handleClose = () => {
  setShow(false)
  setawardsModalshow(false)
  setSelectAwards("")
  setId("");
  Awardclear();
  };

  // Awardvalidation
  const Awardvalidation = () => {
    Awardclear();
    var isvalid = true;
    if (selectAwards.trim() == "") {
      setchechawardkist(true);
      setErrAward("Please Enter Award");
      isvalid = false;
    }
    return isvalid;
  }

  const Awardclear = ()=>{
    setErrAward("")
    setchechawardkist(false)
  }

  const [titleInput, setTitleInput] = useState(useRef(""));
  const OnFouceOut = () => {
    if (titleInput?.current) {
      titleInput?.current.blur();
    }
  };



  const { socket } = useSocket();
  useEffect(() => {
    socket.emit("awards");
    socket.off("awards");
    socket.on("awards", (res) => {
      setAwardsAllList(res.data);
    });
    GetAllAwardsUser();
    if(props.profileId != "") {
      GetAllAwardsUser();
    }
    window.addEventListener("scroll", OnFouceOut);
    return () => {
      window.removeEventListener("scroll", OnFouceOut);
    };
  }, [props.profileId]);

// AddAwards
  const AddAwards = () => {
    if (!Awardvalidation()) {
      return false;
    }
    setContactIsLoading(true);
    var editdata = awardsAllUserAwrds.find((e) => e._id == Id);

    if (editdata) {
      socket.emit("edit_award", {
        token: localStorage.getItem("token"),
        profile_id: props.profileId,
        _id: Id,
        award: selectAwards,
      });
      socket.off("edit_award_" + props.profileId);
      socket.on("edit_award_" + props.profileId, (res) => {
        editdata=""
        setId("")
        Store.addNotification({
          title: res.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],

          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        setContactIsLoading(false);
        setShow(false);
        setSelectAwards("");
        GetAllAwardsUser();
      });
    } else {
      socket.emit("add_award", {
        token: localStorage.getItem("token"),
        profile_id: props.profileId,
        award: selectAwards,
      });
      socket.off("add_award_" + props.profileId);
      socket.on("add_award_" + props.profileId, (res) => {
        setSelectAwards("");
        Store.addNotification({
          title: res.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });

        GetAllAwardsUser();
        setContactIsLoading(false);
        setShow(false);
      });
    }
  };

  // GetAllAwardsUser
  const GetAllAwardsUser = () => {
    socket.emit("user_awards", {
      token: localStorage.getItem("token"),
      profile_id: props.profileId,
      award: selectAwards,
    });
    socket.off("user_awards_" + props.profileId);
    socket.on("user_awards_" + props.profileId, (res) => {
      setAwardsAllUserAwrds(res.data);
      props.setawrdCount(res.data)
    });
  };

  // UserAwrdsDelete
  const UserAwrdsDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true

    }).then((result) => {
      if (result.value) {
    socket.emit("delete_award", {
      token: localStorage.getItem("token"),
      profile_id: props.profileId,
      _id: id,
    });
    socket.off("delete_award_" + props.profileId);
    socket.on("delete_award_" + props.profileId, (res) => {
      GetAllAwardsUser();
      Store.addNotification({
        title: res.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
    });
  }
});
  
  };

  // UserEditAwards
  const UserEditAwards = (id, award) => {
    setShow(true);
    setSelectAwards(award);
    setId(id);
  };

  // awardsModal
  const awardsModal = () =>{
    setawardsModalshow(!awardsModalshow);
  }

  return (
    <>
        {awardsAllUserAwrds.map((e) => {
          return (
            <>
              <Col xs={12} className="p-0">
                <CardDetalis
                  DataDeleteApi={UserAwrdsDelete}
                  data={e}
                  EditApi={UserEditAwards}
                  />
              </Col>
            </>
          );
        })}
                  <Row className="align-items-end justify-content-end">
       
        {awardsAllUserAwrds.length < 5 ? 
         <Tooltip content="Add">
        <Col className="text-right p-0" xs={12}>
          <button className="my-btn" onClick={() => setShow(true)}>
            {" "}
            <img src={addcarddata} alt="add" className="notsaveiocn" />
            Add{" "}
          </button>
        </Col>
        </Tooltip>
        :""}
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        centered
      >
        <Modal.Body className="p-0">
          <Row className="justify-content-center">
          <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
            <Col lg={12}>
              <Row className="bg-white userdatapart awardsDatacolset border-radiustip">
              <div className="col-12 mt-3 tipsbuttonset">
                  <div className="index_newcss">
                <div className="contectinfoshowname mb-2">
                  <div>Awards</div>
                
                </div>
                 <div className="mb-2">
                 <span
                onClick={awardsModal}
                  className="newdashbordbuttoncolor atstipsbuttonset"
                >
                  {awardsModalshow ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                </span>
                </div>
                
                </div>
                </div>
                <Col lg={12} className="mb-1">
                  <Form.Group controlId="startyear">
                    <Form.Label className="mt-1 label_class">
                      <b>Mention your accomplishments</b>
                    </Form.Label>
                    <Form.Control
                          type="text"
                          className={
                            chechawardkist
                              ? "borderdrop "
                              : "borderdrop  input_radius"
                          }
                          name="selectAwards"
                          value={selectAwards}
                          onChange={(e) => setSelectAwards(e.target.value)}
                          placeholder="Mention your accomplishments"
                        ></Form.Control>
                   

                    <span className="err_msg">{errAward}</span>
                  </Form.Group>
                </Col>
                <Col>
              <div className="setmodalfooterbutton">
                <Button
                    variant="danger"
                    className="contact_radius mr-2"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <MyButton type={"submit"} onClick={AddAwards}>
                    {iscontactLoading ? (
                      <Spinner animation="border" role="status" className="spinneerset">
                        <span className="visually-hidden"></span>
                      </Spinner>
                    ) : (
                      "Save"
                    )}
                  </MyButton>
                </div>
                </Col>
              </Row>
            </Col>
                </div>
              <div className="position-relative">

            {awardsModalshow ?
            <Col lg={12} className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips">
              <div className="contactinfo_tip awardsheightset border-radiustip">

                <div className="mt-2">
                  <div>Celebrating Your Achievements with Turn-To:</div>
                  <div className="mt-2">
                    <b>Spotlight on Awards and Honors</b>
                  </div>
                  <div className="mt-2">
                    <b>Crafting a Distinguished Profile:</b>
                  </div>
                  <Accordion defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="my-2">
                      <Accordion.Header className="fontsetAccordion">
                        <img
                          src={footerpagepng}
                          alt="open"
                          width="15px"
                          height="15px"
                        />{" "}
                        Distinction Through Achievement:
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <div>
                            Your Turn-To profile is your platform to showcase
                            awards and honors that underscore your exceptional
                            contributions and accomplishments. Whether it's
                            professional recognition, academic awards, volunteer
                            accolades, athletic achievements, or military
                            honors, each distinction adds a layer of prestige to
                            your persona.
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="mt-2">
                    <b>Optimizing Your Awards Section:</b>
                  </div>
                  <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="my-2">
                      <Accordion.Header className="fontsetAccordion">
                        <img
                          src={footerpagepng}
                          alt="open"
                          width="15px"
                          height="15px"
                        />{" "}
                        Simplicity and Clarity:
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <div>
                            List your awards in concise phrases. The focus is on
                            the recognition, so there’s no need for punctuation
                            or extensive descriptions. A clear, straightforward
                            list ensures that potential employers can quickly
                            grasp the extent of your achievements.
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="my-2">
                      <Accordion.Header className="fontsetAccordion">
                        <img
                          src={footerpagepng}
                          alt="open"
                          width="15px"
                          height="15px"
                        />{" "}
                        Variety and Versatility:
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <div>
                            Don’t limit yourself to conventional professional
                            awards. Broaden the spectrum to include volunteering
                            awards, athletic recognitions, and military service
                            honors. This diversity reflects a well-rounded
                            character, showcasing your ability to excel across
                            different arenas.
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="mt-2">
                    <b>Example for Turn-To</b>
                  </div>
                  <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="my-2">
                      <Accordion.Header className="fontsetAccordion">
                        <img
                          src={footerpagepng}
                          alt="open"
                          width="15px"
                          height="15px"
                        />{" "}
                        Sales Excellence Award 2021:
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <div>Top regional sales performance</div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="my-2">
                      <Accordion.Header className="fontsetAccordion">
                        <img
                          src={footerpagepng}
                          alt="open"
                          width="15px"
                          height="15px"
                        />{" "}
                        Volunteer of the Year 2020:
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <div>Local food bank significant contribution</div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="my-2">
                      <Accordion.Header className="fontsetAccordion">
                        <img
                          src={footerpagepng}
                          alt="open"
                          width="15px"
                          height="15px"
                        />{" "}
                        Team Captain Award 2019:
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <div>
                            Leadership and team-building in inter-company sports
                            league
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3" className="my-2">
                      <Accordion.Header className="fontsetAccordion">
                        <img
                          src={footerpagepng}
                          alt="open"
                          width="15px"
                          height="15px"
                        />{" "}
                        <b> Why It’s Important</b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <div>
                            Incorporating awards and honors into your Turn-To
                            profile does more than just highlight your past
                            successes; it signals to employers and collaborators
                            your potential for future excellence. This section
                            of your profile stands as a testament to your
                            dedication, leadership, and versatility, making you
                            a standout candidate in any job market.
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="mt-2">
                    <b>
                      Your achievements tell a story of ambition, hard work, and
                      recognition. By curating a list of your awards on Turn-To,
                      you invite the professional world to celebrate your
                      successes while setting yourself apart as a candidate of
                      distinction.
                    </b>
                  </div>
                </div>
              </div>
            </Col>
            : ""
            }
            </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Awards;

function CardDetalis(props) {
  return (
    <div className="menu-card position-relative">
      <div className="stretch-card  my-2 p-0 " style={{ minHeight: "80px" }}>
        <div className="card shadow-lg exp_margin">
          <div className="card-body exp_padding">
            <div className="d-flex justify-content-between h-100">
              <div className="left-div d-block d-md-flex">
                <div className="mr-0 mr-md-3">
                  <p>
                    <Avatar size="50" round="8px" name={props.data.award} />
                  </p>
                </div>
                <div>
                  <h5 className="other_wordbreak">
                    <b>{props.data.award}</b>
                  </h5>
                </div>
              </div>
              <div className="right-div d-flex flex-column">
              <Tooltip content="Edit">
                <MyButton
                  className="p-1 mb-1"
                  onClick={(e) =>
                    props.EditApi(props.data._id, props.data.award)
                  }
                >
                  <img
                    src={editdatapng}
                    alt="edit"
                    className="closepngiocnwidth"
                  />
                </MyButton>
                </Tooltip>
              <Tooltip content="Delete">
                <MyButton
                  className="p-1 deletebtn"
                  onClick={(e) => props.DataDeleteApi(props.data._id)}
                >
                  <img
                    src={deletecarddatapng}
                    alt="delete"
                    className="closepngiocnwidth"
                    set="bold"
                  />
                </MyButton>
                </Tooltip>

       
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
