import React, {  useRef } from "react";
// import "./footerpage.css";
import {
  Accordion,
  
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
} from "react-bootstrap";
import "./Contactus.css";
import MyButton from "../../common/Button/MyButton";
import footerpagepng from "../../../../../Component/icontopng/footerpagepng.png";
import { useAuth } from "../../../../../context/AuthProvider";
import { useState } from "react";
import { useSocket } from "../../../../../context/SocketProvider";
import { Store } from "react-notifications-component";
import Tooltip from "react-tooltip-lite";
import turntologo from "../../../../../assets/images/loginpagenewlogo.png";
import ReCAPTCHA from 'react-google-recaptcha';
import { MdOutlineClose } from "react-icons/md";

const NewContactUs = () => {
  const { AuthData,  isAuthenticated } = useAuth();
  const { socket } = useSocket();

  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [number, SetNumber] = useState("");
  const [message, SetMessage] = useState("");

  const [nameError, SetNameError] = useState("");
  const [emailError, SetEmailError] = useState("");
  const [numberError, SetNumberError] = useState("");
  const [messageError, SetMessageError] = useState("");
  const [iscontactLoading, setContactIsLoading] = useState(false);
  const [isRecaptcha,SetIsRecaptcha] = useState(false)
  const [countactusetips ,setCountactusetips] = useState(false);
  const recaptchaRef = useRef();

  // for Recaptcha
  const handleRecaptchaChange = (value) => {
    if(value){
      SetIsRecaptcha(true)
    }else
    {
      SetIsRecaptcha(false)
    }
  }
  const ClearError = () => {
    SetNameError("");
    SetEmailError("");
    SetNumberError("");
    SetMessageError("");
  };

  // Validation
  const Validation = () => {
    ClearError();
    var isValid = true;

    if (name.trim() == "") {
      SetNameError("Please Enter Name ");
      isValid = false;
    }else if (!/^[a-zA-Z]/.test(name)) {
      SetNameError("Name should only contain letters");
      isValid = false;
    }
    if (email.trim() == "") {
      isValid = false;
      SetEmailError("Please Enter Email ");
    } else {
      const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(email);
      if (!emailRegex) {
        SetEmailError("Please Enter Valid Email Address");
        isValid = false;
      }
    }
    if (number.trim() === "") {
      SetNumberError("Please Enter Mobile Number");
      isValid = false;
    } else if (!/^\d+$/.test(number)) {
      SetNumberError("Mobile Number should contain only digits");
      isValid = false;
    } else if (number.length > 15) {
      SetNumberError("Mobile Number should not exceed 15 characters");
      isValid = false;
    }

    if (message.trim() == "") {
      isValid = false;
      SetMessageError("Please Enter Message ");
    }

    return isValid;
  };

  // SubmitEvent
  const SubmitEvent = () => {

    if (!Validation()) {
      return false;
    }

    if (!isAuthenticated) {
        if(!isRecaptcha){
          return false;
        }
    }

    setContactIsLoading(true);
    var data = {
      token: localStorage.getItem("token"),
      name: name,
      email: email,
      number: number,
      message: message,
    };
    // console.log("213213");
    socket.emit("contact_us", data);
    socket.off("contact_us");
    socket.on("contact_us", (res) => {
      SetName("");
      SetEmail("");
      SetNumber("");
      SetMessage("");
      setContactIsLoading(false);
      Store.addNotification({
        title: res.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
      SetIsRecaptcha(false)

    });
  };

  // Popover
  const popoverRight = (
    <Popover
      id="popover-positioned-right"
      className="setpop popover_set company_popover"
    >
      <div className="gray_culturetipnewcss shadow">
        <div className="mt-2">
          <h5>
            <b>Get in Touch with Support</b>
          </h5>
          <hr className="m-0 mt-1"></hr>
          <div className="mt-2">
            This page allows you, to connect with the platform's support team
            for any inquiries or assistance you may need.
          </div>
          <div className="mt-2">
            <b>Here's what you can do:</b>
          </div>
          <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
            <Accordion.Item eventKey="0" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Provide your contact information:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>- Enter your full name.</div>
                  <div className="mt-2">
                    - Enter your valid email address for them to respond.
                  </div>
                  <div className="mt-2">
                    - Include your phone number if you'd like them to reach out
                    by phone (optional).
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Compose your message:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    - Clearly describe your inquiry or issue in the message box.
                  </div>
                  <div className="mt-2">
                    - Be specific and provide any relevant details that might
                    help the support team understand your situation and resolve
                    it efficiently.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="mt-3 mb-3 text-lg-justify">
            <b>Additional Tips:</b>
          </div>
          <div>
            - Review the platform's Help Center or FAQs section before
            contacting them, as you might find answers to commonly asked
            questions there.
          </div>
          <div className="mt-2">
            - Be clear, concise, and polite in your message.
          </div>
          <div className="mt-2">
            - If requesting assistance with a specific job posting or
            application, include relevant details like the job title or
            applicant name for faster resolution.
          </div>
        </div>
      </div>
    </Popover>
  );

  return (
    <>
      <div className="user-profile">
        {isAuthenticated && AuthData.role == "user" && (
          <Row>
            <Col md={6} className="p-0 mb-2 mt-1">
              <h4>Contact Us</h4>
            </Col>
           
          </Row>
        )}
        {isAuthenticated && AuthData.role == "employer" && (
          <Row>
            <Col md={6} className="p-0 mb-2 mt-1">
              <h4>Contact Us</h4>
            </Col>
           
          </Row>
        )}
        {!isAuthenticated && (
          <Row>
            <Col md={6} className="p-0 mb-2 mt-1">
              <h4>Contact Us</h4>
            </Col>
            
          </Row>
        )}
        <div className="addnewcontactusback">
          <Row>
            <Col lg={12} className="p-0">
              <div className="contactuselogocenter">
                <img
                  src={turntologo}
                  alt="turn-to"
                  className="contactsuslogoimg"
                />
              </div>
              <div>
                <OverlayTrigger
                  trigger="click"
                  placement="auto"
                  overlay={popoverRight}
                  rootClose={true}
                  show={countactusetips}
                >
                  <span
                    onClick={()=> setCountactusetips(!countactusetips)}
                    className="newdashbordbuttoncolornew contactustipsbuttonset"
                  >
                    {countactusetips ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                  </span>
                </OverlayTrigger>
              </div>
            </Col>
            <Col lg={12}>
              <Form.Group className="mb-3" controlId="Name">
                <Form.Label>Name: </Form.Label>
                <Form.Control
                  type="text"
                  className="newsearchinputaddborderradius"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => SetName(e.target.value)}
                />
                <span className="err_msg"> {nameError} </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="Name">
                <Form.Label>Email: </Form.Label>
                <Form.Control
                  type="email"
                  className="newsearchinputaddborderradius"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => SetEmail(e.target.value)}
                />
                <span className="err_msg"> {emailError} </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="Name">
                <Form.Label>Mobile Number: </Form.Label>
                <Form.Control
                  type="number"
                  className="newsearchinputaddborderradius"
                  placeholder="Enter Mobile Number"
                  value={number}
                  onChange={(e) => SetNumber(e.target.value)}
                />
                <span className="err_msg"> {numberError} </span>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  className="newsearchinputaddborderradius"
                  rows={3}
                  value={message}
                  onChange={(e) => SetMessage(e.target.value)}
                />
                <span className="err_msg"> {messageError} </span>
              </Form.Group>
            </Col>
          </Row>
          {!isAuthenticated && (
            
          <Col xs={12} className="">
            <ReCAPTCHA
            className="justify-content-center mb-1 d-flex"
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={handleRecaptchaChange}
            />
          </Col>
          )}

          <Col lg={12}>
            <div
              className="d-flex justify-content-center mb-3"
              onClick={(e) => SubmitEvent(e)}
            >
              <Tooltip content="Send Message">
                <MyButton>
                  {iscontactLoading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      className="spinneerset"
                    >
                      <span className="visually-hidden"></span>
                    </Spinner>
                  ) : (
                    "Send Message"
                  )}
                </MyButton>
              </Tooltip>
            </div>
          </Col>
        </div>
      </div>
    </>
  );
};

export default NewContactUs;
