import Reaact from "react";
import "./NewAddPage.css";
import { Accordion} from "react-bootstrap";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";
import footerpagepng from "../../Component/icontopng/footerpagepng.png";

const EmployerProduct = () => {
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  return (
    <>
      <div>
        <HeaderNew />
        <div className="container new_pagesmargin" onClick={RemoveSlider}>
          <h3 className="text-center">
            <b>Turn-To: Your Partner in Hiring</b>
          </h3>
          <hr></hr>

          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Empower Your Recruitment with Turn-To's Innovative Job Map</b>
            </h4>
            <div className="text-justify">
              At Turn-To, we're revolutionizing the way you find talent. Our
              dynamic Job Map search engine is not just an interface; it's a
              window to a vast pool of candidates. Whether you're a small
              business or a large enterprise, Turn-To equips you with a
              cutting-edge platform that blends traditional job posting with the
              latest AI technology, ensuring you reach candidates who are not
              just looking for a job, but for a role where they can truly excel.
            </div>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Post with Ease, Pay as You Grow</b>
            </h4>
            <div className="text-justify">
              Turn-To understands the diverse needs of employers, which is why
              our platform allows you to post jobs one at a time or in bulk. Set
              individual budgets for each position and only pay for the clicks
              and applications that your job listing receives. Our Pay-As-You-Go
              model provides the flexibility you need to manage your hiring
              costs effectively.
            </div>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Seamless Integration for Maximum Exposure</b>
            </h4>
            <div className="text-justify">
              Your jobs won't just live on Turn-To; they'll thrive. Each job
              posted on our platform is seamlessly integrated into your
              dashboard for insightful analytics and also gets prominent
              exposure through our external Job Map, accessible to all
              candidates. What's more, with an easy-to-embed iFrame option, you
              can make these listings a natural extension of your company's
              career page.
            </div>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>One Wallet System for Total Control</b>
            </h4>
            <div className="text-justify">
              Manage your hiring finances with ease using Turn-To's One Wallet
              System. Top up your wallet and allocate funds to job postings as
              needed, all within a single, user-friendly interface. No surprise
              charges, no complicated billing—just simple, transparent
              accounting that puts you in charge.
            </div>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Eco Routes - Our Commitment to Sustainable Hiring</b>
            </h4>
            <div className="text-justify">
              We believe hiring can be eco-friendly too. With Eco Routes,
              Turn-To promotes environmentally conscious job posting. When
              candidates view your job, they'll not only see essential details
              like salary and company information but also the ecological impact
              of their potential commute, empowering them to make informed
              decisions that align with their values.
            </div>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Future-Proof Your Hiring with AI-Powered Matchmaking</b>
            </h4>
            <div className="text-justify">
              Although still on the horizon, Turn-To is committed to integrating
              AI across our platform, offering unmatched precision in matching
              employers with candidates. Our vision is to harness the power of
              AI to evaluate company culture fit, creating a symbiotic
              relationship between your needs and the aspirations of job
              seekers.
            </div>
          </div>
          <div className="mt-2">
            <b>Innovative Hiring Products at Your Fingertips:</b>
          </div>
          <Accordion
            defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7", "8"]}
            alwaysOpen
          >
            <Accordion.Item eventKey="0" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Job Map Search Engine:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Your jobs come to life on a vibrant, interactive map,
                    offering candidates a unique way to connect with your
                    company.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Pay-As-You-Go:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    No subscriptions; just straightforward, cost-effective
                    hiring.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Bulk Job Upload:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Effortlessly transfer your career page listings onto our
                    platform.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Wallet System:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Maintain complete control over your recruitment spend.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Eco Routes:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Showcase your commitment to sustainability in hiring.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                AI Talent Matching:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Align candidates' skills and culture fit with precision.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <div className="mt-2">
              <b>Turn-To's Edge for Candidates:</b>
            </div>
            <Accordion.Item eventKey="6" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                My Saved Jobs Map:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>Personalize your job search with saved listings.</div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                AI-Assisted Search:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Engage with our responsive AI for a tailored job-hunting
                    experience.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                ATS Resume Builder:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Quick, efficient, and ready to get you applying in no time.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Join Turn-To's Hiring Revolution</b>
            </h4>
            <div className="text-justify">
              Are you ready to take the leap into future-forward hiring? With
              Turn-To, you're not just posting jobs; you're inviting talent into
              a journey that's beneficial for both your company and the
              environment. Step into the world of smart hiring—where technology
              meets human aspiration. Connect with Turn-To and let's build the
              future of work, together.
            </div>
          </div>
        </div>
        <div>
          <LandingPageFooter />
        </div>
      </div>
    </>
  );
};

export default EmployerProduct;
