import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "react-autocomplete";
import { Accordion, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import downlordimg from "../../../../../Component/icontopng/hoverdownload.png";
import deletecarddatapng from "../../../../../Component/icontopng/deletecarddata.png";
import editdatapng from "../../../../../Component/icontopng/editdata.png";
import { MdOutlineClose } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";

import {
  Certificate,
  DegreeApi,
  DocumentDelete,
  GetDoumentList,
  Licence,
  UploadDocument,
  postUpdateDocument,
} from "../../../../../Api/Api";
import "./DocumentUpload.css";
import Avatar from "react-avatar";
import MyButton from "../../common/Button/MyButton";
import { Store } from "react-notifications-component";
import addcarddata from "../../../../../Component/icontopng/addcarddata.png";
import footerpagepng from "../../../../../Component/icontopng/footerpagepng.png";
import Tooltip from "react-tooltip-lite";

const DocumentUpload = (props) => {
  const [titleInput, setTitleInput] = useState(useRef(""));
  const [selectDocument, setSelectDocument] = useState("");
  const [DocumentError, setDocumentError] = useState("");
  const [documentLink,setDocumentLink] = useState("")


  const [selectDocumentType, setSelectDocumentType] = useState("Certificate");
  const [selectError, setSelectError] = useState("");

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [expmonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [documentTitleApi, setDocumentTitleApi] = useState([]);
  const [documentListApi, setDocumentListApi] = useState([]);
  const [errorDocumentType, setErrorDocumentType] = useState("");
  const [isDataLording, setIsDataLording] = useState(false);
  const formData = new FormData();
  const [checkdocument, setCheckDocument] = useState(false);
  const [errendmonth, seterrEndmonth] = useState("");
  const [errendyear, seterrEndyear] = useState("");
  const [Id, setId] = useState(null);

  const [documnetuploadModalShow, setdocumnetuploadModalShow] = useState(false);

  // uploadmodel close
  const isUploadModalClose = () => {
    setIsUploadModalOpen(false);
    setSelectDocument("");
    setDocumentLink("")
    setExpYear("");
    setExpMonth("");
    Selectclear()
    setErrorDocumentType("")
    setCheckDocument(false);
    setDocumentLink("")
    setdocumnetuploadModalShow(false);
    setId(null);
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
  };

  // getdocumentlist
  const GetDocumentList = () => {
    GetDoumentList({profile_id: props.profileId})
      .then((response) => {
        if (response.data.status) {
          setDocumentListApi(response.data.data);
          props.setCertificateCount(response.data.data)
        }
      })
      .catch((error) => {
      });
  };
  useEffect(() => {
    if(props.profileId != "") {
      GetDocumentList();
    }
  }, [props.profileId]);

  const month = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const OnFouceOut = () => {
    if (titleInput?.current) {
      titleInput?.current.blur();
    }
  };

  const Selectclear = () => {
    setDocumentError("");
    setSelectError("");
    seterrEndyear("");
    seterrEndmonth("");

  };

  // validation
  const Selectvalidation = () => {
    const d = new Date();
    Selectclear();
    var isvalid = true;
    if (selectDocument.trim() == "") {
      setSelectError("Please Select Document");
      isvalid = false;
    } else if (selectDocument.length > 100) {
      setSelectError("Document should not exceed 100 characters");
      isvalid = false;
    }
    if (!acceptedFiles[0] && Id == null) {
      setDocumentError("Please Select File");
      isvalid = false;
    } else if (acceptedFiles[0]?.size > 2 * 1024 * 1024) {
      setDocumentError("File size exceeds 2 MB limit");
      isvalid = false;
    }

    if (selectDocumentType !== "Certificate"){
      if (expmonth == "") {
        seterrEndmonth("Please Select End Month");
        isvalid = false;
      }
      if (expYear === "") {
        seterrEndyear("Please Select End Year");
        isvalid = false;
      }
    }

    return isvalid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!Selectvalidation()) {
      return false;
    }

    if (Id) {
      updateDocument();
    } else {
      UploadApi();
    }
  };

  // for upload
  const UploadApi = () => {
    setIsDataLording(true);
    formData.append("profile_id", props.profileId);
    formData.append("document_type", selectDocumentType);
    formData.append("document_title", selectDocument);
    formData.append("document", acceptedFiles[0]);
    if(selectDocumentType !== "Certificate"){
      formData.append("expiry_year", expYear);
      formData.append("expiry_month", expmonth);
    }

    UploadDocument(formData, {
      headers: {
        "Content-Type": "multipart/form-data", 
      },
    })
      .then((response) => {
        setIsDataLording(false);
        if (response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          isUploadModalClose();
          setSelectDocument("");
          setSelectDocumentType("Certificate");
          setExpYear("");
          setExpMonth("");
          GetDocumentList();
        }
        setIsUploadModalOpen(false);
      })
      .catch((error) => {
      });
  };

  // update document
  const updateDocument = () => {
    setIsDataLording(true);
    formData.append("profile_id", props.profileId);
    formData.append("document_type", selectDocumentType);
    formData.append("document_title", selectDocument);
    formData.append("document", acceptedFiles[0]);
    if(selectDocumentType !== "Certificate"){
      formData.append("expiry_year", expYear);
      formData.append("expiry_month", expmonth);
    }
    formData.append("_id", Id);

    postUpdateDocument(formData, {
      headers: {
        "Content-Type": "multipart/form-data", 
      },
    })
      .then((response) => {
        setId(null);
        setIsDataLording(false);
        if (response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          setSelectDocument("");
          setSelectDocumentType("Certificate");
          setExpYear("");
          setExpMonth("");
          isUploadModalClose();
          GetDocumentList();
        }
        setIsUploadModalOpen(false);
      })
      .catch((error) => {
      });
  };

  // edit api
  const EditApi = (id) => {
    var editdata = documentListApi.find((e) => e._id == id);
    setIsUploadModalOpen(true);
    setSelectDocumentType(editdata.document_type);
    setSelectDocument(editdata.document_title);
    setExpYear(editdata.expiry_year);
    setExpMonth(editdata.expiry_month);
    setId(editdata._id);
    setDocumentLink(editdata.document)

  };


  //

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path} className="file_decoration mt-3">
      {file.path}
    </li>
  ));

  //LicenceApi
  const LicenceApi = () => {
    Licence()
      .then((response) => {
        setDocumentTitleApi(response.data.data);
      })
      .catch((error) => {
      });
  };

  // CertificateApi
  const CertificateApi = () => {
    Certificate()
      .then((response) => {
        setDocumentTitleApi(response.data.data);
      })
      .catch((error) => {
      });
  };

  // DegreeData
  const DegreeData = () => {
    DegreeApi()
      .then((response) => {
        setDocumentTitleApi(response.data.data);
      })
      .catch((error) => {
      });
  };
  useEffect(() => {
    if (selectDocumentType == "License") {
      LicenceApi();
    }
    if (selectDocumentType == "Certificate") {
      CertificateApi();
    }
    if (selectDocumentType == "Degree") {
      DegreeData();
    }
  }, [selectDocumentType]);

  // DocumentAddCheck
  const DocumentAddCheck = () => {
    setId(null);
    setErrorDocumentType("");
    setDocumentLink("")
    setCheckDocument(false);
    if (selectDocumentType == "") {
      setCheckDocument(true);
      setErrorDocumentType("Please Select Document Types");
      return false;
    }
    setIsUploadModalOpen(true);
  };

  const documentUploadModal = () => {
    setdocumnetuploadModalShow(!documnetuploadModalShow);
  };

  useEffect(()=>{
    window.addEventListener("scroll", OnFouceOut);
    return () => {
      window.removeEventListener("scroll", OnFouceOut);
    };
  },[])
  return (
    <>
      {documentListApi.map((e) => {
        return (
          <Col xs={12} className="p-0">
            <CardDocument
              profileId={props.profileId}
              datacard={e}
              GetDocumentList={GetDocumentList}
              EditApi={EditApi}
              setIsUploadModalOpen={setIsUploadModalOpen}
              formData={formData}
              setSelectDocument={setSelectDocument}
              setExpMonth={setExpMonth}
              setExpYear={setExpYear}
              setSelectDocumentType={setSelectDocumentType}
            />
          </Col>
        );
      })}

      <Row className="align-items-end">
      {documentListApi.length < 2 ?
        <>
        <Col md={10} className="p-0">
          <Form.Group controlId="startmonth">
            <Form.Label className="mt-1 label_class">
              Select Certificate Type
            </Form.Label>
            <Form.Control
              as="select"
              name="startmonth"
              className={
                checkdocument
                  ? "borderdrop "
                  : "borderdrop  input_radius"
              }
              value={selectDocumentType}
              onChange={(e) => setSelectDocumentType(e.target.value)}
            >
              {[
                { name: "Certificate" },
                { name: "License" },
              ].map((e) => (
                <option className="" value={e.name}>
                  {e.name}{" "}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className="text-right p-0" md={2}>
        <Row className="align-items-end justify-content-end">
       
          <Tooltip content="Add">
            <Col className="text-right p-0" xs={12}>
            <button
            className="my-btn doc_margin"
            onClick={(e) => DocumentAddCheck()}
            >
            <img src={addcarddata} alt="add" className="notsaveiocn" />
            Add
          </button>
            </Col>
          </Tooltip>
         
      </Row>
        
        </Col>
        <span className="text-danger ml-3">{errorDocumentType}</span>
        </>
        : " " }
      </Row>

      <Modal
        show={isUploadModalOpen}
        onHide={isUploadModalClose}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        centered
      >
        <Modal.Body className="p-0">
          <Row className="justify-content-center">
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
                <Col lg={12}>
                  <Row className="bg-white userdatapart border-radiustip">
                  <div className="col-12 mt-3 tipsbuttonset tipsbuttonsets">
                      <div className="index_newcss">
                    <div className="contectinfoshowname mb-2">
                      <div>{selectDocumentType}s</div>
                    
                    </div>
                    <div className="mb-2">
                    <span
                        onClick={documentUploadModal}
                        className="newdashbordbuttoncolor atstipsbuttonset"
                      >
                        {documnetuploadModalShow ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                      </span>
                    </div>
                    
                    </div>
                    </div>
                    
                    <Col md={12} className="mb-2">
                      <Form.Group className="mt-2">
                        <Form.Label className="label_class">
                          <b>
                           Enter certificates, licenses, and training in your
                            field.
                          </b>
                        </Form.Label>
                        <div className="jobcategory">
                          <Autocomplete
                            // ref={titleInput}
                            wrapperProps={{ className: "auto-input addnewcssautocompletedataset" }}
                            wrapperStyle={{
                              display: "block",
                              // zIndex: "1",
                              position:"relative"
                            }}
                            getItemValue={(item) => item.label}
                            items={documentTitleApi
                              .filter((ele) =>
                                ele.name
                                  .toLowerCase()
                                  .includes(selectDocument.toLowerCase())
                              )
                              .map((ele) => {
                                return { label: ele.name };
                              })}
                            renderItem={(item, isHighlighted) => (
                              <div
                                style={{
                                  background: isHighlighted ? "lightgray" : "white",
                                }}
                              >
                                {item.label}
                              </div>
                            )}
                            value={selectDocument}
                            onChange={(e) => setSelectDocument(e.target.value)}
                            onSelect={(e) => setSelectDocument(e)}
                            inputProps={{
                              placeholder:
                                "Enter certificates, licenses, and training name",
                            }}
                          />
                        </div>
                        <span className="err_msg">{selectError}</span>
                      </Form.Group>
                    </Col>
                    {selectDocumentType == "Certificate" ? "" : 
                     <>
                    <Col md={6} className="mb-2">
                      <Form.Group controlId="startmonth">
                        <Form.Label>Expiry Month</Form.Label>
                        <Form.Control
                          as="select"
                          name="startMonth"
                          className="borderdrop"
                          value={expmonth}
                          onChange={(e) => setExpMonth(e.target.value)}
                        >
                          <option value="">Select</option>
                          {Object.keys(month).map((option, index) => (
                            <option key={option} value={index + 1}>
                              {month[option]}
                            </option>
                          ))}
                        </Form.Control>
                        <span className="err_msg">{errendmonth}</span>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Form.Group controlId="startmonth">
                        <Form.Label>Expiry Year</Form.Label>
                        <Form.Control
                          as="select"
                          name="startMonth"
                          className="borderdrop"
                          value={expYear}
                          onChange={(e) => setExpYear(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option>2025</option>
                          <option>2026</option>
                          <option>2027</option>
                        </Form.Control>
                        <span className="err_msg">{errendyear}</span>
                      </Form.Group>
                    </Col>
                    </>

                  }
                    <Col md={12}>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="text-center border_dashed">
                          Upload {selectDocumentType} <br></br>
                          <br></br>
                          <span className="upload_photo"> .doc,.docx,.pdf</span>
                        </div>
                      </div>
                      <aside>
                      {files == "" ?
                      documentLink == "" ? "":
                        <ul> <a target="#" href={documentLink}> • Uploaded File</a></ul>:
                        <ul>{files}</ul>
                      }
                      </aside>
                      <span className="err_msg">{DocumentError}</span>
                    </Col>
                    <Col>
                      <div className="setmodalfooterbutton">
                        <button
                          className="btnclose text-white mr-2"
                          onClick={isUploadModalClose}
                        >
                          Close
                        </button>
                        {isDataLording ? (
                          <button className="btnjob text-white">
                            <Spinner
                              animation="border"
                              role="status"
                              className="spinneerset"
                            >
                              <span className="visually-hidden"></span>
                            </Spinner>
                          </button>
                        ) : (
                          <button
                            className="btnjob text-white"
                            onClick={handleSubmit}
                          >
                            Upload
                          </button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div className="position-relative">
                {documnetuploadModalShow ? (
                  <Col
                    lg={12}
                    className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips"
                  >
                    <div className="info_tip5 documnetuploadscrollheight border-radiustip">

                      <div className="mt-2">
                        <div>
                          In the dynamic landscape of job searching on Turn-To,
                          showcasing your professional certifications prominently
                          can significantly elevate your profile. Certifications not
                          only underline your expertise in specific areas but also
                          demonstrate a commitment to your professional development.
                          Here's how you can effectively list your certifications on
                          Turn-To:
                        </div>
                        <div className="mt-2">
                          <b>
                            Maximizing the Impact of Certifications on Your Turn-To
                            Profile
                          </b>
                        </div>
                        <div className="mt-2">
                          <b>Highlighting Key Certifications:</b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Prioritize Essential Certifications:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Start with certifications that are directly
                                  relevant to your job search or required for the
                                  positions you're interested in. Position these at
                                  the top of your list to catch the attention of
                                  employers immediately.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Detail Each Certification:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  For each listed certification, include the
                                  certification name, the organization that issued
                                  it, and the date you received it. This concise
                                  format ensures that employers can quickly gauge
                                  the validity and relevance of your certifications.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Showcase Your Continuous Learning:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  If you have completed notable online courses or
                                  workshops that didn’t result in a certification
                                  but are relevant to your field, don’t hesitate to
                                  include them. Mention the course name and the
                                  providing institution to demonstrate your ongoing
                                  efforts to enhance your skill set.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-2">
                          <b>Strategically Positioning Certifications:</b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Integration Across Your Profile:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  While your certifications will have a dedicated
                                  section, integrating them into your resume summary
                                  and work experience can reinforce your
                                  qualifications. Highlight how specific
                                  certifications have contributed to your success in
                                  past roles.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Limit Non-Essential Certifications:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  While it's tempting to list all your achievements,
                                  focusing on the most impactful certifications will
                                  maintain the clarity and effectiveness of your
                                  profile. Extra or hobby-related certifications can
                                  be included further down the list to provide a
                                  well-rounded view of your interests and abilities
                                  without overshadowing the essentials.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-2">
                          <b>Example Certification Layout for Turn-To</b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Certified Project Management Professional (PMP),
                              Project Management Institute, May 2021:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Utilized project management best practices to lead
                                  a cross-functional team in launching a successful
                                  product.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Google Analytics Certified Professional, Google, March
                              2020
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Leveraged analytics insights to drive a 20%
                                  increase in website traffic for a key project.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Introduction to Data Science Course, Coursera,
                              December 2019
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Enhanced proficiency in data analysis and
                                  visualization techniques.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              <b> Why It Matters</b>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Incorporating certifications into your Turn-To
                                  profile is more than a credential display; it's
                                  about telling the story of your professional
                                  journey and aspirations. By thoughtfully selecting
                                  and positioning your certifications, you provide
                                  potential employers with a snapshot of your
                                  capabilities and dedication to excellence in your
                                  field.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-2">
                          <b>
                            Adapting this strategy on Turn-To will ensure your
                            certifications serve as powerful endorsements of your
                            skills, making you a more attractive candidate to
                            prospective employers.
                          </b>
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </div>

            </div>

          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentUpload;

function CardDocument(props) {

  const month = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  // DeleteApi
  const DeleteApi = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true

    }).then((result) => {
      if (result.value) {
        var data = {
          document_id: id,
          profile_id: props.profileId
        };
        DocumentDelete(data)
          .then((response) => {
            props.GetDocumentList();
            Store.addNotification({
              title: response.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__zoomIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false,
              },
            });
          })
          .catch((error) => {
            // Handle errors
          });
      }
    });
  };
  return (
    <div className="menu-card position-relative">
      <div className="stretch-card  my-2 p-0 " style={{ minHeight: "120px" }}>
        <div className="card shadow-lg exp_margin">
          <div className="card-body exp_padding">
            <div className="d-flex justify-content-between h-100">
              <div className="left-div d-block d-md-flex">
                <div className="mr-0 mr-md-3">
                  <p>
                    <Avatar
                      size="50"
                      round="8px"
                      name={props.datacard.document_title}
                    />
                  </p>
                </div>
                <div>
                  <h5>
                    <b>{props.datacard.document_title}</b>
                  </h5>
                  {props.datacard.document_type !== "Certificate" 
                  ?
                  <div>
                    Expired :- {month[props.datacard.expiry_month]},{" "}
                    {props.datacard.expiry_year}
                  </div>
                  :""}
                </div>
              </div>
              <div className="right-div d-flex flex-column">
              <Tooltip content="Edit">
                <MyButton
                  className="p-1 mb-1"
                  onClick={(e) => props.EditApi(props.datacard._id)}
                >
                  <img
                    src={editdatapng}
                    alt="edit"
                    className="closepngiocnwidth"
                  />
                </MyButton>
                </Tooltip>


                <Tooltip content="Download">
                <MyButton
                  className="p-1 "
                  onClick={(e) => window.open(props.datacard.document)}
                >
                  <img
                    src={downlordimg}
                    alt="download"
                    className="closepngiocnwidth"
                  />
                </MyButton>
                </Tooltip>

              <Tooltip content="Delete">
                <MyButton
                  className="p-1 mt-1 deletebtn"
                  onClick={(e) => DeleteApi(props.datacard._id)}
                >
                  <img
                    src={deletecarddatapng}
                    alt="delete"
                    className="closepngiocnwidth"
                    set="bold"
                  />
                </MyButton>
                </Tooltip>

             

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
