import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import { useAuth } from "../../context/AuthProvider";
import Avatar from "react-avatar";
import "./sidebar.css";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import sidebarLogo from "../../assets/images/loginpagenewlogo.png";
import sidebarmenu from "../../Component/icontopng/sidebarmenu.png";
import sidebarEmployerLogo from '../../assets/images/TURN-TO (1).png';
import sidebarUserLogo from '../../assets/images/TURN-TO (2).png';


const Navbar = () => {
  const { AuthData, setAuthData, isAuthenticated ,setProfileScores} = useAuth();
  var history = useHistory();

  const toggleOffcanvasOld = () => {
    // console.log("nav");
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };
  const toggleRightSidebar = () => {
    document.querySelector(".right-sidebar").classList.toggle("open");
  };
  const LogoutFun = (event) => {
    event.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem("token");
        setAuthData(null);
        setProfileScores({})

        history.push("/jobmap");
      }
    });
  };
  const toggleOffcanvas = () => {
    document.body.classList.remove("sidebar-icon-only");
    var element = document.querySelector(".hover-open");
    if (element) {
      element.classList.remove("hover-open");
    }
  };
  const toggleremoeOffcanvas = () => {
    document.body.classList.add("sidebar-icon-only");
  };
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row  displayclass">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center remove_color" onMouseOver={(e) =>
          window.innerWidth > 991 ? toggleOffcanvas() : null
        }
        onMouseLeave={(e) =>
          window.innerWidth > 991 ? toggleremoeOffcanvas() : null
        } >
        <div className="navbar-brand brand-logo">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            {/* <span className="mdi mdi-menu"></span> */}
            {/* {!isAuthenticated && (
              <img src={sidebarmenu} className="sidebarmenuset" alt="menu"></img>
            )}
            {isAuthenticated && AuthData.role == "user" && (
                  AuthData.info.profile_image == "" ? (
                           <Avatar
                             size="30"
                             round="50px"
                             name={
                               AuthData?.first_name + " " + AuthData?.last_name
                             }
                           />
                         ) : (
                           <img
                             src={AuthData.info.profile_image}
                             alt="user"
                             className="sidebarmenuset usersidebarclickimg p-0"
                           />
                         )
            )}
              {isAuthenticated && AuthData.role == "employer" && (
                    AuthData.info.company_image == "" ? (
                        <Avatar
                          size="30"
                          round="50px"
                          name={AuthData.info.company_name}
                        />
                      ) : (
                        <img
                          src={AuthData.info.company_image}
                          alt="employer"
                          className="sidebarmenuset setprofilesidebarimg p-0"
                        />
                      )
            )} */}
            {!isAuthenticated && (
               <img
               src={sidebarLogo}
               className="width_set_logo"
               alt="Navbar Logo"
             ></img>)}
            {isAuthenticated && AuthData.role == "user" && (
            <img
              src={sidebarUserLogo}
              className="width_set_logo"
              alt="Navbar Logo"
            ></img>)}
            {isAuthenticated && AuthData.role == "employer" && (
            <img
              src={sidebarEmployerLogo}
              className="width_set_logo"
              alt="Navbar Logo"
            ></img>)}
          </button>
        </div>
        <div className="navbar-brand brand-logo-mini"   >
          <button
            className="navbar-toggler navbar-toggler align-self-center show_button"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            {/* <span className="mdi mdi-menu"></span> */}
            {!isAuthenticated && (
              <img src={sidebarmenu} className="sidebarmenuset" alt="menu"></img>
            )}
            {isAuthenticated && AuthData.role == "user" && (
             <div>
               {AuthData.info.profile_image == "" ? (
                        <Avatar
                          size="30"
                          round="50px"
                          name={
                            AuthData?.first_name + " " + AuthData?.last_name
                          }
                        />
                      ) : (
                        <img
                          src={AuthData.info.profile_image}
                          alt="user"
                          className="userprofileimgsidbarset usersidebarclickimg"
                        />
                      )}
             </div>
            )}
            {isAuthenticated && AuthData.role == "employer" && (
              <div className="">
                    {AuthData.info.company_image == "" ? (
                        <Avatar
                          size="30"
                          round="50px"
                          name={AuthData.info.company_name}
                        />
                      ) : (
                        <img
                          src={AuthData.info.company_image}
                          alt="employer"
                          className="userprofileimgsidbarset setprofilesidebarimg"
                        />
                      )}
              </div>
            )}
          </button>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        {/* <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
          <span className="mdi mdi-menu"></span>
        </button> */}
        {/* <ul className="navbar-nav navbar-nav-right ">
          {!isAuthenticated && ( */}
        <>
          {/* <li className="nav-item">
                <Link to={"/user-login"} className="nav-link">
                  <span className="give_border1 shadow">User Login</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/employer-login"} className="nav-link">
                  <span className="give_border1 shadow">Employer Login</span>
                </Link>
              </li> */}
        </>
        {/* )} */}
        {/* {(isAuthenticated && AuthData.role == 'user') && (
            <>
              <li className="nav-item nav-profile">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link ">
                    <div className=" give_border shadow d-flex ">
                      <div className="nav-profile-img">
                        <Link to="/profile">
                          {AuthData.info.profile_image == "" ? <Avatar size="30" round="50px" name={AuthData?.first_name + " " + AuthData?.last_name} /> : <img src={AuthData.info.profile_image} alt="user" />}

                        </Link>
                        <span className="availability-status online"></span>
                      </div>
                      <div className="nav-profile-text set_center">
                        <Link to="/profile">
                          <p className="mb-1 text-black"><Trans>{AuthData?.first_name + " " + AuthData?.last_name}</Trans></p>
                        </Link>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                </Dropdown>
              </li>
              <li className="nav-item">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link count-indicator">
                    <i className="mdi mdi-bell-outline logout_background shadow"></i>
                    <span className="count-symbol bg-danger"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                    <h6 className="p-3 mb-0"><Trans>Notifications</Trans></h6>
                    <div className="dropdown-divider"></div>
                    <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-success">
                          <i className="mdi mdi-calendar"></i>
                        </div>
                      </div>
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject font-weight-normal mb-1"><Trans>Event today</Trans></h6>
                        <p className="text-gray ellipsis mb-0">
                          <Trans>Just a reminder that you have an event today</Trans>
                        </p>
                      </div>
                    </Dropdown.Item>
                    <div className="dropdown-divider"></div>
                    <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-warning">
                          <i className="mdi mdi-settings"></i>
                        </div>
                      </div>
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject font-weight-normal mb-1"><Trans>Settings</Trans></h6>
                        <p className="text-gray ellipsis mb-0">
                          <Trans>Update dashboard</Trans>
                        </p>
                      </div>
                    </Dropdown.Item>
                    <div className="dropdown-divider"></div>
                    <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-info">
                          <i className="mdi mdi-link-variant"></i>
                        </div>
                      </div>
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject font-weight-normal mb-1"><Trans>Launch Admin</Trans></h6>
                        <p className="text-gray ellipsis mb-0">
                          <Trans>New admin wow</Trans>!
                        </p>
                      </div>
                    </Dropdown.Item>
                    <div className="dropdown-divider"></div>
                    <h6 className="p-3 mb-0 text-center cursor-pointer"><Trans>See all notifications</Trans></h6>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </>
          )} */}
        {/* {(isAuthenticated && AuthData.role == 'employer') && (
            <>
              <li className="nav-item nav-profile">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link">
                    <div className=" give_border shadow d-flex ">
                      <div className="nav-profile-img">
                        <Link to="/employer/dashboard">
                          {AuthData.info.company_image == "" ? <Avatar size="30" round="50px" name={AuthData.info.company_name} /> :
                            <img src={AuthData.info.company_image} alt="user" />}
                        </Link>
                        <span className="availability-status online"></span>
                      </div>
                      <div className="nav-profile-text set_center">
                        <Link to="/employer/dashboard">
                          <p className="mb-1 text-black "><Trans>{AuthData.info.company_name}</Trans></p>
                        </Link>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                </Dropdown>
              </li>
            </>
          )} */}
        {/* {isAuthenticated && (
            <li className="nav-item nav-logout d-none d-lg-block">
              <a className="nav-link" onClick={LogoutFun}>
                <i className="mdi mdi-power logout_background shadow"></i>
              </a>
            </li>
          )} */}
        {/* </ul> */}
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvasOld}
        >
          {/* <span className="mdi mdi-menu"></span> */}
          {/* {!isAuthenticated && ( */}
           <img src={sidebarmenu} className="sidebarmenuset" alt="menu"></img>
          {/* )} */}
             {/* {isAuthenticated && AuthData.role == "user" && (
                  AuthData.info.profile_image == "" ? (
                           <Avatar
                             size="25"
                             round="50px"
                             name={
                               AuthData?.first_name + " " + AuthData?.last_name
                             }
                           />
                         ) : (
                           <img
                             src={AuthData.info.profile_image}
                             alt="user"
                             className="opensidebariconset usersidebarclickimg p-0"
                           />
                         )
            )}
              {isAuthenticated && AuthData.role == "employer" && (
                    AuthData.info.company_image == "" ? (
                        <Avatar
                          size="25"
                          round="50px"
                          name={AuthData.info.company_name}
                        />
                      ) : (
                        <img
                          src={AuthData.info.company_image}
                          alt="employer"
                          className="opensidebariconset setprofilesidebarimg p-0"
                        />
                      )
            )} */}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
