import Reaact from "react";
import "./NewAddPage.css";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";

const CandidateProduct = () => {
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  return (
    <>
      <div>
        <HeaderNew />
        {/* first section */}
        <div className="container new_pagesmargin" onClick={RemoveSlider}>
          <h3 className="text-center">
            <b>Turn-To: Empowering Your Job Search</b>
          </h3>
          <hr></hr>

          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Discover Jobs That Align with Your Ambitions</b>
            </h4>
            <div className="text-justify">
              Turn-To is not just a job platform; it’s a journey to your dream
              career. Explore our interactive job map to find opportunities that
              resonate with your career goals and personal values.
            </div>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Your Personalized Job Map: My Saved Jobs</b>
            </h4>
            <div className="text-justify">
              Organize your job search with "My Saved Jobs Map." Bookmark
              positions, track applications, and plan your route with Eco
              Routes, giving you insights into the commute and its environmental
              impact.
            </div>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Optimize Your Resume with Turn-To’s ATS Builder</b>
            </h4>
            <div className="text-justify">
              Our ATS Resume Builder is your first step toward getting noticed.
              Craft a resume that stands out to Applicant Tracking Systems,
              increasing your chances of landing an interview.
            </div>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>All the Tools You Need, at No Cost</b>
            </h4>
            <div className="text-justify">
              Turn-To is committed to providing a comprehensive job search
              experience free of charge for all candidates. From job discovery
              to application, our platform supports you every step of the way.
            </div>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Experience the AI Difference in Job Hunting</b>
            </h4>
            <div className="text-justify">
              Engage with our AI-driven search functionality, designed to
              understand more than just keywords. Our search bar is your
              conversational partner, leading you to job opportunities that
              align with your skills, experience, and aspirations.
            </div>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Ready for a New Chapter?</b>
            </h4>
            <div className="text-justify">
              Join the Turn-To community and take control of your job search
              with our innovative tools. Your next opportunity is just a click
              away.
            </div>
          </div>
        </div>
        <div>
          <LandingPageFooter />
        </div>
      </div>
    </>
  );
};

export default CandidateProduct;
