import Reaact, { useState } from "react";
import "./NewAddPage.css";
import { Accordion, Col, Modal, Nav, Navbar, Row } from "react-bootstrap";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import { FaRegSave } from "react-icons/fa";
import HeaderNew from "./HeaderNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewEmployerLogin from "../candidate/components/common/CompanyDetail/NewEmployerLogin";
import { FaListAlt } from "react-icons/fa";
import NewUserLogin from "../candidate/components/common/CompanyDetail/NewUserLogin";
import microphone from "../../Component/newwithoutloginpageimages/microphone.png";
import searchjob from "../../Component/newwithoutloginpageimages/searchjob.png";
import turntologo from "../../assets/images/loginpagenewlogo.png";
import backsecondsectionimg from '../../Component/newwithoutloginpageimages/backsecondsectionimg.png';
import jobdescription from '../../Component/newwithoutloginpageimages/jobdescription.png';
import appliedjobiocn from '../../Component/newwithoutloginpageimages/appliedjobiocn.png';
import atsreumebulidericon from '../../Component/newwithoutloginpageimages/atsreumebulidericon.png';
import atsresumeredyicon from '../../Component/newwithoutloginpageimages/atsresumeredyicon.png';
import cultureicon from '../../Component/newwithoutloginpageimages/cultureicon.png';
import aiassistedicon from '../../Component/newwithoutloginpageimages/aiassistedicon.png';
import sebacknewimg from '../../Component/newwithoutloginpageimages/backnewimgset.svg';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { CiViewList } from "react-icons/ci";
import { AiOutlineSave } from "react-icons/ai";
import { IoIosList } from "react-icons/io";
import { PiUsersThreeLight } from "react-icons/pi";
import { PiListChecksLight } from "react-icons/pi";
import { MdPlaylistAddCheck } from "react-icons/md";
import { BsListCheck } from "react-icons/bs";
import { BiListCheck } from "react-icons/bi";
import { LiaListAlt } from "react-icons/lia";

const HowToWorkCandidate = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  const [employerloginmodal, setEmployerloginmodal] = useState(false);
  const [modalShowLogin, setModalShowLogin] = useState(false);
  const history = useHistory();

  const postjobbtn = () => {
      history.push("/jobmap");
  }
  
  return (
    <>
      <div>
        <div className="new_pagesmargin" onClick={RemoveSlider}>
          <div className="text-center mb-3">
            <img
              src={turntologo}
              alt="turn-to"
              className="text-center turntologocssnew"
            />
          </div>
          <div className="bg-white py-5">
            <div className="container">
              <Row className="align-items-center">
                <Col md={7}>
                  <h2 className="">
                    <b>
                      Discover Your Dream Job with Turn-To: Empowering Your Job Search
                      Journey
                    </b>
                  </h2>
                  <div className="mt-3 mb-3">
                    <div>
                      <b>
                        Unlock the future of job search with Turn-To. Explore jobs on a
                        map with AI assistance, save favorites, build an ATS-ready resume,
                        and more. Start your journey to a fulfilling career today.
                      </b>
                    </div>
                  </div>
                  <div className="mt-3 mb-3 ">
                    Welcome to Turn-To, where your job search transforms from a daunting
                    task into an exciting journey of discovery. Our innovative platform
                    empowers candidates like you to find your ideal job efficiently.
                    From AI-driven job searches to personalized job maps, here's how
                    Turn-To caters to every aspect of your job-seeking adventure.
                  </div>
                </Col>
                <Col md={5}>
                  <img src={sebacknewimg} alt="img" className="backsecondsectionimg"/>
                </Col>
              </Row>
            </div>
          </div>
          <div className="container px-0 py-5">
            <Row className="align-items-center">
              <Col md={6}>
                <div>
                  <img src={backsecondsectionimg} alt="img" className="backsecondsectionimg"/>
                </div>
              </Col>
              <Col md={6} className="setcolsecondcenterinaldata mt-4 mt-md-0">
                    <h4 className="">
                      <b>AI-Driven Job Search</b>
                    </h4>
                  <div>
                    <div className="firstlinesectionsetinmicro mt-2"> 
                      {/* <img src={microphone} alt="img" className="setmicrophonesectionimg"/> */}
                      <div>
                        <div>
                          <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                          <b>Voice-Activated Search</b>
                        </div>
                        <div className="text-justify mt-1">
                          Say goodbye to endless typing. Speak your job preferences,
                          and let our AI do the rest. Our technology understands the
                          sentiment behind your search, ensuring you find jobs that
                          match your aspirations.
                        </div>
                      </div>
                    </div>
                    <div>

                    <div className="firstlinesectionsetinmicro mt-2"> 
                        <div>
                          <div>
                            <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                            <b>Personalized Job Maps</b>
                           </div>
                          <div className="text-justify mt-1">
                            Visualize your future with Turn-To's dynamic job maps.
                            Explore opportunities in your desired locations with ease.
                            Our maps bring the job market to life, making it easier
                            for you to pinpoint your next career move.
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              
                  
                 
                  
              </Col>
            </Row>
          </div>
          <div className="bg-white py-5">
            <div className="container p-0">
              <div className="text-center">
                   <h4>
                      <b>Our Amazing Features</b>
                    </h4>
                    <div className="mb-2">
                    Turn-To's Features and Perks to ease your life and help you be more productive
                    </div>
              </div>
              <Row>
              <Col lg={4} md={6} className="my-3">
                <div className="howitscardsetcardbox">
                  <div>
                  <AiOutlineSave size={34} fill="#5932ea"/>
                    
                  </div>
                  <div className="mt-4">
                    <h5>
                      <b>My Saved Jobs</b>
                    </h5>
                    <div className="">
                      Keep track of the opportunities that excite you most. Our 'My
                      Saved Jobs' feature creates a personalized map of your
                      bookmarked positions, ensuring you always keep sight of
                      potential dream jobs.
                    </div>
                  </div>
                </div>
                </Col>

                <Col lg={4} md={6} className="my-3">
                <div className="howitscardsetcardbox">
                  <div>
                  <CiViewList size={34} fill="#5932ea"/>
                    
                  </div>
                  <div className="mt-4">
                    <h5>
                      <b>Applied List</b>
                    </h5>
                    <div className="">
                    Track your application journey with our 'Applied List' feature.
                    Stay organized and on top of your job applications in one
                    convenient location.
                    </div>
                  </div>
                </div>
                </Col>

                <Col lg={4} md={6} className="my-3">
                <div className="howitscardsetcardbox">
                  <div>
                    
                    <IoIosList size={34} fill="#5932ea"/>
                  </div>
                  <div className="mt-4">
                    <h5>
                      <b>ATS Resume Builder</b>
                    </h5>
                    <div className="">
                    Craft the perfect resume with Turn-To's ATS Resume Builder.
                    Tailor your resume to stand out in applicant tracking systems,
                    increasing your chances of attracting employers.
                    </div>
                  </div>
                </div>
                </Col>

                <Col lg={4} md={6} className="my-3">
                <div className="howitscardsetcardbox">
                  <div>
                   
                    <BiListCheck size={34} fill="#5932ea"/>
                  </div>
                  <div className="mt-4">
                    <h5>
                      <b>Resume Download Ready</b>
                    </h5>
                    <div className="">
                    Download your ATS-optimized resume anytime, anywhere. Whether
                    applying through Turn-To or elsewhere, your resume is ready to
                    make a great impression.
                    </div>
                  </div>
                </div>
                </Col>
                <Col lg={4} md={6} className="my-3">
                <div className="howitscardsetcardbox">
                  <div>
                  <PiUsersThreeLight size={34} fill="#5932ea"/>
                    
                  </div>
                  <div className="mt-4">
                    <h5>
                      <b>Culture and Preferences</b>
                    </h5>
                    <div className="">
                    Showcase what makes you unique. Our 'Culture and Preferences'
                    section allows you to highlight your ideal work environment and
                    values, aligning you with companies that share your vision.
                    </div>
                  </div>
                </div>
                </Col>
                <Col lg={4} md={6} className="my-3">
                <div className="howitscardsetcardbox">
                  <div>
                    
                    <LiaListAlt size={34} fill="#5932ea"/>
                  </div>
                  <div className="mt-4">
                    <h5>
                      <b>AI-Assisted Job Discovery</b>
                    </h5>
                    <div className="">
                    Let Turn-To's AI guide you through your job search. From saving
                    jobs to tracking applications, our intelligent system learns
                    from your preferences, continually enhancing your search
                    experience.
                    </div>
                  </div>
                </div>
                </Col>
              </Row>
            </div>
          </div>
        
         <div className="container">
          <div className="row mt-4 py-4">
            <Col lg={12} md={12} className="p-0 ">
              <h4 className="text-center">
                <b>Conclusion</b>
              </h4>
              <div className="text-center">
                Turn-To is more than a job search platform; it's your partner in
                defining the future of your career. With our suite of tools
                designed for job seekers, you can confidently navigate the job
                market. Experience a job search that understands you with
                Turn-To.
              </div>
            </Col>
          </div>
         </div>

         <div className="newlastcalltoactionset py-5 mt-5">
           <div className="container">
          <div className="my-3">
            <Row>
              <Col className="p-0" lg={8}>
                  <h5 className="discoversize text-white">
                    <b>
                      Ready to find your dream job? 
                    </b>
                  </h5>
                <h5 className="discoversize text-white">
                  Join Turn-To today and let us
                  empower your job search with our innovative tools and AI
                  assistance.
                </h5>
              </Col>
              <Col
              lg={4}
                className="d-flex align-items-center justify-content-center justify-content-lg-end newcssofflex p-0 mt-3 mt-lg-0"
              >
                <button
                  className="opostivecolorbuutonset"
                  onClick={(e) => postjobbtn()}
                >
                  Empowering Your Job Search Journey
                </button>
              </Col>
            </Row>
          </div>
          </div>
          </div>
        </div>
        <div>
          <LandingPageFooter />
        </div>

        {!isAuthenticated ? (
         <Modal
         show={modalShowLogin}
         onHide={() => setModalShowLogin(false)}
         size="md"
         aria-labelledby="contained-modal-title-vcenter"
         centered
         className="remove_modelback"
       >
         <Modal.Body className="p-0">
           <div className="">
             <NewUserLogin setModalShowLogin={setModalShowLogin} />
           </div>
         </Modal.Body>
       </Modal>
        ) : (
          AuthData?.role === "employer" && (
            <Modal
              show={employerloginmodal}
              onHide={() => setEmployerloginmodal(false)}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="remove_modelback"
            >
              <Modal.Body className="p-0">
                <div className="">
                  <NewEmployerLogin
                    setEmployerloginmodal={setEmployerloginmodal}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )
        )}
      </div>
    </>
  );
};

export default HowToWorkCandidate;
