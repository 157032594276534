import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import SettingsPanel from "./shared/SettingsPanel";
import Footer from "./shared/Footer";
import { withTranslation } from "react-i18next";
// import GoogleMapReact from "google-map-react";
// import TrackPointRound from "./track-point-round/TrackPointRound";
import { MarkerContext } from "../context/markerContext";
// import { myMarker } from "./mock_data/marker";
import { myMarker } from "./mock_data/marker";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactNotifications, Store } from "react-notifications-component";
import 'react-notifications-component/dist/theme.css'
import "animate.css/animate.min.css";
import "../../src/app/candidate/components/common/Card/card.css";
import "../../src/app/candidate/components/common/Card/card.css";
import "../../src/app/candidate/components/common/Card/CompanyDetalisSaveJob/SaveJob.css";
import "../../src/app/candidate/components/common/CompanyDetail/companydetail.css";
import "../../src/app/candidate/components/common/MarkerPoint/markerpoint.css";
import "../../src/app/candidate/components/common/Newpost/newpost.css";
import "../../src/app/candidate/components/common/Skill/skill.css";
import "../../src/app/candidate/components/dashboard/dashboard.css";
import "../../src/app/candidate/components/googlemap/googlemap.css";
import "../../src/app/candidate/components/profile/experience/experience.css";
import "../../src/app/candidate/components/profile/personal-info/personal.css";
import "../../src/app/candidate/components/profile/preference/Preference.css";
import "../../src/app/candidate/components/profile/user-profile-card/card.css";
import "../../src/app/candidate/components/profile/profile.css";
import "../../src/app/candidate/components/save-job/savejob.css";

import "../../src/app/shared/sidebar.css";
import "../../src/app/Main.css";
import "../../src/Component/AllEmployerappliaction/AllEmployerappliaction.css";
import "../../src/Component/ComapanyInfo/UserprofileInfo.css";
import "../../src/Component/Comapanyprofile/Compnyprofile.css";
import "../../src/Component/Companyview/Comapanyview.css";

import "../../src/Component/Easyapply/Easyapply.css";
import "../../src/Component/Footer-Page/footerpage.css";

import "../../src/Component/Payment Pages/PaymentSuccess.css";
import "../../src/Component/Jobs/Jobs.css";
import "../../src/Component/Job-Detail/JobDetail.css";
import "../../src/Component/Component.css";
import "../../src/Component/ReportJoblist/ReportJoblist.css";
import "../../src/Component/policy_and_tc/TermsAndConditions.css";
import "../../src/Component/Employerdashbord.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "flatpickr/dist/themes/material_green.css";
import { useAuth } from "../context/AuthProvider";
import { useSocket } from "../context/SocketProvider";

const App = ({ location }) => {
  const [cardId, setCardId] = useState("");
  const { AuthData, isAuthenticated } = useAuth();
  const { socket } = useSocket();
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const [newCloseSidebar, setNewCloseSidebar] = useState(false);

  const defaultProps = {
    center: {
      lat: 22.303894,
      lng: 70.802162,
    },
    zoom: 11,
  };

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  const onRouteChanged = () => {
    const body = document.querySelector("body");
    if (location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
    } else {
      body.classList.remove("rtl");
    }
    window.scrollTo(0, 0);
    var fullPageLayoutRoutes = [
      "/user-register",
      "/user-login",
      "/employer-register",
      "/employer-login",
      "/user-pages/login-1",
      "/user-pages/register-1",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
      "/job-map-iframe/"
      // "/innovative-employer-solutions" ,
      // "/innovative-candidate-solutions" ,
      // "/faq-employer" ,
      // "/faq-candidate" ,
      // "/job-ads" ,
      // "/jobmap-integration" ,
      // "/saved-jobmap" ,
    ];
    if (window.innerWidth > 1023) {
      fullPageLayoutRoutes = [
        "/user-register",
        "/user-login",
        "/employer-register",
        "/employer-login",
        "/user-pages/login-1",
        "/user-pages/register-1",
        "/user-pages/lockscreen",
        "/error-pages/error-404",
        "/error-pages/error-500",
        "/general-pages/landing-page",
        "/job-map-iframe/",
        // "/innovative-employer-solutions",
        // "/innovative-candidate-solutions",
        // "/faq-employer",
        // "/faq-candidate",
        "/partnership",
        "/hiring-process",
        "/offering-page",
        // "/job-ads",
        // "/jobmap-integration",
        // "/saved-jobmap",
        // "/ats-resume-builder",
        // "/culture-preference",
        "/candidiate-product",
        "/employer-product",
        "/job-pricing",
        "/"
    
      ];
    }else{
      fullPageLayoutRoutes.push( `/job-map/${AuthData?.username}`)

    }
    const isFullPage = fullPageLayoutRoutes.includes(location.pathname) || location.pathname.includes("/job-map-iframe");
    setIsFullPageLayout(isFullPage);
    if (isFullPage) {
      document
        .querySelector(".page-body-wrapper")
        .classList.add("full-page-wrapper");
    } else {
      document
        .querySelector(".page-body-wrapper")
        .classList.remove("full-page-wrapper");
    }
  };

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  let navbarComponent = null;

  if (screenSize.width < 768) {
    if (["/jobmap", "/saved-job", "/employer/job-map","/company-iframe", `/employer/job-map/${AuthData?.info?.company_name}`].includes(location.pathname) || location.pathname.includes("job-map-iframe")) {
      navbarComponent = !isFullPageLayout ? null : null;
    } else {
      navbarComponent = !isFullPageLayout ? <Navbar /> : null;
    }
  } else {
    navbarComponent = !isFullPageLayout ? <Navbar /> : null;
  }
  let sidebarComponent = !isFullPageLayout ? <Sidebar /> : null;
  let settingsPanelComponent = !isFullPageLayout ? <SettingsPanel /> : null;
  let footerpanel = !isFullPageLayout ? <Footer /> : null;

  const closeSidebar = () => {
    const newSiderCloseAllPage = [
      "/jobmap",
      "/saved-job",
      "/user-register",
      "/user-login",
      "/employer-register",
      "/employer-login",
      "/landing-page",
      "/employer/job-map",
      `/employer/job-map/${AuthData?.info?.company_name}`,
      `/job-map/${AuthData?.username}`,
      "/innovative-employer-solutions",
      "/innovative-candidate-solutions",
      "/faq-employer",
      "/faq-candidate",
      "/job-ads",
      "/jobmap-integration",
      "/saved-jobmap",
      "/ats-resume-builder",
      "/culture-preference",
      "/partnership",
      "/offering-page",
      "/hiring-process",
      "/candidiate-product",
      "/employer-product",
      "/job-pricing",
      "/innovative-employer-solutions",
      "/company-iframe"
    ];
    const isAllPageSidebar = newSiderCloseAllPage.includes(location.pathname);
    setNewCloseSidebar(isAllPageSidebar);
    if (!isAllPageSidebar) {
      document?.querySelector(".sidebar-offcanvas")?.classList.remove("active");
    }
  };
  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");

  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentLatitude(position.coords.latitude);
        setCurrentLongitude(position.coords.longitude);
      },
      (error) => {
      }
    );
  }

  useEffect(() => {
    // console.log("currentLatitude",currentLatitude);
    if(currentLatitude !== "" & currentLongitude !== ""){
      var apiexploredata = {
        latitude: currentLatitude,
        longitude: currentLongitude,
        location:""
      };
      if (socket) {
        // console.log("apiexploredata",apiexploredata);

        socket.off("tranding-search-current-count");
        socket.emit("tranding-search-current-count", apiexploredata);
        socket.on("tranding-search-current-count", (res) => {
        // console.log("res",res);

          localStorage.setItem("explorecurrentlocationdata",JSON.stringify(res.data));
        });
      }
    }
  }, [currentLatitude, currentLongitude]);
  return (
    <MarkerContext.Provider value={{ cardId, setCardId }}>
      <ReactNotifications />
      <div className="container-scroller">
        {navbarComponent}
        <div
          className="container-fluid page-body-wrapper"
          style={{ paddingInline: 0 }}
        >
          {sidebarComponent}
          <div className="main-panel">
            <div className="content-wrapper p-0" onClick={() => closeSidebar()}>
              <AppRoutes />
              {settingsPanelComponent}
              {footerpanel}
            </div>
          </div>
        </div>
      </div>
    </MarkerContext.Provider>
  );
};

export default withTranslation()(withRouter(App));
