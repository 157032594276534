import React, { useState } from "react";
import "./NewAddPage.css";
import { Accordion, Col, Modal, Nav, Navbar, Row } from "react-bootstrap";
import footerlogo from "../../assets/images/loginpagenewlogo.png";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";
import MyButton from "../candidate/components/common/Button/MyButton";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewUserLogin from "../candidate/components/common/CompanyDetail/NewUserLogin";
import turntologo from "../../assets/images/loginpagenewlogo.png";
import setimgfirstsection from '../../Component/newwithoutloginpageimages/secondkeyicon.png';
import secondaiicon from '../../Component/newwithoutloginpageimages/secondaiicon.png'
import NewEmployerLogin from "../candidate/components/common/CompanyDetail/NewEmployerLogin";
import sebacknewimg from '../../Component/AboutPageimg/jobfeedclearother.png';

const CultureandPreferenceDropdown = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };

  const [employerloginmodal, setEmployerloginmodal] = useState(false);
  const [modalShowLogin, setModalShowLogin] = useState(false);
  const history = useHistory();

  const postjobbtn = () => {
    if (isAuthenticated && AuthData.role == "user") {
      history.push("/culture-preferences");
    } else {
      setModalShowLogin(true);
    }
  };
  return (
    <>
      <div>
       
        {/* first section */}
        <div className="new_pagesmargin" onClick={RemoveSlider}>
          <div className="text-center mb-3">
            <img
              src={turntologo}
              alt="turn-to"
              className="text-center turntologocssnew"
            />
          </div>
          <div className="bg-white py-5">
            <div className="container">
            <Row className="align-items-center">
                <Col md={7}>
                  <h2 className="">
                    <b>Enhance Your Job Match Precision with Turn-To's Job Feed</b>
                  </h2>
                  <div className="mt-3 mb-3">
                      At Turn-To, we're committed to revolutionizing your job search
                      experience. Our newly enhanced "Culture & Preferences" section is
                      designed to fine-tune your job matches, making them more aligned
                      with your individual needs and aspirations. Here's how our latest
                      improvements enrich your job search journey.
                  </div>
                  </Col>
                  <Col md={5}>
                    <img src={sebacknewimg} alt="img" className="backsecondsectionimg"/>
                  </Col>
              </Row>
            </div>
          </div>

          <div className="container px-0 py-5">
            <Row className="align-items-center">
              <Col md={6}>
                <div>
                    <img src={setimgfirstsection} alt="img" className="backsecondsectionimg"/>
                </div>
              </Col>
              <Col md={6} className="mt-4 mt-md-0">
                <div>
                  <h4>
                    <b>Key Enhancements</b>
                  </h4>

                  <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                    <b>Instructional Tooltips</b>
                  </div>

                  <div>
                    <div className="text-justify">
                      Gain deeper insights with our tooltips. Hover over any question
                      to understand its importance and how your responses refine your
                      job matches.
                    </div>
                  </div>

                  <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                    <b>Smart Forms</b>
                  </div>

                  <div>
                    <div className="text-justify">
                      Experience a personalized form that adapts in real-time,
                      offering you questions based on your previous answers to keep
                      the experience relevant and engaging.
                    </div>
                  </div>

                  <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                    <b>Predictive Text and Autocomplete</b>
                  </div>

                  <div>
                    <div className="text-justify">
                      Save time with our predictive text feature for fields like
                      'Industry Preference'. Start typing, and we'll suggest the rest.
                    </div>
                  </div>

                  <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                    <b>Progressive Disclosure</b>
                  </div>

                  <div>
                    <div className="text-justify">
                      We've simplified the process. Answer a question, and we'll
                      gently guide you to the next, making sure you're not
                      overwhelmed.
                    </div>
                  </div>

                

                </div>
              </Col>
            </Row>
          </div>
          <div className="bg-white">
            <div className="container px-0 py-5">
            <Row className="align-items-center">
                <Col md={6} className="setcolsecondcenterinaldata">
                  <div>
                    <h4>
                      <b>Backend and AI-Driven Features</b>
                    </h4>

                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                      <b>Real-Time Saving</b>
                    </div>

                    <div>
                      <div className="text-justify">
                        Never worry about losing your progress. Our form saves in
                        real-time, ensuring your data is secure even if you accidentally
                        close your browser.
                      </div>
                    </div>

                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                      <b>AI-Powered Recommendations</b>
                    </div>

                    <div>
                      <div className="text-justify">
                        Once you've entered your preferences, our AI algorithm suggests
                        potential job matches or adjustments based on real-time job
                        market trends.
                      </div>
                    </div>

                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                      <b>Natural Language Processing (NLP)</b>
                    </div>

                    <div>
                      <div className="text-justify">
                        We employ NLP to understand your open-ended responses, enhancing
                        the accuracy of job matches by comprehending your preferences in
                        depth.
                      </div>
                    </div>

                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                      <b>User Engagement Analytics</b>
                    </div>

                    <div>
                      <div className="text-justify">
                        We continuously improve by analyzing how you interact with the
                        form, optimizing it to make your job search as efficient as
                        possible.
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mt-4 mt-md-0">
                  <div>
                      <img src={secondaiicon} alt="img" className="backsecondsectionimg"/>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="container">
            <div className="row mt-5 py-3">
              <Col lg={12} md={12} className="p-0 ">
                <h4 className="text-center">
                  <b>Accessibility and Inclusivity</b>
                </h4>
                <div className="text-center">
                  Ensuring accessibility, our form meets WCAG 2.1 standards,
                  making it usable for everyone, regardless of how they access the
                  web.
                </div>
              </Col>
            </div>
          </div>

          
        <div className="newlastcalltoactionset py-4 mt-5">
          <div className="container">
            <div className="row mt-3 mb-3">
              <Col lg={8} className="p-0 ">
                <h5 className="text-white">
                  <b>Getting Started</b>
                </h5>
                <h5 className="mt-2 text-white">
                  Dive into our enhanced "Culture & Preferences" section and see
                  how Turn-To makes finding your next job not just easier, but
                  more aligned with who you are. Welcome to a smarter way to job
                  search.
                </h5>
              </Col>
              <Col
                lg={4}
                className="mt-2 d-flex align-items-center justify-content-center justify-content-lg-end p-0 mt-3 mt-lg-0"
              >
                <button className="opostivecolorbuutonset"  onClick={(e) => postjobbtn()}>Explore Enhanced Features</button>
              </Col>
            </div>
         </div>
        </div>
        </div>
        <div>
          <LandingPageFooter />
        </div>

        {!isAuthenticated ? (
         <Modal
         show={modalShowLogin}
         onHide={() => setModalShowLogin(false)}
         size="md"
         aria-labelledby="contained-modal-title-vcenter"
         centered
         className="remove_modelback"
       >
         <Modal.Body className="p-0">
           <div className="">
             <NewUserLogin setModalShowLogin={setModalShowLogin} />
           </div>
         </Modal.Body>
       </Modal>
        ) : (
          AuthData?.role === "employer" && (
            <Modal
              show={employerloginmodal}
              onHide={() => setEmployerloginmodal(false)}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="remove_modelback"
            >
              <Modal.Body className="p-0">
                <div className="">
                  <NewEmployerLogin
                    setEmployerloginmodal={setEmployerloginmodal}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )
        )}
      </div>
    </>
  );
};

export default CultureandPreferenceDropdown;
