import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import imgset from "../../../../assets/images/pexels-francesco-ungaro-1525041.jpg";
import location from "../../../../Component/icontopng/loaction.png";
import closeimg from "../../../../Component/icontopng/historyclose.png";
// import "./dashboard.css";
import link from "../../../../Component/icontopng/LinkIcon.png";
import cmplink from "../../../../Component/icontopng/earthss.png";
import { useSocket } from "../../../../context/SocketProvider";
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Avatar from "react-avatar";
import Skeleton from "react-loading-skeleton";

const CompanyInfoCard = (props) => {
  const { socket } = useSocket();
  const [companies, setCompanies] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const SoketCall = () => {
    if (socket) {
      var Data = {
        is_third_party: props.is_third_party,
        company_name: props.company_name,
      };
      setIsLoading(true);
      socket.emit("company-profile", Data);
      socket.off("company-profile");
      socket.on("company-profile", (res) => {
        setIsLoading(false);
        var company_website = res.data.company_website;
        if (company_website != "") {
          if (!company_website?.toLowerCase()?.includes("http")) {
            res.data.company_website = "//" + company_website;
          }
        }
        var career_page = res.data.career_page;
        if (career_page != "") {
          if (!career_page?.toLowerCase()?.includes("http")) {
            res.data.career_page = "//" + career_page;
          }
        }
        // console.log("company-profile",res.data);
        setCompanies(res.data);
      });
    }
  };
  // console.log("companies",companies);
  useEffect(() => {
    SoketCall();
  }, [props.company_name]);

  return (
    <>
      <div className="mt-2 p-md-0 pl-md-2">
        <div>
          <div className="cardnewinfocss card coursecard">
            {companies ? (
              <Row>
                <Col
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  className="image_col_center cmpview_flex p-lg-0 p-md-0"
                >
                  {companies?.image == "" ? (
                    <Avatar round size="80" name={companies?.company_name} />
                  ) : (
                    <img
                      className="cmpinfocard_img"
                      variant="top"
                      src={companies?.image}
                      alt="Company"
                    />
                  )}
                </Col>
                <Col
                  xl={9}
                  lg={9}
                  md={9}
                  sm={9}
                  xs={9}
                  className="d-flex landing_setcenter "
                >
                  <div className="w-100 mt-1">
                    <div className="d-flex justify-content-between">
                      {companies.show_profile ? (
                        <Link to={`/company/${companies?.username}`}>
                          <div className="cmpinfocardfont coursename">
                            {companies?.company_name}
                          </div>
                        </Link>
                      ) : (
                        <div className="cmpinfocardfont coursename">
                          {companies?.company_name}
                        </div>
                      )}
                      {props.CloseCompanyCard ? (
                        <div
                          onClick={() => {
                            props.CloseCompanyCard();
                          }}
                        >
                          <img
                            src={closeimg}
                            className="cmpinfocardpin pointer"
                            alt="location"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        {!companies?.location?.address ||
                        companies?.location?.address == "" ? (
                          ""
                        ) : (
                          <div className="d-flex align-items-center small_icon">
                            <img
                              src={location}
                              className="pngiconWidth pointer"
                              alt="location"
                            />
                            <div
                              className="ml-1"
                              style={{ userSelect: "none" }}
                            >
                              {companies?.location?.address}
                            </div>
                          </div>
                        )}
                      </div>
                      {companies.career_page == "" ? (
                        ""
                      ) : (
                        <div>
                          {" "}
                          <a href={companies.career_page} target="_blank">
                            <img
                              src={link}
                              className="cmpinfocardpin pointer"
                              alt="location"
                            />
                          </a>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-between">
                      {companies?.jobs == "" ? (
                        ""
                      ) : (
                        <div className="fontsizecmpinfocard">
                          Job Count : {companies?.jobs}
                        </div>
                      )}
                      {companies.company_website == "" ? (
                        ""
                      ) : (
                        <div>
                          <a href={companies.company_website} target="_blank">
                            <img
                              src={cmplink}
                              className="cmpinfocardpin"
                              alt="location"
                            />
                          </a>
                        </div>
                      )}
                    </div>
                    <div className=""></div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Skeleton count={3} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyInfoCard;
