import React, { useState } from "react";
import "./NewAddPage.css";
import { Accordion, Col, Form, Nav, Navbar, Row } from "react-bootstrap";

import footerpagepng from "../../Component/icontopng/footerpagepng.png";
import { useDropzone } from "react-dropzone";
import MyButton from "../candidate/components/common/Button/MyButton";

const NewRefundPolicy = () => {
  const thumbsContainer = {
    // display: 'flex',
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    // border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    position: "relative",
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "100%",
    height: "100%",
    borderRadius: "5px",
  };

  const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    }, // Only accept image files
    multiple: true, // Allow multiple files
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <>
      <div>
        {/* first section */}
        <div className="terms-container container">
          <h1 className="termofuse text-center">Turn-To Refund Policy</h1>
          <hr></hr>

          <div>
            <h4>
              <b>Effective Date: 3 March, 2024</b>
            </h4>

            <div className="mt-3 mb-3">
              <b>
                Thank you for using Turn-To. We are committed to ensuring your
                satisfaction with our services. This Refund Policy outlines the
                circumstances under which Turn-To may issue refunds.
              </b>
            </div>

            <div>
              <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0" className="my-2">
                  <Accordion.Header className="fontsetAccordion">
                    <img
                      src={footerpagepng}
                      alt="open"
                      width="15px"
                      height="15px"
                    />{" "}
                    Scope of Refund Policy:
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div>
                        This policy applies to fees paid for services rendered
                        by Turn-To, including but not limited to job posting
                        fees (Cost Per Click or Cost Per Application) and any
                        additional services like SEO for Jobs.
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="my-2">
                  <Accordion.Header className="fontsetAccordion">
                    <img
                      src={footerpagepng}
                      alt="open"
                      width="15px"
                      height="15px"
                    />{" "}
                    Refund Conditions:
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div>
                        <b> Incorrect Billing:</b>
                        In addition to cookies, we collect geolocation data to
                        provide location-based services. This data helps in
                        displaying job opportunities relevant to your area.
                      </div>
                      <div>
                        <b>Service Issues: </b>
                        If you encounter significant service-related issues
                        directly attributable to Turn-To, we will work with you
                        to resolve these issues. If we cannot provide a
                        satisfactory solution, a partial or full refund may be
                        offered at Turn-To's discretion.
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="my-2">
                  <Accordion.Header className="fontsetAccordion">
                    <img
                      src={footerpagepng}
                      alt="open"
                      width="15px"
                      height="15px"
                    />{" "}
                    Cancellation Policy:
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div>
                        Job postings can be cancelled at any point, but refunds
                        for cancelled postings will depend on the stage of the
                        job posting and the service used (CPC or CPA).
                      </div>
                      <div className="mt-2">
                        For the SEO for Jobs service, refunds will be considered
                        based on the service terms agreed upon at the time of
                        purchase.
                      </div>
                      <div className="mt-2">
                        <b>Non-Refundable Services:</b>
                        Certain services may be non-refundable, and this will be
                        clearly stated at the time of purchase.
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="my-2">
                  <Accordion.Header className="fontsetAccordion">
                    <img
                      src={footerpagepng}
                      alt="open"
                      width="15px"
                      height="15px"
                    />{" "}
                    Process for Requesting a Refund:
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div>
                        To request a refund, please contact our customer support
                        team at{" "}
                        <a href={"https://gmail.com"} target="_blank">
                          info@turn-to.com
                        </a>{" "}
                        with your account details and a clear explanation of
                        your reason for seeking a refund.
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="my-2">
                  <Accordion.Header className="fontsetAccordion">
                    <img
                      src={footerpagepng}
                      alt="open"
                      width="15px"
                      height="15px"
                    />{" "}
                    Timeframe for Processing Refunds:
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div>
                        Approved refunds will be processed within a certain
                        number of business days (e.g., 7-10 business days),
                        depending on the payment method used.
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="my-2">
                  <Accordion.Header className="fontsetAccordion">
                    <img
                      src={footerpagepng}
                      alt="open"
                      width="15px"
                      height="15px"
                    />{" "}
                    Modifications to the Refund Policy:
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div>
                        Turn-To reserves the right to modify this refund policy.
                        Any changes will be posted on our website and effective
                        immediately.{" "}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" className="my-2">
                  <Accordion.Header className="fontsetAccordion">
                    <img
                      src={footerpagepng}
                      alt="open"
                      width="15px"
                      height="15px"
                    />{" "}
                    Contact Us:
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div>
                        If you have any questions about our refund policy,
                        please contact us at{" "}
                        <a href={"https://gmail.com"} target="_blank">
                          info@turn-to.com
                        </a>{" "}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>

        {/* second section */}
      </div>
    </>
  );
};

export default NewRefundPolicy;
