import React from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import MyButton from "../../common/Button/MyButton";
import { useState } from "react";
import {
  postEmployerchangePassword,
  postchangePassword,
} from "../../../../../Api/Api";
import { Store } from "react-notifications-component";
import { useAuth } from "../../../../../context/AuthProvider";
import Tooltip from "react-tooltip-lite";

function EmployerChangePassword() {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const [passwordOld, setPasswordOld] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [iscontactLoading, setContactIsLoading] = useState(false);

  // validation
  const [errpasswordOld, seterrPasswordOld] = useState("");
  const [errpasswordNew, seterrPasswordNew] = useState("");
  const [errpasswordConfirm, seterrPasswordConfirm] = useState("");

  // for border
  const [checkpasswordOld, setCheckPasswordOld] = useState(false);
  const [checkpasswordNew, setCheckPasswordNew] = useState(false);
  const [checkpasswordConfirm, setCheckPasswordConfirm] = useState(false);

  // const [passworderror, setPasswordError] = useState("");

  const errMsg = () => {
    clearError();
    var isValid = true;
    if (passwordOld.trim() == "") {
      setCheckPasswordOld(true);
      seterrPasswordOld("Please Enter Old Password");
      isValid = false;
    }
    if (passwordNew.trim() === "") {
      setCheckPasswordNew(true);
      seterrPasswordNew("Please Enter New Password");
      isValid = false;
    } else if (passwordOld.trim() === passwordNew.trim()) {
      setCheckPasswordNew(true);
      seterrPasswordNew("Old Password and New Password cannot be the same");
      isValid = false;
    }
    // else if (!isStrongPassword(passwordNew)) {
    //   setCheckPasswordNew(true);
    //   seterrPasswordNew("New Password must be strong");
    //   isValid = false;
    // }
    if (passwordConfirm.trim() == "") {
      setCheckPasswordConfirm(true);
      seterrPasswordConfirm("Please Enter Confirm Password");
      isValid = false;
    } else {
      if (passwordNew !== passwordConfirm) {
        setCheckPasswordConfirm(true);
        seterrPasswordConfirm("Confirm Password Does Not Match New Password");
        isValid = false;
      }
    }
    return isValid;
  };

  const [passwordErrors, setPasswordErrors] = useState([]);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    const errors = [];

    if (newPassword.length < 8) {
      errors.push("Password must be at least 8 characters long");
    }
    if (!/[A-Z]/.test(newPassword)) {
      errors.push("Password must contain at least one uppercase letter");
    }
    if (!/[a-z]/.test(newPassword)) {
      errors.push("Password must contain at least one lowercase letter");
    }
    if (!/\d/.test(newPassword)) {
      errors.push("Password must contain at least one number.");
    }
    if (!/[@$!%*?&]/.test(newPassword)) {
      errors.push("Password must contain at least one special character");
    }

    setPasswordNew(newPassword);
    setPasswordErrors(errors);
    seterrPasswordNew("");
  };

  // const isStrongPassword = (password) => {
  //   const minLength = 8;
  //   const hasUpperCase = /[A-Z]/.test(password);
  //   const hasLowerCase = /[a-z]/.test(password);
  //   const hasNumber = /\d/.test(password);
  //   const hasSpecialChar = /[!@#$%^&*]/.test(password);

  //   return (
  //     password.length >= minLength &&
  //     hasUpperCase &&
  //     hasLowerCase &&
  //     hasNumber &&
  //     hasSpecialChar
  //   );
  // };

  const clearError = (e) => {
    seterrPasswordOld("");
    seterrPasswordNew("");
    seterrPasswordConfirm("");

    setCheckPasswordOld(false);
    setCheckPasswordNew(false);
    setCheckPasswordConfirm(false);
  };

  function clearState() {
    setPasswordOld("");
    setPasswordNew("");
    setPasswordConfirm("");
  }

  const ChangePasswordApi = () => {
    if (!errMsg()) {
      return false;
    }
    setContactIsLoading(true);
    var data = {
      old_password: passwordOld,
      new_password: passwordNew,
    };
    postEmployerchangePassword(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          setContactIsLoading(false);
          return false
        }
        // handle success
        // console.log("change", response);
        if (response.data.status) {
          clearState();
          if (response.data) {
            Store.addNotification({
              title: "Saved Successfully",
              // message: "Saved Successfully",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__zoomIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              // animationIn: ["animate__animated", "animate__fadeIn"],
              // animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false,
              },
            });
            setContactIsLoading(false);
          }
        } else {
          seterrPasswordOld(response.data.message);
        }
      })
      .catch(function (error) {
        // handle error
        setContactIsLoading(false);
      });
  };
  return (
    <>
      {" "}
      <div className="user-profile">
        <Row>
          <Col md={6} className="p-0 mb-2">
            <h4>Change Password</h4>
          </Col>
          <Col md={6} className="text_end pr-2 pt-2">
            {/* <Breadcrumb> */}
            {/* <Breadcrumb.Item href="#"> */}{" "}
            {/* {AuthData?.info?.company_name} */}
            {/* </Breadcrumb.Item> */}
            {/* <Breadcrumb.Item href="#">Change-Password</Breadcrumb.Item> */}
            {/* <Breadcrumb.Item active>Comapnyview</Breadcrumb.Item> */}
            {/* </Breadcrumb> */}
          </Col>
        </Row>

        <div className="terms-container2">
          {/* <h1 className="termofuse text-center">Change Password</h1> */}

          <Form>
            <Row>
              <Col lg={12} className="mb-2">
                <Form.Group controlId="firstname">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control
                    className={
                      checkpasswordOld ? " newsearchinputaddborderradius" : " input_radius newsearchinputaddborderradius"
                    }
                    type="text"
                    placeholder="Enter Old Password"
                    value={passwordOld}
                    onChange={(e) => setPasswordOld(e.target.value)}
                  />
                  <span className="err_msg">{errpasswordOld}</span>
                </Form.Group>
              </Col>
              <Col lg={12} className="mb-2">
                <Form.Group controlId="lastname">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    className={
                      checkpasswordNew ? " newsearchinputaddborderradius" : " input_radius newsearchinputaddborderradius"
                    }
                    type="text"
                    placeholder="Enter New Password"
                    value={passwordNew}
                    onChange={handlePasswordChange}
                    // onChange={(e) => setPasswordNew(e.target.value)}
                  />
                  {passwordNew.length > 0 && passwordErrors.length > 0 && (
                    <div>
                      <div>
                        {passwordErrors.map((error, index) => (
                          <div key={index} className="eroorclass">
                            {error}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <span className="err_msg">{errpasswordNew}</span>
                </Form.Group>
              </Col>
              <Col lg={12} className="mb-2">
                <Form.Group controlId="mobile">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    className={
                      checkpasswordConfirm ? " newsearchinputaddborderradius" : " input_radius newsearchinputaddborderradius"
                    }
                    type="text"
                    placeholder="Enter Confirm Password"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  />
                  <span className="err_msg">{errpasswordConfirm}</span>
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Tooltip content="Save">
                  <Row>
                    <Col sm={12} className="mt-2">
                      <div className="text-center">
                        <MyButton onClick={ChangePasswordApi}>
                          {" "}
                          {iscontactLoading ? (
                            <Spinner
                              animation="border"
                              role="status"
                              className="spinneerset"
                            >
                              <span className="visually-hidden"></span>
                            </Spinner>
                          ) : (
                            "Save"
                          )}
                        </MyButton>
                      </div>
                    </Col>
                  </Row>
                </Tooltip>
              </Col>
             
                
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EmployerChangePassword;
