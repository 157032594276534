import React from "react";
import "./button.css";

function MyButton({ type ,className, id, onClick, children,...other }) {
  return (
    <>
      <button
        type={type ? type : "button"}
        className={className ? `my-btn ${className}` : `my-btn`}
        id={id}
        onClick={onClick}
        {...other}
      >
        {children}
      </button>
    </>
  );
}

export default MyButton;
