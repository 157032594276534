import React, { useEffect, useState } from "react";
import {Col, Modal, Row, Spinner } from "react-bootstrap";
import {
  ClearBulkJobAll,
  CreateSessionNewApiBulk,
  GetPlansJobApi,
  GetbulkData,
} from "../Api/Api";
import DataTable from "react-data-table-component";
import { loadStripe } from "@stripe/stripe-js";
import { Store } from "react-notifications-component";
import Skeleton from "react-loading-skeleton";
import Bulkuploadimg from "../Component/imges/bulkupload.png";
import Bulkupload from "../Component/BulkUpload/Bulkupload";
import historyclose from "../Component/icontopng/historyclose.png";
import MyButton from "../app/candidate/components/common/Button/MyButton";
import location from "../Component/icontopng/loaction.png";
import clock from "../Component/icontopng/clock.png";
import dollar from "../Component/icontopng/dollar.png";
import Skill from "../app/candidate/components/common/Skill/Skill";


const BulkUplord = () => {
  const [selectPlan, setSelectPlan] = useState(0);
  const [PlanData, SetPlanData] = useState([]);
  const [bulkData, SetBulkData] = useState([]);
  const [SelectId, SetSelectId] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDataLoadingApi, setIsDataLoadingApi] = useState(false);
  const [maxJobConut, setMaxJobConut] = useState(0);
  const [isAnyUpdate, setIsAnyUpdate] = useState(0);
  useEffect(() => {
    DataGet();
    GetPlansApiDetalis();
  }, [isAnyUpdate]);

  const DataGet = () => {
    setIsDataLoadingApi(true);
    GetbulkData()
      .then(function (response) {
        if (!response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false;
        }
        if (response.data.status) {
          setDemoFileLink(response.data.demo_excel);
          SetBulkData(
            response.data.data.map((e, index) => {
              e.index = index;
              return e;
            })
          );
        }
        setIsDataLoadingApi(false);
      })
      .catch(function (error) {
        // console.log(error);
        setIsDataLoadingApi(false);
      });
  };
  const GetPlansApiDetalis = () => {
    GetPlansJobApi()
      .then(function (response) {
        if (!response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false;
        }
        if (response.data.status) {
          SetPlanData(response.data.data);
          setSelectPlan(response.data.data[0].days);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [jobdatashow, setjobdatashow] = useState(false);
  const [jobdetailsset, setjobdetailsset] = useState("");
  const tablejobdatamodalclose = () => {
    setjobdatashow(false)
  }

  const jobDatadetailsModalShow = (row) => {
    console.log(row);
    setjobdatashow(true);
    setjobdetailsset(row)
  }
  const columns = [
    {
      name: "No.",
      selector: "_id",
      format: (row, index) => {
        return index + 1 + ((currentPage - 1) * rowsPerPage)
      },
      maxWidth: "55px",
      minWidth: "55px",

    },
    {
      name: "Job Title",
      minWidth: "180px",
      selector: "job_title",
      format: (row) => {
        return (
          <>
            <div className="addnewtablewordbreak cursor-pointer" onClick={(e) => jobDatadetailsModalShow(row)}>
              {row.job_title}
            </div>
          </>
        );
      },
    },
    {
      name: " Type Location ",
      selector: "location_type",
      format: (row) => {
        return row.location_type[0];
      },
    },
    {
      name: "Category",
      selector: "category",
      minWidth: "120px",
      format: (row) => {
        var tabledata = row.category.split(", ");
        return (
          <>
            <div className="d-flex flex-wrap">
              {tabledata.map((e) => {
                return (
                  <>
                    <div className="addnewtablewordbreak">{e}</div>
                  </>
                );
              })}
            </div>
          </>
        );
      },
    },
  ];
  if (window.innerWidth > 0) {
    columns.push(
      {
        name: "Job Type",
        selector: "job_type",
        format: (row) => {
          return row.job_type[0];
        },
      },
      {
        name: "Job Shift",
        selector: "job_shift",
        format: (row) => {
          return row.job_shift[0];
        },
      }
    );
  }
  if (window.innerWidth > 0) {
    columns.push(
      {
        name: "Location",
        minWidth: "130px",
        selector: "job_location",
        minWidth: "200px",
        format: (row) => {
          return (
            <div className="addnewtablewordbreak">
              {row.job_location[0].address + "," + row.job_location[0].zipcode}
            </div>
          )
        }
      },
      {
        name: "Latitude",
        selector: (row) => row.job_location[0].latitude,
      },
      {
        name: "Longitude",
        selector: (row) => row.job_location[0].longitude,
      },
      {
        name: "Start Date",
        selector: "start_date",
        format: (row) => {
          var Time = "";
          var date = "";

          if (row.start_date && !isNaN(new Date(row.start_date).getTime())) {
            const dateObj = new Date(row.start_date);
            const options = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            };
            const optionstime = {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true, 
              hourCycle: "h23", 
            };

            const formattedDate = new Intl.DateTimeFormat(
              "en-GB",
              options
            ).format(dateObj);
            const formattedTime = new Intl.DateTimeFormat(
              "en-GB",
              optionstime
            ).format(dateObj);

            const uppercasedDate = formattedDate.replace(/(am|pm)/i, (match) =>
              match.toUpperCase()
            );
            date = uppercasedDate;
            Time = formattedTime;
          }

          return (
            <div>
              <div className="mb-0">{date}</div>
              <div className="mb-0">{Time}</div>
            </div>
          );
        },
      },
      {
        name: "Total Hiring",
        selector: "total_hiring",
        format: (row) => {
          return row.total_hiring;
        },
      },
      {
        name: "Hiring Time",
        selector: "hiring_time",
        format: (row) => {
          return (
            <div className="addnewtablewordbreak">
              {row.hiring_time}
            </div>
          )
        },
      },
      {
        name: "Salary",
        selector: "salary_type",
        format: (row) => {
          if (row.salary_type.type === "range") {
            return (
              <div className="addnewtablewordbreak">
                {row.salary_type.start} - {row.salary_type.end}
              </div>
            );
          } else {
            return row.salary_type.start;
          }
        },
      },
      {
        name: "Salary Type",
        selector: "salary_type",
        format: (row) => {
          return row?.salary_type?.type;
        },
      },
      {
        name: "Pay Duration",
        selector: "salary_type",
        format: (row) => {
          return row?.salary_type?.pay_duration;
        },
      },

      {
        name: "Supplemental",
        selector: "supplemental",
        format: (row) => {
          return row.supplemental[0];
        },
      },
      {
        name: "Benefits",
        selector: "benefits",
        format: (row) => {
          return (
            <div className="addnewtablewordbreak">
              {row.benefits[0]}
            </div>
          )
        },
      },
      {
        name: "Description",
        selector: "description",
        format: (row) => {
          return (
            <div
              className="BULK addnewtablewordbreak"
              dangerouslySetInnerHTML={{
                __html: row.description,
              }}
            ></div>
          );
        },
      },
      {
        name: "Deadline",
        selector: "deadline",
        format: (row) => {
          return row.deadline ? "Yes" : "No";
        },
      },
      {
        name: "Communication Via",
        selector: "communication_via",
        format: (row) => {
          return (
            <div className="addnewtablewordbreak">
              {row.communication_via[0]}
            </div>
          )
        },
      },
      {
        name: "Programming Languages",
        selector: "programming_languages",
        format: (row) => {
          return row.programming_languages[0];
        },
      },
      {
        name: "Education Levels",
        selector: "education_levels",
        format: (row) => {
          return (
            <div className="addnewtablewordbreak">
              {row.education_levels[0]}
            </div>
          )
        },
      },
      {
        name: "Skills",
        selector: "skills",
        format: (row) => {
          return row.skills[0];
        },
      },
      {
        name: "Apply Link",
        selector: "apply_link",
        minWidth: "150px",
        format: (row) => {
          return (
            <div className="addnewtablewordbreak">
              {row.apply_link}
            </div>
          )
        },
      }
    );
  }

  const SelectRow = (data) => {
    SetSelectId(data?.selectedRows.map((e) => e._id));
  };
  const PaymentApi = async () => {
    var job_ids = SelectJobId();
    setIsDataLoading(true);
    var data = {
      job_ids: job_ids,
      days: selectPlan,
    };
    const stripePromise = loadStripe(process.env.REACT_APP_PAYMENT_KEY);
    CreateSessionNewApiBulk(data)
      .then(async function (response) {
        if (response.data.sessionId) {
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({
            sessionId: response.data.sessionId,
          });
          if (error) {
            // console.error(error);
          }
        }
        setIsDataLoading(false);
      })
      .catch(function (error) {
        setIsDataLoading(false);

        // console.log(error);
      });
  };
  const GetCountSelectRow = () => {
    var selectedPlanDetalis = PlanData.filter((e) => e.days == selectPlan);
    if (selectedPlanDetalis) {
      var maxAmount = bulkData.length * selectedPlanDetalis[0]?.price;
      if (maxAmount > 999999) {
        maxAmount = 999999;
      }
      var noOfCountSelectRow = maxAmount / selectedPlanDetalis[0]?.price;
      setMaxJobConut(parseInt(noOfCountSelectRow));
    }
  };
  const SelectJobId = () => {
    console.log("SelectId.length", SelectId.length);
    if (!SelectId.length) {
      var Id = [];
      for (let index = 0; index < bulkData.length; index++) {
        const element = bulkData[index];
        if (index < maxJobConut) {
          Id.push(element._id);
        }
      }
      return Id;
    } else {
      return SelectId;
    }
  };
  useEffect(() => {
    if (selectPlan) GetCountSelectRow();
  }, [selectPlan, PlanData]);
  const ClearAll = () => {
    var data = {
      job_ids: SelectId,
    };
    ClearBulkJobAll(data)
      .then(function (response) {
        if (!response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false;
        }
        if (response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          DataGet();
          SetSelectId([]);
        } else {
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const [filterText, setFilterText] = useState("");
  const filteredData = bulkData.filter((item) => {
    return (
      item.job_title?.toLowerCase().includes(filterText?.toLowerCase()) ||
      item.job_id
        .toString()
        .toLowerCase()
        .includes(filterText?.toLowerCase()) ||
      item.payment_method?.toLowerCase().includes(filterText?.toLowerCase()) ||
      item.category?.toLowerCase().includes(filterText?.toLowerCase()) ||
      item.job_location[0].city
        ?.toLowerCase()
        .includes(filterText?.toLowerCase()) ||
      item.job_location[0].zipcode
        ?.toString()
        .toLowerCase()
        .includes(filterText?.toLowerCase()) ||
      item.payment_status?.toLowerCase().includes(filterText?.toLowerCase()) ||
      item.createdAt?.toLowerCase().includes(filterText?.toLowerCase()) ||
      item.createdAt?.toLowerCase().includes(filterText?.toLowerCase()) ||
      item.job_status?.toLowerCase().includes(filterText?.toLowerCase())
    );
  });
  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const [demoFileLink, setDemoFileLink] = useState("");
  return (
    <>
      <Row className="mt-2">
        <Col xs={12} className="text-center w-100">
          <img className="bulklogo" src={Bulkuploadimg} />
        </Col>
        {!isDataLoadingApi && (
          <>
            {bulkData.length ? (
              <>
                <Col xs={12} className="d-flex justify-content-center">
                  {PlanData.map((e) => {
                    return (
                      <div className="mx-2">
                        <div className="form-check">
                          <label className="form-check-label text-muted">
                            <input
                              type="radio"
                              className="form-check-input  input_radius"
                              checked={selectPlan == e.days}
                              onClick={() => setSelectPlan(e.days)}
                            />
                            <i className="input-helper"></i>
                            <span className="font-weight-bold">
                              {e.days} Days
                              {selectPlan == e.days &&
                                " $" +
                                (
                                  e.price *
                                  (SelectId.length
                                    ? SelectId.length
                                    : maxJobConut)
                                ).toFixed(2)}
                              {selectPlan == e.days &&
                                `(${SelectId.length
                                  ? SelectId.length
                                  : maxJobConut
                                } Jobs)`}
                            </span>
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </Col>
                <Col xs={12} className="p-0">
                  <Row className="align-items-center">
                    <Col md={4} className="my-2 order-md-1 order-3">
                      <input
                        className="form-control my-2 newsearchinputaddborderradius calanderzindex"
                        type="text"
                        placeholder="Search By Job title , Job id , Zip code"
                        value={filterText}
                        onChange={handleFilterChange}
                      />
                    </Col>
                    <Col md={4} className="my-2 text-center order-md-2 order-1">
                      {bulkData.length ? (
                        <>
                          {isDataLoading ? (
                            <button
                              type="submit"
                              className="btnjob mr-2 mt-1 text-white"
                            >
                              <Spinner
                                className="spinneerset"
                                animation="border"
                                role="status"
                              >
                                <span className="visually-hidden"></span>
                              </Spinner>
                            </button>
                          ) : (
                            <button
                              onClick={() => PaymentApi()}
                              type="submit"
                              className="btnjob mr-2 mt-1 text-white"
                            >
                              Confirm and Pay
                            </button>
                          )}
                          <button
                            type="submit"
                            className="secondarybtn mr-2 mt-1 my_btn"
                            onClick={() => ClearAll()}
                          >
                            {SelectId.length
                              ? "Delete " + SelectId.length
                              : "Clear All"}
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col md={4} className="my-2 order-md-3 order-2">
                      <h5 className="setlatcolumnsettest">
                        {bulkData.length} job in your bulk
                        upload.
                      </h5>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <Col md={12} className="mb-3 text-right"></Col>
            )}
          </>
        )}
        <Col xs={12} className="mb-5">
          {isDataLoadingApi ? (
            <Skeleton count={15} height={40}>
              {" "}
            </Skeleton>
          ) : bulkData.length ? (
            <DataTable
              onChangeRowsPerPage={(e) => setRowsPerPage(e)}
              onChangePage={(e) => setCurrentPage(e)}
              paginationDefaultPage={currentPage}
              paginationPerPage={rowsPerPage}
              pagination
              columns={columns}
              data={filteredData}
              selectableRows
              onSelectedRowsChange={SelectRow}
            />
          ) : (
            <>
              <Row className="justify-content-center bulk-upload-center">
                <div className="col-12 col-md-6">
                  <div className="d-flex justify-content-center">
                    <div>
                      <h2 className="text-center">
                        {" "}
                        Liberate Your Hiring. Unlock Your Potential.
                      </h2>
                      <p className="text-center">
                        Experience the power of AI-driven job matching and a
                        platform built for efficiency.
                      </p>
                      <div className="py-3 borderbulkuploard">
                        <Bulkupload
                          isAnyUpdate={isAnyUpdate}
                          setIsAnyUpdate={setIsAnyUpdate}
                          demoFileLink={demoFileLink}
                        />
                      </div>
                      <div>
                        <div className="d-flex justify-content-center mt-2">
                          <div className="">
                            <div className="p-2 text-center">
                              Find the right candidate for your business while
                              contributing to a greener future by posting your
                              job ads on Turn-To. Our job matching technology
                              powered by Al and human potential ensures a
                              seamless recruitment process, reducing carbon
                              footprint one job at a time. Upload your job
                              listings on Turn-To today.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </>
          )}
        </Col>
      </Row>

      <Modal show={jobdatashow} size="lg" onHide={tablejobdatamodalclose} centered>
        <Modal.Header>
          <Modal.Title>
            Job Details
            <img
              src={historyclose}
              alt="close"
              className="close_model set_close cursor-pointer reportclosepng"
              onClick={tablejobdatamodalclose}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bulkuploadheight">
        

          <div className="row">
            <Col md={12} className="p-0">
              <b className="bulkuploadmodelsize">{jobdetailsset.job_title}</b>
            </Col>
            <Col md={12} className="p-0 d-flex flex-wrap">
              {jobdetailsset.job_location?.length > 0 && (
                <div className="mr-1 m-1">
                  <div className="d-flex align-items-center small_icon">
                    <img src={location} className="pngiconWidth" alt="location" />
                    <div className="ml-1">

                      {jobdetailsset.job_location[0].city}, {jobdetailsset.job_location[0].state},{jobdetailsset.job_location[0].zipcode}
                    </div>
                  </div>
                </div>
              )}
              {jobdetailsset.job_type?.length > 0 && (
                <div className="mr-1 m-1">
                  <div className="d-flex align-items-center small_icon">
                    <img src={clock} className="pngiconWidth" alt="clock" />
                    <div className="ml-1">{jobdetailsset.job_type[0]}</div>
                  </div>
                </div>
              )}
              {jobdetailsset.salary_type && (
                <div className="mr-1 m-1">
                  <div className="d-flex align-items-center small_icon">
                    <img src={dollar} className="pngiconWidth" alt="dollar" />
                    <div className="ml-1">
                      {jobdetailsset.salary_type.type === "range" ? (
                        <div className="addnewtablewordbreak">
                          {jobdetailsset.salary_type.start} - {jobdetailsset.salary_type.end}
                        </div>
                      ) : (
                        <div className="addnewtablewordbreak">{jobdetailsset.salary_type.start}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {jobdetailsset.total_hiring && (
                <div className="mr-1 m-1">
                  <div className="d-flex align-items-center small_icon">
                    <div className="ml-1">{jobdetailsset.total_hiring}</div>
                  </div>
                </div>
              )}

            </Col>
          </div>
          <hr className="m-0 my-1"></hr>
          <div className="d-flex flex-wrap align-content-flex-start">
            <div className="w-100">
              <h6 className=""><b>Over View</b></h6>
            </div>

            {jobdetailsset?.total_hiring ? (
              <div className="requSec stapborder">
                <h6 className="mb-0"><b>Total Hiring</b></h6>
                <div>{jobdetailsset?.total_hiring}</div>
              </div>
            ) : (
              <div className="requSec stapborder">
                <h6 className="mb-0"><b>Total Hiring</b></h6>
                <div>0</div>
              </div>
            )}

            {jobdetailsset.job_status && (
              <div>
                <div className="requSec stapborder">
                  <h6 className="mb-0"><b>Job Status </b></h6>
                  <div className="">{jobdetailsset?.job_status}</div>
                </div>
              </div>
            )}


            {jobdetailsset?.category && (
              <div className="requSec stapborder">
                <h6 className="mb-0"><b>Category</b></h6>
                <div>{jobdetailsset?.category}</div>
              </div>
            )}
            {jobdetailsset.job_id > 0 && (
              <div>
                <div className="requSec stapborder">
                  <h6 className="mb-0"><b> Job Id</b></h6>
                  <div className="jobId_left">{jobdetailsset.job_id}</div>
                </div>
              </div>
            )}
            {jobdetailsset.start_date && (
              <div>
                <div className="requSec stapborder">
                  <h6 className="mb-0"><b> Start Date</b></h6>
                  <div>
                    {jobdetailsset && jobdetailsset.start_date && (() => {
                      var Time = "";
                      var date = "";

                      const dateObj = new Date(jobdetailsset.start_date);
                      const options = {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      };
                      const optionstime = {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                        hourCycle: "h23", 
                      };

                      const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(dateObj);
                      const formattedTime = new Intl.DateTimeFormat("en-GB", optionstime).format(dateObj);

                      const uppercasedDate = formattedDate.replace(/(am|pm)/i, (match) => match.toUpperCase());
                      date = uppercasedDate;
                      Time = formattedTime;
                      return (

                        <div>
                          <span className="mb-0">{date} </span>
                          <span className="mb-0">{Time}</span>
                        </div>

                      )
                    })()}
                  </div>
                </div>
              </div>
            )}
            {jobdetailsset.hiring_time && (
              <div>
                <div className="requSec stapborder">
                  <h6 className="mb-0"><b> Hiring Time</b></h6>
                  <div className="jobId_left">{jobdetailsset.hiring_time}</div>
                </div>
              </div>
            )}
            {jobdetailsset.salary_type && (
              <div>
                <div className="requSec stapborder">
                  <h6 className="mb-0"><b>Salary</b></h6>
                  <div className="jobId_left">{jobdetailsset.salary_type.type === "range" ? (
                    <div className="addnewtablewordbreak">
                      {jobdetailsset.salary_type.start} - {jobdetailsset.salary_type.end}
                    </div>
                  ) : (
                    <div className="addnewtablewordbreak">{jobdetailsset.salary_type.start}</div>
                  )}</div>
                </div>
              </div>
            )}
            {jobdetailsset?.salary_type?.type && (
              <div>
                <div className="requSec stapborder">
                  <h6 className="mb-0"><b> Salary Type</b></h6>
                  <div className="jobId_left"> {jobdetailsset?.salary_type?.type}</div>
                </div>
              </div>
            )}
            {jobdetailsset?.salary_type?.pay_duration && (
              <div>
                <div className="requSec stapborder">
                  <h6 className="mb-0"><b> Pay Duration</b></h6>
                  <div className="jobId_left">   {jobdetailsset.salary_type?.pay_duration}</div>
                </div>
              </div>
            )}
            {jobdetailsset?.supplemental?.length > 0 && (
              <div>
                <div className="requSec stapborder">
                  <h6 className="mb-0"><b> Supplemental</b></h6>
                  <div className="jobId_left">  {jobdetailsset?.supplemental[0]}</div>
                </div>
              </div>
            )}
            {jobdetailsset?.location_type && (
              <div>
                <div className="requSec stapborder">
                  <h6 className="mb-0"><b> Type Location </b></h6>
                  <div className="jobId_left">  {jobdetailsset?.location_type}</div>
                </div>
              </div>
            )}
                {/* {jobdetailsset.job_type?.length > 0 && (
                  <div className="requSec stapborder">
                    <h6 className="mb-0"><b>Job Type</b></h6>
    
                    <div> {jobdetailsset.job_type?.join(", ")}</div>
                  </div>
                )} */}
            {jobdetailsset?.apply_link && (
              <div>
                <div className="requSec stapborder">
                  <h6 className="mb-0"><b> Apply Link</b></h6>
                  <div className="jobId_left"><a href={jobdetailsset.apply_link} target="_blank">{jobdetailsset.apply_link}</a> </div>
                </div>
              </div>
            )}           
          </div>
          <div className="row">
            <Col md={12} className="p-0">
              <div className="w-100">
                <h6 className="mt-2 mb-1"><b>Job Description</b></h6>
                <div
                  dangerouslySetInnerHTML={{ __html: jobdetailsset.description }}
                  className="desc mr-2"
                ></div>

                {jobdetailsset.skills?.length > 0 && (
                  <div className="requSec">
                    <h6 className="mt-2 mb-1"><b>Skills</b></h6>

                    {jobdetailsset.skills?.map((ele, index) => {
                      return <Skill key={index + ele}>{ele}</Skill>;
                    })}
                  </div>
                )}
                {jobdetailsset.education_levels?.length > 0 && (
                  <div className="requSec">
                    <h6 className="mt-2 mb-1"><b>Education</b></h6>

                    {jobdetailsset.education_levels?.map((ele, index) => {
                      return <Skill key={index + ele}>{ele}</Skill>;
                    })}
                  </div>
                )}
                {jobdetailsset.programming_languages?.length > 0 && (
                  <div className="requSec">
                    <h6 className="mt-2 mb-1"><b>Programming languages</b></h6>
                    {jobdetailsset.programming_languages?.map((ele, index) => {
                      return <Skill key={index + ele}>{ele}</Skill>;
                    })}
                  </div>
                )}

                {jobdetailsset.job_type?.length > 0 && (
                  <div className="requSec">
                    <h6 className="mt-2 mb-1"><b>Job Type</b></h6>

                    {jobdetailsset.job_type?.map((ele, index) => {
                      return <Skill key={index + ele}>{ele}</Skill>;
                    })}
                  </div>
                )}
                {jobdetailsset.job_shift?.length > 0 && (
                  <div className="requSec">
                    <h6 className="mt-2 mb-1"><b>Job Shift</b></h6>

                    {jobdetailsset.job_shift?.map((ele, index) => {
                      return <Skill key={index + ele}>{ele}</Skill>;
                    })}
                  </div>
                )}
                {jobdetailsset?.benefits?.length > 0 && (
                  <div className="requSec">
                    <h6 className="mt-2 mb-1"><b>Benefits</b></h6>

                    {jobdetailsset?.benefits?.map((ele, index) => {
                      return <Skill key={index + ele}>{ele}</Skill>;
                    })}
                  </div>
                )}
                {jobdetailsset?.communication_via?.length > 0 && (
                  <div className="requSec">
                    <h6 className="mt-2 mb-1"><b>Communication Via</b></h6>

                    {jobdetailsset?.communication_via?.map((ele, index) => {
                      return <Skill key={index + ele}>{ele}</Skill>;
                    })}
                  </div>
                )}
               
                
              </div>
            </Col>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <MyButton className="secondarybtn" onClick={tablejobdatamodalclose}>
            Close
          </MyButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BulkUplord;
