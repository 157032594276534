import Reaact, { useState } from "react";
import "./NewAddPage.css";
import { Col } from "react-bootstrap";
import { BsDot } from "react-icons/bs";

const ADA = () => {
  return (
    <>
      <div>
        {/* first section */}
        <div className="user-profile">
          <div className="terms-container marginundersmallscreen">
            <Col lg={12} className="p-0 d-flex justify-content-center"></Col>
            <h1 className="termofuse text-center">
              {" "}
              Turn-To ADA Compliance Policy
            </h1>
            <hr></hr>

            <div className="mt-3 mb-3 text-justify">
              <b>
                Turn-To is committed to ensuring that our digital products and
                services are accessible to everyone, including individuals with
                disabilities. This policy outlines our commitment to compliance
                with the Americans with Disabilities Act (ADA) and our approach
                to creating accessible digital experiences.
              </b>
            </div>

            <h5 className="mb-2 mt-3 fontsizeh5">
              <BsDot size={25} />
              <b> Scope:</b>
            </h5>

            <div>
              <div className="text-justify">
                This policy applies to all Turn-To digital products and
                services, including websites, mobile apps, software, and digital
                content.
              </div>
            </div>

            <div className="mt-3">
              <h5>
                <b>Responsibilities:</b>
              </h5>
              <div className="mt-2     text-justify">
                <div>
                  <BsDot size={25} /> Turn-To is responsible for ensuring that
                  our digital products and services meet the standards outlined
                  in this policy.
                </div>
              </div>
              <div className="mt-2 mb-3 text-justify">
                <div>
                  <BsDot size={25} /> All employees and contractors who develop,
                  maintain, or contribute to our digital products and services
                  are responsible for following this policy.
                </div>
              </div>
              <h5 className="mb-2 mt-3 fontsizeh5">
                <BsDot size={25} />
                <b> Accessibility Standards:</b>
              </h5>
              <div>
                <div className="text-justify">
                  Turn-To will follow the Web Content Accessibility Guidelines
                  (WCAG) 2.1 Level AA and the Americans with Disabilities Act
                  (ADA) guidelines to ensure that our digital products and
                  services are accessible to individuals with disabilities.
                </div>
              </div>
              <div className="mt-3">
                <h5>
                  <b>Accessibility Features:</b>
                </h5>
                <div className="mt-2">
                  <b>
                    Our digital products and services will include the following
                    accessibility features:
                  </b>
                </div>
                <div className="mt-2     text-justify">
                  <div>
                    <BsDot size={25} /> Clear and consistent navigation and menu
                    options.
                  </div>
                </div>
                <div className="mt-2 mb-3 text-justify">
                  <div>
                    <BsDot size={25} /> Alternative text for images and
                    graphics.
                  </div>
                </div>{" "}
                <div className="mt-2     text-justify">
                  <div>
                    <BsDot size={25} /> Closed captions and audio descriptions
                    for video content.
                  </div>
                </div>
                <div className="mt-2 mb-3 text-justify">
                  <div>
                    <BsDot size={25} /> Keyboard-navigable menus and interactive
                    elements.
                  </div>
                </div>{" "}
                <div className="mt-2     text-justify">
                  <div>
                    <BsDot size={25} /> High contrast colors and clear
                    typography.
                  </div>
                </div>
                <div className="mt-2 mb-3 text-justify">
                  <div>
                    <BsDot size={25} /> Compatibility with assistive
                    technologies like screen readers and magnification software.
                  </div>
                </div>
              </div>
              <h5 className="mb-2 mt-3 fontsizeh5">
                <BsDot size={25} />
                <b> Governing Law:</b>
              </h5>
              Training and Support:
              <div>
                <div className="text-justify">
                  Turn-To will provide training and support to employees and
                  contractors on accessibility best practices and the
                  requirements of this policy.
                </div>
              </div>
              <h5 className="mb-2 mt-3 fontsizeh5">
                <BsDot size={25} />
                <b> Compliance:</b>
              </h5>
              <div>
                <div className="text-justify">
                  Turn-To will ensure that our digital products and services
                  comply with the ADA and other applicable laws and regulations.
                </div>
              </div>
              <div className="text-justify">
                This policy is effective as of 1 January, 2024 and will be
                reviewed and updated annually or as needed.
              </div>
              <div className="mt-3 text-justify">
                <b>
                  If you have any questions or concerns about this policy,
                  please contact us at{" "}
                  <a href={"https://gmail.com"} target="_blank">
                    contactus@turn-to.com
                  </a>
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ADA;
