import Reaact, { useState } from "react";
import "./NewAddPage.css";
import { Accordion, Col, Modal, Nav, Navbar, Row } from "react-bootstrap";
import footerlogo from "../../assets/images/loginpagenewlogo.png";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewEmployerLogin from "../candidate/components/common/CompanyDetail/NewEmployerLogin";
import turntologo from "../../assets/images/loginpagenewlogo.png";
import setimgfirstsection from '../../Component/newwithoutloginpageimages/setpageicon.png';
import setsecondsectionimg from '../../Component/newwithoutloginpageimages/seconkeysetiocn.png';
import setimghowitssection from '../../Component/newwithoutloginpageimages/howitsectionimg.png'
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import sebacknewimg from '../../Component/AboutPageimg/jobsiframeclear.png';

const JobMapIntigration = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  const [employerloginmodal, setEmployerloginmodal] = useState(false);
  const history = useHistory();
  const postjobbtn = () => {
    if (isAuthenticated && AuthData.role == "employer") {
      history.push("/employer/post-job");
    } else {
      setEmployerloginmodal(true);
    }
  };
  return (
    <>
      <div>
        <div className="new_pagesmargin" onClick={RemoveSlider}>
          <div className="text-center mb-3">
            <img
              src={turntologo}
              alt="turn-to"
              className="text-center turntologocssnew"
            />
          </div>
          <div className="bg-white py-5">
            <div className="container">
              <Row className="align-items-center">
                <Col md={7}>
                  <h3>
                    <b>Elevate Your Career Page with Turn-To's Iframe Feature</b>
                  </h3>
                  <div className="mt-3 mb-3">
                    <b>
                      Revolutionize your career page with Turn-To's Iframe Job Map,
                      offering a direct link to an interactive job map. Enhance your
                      recruitment strategy today!
                    </b>
                  </div>
                  <div className="mt-3 mb-3">
                    In an era where attracting top talent is paramount, Turn-To offers
                    an innovative solution for employers to enhance their career pages:
                    a comprehensive media kit featuring a direct URL to an interactive
                    job map. This feature modernizes your recruitment approach and
                    provides a seamless bridge between your company's career page and
                    Turn-To's dynamic job discovery platform.
                  </div>
                  </Col>
                  <Col md={5}>
                   <img src={sebacknewimg} alt="img" className="backsecondsectionimg"/>
                  </Col>
              </Row>
            </div>
          </div>
          <div className="container px-0 py-5">
            <Row className="align-items-center">
              <Col md={6}>
                <div>
                    <img src={setimgfirstsection} alt="img" className="backsecondsectionimg"/>
                </div>
              </Col>
              <Col md={6} className="setcolsecondcenterinaldata mt-4 mt-md-0">
              <div>
                <h4>
                  <b>Why Opt for Turn-To's Media Kit?</b>
                </h4>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                      <b>Direct Access</b>
                    </div>
                    <div>
                      <div className="text-justify">
                        Offer potential candidates a direct pathway to view all
                        your job listings on an interactive map.
                      </div>
                    </div>
                    <div className="mt-2">
                       <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                      <b>Engagement Boost</b>
                    </div>
                    <div>
                      <div className="text-justify">
                        Enhance user engagement on your career page with a
                        visually appealing Turn-To icon linked to dynamic job
                        opportunities.
                      </div>
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                      <b>Simplified Integration</b>
                    </div>
                      <div>
                        <div className="text-justify">
                          Easily incorporate the Turn-To media kit into your
                          website, enriching your career page with minimal effort.
                        </div>
                      </div>
                      <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                      <b>SInnovative Recruitment</b>
                    </div>
                      <div>
                        <div className="text-justify">
                          Stand out in the recruitment landscape by providing a
                          unique, interactive job search experience.
                        </div>
                      </div>
              </div>
              </Col>
            </Row>
          </div>
          <div className="bg-white">
            <div className="container px-0 py-5">
              <Row className="align-items-center">    
                <Col md={6} className="setcolsecondcenterinaldata">
                  <div>
                    <h4 className="pb-2 pb-md-0">
                      <b>Key Features</b>
                    </h4>
                          <div className="mt-2">
                            <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                            <b>Customizable Media Kit</b>
                          </div>
                          <div>
                            <div className="text-justify">
                              Tailor the Turn-To icon to fit the aesthetics of your
                              career page, ensuring brand consistency.
                            </div>
                          </div>
                          <div className="mt-2">
                            <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                            <b>Interactive Job Map URL</b>
                          </div>
                          <div>
                            <div className="text-justify">
                              Connect candidates directly to a map showcasing your job
                              vacancies, facilitating an immersive job exploration
                              experience.
                            </div>
                          </div>
                          <div className="mt-2">
                            <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                            <b>Effortless Implementation</b>
                          </div>
                          <div>
                            <div className="text-justify">
                              Add the media kit to your career page with a simple URL
                              and icon placement, enhancing your site's functionality
                              and appeal.
                            </div>
                          </div>
                          <div className="mt-2">
                            <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                            <b>Cost-Effective Solution</b>
                          </div>
                          <div>
                            <div className="text-justify">
                              Leverage this powerful recruitment tool at no additional
                              cost, paying only for clicks or applications through our
                              CPA and CPC models.
                            </div>
                          </div>
                  </div>
                </Col>
                <Col md={6} className="mt-4 mt-md-0">
                  <div>
                      <img src={setsecondsectionimg} alt="img" className="backsecondsectionimg"/>
                  </div>
                </Col>
                </Row>
            </div>
          </div>

          <div className="container px-0 py-5">
            <Row className="align-items-center">
              <Col md={6}>
                <div>
                    <img src={setimghowitssection} alt="img" className="backsecondsectionimg"/>
                </div>
              </Col>
              <Col md={6} className="setcolsecondcenterinaldata mt-4 mt-md-0">
                <div>
                  <h4 className="pt-2 pt-md-0">
                    <b>How It Works?</b>
                  </h4>
                        <div className="mt-2">
                            <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                            <b>Access the Media Kit</b>
                          </div>
                        <div>
                          <div className="text-justify">
                            Log into your Turn-To dashboard and navigate the media kit
                            section.
                          </div>
                        </div>
                        <div className="mt-2">
                            <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                            <b>Customize Your Kit</b>
                          </div>
                        <div>
                          <div className="text-justify">
                            Choose the Turn-To icon that best matches your career page
                            design.
                          </div>
                        </div>
                        <div className="mt-2">
                            <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                            <b>Embed the URL</b>
                          </div>
                        <div>
                          <div className="text-justify">
                            Copy the provided URL linked to your job map and embed it
                            along with the icon on your career page.
                          </div>
                        </div>
                        <div className="mt-2">
                            <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                            <b>Drive Traffic</b>
                          </div>
                        <div>
                          <div className="text-justify">
                            Encourage job seekers to click the Turn-To icon, leading
                            them to an interactive map of your job openings.
                          </div>
                        </div>
                        <div className="mt-2">
                            <IoIosCheckmarkCircleOutline size={20} fill="#5932ea" className="mr-1"/>
                            <b>Monitor and Optimize</b>
                          </div>
                        <div>
                          <div className="text-justify">
                            Track the performance of your media kit integration and
                            optimize based on candidate engagement and application
                            rates.
                          </div>
                        </div>
                </div>
              </Col>
            </Row>
          </div>

        <div className="bg-white">
          <div className="container">
            <div className="row py-5">
              <Col lg={12} md={12} className="p-0 ">
                <h4 className="text-center">
                  <b>Conclusion</b>
                </h4>
                <div className="text-center">
                  Integrating Turn-To's media kit into your career page gives
                  candidates a direct and engaging path to explore job
                  opportunities through an interactive map. This unique feature
                  enhances the appeal of your career page and positions your
                  company as a forward-thinking employer. Embrace the future of
                  recruitment with Turn-To's media kit and innovatively connect
                  with talent.
                </div>
              </Col>
            </div>
          </div>
        </div>
          <div className="newlastcalltoactionset py-4">
           <div className="container">
           <div className="my-3 row">
              <Row>
                <Col  lg={9} className="p-0">
                <h5 className="discoversize text-white">
                   <b>Ready to revolutionize your recruitment strategy? </b>
                </h5>
                  <h5 className="discoversize text-white">
                    Add Turn-To's media kit to your career page now and offer
                    candidates an unparalleled job discovery experience.
                  </h5>
                </Col>
                <Col
                 lg={3}
                 
                  className="d-flex align-items-center justify-content-center  justify-content-lg-end newcssofflex p-0 mt-3 mt-lg-0"
                >
                  <button
                    className="opostivecolorbuutonset"
                    onClick={(e) => postjobbtn()}
                  >
                    Post Job
                  </button>
                </Col>
              </Row>
            </div>
          </div>
          </div>
        </div>
        <div>
          <LandingPageFooter />
        </div>
        {!isAuthenticated ? (
          <Modal
            show={employerloginmodal}
            onHide={() => setEmployerloginmodal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="remove_modelback"
          >
            <Modal.Body className="p-0">
              <div className="">
                <NewEmployerLogin
                  setEmployerloginmodal={setEmployerloginmodal}
                />
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          AuthData?.role === "user" && (
            <Modal
              show={employerloginmodal}
              onHide={() => setEmployerloginmodal(false)}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="remove_modelback"
            >
              <Modal.Body className="p-0">
                <div className="">
                  <NewEmployerLogin
                    setEmployerloginmodal={setEmployerloginmodal}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )
        )}
      </div>
    </>
  );
};

export default JobMapIntigration;
