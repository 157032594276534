import React, { useEffect, useRef } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
} from "react-bootstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PersonalInfo from "./personal-info/PersonalInfo";
import camera from "../../../../assets/images/profiles/camera.png";
import cover from "../../../../assets/images/image_2023_09_25T11_33_26_450Z.png";
import MyButton from "../common/Button/MyButton";
import Skill from "../common/Skill/Skill";
import no_avatar from "../../../../assets/images/image_2023_09_25T11_34_04_250Z.png";
import footerpagered from "../../../../../src/Component/icontopng/redarrow.png";
import footerpagepng from "../../../../../src/Component/icontopng/footerpagepng.png";

import footerpagepnggreen from "../../../../../src/Component/icontopng/greenarrow.png";

import "./profile.css";
import Swal from "sweetalert2";
import Autocompletemap from "react-google-autocomplete";
import { MdOutlineClose } from "react-icons/md";
import {
  PostprofileUpdate,
  
  getprofiledata,
  postcheckUserName,
  postupdateCovereImg,
  postupdateProfileImg,
  postupdateSkill,
} from "../../../../Api/Api";
import Autosuggest from "react-autosuggest";
import Skeleton from "react-loading-skeleton";
import { Store } from "react-notifications-component";
import historyclose from "../../../../Component/icontopng/historyclose.png";
import { useAuth } from "../../../../context/AuthProvider";
import InfoAtsBuilder from "./InfoPop/InfoAtsBuilder";
import { useSocket } from "../../../../context/SocketProvider";
import ProfileComponent from "./ProfileComponent";
import deleteicon from "../../../../Component/icontopng/deleteapppng.png";
import Editicon from "../../../../Component/icontopng/paymentedit.png";
function MultipleResume() {
  var history = useHistory();
  const { socket } = useSocket();

  const imageRef = useRef();
  const coverImageRef = useRef();
  const [defaultUserImage, setDefaultUserImage] = useState(no_avatar);
  const [defaultUserCoverImage, setDefaultUserCoverImage] = useState(cover);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedCoverFile, setSelectedCoverFile] = useState();
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [show, setShow] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [showEditcontact, setshowEditcontact] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileTitleLimt,SetProfileTitleLimt] = useState(12)
  const [skeletonschedule, setSkeletonschedule] = useState(true);
  const [showatsresume , setshowatsresume] = useState(false);
  const [skeletonschedulecoverimg, setSkeletonschedulecoverimg] =
    useState(true);
  const [skeletonscheduleUserName, setSkeletonscheduleUserName] =
    useState(true);

  const formData = new FormData();
  // model close
  const handleClose = () => {
    setShow(false);
    setupdateSkillModalShow(false);
    getProfiledataApi();
    skillError();
  };

  // personal info model close
  const handleClosePersonalInfo = () => {
    getProfiledataApi();
    setShowPersonalInfo(false);
  };

  // personal info model show
  const handleShowPersonalInfo = () => {
    setshowEditcontact(true);
  };
  // edit contact
  const handleEditContact = () => {
    getProfiledataApi();
    clearError();
    setshowEditcontact(false);
    setcontactInfoMoadlShow(false);
  };
  const showOpenAvtarFileDialog = () => {
    imageRef.current.click();
  };

  const showOpenCoverFileDialog = () => {
    coverImageRef.current.click();
  };

  // set user profile
  const setUserProfile = (profile_slug) => {
    if (profileData.username == "" && profileData.profile_slug == "") {
      Swal.fire("Please Complete Your Profile", "", "question");
    } else {
      history.push(`/user/${profileData.username}/${profile_slug}`);
    }
  };

  // for avtar change
  const handleAvtarChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!allowedTypes.includes(file?.type)) {
      return false;
    }
    setSelectedFile(file);
  };

  const handleCoverChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!allowedTypes.includes(file?.type)) {
      return false;
    }
    setSelectedCoverFile(file);
  };

  // for image
  useEffect(() => {
    if (selectedFile) {
      const objectURL = URL.createObjectURL(selectedFile);
      setDefaultUserImage(objectURL);
      UpdatePofileImgApi();
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedCoverFile) {
      const objectURL = URL.createObjectURL(selectedCoverFile);
      setDefaultUserCoverImage(objectURL);
      UpdateCoverImgApi();
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [selectedCoverFile]);

  //modal open state

  const [contactInfoMoadlShow, setcontactInfoMoadlShow] = useState(false);

  const [updateSkillModalShow, setupdateSkillModalShow] = useState(false);

  // edit code
  const { AuthData, setAuthData } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");

  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [professionalAffiliations, setProfessionalAffiliations] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [bio, setBio] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [locationGetAutoComplet, SetLocationGetAutoComplet] = useState("");
  const [isUserNameAvailable, setIsUserNameAvailable] = useState("");
  const [falseUserName, setfalseUserName] = useState("");
  const [isupdateLoading, setupdateIsLoading] = useState(false);

  //  validation State
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [linkdinError, setlinkdinError] = useState("");
  const [errSugesstion, setErrorSugesstion] = useState("");

  const [locationGetAutoCompletError, SetLocationGetAutoCompletError] =
    useState("");

  function isValidLinkedIn(linkedinProfile) {
    const linkedinRegex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;

    return linkedinRegex.test(linkedinProfile);
  }

  const Validation = () => {
    var isValid = true;
    if (firstName.trim() === "") {
      setFirstNameError("Please Enter FirstName");
      isValid = false;
    } else if (firstName.length > 50) {
      setFirstNameError("First Name should not exceed 50 characters");
      isValid = false;
    } else if (!/^[a-zA-Z]/.test(firstName)) {
      setFirstNameError("First Name should only contain letters");
      isValid = false;
    }

    if (lastName.trim() == "") {
      setLastNameError("Please Enter LastName ");
      isValid = false;
    } else if (lastName.length > 50) {
      setLastNameError("Last Name should not exceed 50 characters");
      isValid = false;
    } else if (!/^[a-zA-Z]/.test(lastName)) {
      setLastNameError("Last Name should only contain letters");
      isValid = false;
    }
    if (userName.trim() === "") {
      setfalseUserName("Please Enter User Name");
      isValid = false;
    }
    else {
      const isValidUsername = /^[a-zA-Z0-9_]{3,20}$/.test(userName);
      if (!isValidUsername) {
        setfalseUserName("Please Enter Vaild UserName");
        isValid = false;
      }
    }
    if (mobileNumber.trim() === "") {
      setMobileNumberError("Please Enter Mobile Number");
      isValid = false;
    } else if (!/^\d+$/.test(mobileNumber)) {
      setMobileNumberError("Mobile Number should contain only digits");
      isValid = false;
    } else if (mobileNumber.length > 15) {
      setMobileNumberError("Mobile Number should not exceed 15 characters");
      isValid = false;
    }
    if (linkedinProfile.trim() == "") {
      setlinkdinError("Please Enter Linkdin Profile");
      isValid = false;
    } else if (!isValidLinkedIn(linkedinProfile)) {
      setlinkdinError("Please enter a valid LinkedIn URL");
      isValid = false;
    } else if (linkedinProfile.length > 100) {
      setlinkdinError("Linkdin Profile should not exceed 100 characters");
      isValid = false;
    }
    if (locationGetAutoComplet.trim() == "") {
      SetLocationGetAutoCompletError("Please Enter Location");
      isValid = false;
    } else if (locationGetAutoComplet.length > 100) {
      SetLocationGetAutoCompletError(
        "Location should not exceed 100 characters"
      );
      isValid = false;
    } else if (!/^[a-zA-Z]/.test(locationGetAutoComplet)) {
      SetLocationGetAutoCompletError("Location should only contain letters");
      isValid = false;
    }
    return isValid;
  };

  const clearError = () => {
    setfalseUserName("");
    SetLocationGetAutoCompletError("");
    setMobileNumberError("");
    setLastNameError("");
    setFirstNameError("");
    setlinkdinError("");
  };

  // get location data
  const locationgetdata = (data) => {
    if (data.geometry.location.lat()) {
      setLatitude(data.geometry.location.lat());
    }
    if (data.geometry.location.lng()) {
      setLongitude(data.geometry.location.lng());
    }
  };

  // check username
  const checkUsername = (e) => {
    const isValidUsername = /^[a-zA-Z0-9_]{3,20}$/.test(userName);
    if (!isValidUsername) {
      setfalseUserName("Please Enter Vaild UserName");
      return false
    }
    if (userName.trim() !== "") {
      var data = {
        username: userName,
      };
      postcheckUserName(data)
        .then(function (response) {
          if (response.data.status) {
            setfalseUserName("");
          } else {
            setfalseUserName(response.data.message);
          }
        })
        .catch(function (error) {
        });
    }
  };

  // for submit api
  const SubmitApi = () => {
    clearError();
    if (!Validation()) {
      return false;
    }
    var data = {
      first_name: firstName,
      last_name: lastName,
      mobile_number: mobileNumber,
      linkedin: linkedinProfile,
      professionalAffiliations: professionalAffiliations,
      username: userName,
      location: {
        latitude: latitude,
        longitude: longitude,
        address: locationGetAutoComplet,
      },
    };
    PostprofileUpdate(data)
      .then(function (response) {
        setAuthData({
          ...AuthData,
          first_name: firstName,
          last_name: lastName,
        });

        setIsUserNameAvailable(false);
        Store.addNotification({
          title: "Contact Info Saved",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        setEditProfile(false);
        handleEditContact();
        getProfiledataApi();
      })
      .catch(function (error) {
      });
  };

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    getProfiledataApi();
  }, []);
  //---------------------------------------------------------------------------------------

  // get profile data
  const getProfiledataApi = () => {
    getprofiledata()
      .then(function (response) {
        setFirstName(response.data.data.first_name);
        setProfessionalAffiliations(
          response.data.data.info?.professionalAffiliations
        );
        setLinkedinProfile(response.data.data.info?.linkedin);
        setLastName(response.data.data.last_name);
        setUserName(response.data.data.username);
        setMobileNumber(response.data.data.mobile_number);
        setBio(response.data.data.info.bio);

        SetLocationGetAutoComplet(response.data.data.info.location.address);
        setIsUserNameAvailable(response.data.data.username == "");
        setSelectedSkills(response.data.data.info?.skills);
        setProfileData(response.data.data);
        setSkeletonscheduleUserName(false);
        setSkeletonschedule(false);
        if (response.data.data.info.profile_image !== "") {
          setDefaultUserImage(response.data.data.info.profile_image);
        } else {
          setDefaultUserImage(no_avatar);
        }

        setSkeletonschedulecoverimg(false);

        if (response.data.data.info.cover_image !== "") {
          setDefaultUserCoverImage(response.data.data.info.cover_image);
        } else {
          setDefaultUserCoverImage(cover);
        }
      })
      .catch(function (error) {
      });
  };

  // skill validation
  const skillValidation = () => {
    skillError();

    let isValid = true;
    if (!dataSupplementalPayforSkill.length) {
      setErrorSugesstion("Please Add Skill");
      isValid = false;
    }
    if (dataSupplementalPayforSkill.length > 10) {
      setErrorSugesstion("You can only add a maximum of 10 skills");
      isValid = false;
    }

    return isValid;
  };

  const skillError = () => {
    setErrorSugesstion("");
  };

  // skill update api
  const SkillUpdateApi = () => {
    if (!skillValidation()) {
      return false;
    }
    setupdateIsLoading(true);
    var data = {
      skills: dataSupplementalPayforSkill,
      profile_id: activeProfileId,
    };
    postupdateSkill(data)
      .then(function (response) {
        if (response.data.status === true) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
        }
        setupdateIsLoading(false);
        handleClose();
      })
      .catch(function (error) {
        Store.addNotification({
          title: error.response.data.error.skills,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        setupdateIsLoading(false);
      });
  };

  // --------------------------------------------------------------------------------------

  // suggestion api
  const getSuggestionsforSkill = (valueforSkill) => {
    const inputValueforSkill = valueforSkill.trim().toLowerCase();
    const inputLengthforSkill = inputValueforSkill.length;
    var formattedValue = dataSupplementalPayforSkill.map((ele) =>
      ele.toLowerCase()
    );
    return inputLengthforSkill < 0
      ? []
      : selectedSkills.filter(
        (lang) =>
          lang.name.toLowerCase().includes(inputValueforSkill) &&
          !formattedValue.includes(lang.name.toLowerCase())
      );
  };

  const getSuggestionValueforSkill = (suggestion) => suggestion.name;

  const renderSuggestionforSkill = (suggestionforSkill) => (
    <div>{suggestionforSkill.name}</div>
  );
  const [valueforSkill, setValueforSkill] = useState("");
  const [suggestionsforSkill, setSuggestionsforSkill] = useState([]);
  const [dataSupplementalPayforSkill, SetDataSupplementalPayforSkill] =
    useState([]);
  const onChangeforSkill = (event, { newValue }) => {
    setValueforSkill(newValue);
  };

  const onSuggestionsFetchRequestedforSkill = ({ value }) => {
    setSuggestionsforSkill(getSuggestionsforSkill(value));
  };

  const onSuggestionsClearRequestedforSkill = () => {
    setSuggestionsforSkill([]);
  };

  const inputPropsforSkill = {
    placeholder: "Type and click enter to add a new one",
    value: valueforSkill,
    onChange: onChangeforSkill,
  };

  const onSuggestionSelectedforSkill = (
    event,
    { suggestion, suggestionValue }
  ) => {
    // Here, you can access the selected suggestion and its value
    SetDataSupplementalPayforSkill((dataSupplementalPayforSkill) => [
      ...dataSupplementalPayforSkill,
      suggestionValue,
    ]);
    setValueforSkill("");
    setSuggestionsforSkill([]);
  };

  const deleteSupplementforSkill = (item) => {
    SetDataSupplementalPayforSkill(
      dataSupplementalPayforSkill.filter((ele) => ele != item)
    );
  };

  useEffect(() => {
    onSuggestionsClearRequestedforSkill();
  }, [dataSupplementalPayforSkill]);
  // --------------------------------------------------------------------------------------

  // updateprofile Img api
  const UpdatePofileImgApi = () => {
    formData.append("profile_image", selectedFile);

    postupdateProfileImg(formData, {
      headers: {
        "Content-Type": "multipart/form-data", 
      },
    })
      .then((response) => {
      })
      .catch((error) => {
      });
  };

  // updatecover img api
  const UpdateCoverImgApi = () => {
    formData.append("cover_image", selectedCoverFile);

    postupdateCovereImg(formData, {
      headers: {
        "Content-Type": "multipart/form-data", 
      },
    })
      .then((response) => {
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    document.title = "Profile | Turn-To";
  }, []);

  const contactinfotipsmodal = () => {
    setcontactInfoMoadlShow(!contactInfoMoadlShow);
  };

  // for skill model update
  const updateSkillModal = () => {
    setupdateSkillModalShow(!updateSkillModalShow);
  };

  const popoverRight = (
    <Popover id="popover-positioned-right" className="setpop popoverindexset">
      <div className="gray_culturetip shadow culturenewcsstips">
        <div className="mt-2">
          <h5>
            <b>Crafting an ATS-Friendly Resume: A Step-by-Step Guide</b>
          </h5>
          <div className="mt-2 mb-2">
            In today's digital age, where the first round of job application
            screenings is often conducted by machines, crafting an ATS-friendly
            resume has become crucial for job seekers. Applicant Tracking
            Systems (ATS) are software tools companies use to sift through
            resumes, making the job application process more manageable for
            hiring managers by automatically filtering out candidates who don't
            meet specific criteria. However, this also means that a resume not
            optimized for an ATS might only reach human eyes if the applicant is
            qualified. Here's a comprehensive guide to creating an ATS-optimized
            resume to ensure your application stands out.
          </div>
          <h5>
            <b>Understanding the Importance of ATS Optimization</b>
          </h5>
          <div>
            An ATS scans a resume for keywords, skills, and other relevant
            information to determine if an applicant matches the job
            description. It's essential to tailor your resume to each job you're
            applying for, including relevant keywords and presenting your
            information in a format that the ATS can quickly process.
          </div>

          <div className="mt-2 mb-1">
            <b>Step 1: Start with the Right Keywords</b>
          </div>
          <div>
            Your first step in creating an ATS-friendly resume is to
            meticulously review the job description and identify the keywords
            that represent the essential qualifications, skills, and experiences
            required for the role. These keywords could range from specific
            skills and technologies to job titles and certifications.
            Incorporating these keywords into your resume is crucial as it
            increases the chances of your resume passing the ATS screening.
          </div>
          <div className="mt-2 mb-1">
            <b>Step 2: Optimize Your Resume's Format</b>
          </div>
          <div>
            An ATS-friendly resume format is simple without any complex
            formatting that could confuse the ATS. Here are essential formatting
            tips to ensure your resume is ATS-compatible:
          </div>
          <Accordion
            defaultActiveKey={["0", "1", "2", "3", "4", "5", "6"]}
            alwaysOpen
          >
            <Accordion.Item eventKey="0" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Use a straightforward layout:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Avoid using tables, columns, or other intricate design
                    elements that might not be ATS-friendly.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Choose an appropriate file type:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    A Word document (doc or docx) or a plain-text file is
                    typically the safest bet for ATS compatibility.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Stick to standard headings:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Use typical headings like "Work Experience," "Education,"
                    and "Skills" to ensure the ATS can correctly categorize the
                    information.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Select ATS-friendly fonts:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Fonts such as Arial, Calibri, or Times New Roman are
                    recommended for their readability by most ATS.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <div className="mt-2 mb-1">
              <b>Step 3: Fine-tune Your Resume's Content</b>
            </div>
            <div className="mt-1">
              With the right keywords and format in place, focus on fine-tuning
              the content of your resume:
            </div>
            <div className="mt-1">
              Align your professional summary and job titles with the job you're
              applying for, ensuring they contain keywords from the job
              description.
            </div>
            <div className="mt-1">
              Quantify your achievements wherever possible, as tangible results
              can make your resume more compelling.
            </div>
            <div className="mt-1">
              List your skills and qualifications clearly, matching them to
              those in the job description.
            </div>
            <div className="mt-1">
              Include the acronym, the complete form of certifications, and
              technical terms to cover all bases.
            </div>
            <div className="mt-2 mb-1">
              <b>Additional Tips for ATS Resume Success</b>
            </div>
            <Accordion.Item eventKey="4" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Customize your resume for each job application:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Tailoring your resume for each position is more work but
                    significantly increases your chances of getting past the
                    ATS.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Use a professional resume builder:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Platforms like Turn-To offer tools to help you create an
                    ATS-friendly resume while allowing you to manage and track
                    your job applications effectively.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Leverage online resources:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Tools such as Jobscan's resume scanner can provide insights
                    into how well your resume matches a specific job description
                    and offer suggestions for improvement.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="text-justify">
            <b> Conclusion</b>
          </div>
          <div className="mt-2">
            Navigating the ATS is a crucial step in the modern job application
            process. Understanding how these systems work and tailoring your
            resume accordingly can significantly increase your chances of making
            it to the interview stage. Remember, the goal is not just to get
            past the ATS but to make your resume compelling enough for when it
            lands in front of human eyes. With Turn-To's innovative tools and
            this guide to creating an ATS-friendly resume, you're well on your
            way to securing your next job opportunity.
          </div>
        </div>
      </div>
    </Popover>
  );

  const [isOpenAddProfile, setIsOpenAddProfile] = useState(false);
  const [isOpenAddProfileEdit, setIsOpenAddProfileEdit] = useState(false);
  const [profileTitle, setProfileTitle] = useState("");
  const [profileTitleEdit, setProfileTitleEdit] = useState("");
  const [profileTitleEditId, setProfileTitleEditId] = useState("");
  const [profileList, setProfileList] = useState([]);
  const [activeProfileId, setActiveProfileId] = useState("");
  const [activeProfileTitle, setActiveProfileTitle] = useState("")
  const ColseAddProfileModal = () => {
    setIsOpenAddProfile(false);
    setProfileTitle("")
  };

  const ColseAddProfileModalEdit = () => {
    setIsOpenAddProfileEdit(false);
  };

  const CreateProfileMultiple = () => {
    if (socket) {
      var data = {
        token: localStorage.getItem("token"),
        title: profileTitle,
      };
      socket.emit("create-profile", data);
      socket.off("create-profile");

      socket.on("create-profile", (res) => {
        if (!res.status) {
          Store.addNotification({
            title: res.message,
            type: res.status ? "success" : "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false;
        }
        GetProfileListApi();
        Store.addNotification({
          title: res.message,
          type: res.status ? "success" : "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        if (res.status) {
          setProfileTitle("");
          GetProfileListApi();
          ColseAddProfileModal();
        }
      });
    }
  };
  const EditProfileMultipleSoket = () => {
    if (socket) {
      var data = {
        token: localStorage.getItem("token"),
        title: profileTitleEdit,
        profile_id: profileTitleEditId,
      };
      setIsLoading(true);
      socket.emit("update-profile", data);
      socket.off("update-profile");
      socket.on("update-profile", (res) => {
        Store.addNotification({
          title: res.message,
          type: res.status ? "success" : "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        setIsLoading(false);
        if (!res.status) {
          return false;
        }
        GetProfileListApi();

        if (res.status) {
          setProfileTitleEdit("");
          ColseAddProfileModalEdit();
        }
      });
    }
  };

  const GetProfileListApi = () => {
    if (socket) {
      var data = {
        token: localStorage.getItem("token"),
      };
      socket.emit("get-profile-list", data);
      socket.off("get-profile-list", data);
      socket.on("get-profile-list", (response) => {
        if (!response.status) {
          Store.addNotification({
            title: response.message,
            type: response.status ? "success" : "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false;
        }
        setProfileList(response.data);
        if (response.data.length) {
          setActiveProfileId(response.data[0]._id)
          setActiveProfileTitle(response.data[0].title)
        }
      });
    }
  };
  useEffect(() => {
    GetProfileListApi();
  }, []);

  useEffect(() => {
    if (profileList.length && activeProfileId == "") {
      setActiveProfileId(profileList[0]._id);
    }
  }, [profileList]);

  const DeleteProfileMultiple = (userData) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true
    }).then((result) => {
      if (result.value && socket) {
        var data = {
          profile_id: userData._id,
          token: localStorage.getItem("token"),
        };

        socket.emit("delete-profile", data);
        socket.off("delete-profile");
        socket.on("delete-profile", (res) => {
          Store.addNotification({
            title: res.message,
            type: res.status ? "success" : "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          GetProfileListApi();
        });
      }
    });
  };

  const EditProfileMultiple = (data) => {
    setProfileTitleEdit(data.title);
    setProfileTitleEditId(data._id);
    setIsOpenAddProfileEdit(true);
  };

  const profileCount = profileList.length;
  return (
    <>
      <div className="user-profile mb-5">
        <div className="page-header m-0 mt-1 mb-2">
          <div className="col-md-12 col-sm-12 p-0">
            <Row>
              <div className="col-md-12 col-sm-12 p-0">
                <Row>
                  <Col md={6} className="p-0">
                    <h4>ATS Resume Builder</h4>
                  </Col>
                  <Col md={6} className="text_end p-0"></Col>
                </Row>
              </div>
            </Row>
          </div>
        </div>

        <div className="row">
          <div className="col-12 p-0">
            <div className="card container newsearchinputaddborderradius p-0">
              <div className="card-body paddingcard ">
                <div className="row">
                  <div className="col-lg-12 left-side pt-3 pb-3 index_bottom">
                   

                    <Container className="p-0">
                      <div>
                        <div className="settopsectioninatsresumemultiple">
                          <div>
                            <div className="position-relative px-2">
                              <div className="mb-2 profilecenter">
                                {skeletonschedule ? (
                                  <div className="img-lg rounded-circle profile-img">
                                    <Skeleton
                                      circle
                                      height="100%"
                                      containerClassName="avatar-skeleton"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <img
                                      src={defaultUserImage}
                                      alt="profile"
                                      className="img-lg rounded-circle profile-img"
                                    />
                                    <input
                                      type="file"
                                      accept="image/*"
                                      ref={imageRef}
                                      onChange={handleAvtarChange}
                                      style={{ display: "none" }}
                                    />
                                  </>
                                )}
                                <MyButton
                                  className="p-1 border-0 ProfileBtn"
                                  onClick={showOpenAvtarFileDialog}
                                >
                                  <img
                                    src={camera}
                                    className="w-100"
                                    alt="camera"
                                  />
                                </MyButton>
                              </div>
                            </div>
                          </div>
                            <div className="">
                              <h4 className="text-center resumeblcok"> {profileList.length ? "Select Resume" : " Add Resume"}</h4>
                              <div className=" position-relative">
                                <div className="align-items-center cardaddprofilebtn">
                                  <div className="align-items-center cardsmallset">
                                   
                                    <h4 className="text-center resumeblcoksmall"> {profileList.length ? "Select Resume" : " Add Resume"}</h4>

                                    <div className="p-0">
                                      <div className="tabsetmobile align-items-center flex-wrap">
                                        {profileList.map((e, index) => {
                                          const applyBorderRight =
                                            (profileCount === 2 && index === 0) || 
                                            (profileCount === 3 && index < 2) || 
                                            (profileCount > 3 && index < profileCount - 1);
                                            
                                          return (
                                            <div key={e._id} className={`my-1 mx-md-1 widthsetprofile ${applyBorderRight ? 'borderrightinsetresumename' : ''}`}>
                                              <div
                                                className={
                                                  activeProfileId === e._id
                                                    ? 'activetabprofile d-flex align-items-center p-1 shadow iconsmallscreeen'
                                                    : 'd-flex align-items-center p-1 shadow iconsmallscreeen unactivetabrofile'
                                                }
                                                onClick={(ele) => {
                                                  setActiveProfileId(e._id);
                                                  setActiveProfileTitle(e.title);
                                                }}
                                              >
                                                <div className="p-2">
                                                  <h5 className="exp_color set_accordionfont text-left wordbrak">
                                                    {e.title}
                                                  </h5>
                                                </div>
                                                <div className="text-right">
                                                  <div>
                                                    <img
                                                      src={Editicon}
                                                      className="iconheader"
                                                      onClick={(ele) => EditProfileMultiple(e)}
                                                    />
                                                  </div>
                                                  <div>
                                                    <img
                                                      src={deleteicon}
                                                      className="iconheader"
                                                      onClick={(ele) => DeleteProfileMultiple(e)}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div>
                              <OverlayTrigger
                                trigger="click"
                                placement="auto"
                                overlay={popoverRight}
                                rootClose={true}
                                show={showatsresume}
                              >
                                <span 
                                onClick={() => setshowatsresume(!showatsresume)}
                                className="newdashbordbuttoncolor atstipsbuttonsetmain setmultiresumetipszindex float-right">
                                  {showatsresume ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                                </span>
                              </OverlayTrigger>
                              <div
                                    className={
                                      profileList.length && window.innerWidth > 992
                                        ? ""
                                        : "text-center"
                                    }>

                                    {profileList.length < 3 ? (
                                      <MyButton
                                       className="mt-2"
                                       onClick={(e) => setIsOpenAddProfile(true)}
                                      >
                                        Add Resume
                                      </MyButton>
                                    ) : (
                                      ""
                                    )}

                                  </div>
                            </div>
                          </div>
                      </div>
                    </Container>

                    <Accordion  defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}  alwaysOpen>
                      <h3 className="exp_color set_accordionfont my-3">{activeProfileTitle}</h3>
                      <h5>General Information</h5>
                      <Accordion.Item eventKey="0" className="mt-1">
                        <Accordion.Header className="set_Accordion">
                          <div className="d-flex align-items-center">
                            {profileData.first_name === "" || profileData.last_name === "" || locationGetAutoComplet === "" || profileData.mobile_number === "" || profileData.username === "" || profileData.info?.linkedin === "" ?
                              <img
                                src={footerpagered}
                                alt="open"
                                width="15px"
                                height="15px"
                              /> :
                              <img
                                src={footerpagepnggreen}
                                alt="open"
                                width="15px"
                                height="15px"
                              />
                            }
                           
                            <h5 className="exp_color set_accordionfont">
                              Contact Information
                            </h5>
                          </div>
                          <div>
                            <div className="" onClick={handleShowPersonalInfo}>
                              <div className="addcontactinfoedit"></div>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row mt-2">
                            {skeletonscheduleUserName ? (
                              <div className="userskillsection">
                                <Skeleton containerClassName="skill-skeleton" />
                              </div>
                            ) : (
                              <>
                                <Col lg={6} xs={6} className="p-0 ">
                                  <div>First Name</div>
                                </Col>
                                {skeletonscheduleUserName ? (
                                  <div className="usernameheight">
                                    <Skeleton containerClassName="username-skeleton" />
                                  </div>
                                ) : (
                                  <>
                                    {editProfile ? (
                                      <Col lg={6} className="p-0 ">
                                        <Form.Group controlId="firstname">
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter First Name"
                                            value={firstName}
                                            onChange={(e) =>
                                              setFirstName(e.target.value)
                                            }
                                          />
                                          <span className="err_msg">
                                            {firstNameError}
                                          </span>
                                        </Form.Group>
                                      </Col>
                                    ) : (
                                      <Col
                                        lg={6}
                                        xs={6}
                                        className="p-0 index_edit"
                                      >
                                        <div className="text-muted mb-1 ">
                                          {profileData.first_name}{" "}
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                )}

                                <Col lg={6} xs={6} className="p-0 ">
                                  <div>Last Name</div>
                                </Col>
                                {skeletonscheduleUserName ? (
                                  <div className="usernameheight">
                                    <Skeleton containerClassName="username-skeleton" />
                                  </div>
                                ) : (
                                  <>
                                    {editProfile ? (
                                      <Col lg={6} className="p-0 ">
                                        <Form.Group
                                          controlId="firstname"
                                          className="mt-1"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter Last Name"
                                            value={lastName}
                                            onChange={(e) =>
                                              setLastName(e.target.value)
                                            }
                                          />
                                          <span className="err_msg">
                                            {lastNameError}
                                          </span>
                                        </Form.Group>
                                      </Col>
                                    ) : (
                                      <Col
                                        lg={6}
                                        xs={6}
                                        className="p-0 index_edit"
                                      >
                                        <div className="text-muted mb-1 index_edit">
                                          {profileData.last_name}
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                )}

                                <Col lg={6} xs={6} className="p-0 ">
                                  <div>Preferred Location</div>
                                </Col>
                                {skeletonscheduleUserName ? (
                                  <div className="usernameheight">
                                    <Skeleton containerClassName="country-state-skeleton" />
                                  </div>
                                ) : (
                                  <>
                                    {editProfile ? (
                                      <Col lg={6} className="p-0 ">
                                        <>
                                          <Autocompletemap
                                            className="form-control"
                                            apiKey={
                                              process.env
                                                .REACT_APP_GOOGLE_MAP_KEY
                                            }
                                            options={{ types: ["geocode"] }}
                                            value={locationGetAutoComplet}
                                            onChange={(e) =>
                                              SetLocationGetAutoComplet(
                                                e.target.value
                                              )
                                            }
                                            onPlaceSelected={(place) => {
                                              SetLocationGetAutoComplet(
                                                place.formatted_address
                                              );
                                              locationgetdata(place);
                                            }}
                                          />
                                          <span className="err_msg">
                                            {locationGetAutoCompletError}
                                          </span>
                                        </>
                                      </Col>
                                    ) : (
                                      <Col lg={6} xs={6} className="p-0 ">
                                        <div className="text-muted mb-1 index_edit">
                                          {locationGetAutoComplet}
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                )}

                                <Col lg={6} xs={6} className="p-0 ">
                                  <div>Mobile Number</div>
                                </Col>
                                {skeletonscheduleUserName ? (
                                  <div className="usernameheight float-right">
                                    <Skeleton containerClassName="phone-skeleton" />
                                  </div>
                                ) : (
                                  <>
                                    {editProfile ? (
                                      <Col lg={6} className="p-0 ">
                                        <Form.Group
                                          controlId="firstname"
                                          className="mt-1"
                                        >
                                          <Form.Control
                                            type="number"
                                            value={mobileNumber}
                                            onChange={(e) =>
                                              setMobileNumber(e.target.value)
                                            }
                                            placeholder="Enter Mobile Number"
                                          />
                                          <span className="err_msg">
                                            {mobileNumberError}
                                          </span>
                                        </Form.Group>
                                      </Col>
                                    ) : (
                                      <Col lg={6} xs={6} className="p-0 ">
                                        <div className="text-muted mb-1 index_edit">
                                          {profileData.mobile_number}
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                )}

                                <Col lg={6} xs={6} className="p-0 ">
                                  <div>Username</div>
                                </Col>
                                {skeletonscheduleUserName ? (
                                  <div className="usernameheight float-right">
                                    <Skeleton containerClassName="gender-skeleton" />
                                  </div>
                                ) : (
                                  <>
                                    {editProfile ? (
                                      isUserNameAvailable ? (
                                        <Col lg={6} className="p-0 mt-1">
                                          <Col lg={12} className="p-0">
                                            <Form.Group controlId="username mt-1">
                                              <Form.Control
                                                type="text"
                                                placeholder="Enter Username"
                                                onBlur={(e) => checkUsername()}
                                                value={userName}
                                                onChange={(e) => {
                                                  setUserName(e.target.value);
                                                }}
                                              />
                                            </Form.Group>
                                            <span className="err_msg">
                                              {falseUserName}
                                            </span>
                                          </Col>
                                        </Col>
                                      ) : (
                                        <Col lg={6} className="p-0 mt-1">
                                          <Col lg={12} className="p-0">
                                            <Form.Group controlId="username mt-1">
                                              <Form.Control
                                                type="text"
                                                placeholder="Enter Username"
                                                onBlur={(e) => checkUsername()}
                                                value={userName}
                                                disabled
                                              />
                                            </Form.Group>
                                          </Col>
                                        </Col>
                                      )
                                    ) : (
                                      <Col lg={6} xs={6} className="p-0 ">
                                        <div className="text-muted mb-1 index_edit">
                                          {profileData.username}
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                )}

                                <Col lg={6} xs={6} className="p-0 ">
                                  <div>Linkedin profile*</div>
                                </Col>
                                {skeletonscheduleUserName ? (
                                  <div className="usernameheight float-right">
                                    <Skeleton containerClassName="phone-skeleton" />
                                  </div>
                                ) : (
                                  <>
                                    {editProfile ? (
                                      <Col lg={6} className="p-0 ">
                                        <Form.Group
                                          controlId="firstname"
                                          className="mt-1"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Linkedin Profile"
                                            value={linkedinProfile}
                                            onChange={(e) =>
                                              setLinkedinProfile(e.target.value)
                                            }
                                          />
                                          <span className="err_msg">
                                            {linkdinError}
                                          </span>
                                        </Form.Group>
                                      </Col>
                                    ) : (
                                      <Col lg={6} xs={6} className="p-0 ">
                                        <div className="text-muted mb-1 index_edit">
                                          {profileData.info?.linkedin}
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <hr className="mb-0"></hr>
                      <div className="postion-relative">
                        <div className="tabboderremove">
                          {profileList.length ? (
                            <Tabs
                              activeKey={activeProfileId}
                              onSelect={(data) => {
                                setActiveProfileId(data);
                              }}
                              id="multipleresume"
                              className="mb-1 multipleresumetab border-0 d-none"
                            >
                              {profileList.map((e) => {
                                return (
                                  <Tab
                                    eventKey={e._id}
                                    title={
                                      <div className="d-flex align-items-center p-1 row">
                                        <div className="p-0 col-8 col-md-10">
                                          <h5 className="exp_color set_accordionfont text-left wordbrak">
                                            {" "}
                                            {e.title}
                                          </h5>
                                        </div>
                                        <div className="col-4 col-md-2 text-right">
                                          <div>
                                            <img
                                              src={Editicon}
                                              className="iconheader"
                                              onClick={(ele) =>
                                                EditProfileMultiple(e)
                                              }
                                            />
                                          </div>
                                          <div>
                                            <img
                                              src={deleteicon}
                                              className="iconheader"
                                              onClick={(ele) =>
                                                DeleteProfileMultiple(e)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  >
                                    <ProfileComponent
                                      setUserProfile={setUserProfile}
                                      profile_id={e._id}
                                    />
                                  </Tab>
                                );
                              })}
                            </Tabs>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* model */}
      <Modal
        size="lg"
        show={showPersonalInfo}
        onHide={handleClosePersonalInfo}
        centered
      >
        <Modal.Header className="model_personal">
          <Modal.Title className="close_info">
            Personal Info
            <img
              src={historyclose}
              alt="close"
              className="close_personal reportclosepng"
              onClick={handleClosePersonalInfo}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PersonalInfo close={handleClosePersonalInfo} />
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        centered
      >
        <Modal.Body className="p-0">
          <div className="d-flex justify-content-center">
            <div className="modalsetwidth">
              <Col lg={12}>
                <Row className="bg-white userdatapart border-radiustip">
                  <div className="col-12 mt-3 tipsbuttonset">
                    <div className="index_newcss">
                      <div className="contectinfoshowname mb-2">
                        <div>Skills</div>
                      </div>
                      <div className="mb-2">
                        <span
                          onClick={updateSkillModal}
                          className="newdashbordbuttoncolor atstipsbuttonset"
                        >
                          {updateSkillModalShow ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                        </span>
                      </div>
                    </div>
                  </div>

                  <Col>
                    <Form.Group controlId="">
                      <Form.Label className="titlename mt-2">
                        <b>Choose Up To 8</b>
                      </Form.Label>
                      <Col xs={12} className="p-0">
                        <Row>
                          {dataSupplementalPayforSkill.map((item) => {
                            return (
                              <>
                                <div
                                  className="mx-1 my-1 cursor-pointer"
                                  onClick={(e) =>
                                    deleteSupplementforSkill(item)
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    <Skill>{item}</Skill>
                                    <i className="mdi mdi-close"></i>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </Row>
                      </Col>

                      <Autosuggest
                        suggestions={suggestionsforSkill}
                        onSuggestionsFetchRequested={
                          onSuggestionsFetchRequestedforSkill
                        }
                        alwaysRenderSuggestions={true}
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequestedforSkill
                        }
                        onSuggestionSelected={onSuggestionSelectedforSkill}
                        getSuggestionValue={getSuggestionValueforSkill}
                        renderSuggestion={renderSuggestionforSkill}
                        inputProps={inputPropsforSkill}
                      />
                      <span className="err_msg">{errSugesstion}</span>
                    </Form.Group>
                    <div className="setmodalfooterbutton">
                      <Button
                        variant="danger"
                        onClick={handleClose}
                        className="contact_radius mr-2"
                      >
                        Cancel
                      </Button>
                      {dataSupplementalPayforSkill.length < 9 && (
                        <button
                          variant="primary"
                          className="my-btn "
                          onClick={SkillUpdateApi}
                        >
                          {isupdateLoading ? (
                            <Spinner
                              animation="border"
                              role="status"
                              className="spinneerset"
                            >
                              <span className="visually-hidden"></span>
                            </Spinner>
                          ) : (
                            "Update"
                          )}
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
            <div className="position-relative">
              {updateSkillModalShow ? (
                <Col
                  lg={12}
                  className="tipsmodalsetmobilescreen addnewsettipopenmodalwidth setpaddingmobiletips"
                >
                  <div className="contactinfo_tip updateSkillscrollheight border-radiustip">
                    <div className="mt-2">
                      <div>
                        For Turn-To, here's a guide to help users optimize their
                        skills section in their profiles or resumes:
                      </div>
                      <div className="mt-2">
                        <b>Showcasing Your Skills on Turn-To</b>
                      </div>
                      <div>
                        Your skills section is a vital part of your profile or
                        resume, offering a snapshot of your abilities and
                        setting you apart from other candidates. On Turn-To,
                        this is your chance to shine and make a memorable
                        impression on potential employers by succinctly listing
                        your work-related talents.
                      </div>
                      <div className="mt-2">
                        <b>Tips for an Impactful Skills Section:</b>
                      </div>
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        <Accordion.Item eventKey="0" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Keep Punctuation Minimal:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Let your skills speak for themselves without the
                                need for periods or exclamation marks. Bullet
                                points create a cleaner look.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
                        <Accordion.Item eventKey="0" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Emphasize Transferable Skills:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                If you find your role-specific skills are
                                limited, highlight universal skills such as
                                "Effective Communication," "Team Collaboration,"
                                or "Strategic Planning."
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Draw From the Job Description:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Align your listed skills with those mentioned in
                                the job you’re applying for. Employers
                                appreciate candidates who reflect the qualities
                                they’re seeking.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion
                        defaultActiveKey={["0", "1", "2", "3"]}
                        alwaysOpen
                      >
                        <Accordion.Item eventKey="0" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Customize for Each Application:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Tailor your skills section for every job
                                application to demonstrate how you’re the
                                perfect fit for each unique role.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Format with Clarity:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Capitalize the first letter of each skill and
                                stick to concise, 2-3 word phrases for easy
                                reading.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Optimal Length:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Your skills section should not dominate your
                                resume or profile. Aim for it to occupy no more
                                than a quarter of the page.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Skill Quantity
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Limit yourself to 3-4 skills per column,
                                ensuring you showcase a total of 9-12 of your
                                most relevant skills.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Example Skills Layout for Turn-To
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>- Effective Leadership</div>
                              <div>- Agile Methodologies</div>
                              <div>- Creative Problem-Solving</div>
                              <div>- Customer Engagement</div>
                              <div>- Data Analysis Proficiency</div>
                              <div>- Cross-Functional Teamwork</div>
                              <div>- Innovative Thinking</div>
                              <div>- Tech Savviness</div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="mt-2">
                        Remember, the skills section is an opportunity to
                        illustrate the unique talents that make you the ideal
                        candidate for the position. By focusing on relevance,
                        clarity, and brevity, you can effectively capture the
                        attention of employers on Turn-To.
                      </div>
                      <div className="mt-2">
                        <b>
                          This guide aims to assist Turn-To users in crafting a
                          compelling skills section that showcases their talents
                          and aligns with potential employers' needs, enhancing
                          their visibility in the job market.
                        </b>
                      </div>
                    </div>
                  </div>
                </Col>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <InfoAtsBuilder />

      {/* contact info model */}
      <Modal
        show={showEditcontact}
        onHide={handleEditContact}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        centered
      >
        <Modal.Body className="p-0">
          <Row>
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
                <Col lg={12}>
                  <Row className="bg-white userdatapart border-radiustip">
                    <div className="col-12 mt-3 tipsbuttonset">
                      <div className="index_newcss">
                        <div className="contectinfoshowname mb-2">
                          <div>Contact Info</div>
                        </div>
                        <div className="mb-2">
                          <span
                            onClick={contactinfotipsmodal}
                            className="newdashbordbuttoncolor atstipsbuttonset"
                          >
                            {contactInfoMoadlShow ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                          </span>
                        </div>
                      </div>
                    </div>
                    <Col lg={6} className="">
                      <Form.Group controlId="firstname">
                        <Form.Label className="mt-2 label_class">
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="newsearchinputaddborderradius"
                          placeholder="Enter First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <span className="err_msg">{firstNameError}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="">
                      <Form.Group controlId="lastname">
                        <Form.Label className="mt-2 label_class">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="newsearchinputaddborderradius"
                          placeholder="Enter Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <span className="err_msg">{lastNameError}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="">
                      <Form.Group controlId="mobilnumber">
                        <Form.Label className="mt-2 label_class">
                          Mobile Number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="newsearchinputaddborderradius"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          placeholder="Enter Mobile Number"
                        />
                        <span className="err_msg">{mobileNumberError}</span>
                      </Form.Group>
                    </Col>{" "}
                    <Col lg={6} className="">
                      <Form.Group controlId="linkdin">
                        <Form.Label className="mt-2 label_class">
                          Linkedin profile*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="newsearchinputaddborderradius"
                          placeholder="Linkedin Profile"
                          value={linkedinProfile}
                          onChange={(e) => setLinkedinProfile(e.target.value)}
                        />
                        <span className="err_msg">{linkdinError}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="">
                      <Form.Group controlId="role">
                        <Form.Label className="mt-2 label_class">
                          Username
                        </Form.Label>
                        {isUserNameAvailable ? (
                          <>
                            {" "}
                            <Form.Control
                              type="text"
                              className="newsearchinputaddborderradius"
                              placeholder="Enter Username"
                              onBlur={(e) => checkUsername()}
                              value={userName}
                              onChange={(e) => {
                                setUserName(e.target.value);
                              }}
                            />
                            <span className="err_msg">{falseUserName}</span>
                          </>
                        ) : (
                          <Form.Control
                            type="text"
                            className="newsearchinputaddborderradius"
                            placeholder="Enter Username"
                            onBlur={(e) => checkUsername()}
                            value={userName}
                            disabled
                          />
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="">
                      <Form.Group controlId="location">
                        <Form.Label className="mt-2 label_class">
                          Preferred Location
                        </Form.Label>
                        <>
                          <Autocompletemap
                            className="form-control newsearchinputaddborderradius"
                            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                            options={{ types: ["geocode"] }}
                            value={locationGetAutoComplet}
                            onChange={(e) =>
                              SetLocationGetAutoComplet(e.target.value)
                            }
                            onPlaceSelected={(place) => {
                              SetLocationGetAutoComplet(
                                place.formatted_address
                              );
                              locationgetdata(place);
                            }}
                          />
                          <span className="err_msg">
                            {locationGetAutoCompletError}
                          </span>
                        </>
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <div className="setmodalfooterbutton">
                        <Button
                          variant="danger"
                          className="contact_radius mr-2"
                          onClick={handleEditContact}
                        >
                          Cancel
                        </Button>
                        <MyButton type={"submit"} onClick={SubmitApi}>
                          Save
                        </MyButton>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div className="position-relative">
                {contactInfoMoadlShow ? (
                  <Col
                    lg={12}
                    className="tipsmodalsetmobilescreen addnewsettipopenmodalwidth setpaddingmobiletips"
                  >
                    <div className="info_tip1 scrollheightset border-radiustip">
                      {/* <h5 className="tips">Tips</h5>
                    <hr className="m-0"></hr> */}

                      <div className="mt-2">
                        <div>
                          <b>The Importance of Contact Information</b>
                        </div>
                        <div className="mt-3 mb-3">
                          Over 80% of employers emphasize the necessity of
                          having a contact number on a resume.** Ensure your
                          profile or resume includes comprehensive contact
                          details to increase your chances of being invited for
                          an interview.
                        </div>
                        <div>
                          <b>Key Details Employers Look For:</b>
                        </div>
                        <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Phone Number:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  A direct line to reach you for potential job
                                  opportunities.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              City and State:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Your current location helps employers gauge
                                  logistical considerations, especially for
                                  local positions.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div>
                          <b>Applying for Distant Jobs?</b>
                        </div>
                        <div className="mt-3 mb-3">
                          If you're eyeing positions in other areas, mentioning
                          your intent to relocate in your cover letter can
                          significantly boost your candidacy. It provides
                          context to your application, demonstrating your
                          genuine interest and commitment to the role.
                        </div>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Action Steps:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  1. Ensure your phone number is up-to-date.
                                </div>
                                <div>
                                  2. Verify your city and state reflect your
                                  current or intended location.
                                </div>
                                <div>
                                  3. Tailor your cover letter to address any
                                  location-related concerns.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-3 mb-3">
                          By paying attention to these details, you not only
                          adhere to what most employers look for but also
                          position yourself as a thoughtful and serious
                          candidate.
                        </div>
                        <div className="mt-2 mb-2">
                          <b>
                            Turn-To is dedicated to connecting you with your
                            ideal job by ensuring every detail counts.
                          </b>
                        </div>
                        <div className="mt-2 mb-2">
                          <b>
                            ©2024 Turn-To - Your bridge to the next career
                            opportunity.
                          </b>
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={isOpenAddProfile}
        onHide={ColseAddProfileModal}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="contactinfomodeldiv">
            <div>Add Resume</div>
            <img
              src={historyclose}
              alt="close"
              className="close_model set_editcontact reportclosepng"
              onClick={ColseAddProfileModal}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12} className="">
              <Form.Group controlId="firstname">
                <Form.Label className="mt-2 label_class">
                  Profile Title
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={profileTitleLimt}
                  className="newsearchinputaddborderradius"
                  placeholder="Enter Profile Title"
                  value={profileTitle}
                  onChange={(e) => setProfileTitle(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      CreateProfileMultiple();
                    }
                  }}
                />
                <span className="err_msg">{firstNameError}</span>
              </Form.Group>
            </Col>
            <Col lg={12} className="d-flex justify-content-end">
              <div>{profileTitle ? profileTitle.length : 0}/{profileTitleLimt}</div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className="my-btn" onClick={CreateProfileMultiple}>
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isOpenAddProfileEdit}
        onHide={ColseAddProfileModalEdit}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="contactinfomodeldiv">
            <div>Edit Profile</div>
            <img
              src={historyclose}
              alt="close"
              className="close_model set_editcontact reportclosepng"
              onClick={ColseAddProfileModalEdit}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12} className="">
              <Form.Group controlId="firstname">
                <Form.Label className="mt-2 label_class">
                  Profile Title
                </Form.Label>
                <Form.Control
                  type="text"
                  className="newsearchinputaddborderradius"
                  placeholder="Enter Profile Title"
                  maxLength={profileTitleLimt}
                  value={profileTitleEdit}
                  onChange={(e) => setProfileTitleEdit(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      EditProfileMultipleSoket();
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={12} className="d-flex justify-content-end">
              <div>{profileTitleEdit ? profileTitleEdit.length : 0}/{profileTitleLimt}</div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <button className="my-btn">
              <Spinner
                style={{ width: "15px", height: "15px" }}
                animation="border"
                role="status"
              ></Spinner>
            </button>
          ) : (
            <button className="my-btn" onClick={EditProfileMultipleSoket}>
              Update
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MultipleResume;
