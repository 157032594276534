import Reaact, { useState } from "react";
import "./NewAddPage.css";
import { Accordion, Col, Modal, Nav, Navbar, Row } from "react-bootstrap";
import footerlogo from "../../assets/images/loginpagenewlogo.png";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";
import NewEmployerLogin from "../candidate/components/common/CompanyDetail/NewEmployerLogin";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewUserLogin from "../candidate/components/common/CompanyDetail/NewUserLogin";

const BillingRecruitment = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const [employerloginmodal, setEmployerloginmodal] = useState(false);

  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  const history = useHistory();
  const postjobbtn = () => {
    if (isAuthenticated && AuthData.role == "user") {
      history.push("/profile");
    } else {
      setEmployerloginmodal(true);
    }
  };
  return (
    <>
      <div>
        <HeaderNew />
        <div className="container new_pagesmargin" onClick={RemoveSlider}>
          <h3 className="text-center">
            <b>
              Turn-To: Simple, Transparent Billing for Your Recruitment Needs
            </b>
          </h3>
          <hr></hr>

          <div className="mt-3 mb-3">
            Turn-To's straightforward billing structure ensures clarity and
            predictability for your recruitment budget.
          </div>
          <div>
            <h5>
              <b>How It Works:</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Flat-Rate Pricing
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Unlike traditional job boards with complex calculations,
                      Turn-To offers a simple daily rate per job posting. Choose
                      between 15-day or 30-day placements to fit your specific
                      needs.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Transparent Invoicing:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      We generate invoices on the first day of each month,
                      detailing the previous month's job posting activity. This
                      allows you to track your recruitment expenses easily.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Example:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      You post a job at $10/day with a 15-day placement. Your
                      total Charge for this job posting would be:<br></br>
                      <b>Daily Rate:</b> $10<br></br>
                      <b>Placement Duration:</b> 15 days<br></br>
                      <b>Total Charge: $10/day * 15 days =</b> $150
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="mt-3">
            <h5>
              <b>Benefits of Flat-Rate Billing:</b>
            </h5>
            <Accordion
              defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
              alwaysOpen
            >
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Effortless Budgeting:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Know how much you'll spend upfront, allowing for accurate
                      recruitment cost planning.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Flexibility:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Choose the placement duration (15 or 30 days) that best
                      aligns with your hiring needs.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Focus on Results:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Concentrate on attracting top talent without worrying
                      about hidden fees or complex pricing structures.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="mt-3">
            <h5>
              <b>Additional Resources:</b>
            </h5>
            <div className="mt-3 mb-3">
              We're here to help! If you have any questions about billing,
              explore our additional resources
            </div>
            <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Viewing Your Invoice:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      A step-by-step guide to accessing your monthly invoices.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Updating Billing Information:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Learn how to update your payment method on file easily.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Contacting Turn-To Support:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Our dedicated team is happy to answer any questions.
                      please email us at{" "}
                      <a href={"https://gmail.com"} target="_blank">
                        info@turn-to.com
                      </a>
                    </div>
                    <div className="text-justify mt-1">
                      Turn-To prioritizes simplicity and transparency in
                      everything we do. Our flat-rate billing system reflects
                      that commitment, empowering you to focus on what matters
                      most - building a talented team.
                    </div>
                    <div className="text-justify mt-3">
                      <b>
                        Let's get started! Sign up today and experience the
                        power of Turn-To's AI-driven job map with transparent,
                        predictable billing.
                      </b>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div>
          <LandingPageFooter />
        </div>
      </div>
    </>
  );
};

export default BillingRecruitment;
