import React from "react";
import "./AboutPage.css";
import firstsecimg from "./../../Component/AboutPageimg/secondimg.png";
import firstthirdimg from "./../../Component/AboutPageimg/thirdimg.png";
import fisthforthimg from "./../../Component/AboutPageimg/forthimg.jpg";

import { Col, Row } from "react-bootstrap";
import LandingPageFooter from "../../app/Landingpage/LandingPageFooter";

const AboutPage = () => {
  return (
    <>
      <div className="aboutpagebgimg">
        <div className="container">
          <div className="about_fisrtsec">
            <div className="about_firstsubsec">
              <h1 className="about_maps">Turn-To My Saved Jobs</h1>
              <p class="about_firtp">Your Personalized Job Map Hub.</p>
              <p>
                <a class="about_started" href="#">
                  Get started
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about_second ">
        <div className="container">
          <div className="about_intro">
            <h1 className="aboutsec_map">Features of My Saved Jobs</h1>
            <p className="aboutsec_easily">
              Lorem ipsum dolor sit amet, consectetur adipiscing.
            </p>
          </div>
          <Row>
            <Col lg={7}>
              <div>
                <img
                  src={firstsecimg}
                  alt="firstsecimg"
                  className="firstsecimg"
                ></img>
              </div>
            </Col>
            <Col lg={5}>
              <div class="about_colfive">
                <strong>Interactive Map View</strong>
                <p>
                  See all your saved jobs displayed on a dynamic map for easy
                  visualization of job locations and potential commutes.
                </p>

                <strong>Customizable Lists</strong>
                <p>
                  Organize your saved jobs into custom lists based on criteria
                  like industry, job type, or priority level.
                </p>

                <strong>Job Details at a Glance</strong>
                <p>
                  Access key information about each saved job with just one
                  click, including job title, company name, and application
                  status.
                </p>

                <strong>Effortless Navigation</strong>
                <p>
                  Seamlessly switch between map view and list view to suit your
                  preference while planning your job applications.
                </p>

                <strong>Real-Time Updates</strong>
                <p>
                  Receive notifications for any changes to your saved jobs, such
                  as application deadlines or status updates.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="about_second ">
        <div className="container">
          <div className="about_intro1">
            <h1 className="aboutsec_map">Get Started with My Saved Jobs</h1>
            <p className="aboutsec_easily">
              Lorem ipsum dolor sit amet, consectetur adipiscing.
            </p>
          </div>
          <Row>
            <Col lg={5}>
              <div class="about_colfive1">
                <strong>Save with Ease</strong>
                <p>
                  Bookmark jobs directly from search results or job descriptions
                  with a single click.
                </p>

                <strong>Organize Your Search</strong>
                <p>
                  Use filters and tags to manage your saved jobs and keep your
                  job search focused and efficient.
                </p>

                <strong>Track Your Progress</strong>
                <p>
                  Monitor your application statuses and follow up on
                  opportunities right from your saved jobs dashboard.
                </p>
              </div>
            </Col>
            <Col lg={7}>
              <div>
                <img
                  src={firstthirdimg}
                  alt="firstthirdimg"
                  className="firstsecimg"
                ></img>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="about_second ">
        <div className="container">
          <div className="about_intro">
            <h1 className="aboutsec_map">Share and Collaborate</h1>
            <p className="aboutsec_easily">
              Lorem ipsum dolor sit amet, consectetur adipiscing.
            </p>
          </div>
          <Row>
            <Col lg={7}>
              <div className="about_colseven">
                <img
                  src={fisthforthimg}
                  alt="firthforthimg"
                  className="fisthforthimg"
                ></img>
              </div>
            </Col>
            <Col lg={5}>
              <div class="about_colfive">
                <strong>Collaborate on Your Job Hunt</strong>
                <p>
                  hare your saved jobs with mentors, friends, or family for
                  feedback and support.
                </p>

                <strong>Export Options</strong>
                <p>
                  {" "}
                  Easily export your saved jobs list for external review or to
                  keep a personal backup.
                </p>

                <strong>Mobile Accessibility</strong>
                <p>
                  Access your saved jobs on-the-go with the Turn-To mobile app,
                  syncing your data across devices.
                </p>

                <strong>Personalized Alerts</strong>
                <p>
                  Set up alerts for new job opportunities that match the
                  criteria of your saved jobs.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="about_second ">
        <div className="container">
          <div className="about_intro">
            <h1 className="aboutsec_map">
              Become part of a community that supports your job search journey.
              Share insights, get advice, and connect with other job seekers.
            </h1>
            <p>
              <a class="about_started" href="#">
                Join the Turn-To Community
              </a>
            </p>
          </div>
        </div>
      </div>
      <div>
        <LandingPageFooter />
      </div>
    </>
  );
};

export default AboutPage;
