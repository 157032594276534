import React, { useEffect,  useState } from "react";
import Preference from "../preference/Preference";
import { Col, Container, OverlayTrigger, Row, Popover, Accordion } from "react-bootstrap";
import footerpagepng from "../../../../../Component/icontopng/footerpagepng.png"
import { MdOutlineClose } from "react-icons/md";
import { getprofiledata } from "../../../../../Api/Api";
import "../profile.css";
import InfoCulture from "../InfoPop/InfoCulture";
import { Store } from "react-notifications-component";

const Cultureandpreferences = () => {
  const [profileData, setProfileData] = useState({});
  const [jobfeedtipopen , setjobfeedtipopen] = useState(false);

  useEffect(() => {
    getProfiledataApi();
  }, []);

  // getProfiledataApi
  const getProfiledataApi = () => {
    getprofiledata()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
       
        setProfileData(response.data.data);
      })
      .catch(function (error) {
      });
  };

  // Popover
  const popoverRight = (
    <Popover
      id="popover-positioned-right"
      className="setpop popover_set"
    >
      <div className="gray_culturetip shadow culturenewcsstips">

        <div className="mt-2">
          <div>
            <b>
              Craft Your Perfect Job Search
            </b>
          </div>
          <div className="mt-3 mb-3">
             By reflecting on your *unique preferences and work style*. Answering these questions will help you
          </div>
          <div>
            *Identify* your ideal work environment and culture.
          </div>
          <div>
            *Refine* your search based on what matters most to *you*.
          </div>
          <div>
            *Discover* jobs that align with your *values and aspirations*.
          </div>
          <div className="mt-3">
            <b>
              Use these prompts to explore:
            </b>
          </div>
          <Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8']} alwaysOpen>
            <Accordion.Item eventKey="0" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Ideal Work Environment:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Describe your preferred work setting
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Feedback and Recognition:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    How do you thrive on feedback and recognition
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Work-Life Balance:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    What work-life balance arrangements are important to you
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Values Alignment:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    What values are most important to you in a workplace
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Commute Preferences:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Set your preferred commute time/distance.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Company Culture:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    What kind of work environment do you find most inspiring and motivating?
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Company Size:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Do you prefer a large, medium, or small company setting?
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Benefits & Perks:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Which benefits are most important to you
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8" className="my-2">
              <Accordion.Header className="fontsetAccordion">
                <img
                  src={footerpagepng}
                  alt="open"
                  width="15px"
                  height="15px"
                />{" "}
                Adaptability:
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    Share an example of how you've successfully adapted to a new company culture.
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="text-justify">
            <b> By *answering honestly and thoughtfully, you'll gain valuable insight into your **unique work personality. This information, combined with your search bar history and objectives, will paint a **holistic picture* of your ideal career path.</b>
          </div>
          <div className="mt-2"> <b>
            Remember:</b></div>
          <div className="text-justify mt-2">
            Be *specific* in your answers for the most relevant results.
          </div>
          <div className="text-justify mt-2">
            This information will help refine your job search and *uncover opportunities* that fit you perfectly.
          </div>
          

        </div>
      </div>
    </Popover>
  );

  return (
    <>
      <div className="user-profile">
        <Row>
          <Col md={6} className="p-0 mb-2 mt-1">
            <h4>Job Feed Preferences</h4>
          </Col>
          <Col md={6} className="text_end pr-2 pt-2">
            
          </Col>
        </Row>

        <Container className="bg-white py-3 mb-5 addnewCulturePreferences">
          <Row>
            <Col>
              <h4>Optimize Your Job Feed</h4>
              <OverlayTrigger
                trigger="click"
                placement="auto"
                overlay={popoverRight}
                rootClose={true}
                show={jobfeedtipopen}
              >
                <span
                  onClick={()=>setjobfeedtipopen(!jobfeedtipopen)}
                  className="newdashbordbuttoncolor fix_tips mt-4"
                >
                  {jobfeedtipopen ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                </span>
              </OverlayTrigger>

              <Preference profileData={profileData} profileDataCulture={profileData.culture_fit}/>
            </Col>
          </Row>
        </Container>
        <InfoCulture />
      </div>
    </>
  );
};
export default Cultureandpreferences;
