import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import Autocomplete from "react-autocomplete";
import { useSocket } from "../../../../../context/SocketProvider";
import footerpagepng from "../../../../../Component/icontopng/footerpagepng.png";
import { MdOutlineClose } from "react-icons/md";
import {
  ProfessionalAffiliationsDataAdd,
  ProfessionalAffiliationsData,
  ProfessionalAffiliationsDataDelete,
  PostAffiliationsUpdate,
} from "../../../../../Api/Api";
import { Store } from "react-notifications-component";
import Avatar from "react-avatar";
import editdatapng from "../../../../../Component/icontopng/editdata.png";
import deletecarddatapng from "../../../../../Component/icontopng/deletecarddata.png";
import MyButton from "../../common/Button/MyButton";
import addcarddata from "../../../../../Component/icontopng/addcarddata.png";
import Swal from "sweetalert2";
import Tooltip from "react-tooltip-lite";

const OtherSection = (props) => {
  const { socket } = useSocket();
  const [titleInput, setTitleInput] = useState(useRef(""));
  const [languagesknow, setLanguagesknow] = useState("");
  const [proficiencyLevels, setProficiencyLevels] = useState("");
  const [language_id, setlanguage_id] = useState("");
  const [languagesData, setLanguagesData] = useState([]);
  const [languageError, setLanguagesError] = useState("");
  const [proficiencyError, setProficiencyError] = useState("");
  const [languagesDataList, setLanguagesDataList] = useState([]);
  const [iscurrentilyWorking, setIscurrentilyWorking] = useState(false);
  const [endyear, setendyear] = useState("");
  const [affliation, setAffliation] = useState("");
  const [errendyear, seterrEndyear] = useState("");
  const [iscontactLoading, setContactIsLoading] = useState(false);
  const [professionalModalShow, setprofessionalModalShow] = useState(false);
  const [errJobDescription, seterrJobDescription] = useState("");


  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setprofessionalModalShow(false);
    setLanguagesknow("");
    setProficiencyLevels("");
    setAffliation("");
    setendyear("");
    setlanguage_id(null);
    setIscurrentilyWorking(false);
    Otpclear()
  }
  const OnFouceOut = () => {
    if (titleInput?.current) {
      titleInput?.current.blur();
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", OnFouceOut);
    return () => {
      window.removeEventListener("scroll", OnFouceOut);
    };
  }, [])

  useEffect(() => {
    if (props.profileId != "") {
      LanguageDataGetApi();
    }
    socket.emit("professional-affiliations", {}, (res) => {
      setLanguagesData(res.data);
    });
  }, [props.profileId]);

  // LanguageDataGetApi
  const LanguageDataGetApi = () => {
    ProfessionalAffiliationsData({ profile_id: props.profileId })
      .then((response) => {
        if (!response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        setLanguagesDataList(response.data.data);
        props.setlanguagecountssss(response.data.data)
      })
      .catch((error) => {
      });
  };

  // Otpclear
  const Otpclear = () => {
    setProficiencyError("");
    setLanguagesError("");
    seterrEndyear("");
    seterrJobDescription("")
  };

  // Otpvalidation
  const Otpvalidation = () => {
    Otpclear();
    var isvalid = true;
    if (languagesknow.trim() == "") {
      setLanguagesError("Please Select Organization");
      isvalid = false;
    } else if (languagesknow.length > 100) {
      setLanguagesError("Language should not exceed 100 characters");
      isvalid = false;
    }
    if (proficiencyLevels == "") {
      setProficiencyError("Please Select Start Year");
      isvalid = false;
    }
    if (!iscurrentilyWorking) {
      if (endyear === "") {
        seterrEndyear("Please Select End Year");
        isvalid = false;
      } else {
        const startYearInt = parseInt(proficiencyLevels);
        const endYearInt = parseInt(endyear);

        if (startYearInt >= endYearInt) {
          setProficiencyError("Start year should be less than end year");
          isvalid = false;
        } else if (startYearInt === endYearInt) {
          setProficiencyError("Start year should not be equal to end year");
          isvalid = false;
        }
      }
    }
    if (affliation.trim() == "") {
      seterrJobDescription("Please Enter Professional Affiliations");
      isvalid = false;
    } else if (affliation.length > 750) {
      seterrJobDescription("Professional Affiliations should not exceed 750 characters");
      isvalid = false;
    }
    return isvalid;
  };

  // ProfessionalAffiliationsDataAddApi
  const ProfessionalAffiliationsDataAddApi = () => {
    var data = {
      profile_id: props.profileId,
      id: language_id,
      name: languagesknow,
      level: proficiencyLevels,
      currently_worked: iscurrentilyWorking,
      end_year: iscurrentilyWorking ? "" : endyear,
      description: affliation,
    };
    ProfessionalAffiliationsDataAdd(data)
      .then((response) => {
        if (!response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        if (response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          setLanguagesknow("");
          setProficiencyLevels("");
          setAffliation("");
          setendyear("");
          setIscurrentilyWorking(false);
          setContactIsLoading(false);
          setShow(false);
          LanguageDataGetApi();
        }
      })
      .catch((error) => {
        setContactIsLoading(false);
      });
  };

  // EditApi
  const EditApi = (id) => {
    setShow(true);
    var editdata = languagesDataList.find((e) => e._id == id);
    setLanguagesknow(editdata.name);
    setProficiencyLevels(editdata.level);
    setAffliation(editdata.description.replace(/<p><br><\/p>/g, ""));
    setlanguage_id(editdata._id);
    if (!editdata.currently_worked) {
      setendyear(editdata.end_year);
    } else {
      setendyear("");
    }
    setIscurrentilyWorking(editdata.currently_worked);
  };

  // updateEducation
  const updateEducation = () => {
    var data = {
      profile_id: props.profileId,
      language_id: language_id,
      name: languagesknow,
      level: proficiencyLevels,
      currently_worked: iscurrentilyWorking,
      end_year: iscurrentilyWorking ? "" : endyear,
      description: affliation,
    };
    PostAffiliationsUpdate(data)
      .then(function (response) {
        if (!response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        
        setlanguage_id(null);
        if (response.data) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          setlanguage_id(null);
          setLanguagesknow("");
          setProficiencyLevels("");
          setAffliation("");
          setendyear("");
          setIscurrentilyWorking("");
          setContactIsLoading(false);
          setShow(false);
          LanguageDataGetApi();
        }
      })
      .catch(function (error) {
        setContactIsLoading(false);
      });
  };

  // ProfessionalAffiliationsDataDeleteApi
  const ProfessionalAffiliationsDataDeleteApi = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true

    }).then((result) => {
      if (result.value) {
        var data = {
          language_id: id,
          profile_id: props.profileId,
        };
        ProfessionalAffiliationsDataDelete(data)
          .then((response) => {
            if (!response.data.status) {
              Store.addNotification({
                title: response.data.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__zoomIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
               
                dismiss: {
                  duration: 2000,
                  onScreen: false,
                },
              });
              return false
            }
            if (response.data.status) {
              Store.addNotification({
                title: response.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__zoomIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: false,
                },
              });
              LanguageDataGetApi();
            }
          })
          .catch((error) => {
          });
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!Otpvalidation()) {
      return false;
    }
    setContactIsLoading(true);
    if (language_id) {
      updateEducation();
    } else {
      ProfessionalAffiliationsDataAddApi();
    }
    setShow(false);
  };

  // professionalModal
  const professionalModal = () => {
    setprofessionalModalShow(!professionalModalShow);
  }

  const startYear = 1980;
  const endYear = 2028;
  const years = [];

  for (let year = startYear; year <= endYear; year++) {
    years.push({ name: year.toString() });
  }

  const startYearend = 1980;
  const endYearother = 2028;
  const endyears = [];

  for (let year = startYearend; year <= endYearother; year++) {
    endyears.push({ name: year.toString() });
  }

  return (
    <>
      {languagesDataList?.map((e) => {
        return (
          <CardDetalis
            data={e}
            ProfessionalAffiliationsDataDeleteApi={
              ProfessionalAffiliationsDataDeleteApi
            }
            EditApi={EditApi}
          />
        );
      })}
      <Row className="align-items-end justify-content-end">
        {languagesDataList?.length < 3 ?
          <Tooltip content="Add">
            <Col className="text-right p-0" xs={12}>
              <button className="my-btn" onClick={() => setShow(true)}>
                {" "}
                <img src={addcarddata} alt="add" className="notsaveiocn" />
                Add{" "}
              </button>
            </Col>
          </Tooltip>
          : ""}
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        centered
      >
        <Modal.Body className="p-0">
          <Row className="justify-content-center">
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
                <Col lg={12}>
                  <Row className="bg-white userdatapart border-radiustip">
                    <div className="col-12 mt-3 tipsbuttonset tipsbuttonset">
                      <div className="index_newcss">
                        <div className="contectinfoshowname mb-2">
                          <div className="professionalwidth">Professional Affiliations</div>

                        </div>
                        <div className="mb-2">
                          <span
                            onClick={professionalModal}
                            className="newdashbordbuttoncolor affiliationstips12"
                          >
                            {professionalModalShow ? <MdOutlineClose className="setcloseiconwidthwidth" /> : "Tips"}
                          </span>
                        </div>

                      </div>
                    </div>

                    <Col md={12}>
                      <Form.Group className="mt-2">
                        <Form.Label className="label_class">
                          {" "}
                          Select Organization{" "}
                        </Form.Label>
                        <div className="jobcategory">
                          <Autocomplete
                            // ref={titleInput}
                            wrapperProps={{ className: "auto-input addnewcssautocompletedataset" }}
                            wrapperStyle={{
                              display: "block",
                              // zIndex: "1",
                              position: "relative"
                            }}
                            getItemValue={(item) => item.label}
                            items={languagesData
                              .filter((ele) =>
                                ele.name
                                  .toLowerCase()
                                  .includes(languagesknow.toLowerCase())
                              )
                              .map((ele) => {
                                return { label: ele.name };
                              })}
                            renderItem={(item, isHighlighted) => (
                              <div
                                style={{
                                  background: isHighlighted ? "lightgray" : "white",
                                }}
                              >
                                {item.label}
                              </div>
                            )}
                            value={languagesknow}
                            onChange={(e) => setLanguagesknow(e.target.value)}
                            onSelect={(e) => setLanguagesknow(e)}
                            inputProps={{
                              placeholder:
                                "Select Organization",
                            }}
                          />
                        </div>
                        <span className="err_msg">{languageError}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="startmonth">
                        <Form.Label className="mt-1 label_class">
                          Start Year
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="startmonth"
                          className="borderdrop"
                          value={proficiencyLevels}
                          onChange={(e) => setProficiencyLevels(e.target.value)}
                        >
                          <option value="">Select</option>
                          {years.map((e) => (
                            <option key={e.name} value={e.name}>
                              {e.name}
                            </option>
                          ))}
                        </Form.Control>
                        <span className="err_msg">{proficiencyError}</span>
                      </Form.Group>
                    </Col>
                    {iscurrentilyWorking ? (
                      ""
                    ) : (
                      <>
                        <Col lg={6} className="mb-1">
                          <Form.Group controlId="endyear">
                            <Form.Label className="mt-1 label_class">
                              End Year
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="startmonth"
                              className="borderdrop"
                              value={endyear}
                              onChange={(e) => setendyear(e.target.value)}
                            >
                              <option value="">Select</option>
                              {endyears.map((e) => (
                            <option key={e.name} value={e.name}>
                              {e.name}
                            </option>
                          ))}
                            </Form.Control>
                            <span className="err_msg">{errendyear}</span>
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    <Col lg={12}></Col>

                    <div className="form-check mx-3">
                      <label className="form-check-label text-muted  label_class">
                        <input
                          type="checkbox"
                          className="form-check-input  input_radius"
                          checked={iscurrentilyWorking}
                          onChange={(e) =>
                            setIscurrentilyWorking(!iscurrentilyWorking)
                          }
                        />
                        <i className="input-helper"></i>I am currently working
                      </label>
                    </div>


                    <Col lg={12} className="mb-2">
                      <Form.Label>
                        <b>About Professional Affiliations</b>
                      </Form.Label>

                      <Form.Group
                        controlId="discription"
                        className="mt-1"
                      >
                        <Form.Control
                          as="textarea" rows={3}
                          placeholder="Please type here.."
                          value={affliation}
                          onChange={(e) => setAffliation(e.target.value)}
                          maxLength={750}
                        />



                        <span className="err_msg">{errJobDescription}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={12} className="d-flex justify-content-end">
                      <div>{affliation.length}/750</div>
                    </Col>
                    <Col>
                      <div className="setmodalfooterbutton">
                        <Button
                          variant="danger"
                          className="contact_radius mr-2"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <MyButton type={"submit"} onClick={handleSubmit}>
                          {iscontactLoading ? (
                            <Spinner animation="border" role="status" className="spinneerset">
                              <span className="visually-hidden"></span>
                            </Spinner>
                          ) : (
                            "Save"
                          )}
                        </MyButton>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div className="position-relative">

                {professionalModalShow ?
                  <Col lg={12} className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips">
                    <div className="info_tip6 professionalheightset border-radiustip">
                     

                      <div className="mt-2">
                        <div>
                          Crafting a compelling presentation of your professional
                          affiliations on Turn-To can significantly bolster your
                          profile, showcasing your active engagement in your
                          industry's community. Here’s how you can optimize the
                          inclusion of your professional affiliations on Turn-To:
                        </div>
                        <div className="mt-2">
                          <b>
                            Leveraging Professional Affiliations on Your Turn-To
                            Profile
                          </b>
                        </div>
                        <div className="mt-2">
                          <b>Strategic Inclusion of Affiliations:</b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Selective Highlighting:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Focus on affiliations that are most relevant to your
                                  career goals or the positions you are targeting.
                                  Prioritize organizations that are well-regarded in
                                  your industry to instantly communicate your
                                  commitment to professional development.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Detailed Descriptions
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  For each affiliation, include the organization's
                                  name, your role (if any), and the duration of your
                                  membership. If you’ve taken an active role, such as
                                  serving on a committee or leading a project,
                                  describe your contributions and any notable
                                  achievements.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-2">
                          <b>Enhancing Your Profile with Affiliations:</b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Networking and Community Engagement:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Use your affiliations to illustrate your involvement
                                  in the professional community. This not only
                                  demonstrates your passion for your field but also
                                  suggests a robust network that could benefit
                                  potential employers.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Evidence of Skills and Leadership:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Active participation in professional organizations
                                  often involves collaboration, leadership, and
                                  industry-specific skills. Highlight these
                                  experiences to provide concrete examples of your
                                  competencies.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-2">
                          <b>Example Affiliation Layout for Turn-To</b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              American Marketing Association (AMA), Member,
                              2018-Present:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Contributed to the organization of annual marketing
                                  conferences, enhancing networking opportunities for
                                  professionals in the region.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Project Management Institute (PMI), Chapter Vice
                              President, 2019-2021
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Led a team to develop and implement a mentorship
                                  program, resulting in a 30% increase in chapter
                                  membership engagement.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="2" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              <b> Why It Matters</b>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Your professional affiliations tell a story beyond
                                  your individual achievements; they reflect your
                                  dedication to staying connected and informed within
                                  your industry. They signal to employers that you are
                                  a proactive professional who values growth,
                                  networking, and the exchange of ideas.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-2">
                          <b>
                            By thoughtfully integrating your professional affiliations
                            into your Turn-To profile, you not only enrich your
                            personal narrative but also affirm your commitment to your
                            professional community. This approach can make your
                            profile stand out, encouraging potential employers to view
                            you as a well-connected and active participant in your
                            field.
                          </b>
                        </div>
                      </div>
                    </div>
                  </Col>
                  : ""
                }
              </div>

            </div>

          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtherSection;

function CardDetalis(props) {
  return (
    <div className="menu-card position-relative">
      <div className="stretch-card  my-2 p-0 " style={{ minHeight: "80px" }}>
        <div className="card shadow-lg exp_margin">
          <div className="card-body exp_padding">
            <div className="d-flex justify-content-between h-100">
              <div className="left-div d-block d-md-flex">
                <div className="mr-0 mr-md-3">
                  <p>
                    <Avatar size="50" round="8px" name={props.data.name} />
                  </p>
                </div>
                <div>
                  <h5 className="other_wordbreak">
                    <b>{props.data.name}</b>
                  </h5>
                  <h6>{props.data.level} -{" "}
                    {props.data.end_year
                      ? props.data.end_year
                      : "Present"}</h6>
                  <div></div>
                </div>
              </div>
              <div className="right-div d-flex flex-column">
                <Tooltip content="Edit">
                  <MyButton
                    className="p-1 mb-2"
                    onClick={(e) => props.EditApi(props.data._id)}
                  >
                    <img
                      src={editdatapng}
                      alt="edit"
                      className="closepngiocnwidth"
                    />
                  </MyButton>
                </Tooltip>
                <Tooltip content="Delete">
                  <MyButton
                    className="p-1 mb-2 deletebtn"
                    onClick={(e) =>
                      props.ProfessionalAffiliationsDataDeleteApi(props.data._id)
                    }
                  >
                    <img
                      src={deletecarddatapng}
                      alt="delete"
                      className="closepngiocnwidth"
                      set="bold"
                    />
                  </MyButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
