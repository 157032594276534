import axios from "axios";
import api from "./url";

axios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("token")) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const postuseremaliotp = (data) => {
  return axios.post(api.sendOTP, data);
};
export const postuserfeedback = (data) => {
  return axios.post(api.userFeedback, data);
};
export const postuseremaliotpverify = (data) => {
  return axios.post(api.verifyOTP, data);
};
// user Register
export const postuserregisterUser = (data) => {
  return axios.post(api.userRegister, data);
};
// user Login
export const postuserlogin = (data) => {
  return axios.post(api.userLogin, data);
};
// user Register Employer
export const postemployerregisterUser = (data) => {
  return axios.post(api.employerRegister, data);
};
// user Login Employer
export const postemployerlogin = (data) => {
  return axios.post(api.employerLogin, data);
};
//  get jobpost form filed data
export const getjobFormFileddata = () => {
  return axios.get(api.getJobFormData);
};

export const LoginUserApi = (data) => {
  return axios.post(api.login, data);
};

// Dashbord Dropdown Data get
export const getdashborddropdown = () => {
  return axios.get(api.getDashboardFilterData);
};

export const getLocationData = (data) => {
  return axios.get(api.getLocationData, {
    params: {
      location: data,
    },
  });
};
// job post 1 page
export const postjobBriefpage = (data) => {
  return axios.post(api.jobPostBrief, data);
};
// job post 2 page
export const postsalarypage = (data) => {
  return axios.post(api.jobPostSalary, data);
};
// job post 3 page
export const postDetalisPage = (data) => {
  return axios.post(api.jobPostDetalis, data);
};

// Job Published Api
export const postjobPublished = (data) => {
  return axios.post(api.jobPublished, data);
};
//  get JOb
export const getJobList = (data) => {
  return axios.get(api.joblist, { params: data });
};
export const getApplicantsJobList = (data) => {
  return axios.get(api.applicantsJobList, { params: data });
};

// ------------------------------------------------------------
// export const getuserdashbords = (data) => {
//   return axios.get(api.getuserdashbords, { params: {
//     location: data
//   } });
// }

export const getUserDashbords = () => {
  return axios.get(api.getUserDashbord);
};

export const getUserProfileData = () => {
  return axios.get(api.profile);
};
// ------------------------------------------------------------
export const getProfileEmploymentType = () => {
  return axios.get(api.profileEmploymentType);
};

//
export const postaddExperience = (data) => {
  return axios.post(api.addExperience, data);
};

//
// ------------------------------------------------------------
export const getExperienceLists = (params) => {
  return axios.get(api.getExperienceList ,{params:params});
};
// ------------------------------------------------------------
export const PostdeleteExperience = (data) => {
  return axios.post(api.deleteExperience, data);
};
//
export const postaddEducation = (data) => {
  return axios.post(api.addEducation, data);
};

export const getprofiledata = () => {
  return axios.get(api.profiledat);
};

export const getfieldofstudy = () => {
  return axios.get(api.fieldofstudy);
};

export const postchangePassword = (data) => {
  return axios.post(api.changePassword, data);
};

export const postEmployerchangePassword = (data) => {
  return axios.post(api.employerPasswordChange, data);
};

export const getEducationList = (params) => {
  return axios.get(api.educationList, {params});
};

export const PostdeleteEducation = (data) => {
  return axios.post(api.deleteEducation, data);
};

export const PostprofileUpdate = (data) => {
  return axios.post(api.profileUpdate, data);
};
export const PostAffiliationsUpdate = (data) => {
  return axios.post(api.AffiliationsUpdate, data);
};
export const getAllSkill = () => {
  return axios.get(api.getAllSkill);
};
export const postupdateSkill = (data) => {
  return axios.post(api.updateSkill, data);
};
export const postupdateProfileImg = (data) => {
  return axios.post(api.profileImgUpdate, data);
};
export const postupdateCovereImg = (data) => {
  return axios.post(api.coverImgUpdate, data);
};
export const postupdateResume = (data) => {
  return axios.post(api.updateResume, data);
};
export const postDeleteResume = () => {
  return axios.delete(api.deleteResume);
};
export const getJobsApi = (params, config) => {
  return axios.get(api.getJobs, { ...config, params: params });
};
export const postSaveJob = (data) => {
  return axios.post(api.savejobs, data);
};
export const postReportJob = (data) => {
  return axios.post(api.reportJob, data);
};

export const postNotInterestedJob = (data) => {
  return axios.post(api.notInterestedjob, data);
};

// company profile
export const getCompanyProfile = () => {
  return axios.get(api.companyProfile);
};
export const postupdateBasicDetail = (data) => {
  return axios.post(api.updateBasicDetail, data);
};
export const postupdateContactDetail = (data) => {
  return axios.post(api.updateContactDetail, data);
};
export const postupdateSocialNetwork = (data) => {
  return axios.post(api.updateSocialNetwork, data);
};
export const postPreferenceUpdate = (data) => {
  return axios.post(api.preferenceUpdate, data);
};
export const postUploadPhoto = (data) => {
  return axios.post(api.uploadPhoto, data);
};
export const postUpdateBio = (data) => {
  return axios.post(api.updateBio, data);
};
export const postUpdateheader = (data) => {
  return axios.post(api.updateheader, data);
};
export const postUploadVidio = (data) => {
  return axios.post(api.uploadVidio, data);
};
export const postUploadVidiolink = (data) => {
  return axios.post(api.uploadVidiolink, data);
};

export const getSearchCompanies = (data) => {
  return axios.get(api.searchCompanies, {
    params: {
      search: data,
    },
  });
};

export const getJobLocationTypeApi = () => {
  return axios.get(api.getJobLocationType);
};

export const geteducationLevel = () => {
  return axios.get(api.educationLevel);
};

export const getUserSavedJobList = (data) => {
  return axios.get(api.userSavedJobList, { params: data });
};
export const getCompanyDetail = (comapny_name) => {
  return axios.get(api.companyDetail + "/" + comapny_name);
};

export const postVideoDeleteCompanyProfile = (data) => {
  return axios.post(api.videoDeleteCompanyProfile, data);
};
export const postGallaryimageDeleteCompanyProfile = (data) => {
  return axios.post(api.gallaryimageDeleteCompanyProfile, data);
};

export const postUpdateExpApi = (data) => {
  return axios.post(api.updateExp, data);
};
export const postUpdateEducation = (data) => {
  return axios.post(api.updateEduation, data);
};
export const postUpdateDocument = (data) => {
  return axios.post(api.updateDocument, data);
};
export const postUpdateprofile = (data) => {
  return axios.post(api.updateProfile, data);
};
export const postUpdatelanguage = (data) => {
  return axios.post(api.LanguageUpdate, data);
};
export const postForgetpassword = (data) => {
  return axios.post(api.forgetPassword, data);
};
export const postResetpassword = (data) => {
  return axios.post(api.resetPassword, data);
};
export const getjobCategoryApi = () => {
  return axios.get(api.getjobCategory);
};
export const postesayappliyjob = (data) => {
  return axios.post(api.esayappliyjob, data);
};
export const postcompanyPublicViewStatus = (data) => {
  return axios.post(api.companyPublicViewStatus, data);
};

// all application page userlist api
export const getUserAppliedList = (data) => {
  return axios.get(api.userAppliedList, {
    params: data,
  });
};

export const postapplicationStatus = (data) => {
  return axios.post(api.applicationStatus, data);
};

export const postcheckUserName = (data) => {
  return axios.post(api.checkusername, data);
};

export const postJobClick = (data) => {
  return axios.post(api.jobclick, data);
};
export const postApplyJobClick = (data) => {
  return axios.post(api.applyJobClick, data);
};

export const getUserProfileInfo = (username) => {
  return axios.get(api.userprofileInfo + "/" + username);
};

export const getgetJobDetalis = (data) => {
  return axios.get(api.getJobDetalis + "/" + data);
};

export const getgetJobDetalis1 = (job_id) => {
  return axios.get(api.getJobDetalis1 + "/" + job_id);
};

export const getUserProfileResumeInfo = (username, profile_slug) => {
  return axios.get(api.userprofileInfo + "/" +  username + "/" + profile_slug);
};

export const getAllApplicationJobList = () => {
  return axios.get(api.allapplicationJobList);
};

export const postusergooglelogin = (data) => {
  return axios.post(api.usergooglelogin, data);
};

export const postempgooglelogin = (data) => {
  return axios.post(api.empgooglelogin, data);
};

export const getCompanyDetailjob = (comapny_name, params) => {
  return axios.get(api.companyDetailjob + "/" + comapny_name, {
    params,
  });
};

export const getAppliedJob = (data) => {
  return axios.get(api.getappliedjob, { params: data });
};

export const getSearchHistory = (data) => {
  return axios.get(api.getsearchhistory);
};

export const getjobdetalisEdit = (data) => {
  return axios.get(api.getjobdetalisemp + data);
};

export const getClosedJob = (data) => {
  return axios.get(api.getclosedjob);
};

export const postCheckPublicUrl = (data) => {
  return axios.post(api.checkpublicurl, data);
};

export const getEmployDashboard = (data) => {
  return axios.get(api.getemploydashboard, { params: data });
};

export const getReportJobList = (data) => {
  return axios.get(api.reportjoblistemployer, { params: data });
};

export const getJobDetail = (_id) => {
  return axios.get(api.getjobdetail + "/" + _id);
};

export const PostDeleteHistory = (data) => {
  return axios.post(api.deletesearchhistory, data);
};

export const PostDeleteAllHistory = (data) => {
  return axios.post(api.deleteallsearchhistory, data);
};

export const PostDeleteNotInterstedHistory = (data) => {
  return axios.post(api.deleteallnotinterstedhistory, data);
};

export const PostNotInterstedHistory = (data) => {
  return axios.post(api.deletenotinterstedhistory, data);
};

export const GetJobTitle = (data) => {
  return axios.get(api.searchTitle, { params: data });
};

export const GetLocationDatalatlug = (data) => {
  return axios.get(
    api.locationtodata +
      "?5m2&1d22.2651381&2d70.8053406&9sen-US&callback=_xdc_._riuylv&key=AIzaSyB5rvNHL53mLSYmz2KvElbvpuXgnDxIELQ&token=7772"
  );
};

export const getRelatedJobs = (_id, data) => {
  return axios.post(api.getRelatedJobs + "/" + _id, data);
};

export const userAppleLoginAPI = (data) => {
  return axios.post(api.userApplelogin, data);
};

export const employerAppleLoginAPI = (data) => {
  return axios.post(api.empApplelogin, data);
};

export const BookMarkClearApi = (data) => {
  return axios.post(api.bookMarkClear, data);
};

export const AppliedJobClearApi = (data) => {
  return axios.post(api.appliedJobClearApi, data);
};

export const CreateSessionApi = (data) => {
  return axios.post(api.createSession, data);
};

export const CreateSessionNewApi = (data) => {
  return axios.post(api.createSessionNew, data);
};

export const CreateSessionNewApiBulk = (data) => {
  return axios.post(api.createSessionNewBulk, data);
};

export const GetPaymentDetalisApi = (data) => {
  return axios.get(api.getPaymentDetalis + data);
};

export const GetPaymentDetalisNewApi = (data) => {
  return axios.get(api.getPaymentDetalisNew + data);
};

export const getAllFolderListApi = () => {
  return axios.get(api.getAllFolderList);
};

export const savejobInFolderApi = (data) => {
  return axios.post(api.savejobInFolder, data);
};

export const userSaveCollectionApi = (data) => {
  return axios.post(api.userSaveCollection, data);
};

export const addCollectionFolderApi = (data) => {
  return axios.post(api.addCollectionFolder, data);
};

export const getPaymentHistroy = (data) => {
  return axios.get(api.paymentHistory, { params: data });
};
export const GetPlansApi = (data) => {
  return axios.get(api.getPlans, { params: data });
};
export const GetPlansJobApi = (data) => {
  return axios.get(api.getJobPlans, { params: data });
};

export const RefundRequest = (data) => {
  return axios.post(api.refundRequest, data);
};

export const RefundList = () => {
  return axios.get(api.refundList);
};

export const UploadDocument = (data) => {
  return axios.post(api.uploadDocument, data);
};

export const DocumentDelete = (data) => {
  return axios.post(api.documentDelete, data);
};

export const Certificate = () => {
  return axios.get(api.certificateList);
};
export const Licence = () => {
  return axios.get(api.licenceList);
};

export const GetDoumentList = (params) => {
  return axios.get(api.getDoumentList, {params});
};

export const UpdateBudget = (data) => {
  return axios.post(api.updateBudget, data);
};
export const UpdateBankdetails = (data) => {
  return axios.post(api.updateBankdetails, data);
};

export const LanguageDataAdd = (data) => {
  return axios.post(api.languageDataAdd, data);
};
export const LanguageDataApi = (params) => {
  return axios.get(api.languageData, {params});
};
export const LanguageDataDelete = (data) => {
  return axios.post(api.languageDataDelete, data);
};

export const ProfessionalAffiliationsDataAdd = (data) => {
  return axios.post(api.professionalAffiliationsDataAdd, data);
};
export const ProfessionalAffiliationsData = (params) => {
  return axios.get(api.professionalAffiliationsData, {params});
};
export const ProfessionalAffiliationsDataDelete = (data) => {
  return axios.post(api.professionalAffiliationsDataDelete, data);
};

export const EmployerJobPostAddQuestion = (data) => {
  return axios.post(api.employerPostjobQuestion, data);
};

export const ChangeJoblisPositionStatus = (data) => {
  return axios.post(api.changePositionStatusJob, data);
};

export const DegreeApi = () => {
  return axios.get(api.degreeList);
};

export const RefundRequestNew = (data) => {
  return axios.post(api.refundRequest, data);
};

export const BulkUplordJob = (data) => {
  return axios.post(api.bulkUplordJob, data);
};

export const NotificationAllApi = (data) => {
  return axios.get(api.NotificationAll, { params: data });
};
export const GetbulkData = () => {
  return axios.get(api.getbulkData);
};

export const GetCoursesData = () => {
  return axios.get(api.getCoursesData);
};

export const ClearBulkJobAll = (data) => {
  return axios.post(api.clearBulkJobAll, data);
};

export const animationPlaceholderList = [
  "What do you want to do?",
  "School psychologist positions",
  "Registered dietitian oncology jobs",
  "Travel nurse opportunities",
  "Senior project manager openings",
  "Electrical estimator vacancies",
  "Truck gate security officer roles",
  "Hair stylist jobs",
  "Facilities technician positions",
  "Groundskeeper openings",
  "Roofing manager careers",
  "Line cook jobs",
  "Customer service rep openings",
  "Flatbed CDL driver positions",
  "Side gig opportunities",
  "Account executive roles",
  "Field data collector jobs",
  "Delivery driver vacancies",
  "Carpenter positions",
  "Retail cosmetics openings",
  "Forklift operator jobs",
  "Physician vacancies",
  "Direct care worker positions",
  "VP financial consultant roles",
  "Middle school math teacher jobs",
  "Kindergarten teacher positions",
  "Donation attendant openings",
  "OBGYN jobs",
  "Security supervisor roles",
  "Manager positions",
  "Site electrician vacancies",
  "Housekeeping openings",
  "Room attendant jobs",
  "Maintenance technician positions",
];
export const GetProfileList = () => {
  return axios.get(api.getProfileList);
};
