import React, { useEffect, useState } from "react";
import {
  Accordion,
  
  Col,
 
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import MyButton from "../../common/Button/MyButton";
import footerpagepng from "../../../../../Component/icontopng/footerpagepng.png";
import { MdOutlineClose } from "react-icons/md";
import {
  getjobCategoryApi,
  postPreferenceUpdate,
} from "../../../../../Api/Api";
import { Store } from "react-notifications-component";
import "./Preference.css";
import { useSocket } from "../../../../../context/SocketProvider";

const categorties = [
  "IT",
  "Civil engineer",
  "Architect",
  "Biomedical engineer",
  "Sustainable designer",
];

const jobType = ["Full-time", "Part-time", "Contract", "Freelancer", "Fresher"];
function Preference(props) {
  const { socket } = useSocket();
  const [jobRole, SetJobRole] = useState("");
  const [jobtypes, SetJobTypes] = useState("");
  const [location, Setlocation] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [categorys, setCategorys] = useState("");
  const [categoryJob, setCategoryJob] = useState([]);
  const [optionDataApi, setOptionDataApi] = useState("");

  // validation
  const [errjobRole, seterrJobRole] = useState("");
  const [errjobtypes, seterrJobTypes] = useState("");
  const [errlocation, seterrlocation] = useState("");
  const [errcheckboxes, seterrCheckboxes] = useState([]);
  const [errorCategorys, setErrorcategorys] = useState("");
  const [jobUpdate, setJobUpdate ]=useState("")

  useEffect(() => {
    socket.emit("user_culture_dropdown");
    socket.off("user_culture_dropdown");
    socket.on("user_culture_dropdown", (res) => {
      setOptionDataApi(res.data);
    });
  }, [props]);

  

  const clearError = (e) => {
    seterrJobRole("");
    seterrJobTypes("");
    seterrlocation("");
    seterrCheckboxes("");
    setErrorcategorys("");

   
  };

  const handleCheckboxChange = (value, checked) => {
    if (checked) {
      setCheckboxes([...checkboxes, value]);
    } else {
      setCheckboxes(checkboxes.filter((item) => item !== value));
    }
  };

  useEffect(() => {
    getjobCategoryApi()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        setCategoryJob(response.data.data);
      })
      .catch(function (error) {
      });
  }, []);

  const PreferenceUpdateApi = () => {
   
    var data = {
      category: categoryJob[0].name,
      role_title: jobRole,
      job_type: jobtypes,
      location_type: checkboxes,
      location: location,
    };
    postPreferenceUpdate(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        if (response.data) {
          Store.addNotification({
            title: "Saved Successfully",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
        }
      })
      .catch(function (error) {
      });
  };

  // States Value
  const [industryPreference, setIndustryPreference] = useState([]);
  const [preferredJobFunction, setPreferredJobFunction] = useState([]);
  const [awardsModalshow, setawardsModalshow] = useState(false);

  const [jobTypePreference, setJobTypePreference] = useState([]);
  const [workEnvironmentPreference, setWorkEnvironmentPreference] = useState(
    []
  );
  const [locationPreference, setlocationPreference] = useState([]);
  const [salaryExpectation, setSalaryExpectation] = useState([]);
  const [workSchedulePreference, setWorkSchedulePreference] = useState([]);
  const [companyCulturePreference, setCompanyCulturePreference] = useState([]);
  const [professionalDevelopmentInterest, setProfessionalDevelopmentInterest] =
    useState([]);
  const [valuesAlignment, setValuesAlignment] = useState([]);
  const [commuteandRelocation, setCommuteandRelocation] = useState([]);
  const [technologyToolsFamiliarity, setTechnologyToolsFamiliarity] = useState(
    []
  );

  const awardsModal = () => {
    setawardsModalshow(!awardsModalshow);
  };

  // Question Wis Modal State
  const [isFirstOpen, setIsFirstOpen] = useState(false);
  const [isSecondOpen, setIsSecondOpen] = useState(false);
  const [isThreeOpen, setIsThreeOpen] = useState(false);
  const [isFourOpen, setIsFourOpen] = useState(false);
  const [isFiveOpen, setIsFiveOpen] = useState(false);
  const [isSixOpen, setIsSixOpen] = useState(false);
  const [isSevenOpen, setIsSevenOpen] = useState(false);
  const [isEightOpen, setIsEightOpen] = useState(false);
  const [isNineOpen, setIsNineOpen] = useState(false);
  const [isTenOpen, setIsTenOpen] = useState(false);
  const [isElevenOpen, setElevenOpen] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState(false);


  const AddPref = () => {
    var data = {
      industryPreference: industryPreference,
      preferredJobFunction: preferredJobFunction,
      jobTypePreference: jobTypePreference,
      workEnvironmentPreference: workEnvironmentPreference,
      locationPreference: locationPreference,
      salaryExpectation: salaryExpectation,
      workSchedulePreference: workSchedulePreference,
      professionalDevelopmentInterest: professionalDevelopmentInterest,
      valuesAlignment: valuesAlignment,
      commuteandRelocation: commuteandRelocation,
      job_alert_by:jobUpdate,
      token: localStorage.getItem("token"),
    };
    socket.emit("user_preference", data);
    socket.off("user_preference");
    socket.on("user_preference", (res) => {
      
    });
  };
  useEffect(() => {
    setIndustryPreference(props.profileData?.preferences?.industryPreference);
    setPreferredJobFunction(
      props.profileData?.preferences?.preferredJobFunction
    );
    setJobTypePreference(props.profileData?.preferences?.jobTypePreference);
    setWorkEnvironmentPreference(
      props.profileData?.preferences?.workEnvironmentPreference
    );
    setlocationPreference(props.profileData?.preferences?.locationPreference);
    setSalaryExpectation(props.profileData?.preferences?.salaryExpectation);
    setWorkSchedulePreference(
      props.profileData?.preferences?.workSchedulePreference
    );
    setProfessionalDevelopmentInterest(
      props.profileData?.preferences?.professionalDevelopmentInterest
    );
    setJobUpdate(props.profileData?.preferences?.job_alert_by)
    setCheckBoxValue(props.profileData?.preferences?.job_alert_by !== "")
    setValuesAlignment(props.profileData?.preferences?.valuesAlignment);
    setCommuteandRelocation(
      props.profileData?.preferences?.commuteandRelocation
    );
  }, [props.profileData]);


  useEffect(()=>{
    if(checkBoxValue){
      setJobUpdate("daily")
    }else{
      setJobUpdate("")
    }
  },[checkBoxValue])

  // 
  const [describeans, setDescribeans] = useState(
    props.profileData?.work_environment
  );
  const [howprefer, setHowprefer] = useState(
    props.profileData?.receive_feedback
  );
  const [whatworklife, setWhatworklife] = useState(
    props.profileData?.work_life_balance
  );
  const [canyoushare, setCanyoushare] = useState(
    props.profileData?.new_company_culture
  );
  const [importantworkplace, setImportantworkplace] = useState(
    props.profileData?.important_workspace_values
  );
  const [preferredCommute, setPreferredCommute] = useState(
    props.profileData?.preferred_commute_time_distance
  );
  const [industryPreference1, setIndustryPreference1] = useState(
    props.profileData?.industry_preference
  );
  const [companySizePreference, setCompanySizePreference] = useState(
    props.profileData?.company_size
  );
  const [benefitsPerks, setBenefitsPerks] = useState(
    props.profileData?.benefits_perks
  );
  const [companyCulturePreference1, setCompanyCulturePreference1] = useState(
    props.profileData?.companyCulturePreference
  );

  // validation
  const [errdescribeans, seterrDescribeans] = useState("");
  const [errhowprefer, seterrHowprefer] = useState("");
  const [errwhatworklife, seterrWhatworklife] = useState("");
  const [errcanyoushare, seterrCanyoushare] = useState("");
  const [errimportantworkplace, seterrImportantworkplace] = useState("");
  const [optionDataApi1, setOptionDataApi1] = useState("");

  // Question Wis Modal State
  const [isFirstOpen1, setIsFirstOpen1] = useState(false);
  const [isSecondOpen1, setIsSecondOpen1] = useState(false);
  const [isThreeOpen1, setIsThreeOpen1] = useState(false);
  const [isFourOpen1, setIsFourOpen1] = useState(false);
  const [isFiveOpen1, setIsFiveOpen1] = useState(false);
  const [isSixOpen1, setIsSixOpen1] = useState(false);
  const [isSevenOpen1, setIsSevenOpen1] = useState(false);
  const [isEightOpen1, setEightOpen1] = useState(false);
  //
  useEffect(() => {
    socket.emit("user_culture_dropdown");
    socket.off("user_culture_dropdown");
    socket.on("user_culture_dropdown", (res) => {
      setOptionDataApi1(res.data);
    });
  }, []);
  useEffect(() => {
    setDescribeans(props.profileDataCulture?.work_environment);
    setHowprefer(props.profileDataCulture?.receive_feedback);
    setWhatworklife(props.profileDataCulture?.work_life_balance);
    setCanyoushare(props.profileDataCulture?.new_company_culture);
    setImportantworkplace(props.profileDataCulture?.important_workspace_values);
    setPreferredCommute(props.profileDataCulture?.preferred_commute_time_distance);
    setIndustryPreference1(props.profileDataCulture?.industry_preference);
    setCompanySizePreference(props.profileDataCulture?.company_size);
    setBenefitsPerks(props.profileDataCulture?.benefits_perks);
    setCompanyCulturePreference1(props.profileDataCulture?.companyCulturePreference);
  }, [props.profileDataCulture]);

  const clearError1 = (e) => {
    seterrDescribeans("");
    seterrHowprefer("");
    seterrWhatworklife("");
    seterrCanyoushare("");
    seterrImportantworkplace("");
  };

  // validation
  const errMsg = () => {
    clearError1();
    var isValid = true;
    if (describeans.length < 0) {
      seterrDescribeans("Please fill the answer");
      isValid = false;
    }
    if (howprefer.length < 0) {
      seterrHowprefer("Please fill the answer");
      isValid = false;
    }
    if (whatworklife.length < 0) {
      seterrWhatworklife("Please fill the answer");
      isValid = false;
    }
    if (canyoushare.length < 0) {
      seterrCanyoushare("Please fill the answer");
      isValid = false;
    }
    if (importantworkplace.length < 0) {
      seterrImportantworkplace("Please fill the answer");
      isValid = false;
    }
    return isValid;
  };

  // Addculture
  const Addculture = () => {
    if (!errMsg()) {
      return false;
    }

    var data = {
      work_environment: describeans,
      receive_feedback: howprefer,
      work_life_balance: whatworklife,
      new_company_culture: canyoushare,
      important_workspace_values: importantworkplace,
      preferred_commute_time_distance: preferredCommute,
      industry_preference: industryPreference,
      company_size: companySizePreference[0],
      companyCulturePreference: companyCulturePreference,
      benefits_perks: benefitsPerks,
      token: localStorage.getItem("token"),
    };
    socket.emit("user_culture_fit", data);
    socket.off("user_culture_fit");
    socket.on("user_culture_fit", (res) => {
      
    });
  };

  // SubmitData
  const SubmitData = () =>{
    AddPref()
    Addculture()
    Store.addNotification({
        title: "Data saved successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
  }
  return (
    
    <>
    {/* preference */}
    <Row>
      <Col md={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>1.Industry Preferences.</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            type="text"
            rows={2}
            readOnly
            value={industryPreference}
            onClick={() => setIsFirstOpen(true)}
            placeholder="Enter Your Answer"
          />
        </Form.Group>

        <ModalOptionMultiple
          show={isFirstOpen}
          OptionData={optionDataApi.Industry}
          close={() => setIsFirstOpen(false)}
          SetData={setIndustryPreference}
          dataOld={industryPreference}
          question="1. Industry Preference. ?"
          placeholder="Enter Industry Preference"
          title="Industry Preference
          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip1content="Selecting an industry will focus your search on relevant jobs and companies."
          tip2content="Choose Other if your desired industry isn't listed. You can then specify it in the provided field.
          "
        />
      </Col>

      <Col md={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>2.Preferred Jobs.</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            type="text"
            rows={2}
            readOnly
            value={preferredJobFunction}
            onClick={() => setIsSecondOpen(true)}
            placeholder="Enter Your Answer"
          />
        </Form.Group>

        <ModalOptionMultiple
          show={isSecondOpen}
          OptionData={optionDataApi.Preferred}
          close={() => setIsSecondOpen(false)}
          SetData={setPreferredJobFunction}
          dataOld={preferredJobFunction}
          question="2. Preferred Job. ?"
          placeholder="Enter Preferred Job"
          title="Preferred Job
          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip1content="Selecting a preferred job title will help you find opportunities that align with your skills and interests."
          tip2content="Choose Other if your desired job title isn't listed. You can then enter it in the provided field.
          "
        />
      </Col>

      <Col md={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>3.Job Type Preferences</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            type="text"
            rows={2}
            value={jobTypePreference}
            onClick={() => setIsThreeOpen(true)}
            placeholder="Enter Your Answer"
            readOnly
          />
        </Form.Group>

        <ModalOptionMultiple
          show={isThreeOpen}
          OptionData={optionDataApi.JobType}
          close={() => setIsThreeOpen(false)}
          SetData={setJobTypePreference}
          dataOld={jobTypePreference}
          question="3. Job Type Preference ?"
          placeholder="Enter Job Type Preference"
          title="Job Type Preference
          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip1content=" Consider your desired level of commitment and flexibility when choosing your preferred job type."
          tip2content=" Other allows you to specify if you're open to temporary, internship, or volunteer opportunities (be sure to clarify in your resume/cover letter). 
          "
        />
      </Col>

      <Col md={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>4.Work Environment Preferences</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            type="text"
            rows={2}
            value={workEnvironmentPreference}
            onClick={() => setIsFourOpen(true)}
            placeholder="Enter Your Answer"
            readOnly
          />
        </Form.Group>

        <ModalOptionMultiple
          show={isFourOpen}
          OptionData={optionDataApi.WorkEnvironment}
          close={() => setIsFourOpen(false)}
          SetData={setWorkEnvironmentPreference}
          dataOld={workEnvironmentPreference}
          question="4. Work Environment Preference ?"
          placeholder="Enter Work Environment Preference"
          title="Work Environment Preference"
          tip1="Tip 1"
          tip2="Tip 2"
          tip1content="Choosing your preferred work environment can significantly impact your work-life balance and overall satisfaction."
          tip2content="Consider factors like collaboration needs, focus requirements, and personal preferences when selecting your desired environment."
        />
      </Col>

      <Col md={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>5. Location Preferences</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            type="text"
            rows={2}
            value={locationPreference}
            onClick={() => setIsFiveOpen(true)}
            placeholder="Enter Your Answer"
            readOnly
          />
        </Form.Group>

        <ModalOptionMultiple
          show={isFiveOpen}
          OptionData={optionDataApi.Location}
          close={() => setIsFiveOpen(false)}
          SetData={setlocationPreference}
          dataOld={locationPreference}
          question="5. Location Preference ?"
          placeholder="Enter Location Preference"
          title="Location Preference"
          tip1="Tip 1"
          tip2="Tip 2"
          tip1content="Selecting your location preference helps tailor your search to jobs within your desired area or open to relocation opportunities."
          tip2content="Choose Local and specify your city/state if you prefer jobs in your current location.
         
          "
          tip3content="Select Open to Relocation if you're flexible and willing to relocate for the right opportunity. "
          tip4content=" Choose Remote Work Only to find positions offering remote work options regardless of location."
          tip5content=" Select Other if you have specific location preferences that don't fit the above categories (e.g., willing to relocate within a specific region, specific remote work preferences). "
          tip6content=" Be sure to clarify your preferences in the provided field."
        />
      </Col>

      <Col md={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>6. Salary Expectations</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            type="text"
            rows={2}
            value={salaryExpectation}
            onClick={() => setIsSixOpen(true)}
            placeholder="Enter Your Answer"
            readOnly
          />
        </Form.Group>

        <ModalOptionMultiple
          show={isSixOpen}
          OptionData={optionDataApi.Salary}
          close={() => setIsSixOpen(false)}
          SetData={setSalaryExpectation}
          dataOld={salaryExpectation}
          question="6. Salary Expectation ?"
          placeholder="Enter Salary Expectation"
          title="Salary Expectation 
          "
          tip1="Provide a neutral statement"
          tip2="Offer guidance on salary research"
          tip1content="Understanding your salary expectations can be helpful in your job search. "
          tip2content="Salary expectations can vary depending on several factors.Remember, the goal is to provide helpful information without disclosing specific salary ranges or potentially influencing their expectations in a way that could disadvantage them during negotiations.
          "
          tip3content="We recommend researching average salaries for your desired position and experience level to inform your expectations."
        />
      </Col>

      <Col md={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>7. Work Schedule Preferences</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            type="text"
            rows={2}
            value={workSchedulePreference}
            onClick={() => setIsSevenOpen(true)}
            placeholder="Enter Your Answer"
            readOnly
          />
        </Form.Group>

        <ModalOptionMultiple
          show={isSevenOpen}
          awardsModalshow={awardsModalshow}
          setawardsModalshow={setawardsModalshow}
          awardsModal={awardsModal}
          OptionData={optionDataApi.WorkSchedule}
          close={() => setIsSevenOpen(false)}
          SetData={setWorkSchedulePreference}
          dataOld={workSchedulePreference}
          question="7. Work Schedule Preference ?"
          placeholder="Enter Work Schedule Preference"
          title="Work Schedule Preference"
          tip1="Tip 1"
          tip2="Tip 2"
          tip1content="Selecting your preferred work schedule can significantly impact your work-life balance and personal routine."
          tip2content="Consider your desired level of flexibility, personal commitments, and energy levels when choosing your preferred schedule.
         
          "
          tip3content=" Standard 9-5: Choose this if you prefer a traditional Monday-Friday work schedule with set working hours. "
          tip4content="  Shift Work: This option is for those comfortable with rotating or non-traditional working hours, potentially including evenings, weekends, or overnight shifts."
          tip5content="   Nights and Weekends: Choose this if you prefer working primarily during nighttime or weekend hours. "
          tip6content="  Other: Select this if you have specific schedule preferences that don't fit the above categories (e.g., compressed workweek, part-time schedule with specific days). Be sure to clarify your preferences in the provided field."
        />
      </Col>

     

      <Col md={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>8. Professional Development Interests</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            type="text"
            rows={2}
            value={professionalDevelopmentInterest}
            onClick={() => setIsNineOpen(true)}
            placeholder="Enter Your Answer"
            readOnly
          />
        </Form.Group>

        <ModalOption
          show={isNineOpen}
          OptionData={optionDataApi.ProfessionalDevelopmentInterest}
          close={() => setIsNineOpen(false)}
          SetData={setProfessionalDevelopmentInterest}
          dataOld={professionalDevelopmentInterest}
          question="8. Professional Development Interest ?"
          placeholder="Enter Professional Development Interest"
          title="Professional Development Interest
          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip1content="Investing in professional development can enhance your skills, knowledge, and career prospects."
          tip2content="Consider your career goals and areas you'd like to strengthen when reflecting on your interest in professional development.
          "
        />
      </Col>

      <Col md={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>9. Values Alignments</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            type="text"
            rows={2}
            value={valuesAlignment}
            onClick={() => setIsTenOpen(true)}
            placeholder="Enter Your Answer"
            readOnly
          />
        </Form.Group>

        <ModalOptionMultiple
          show={isTenOpen}
          OptionData={optionDataApi.ValuesAlignment}
          close={() => setIsTenOpen(false)}
          SetData={setValuesAlignment}
          dataOld={valuesAlignment}
          question="9. Values Alignment  ?"
          placeholder="Enter Values Alignment"
          title="Values Alignment
          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip1content="Identifying your core values helps you find a workplace that aligns with your personal beliefs and brings fulfillment to your work experience."
          tip2content="Reflect on what's important to you in a company culture and select the values that resonate most with you.
          "
        />
      </Col>

      <Col md={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>10. Commute and Relocations.</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            type="text"
            rows={2}
            value={commuteandRelocation}
            onClick={() => setElevenOpen(true)}
            placeholder="Enter Your Answer"
            readOnly
          />
        </Form.Group>

        <ModalOptionMultiple
          show={isElevenOpen}
          OptionData={optionDataApi.CommuteandRelocation}
          close={() => setElevenOpen(false)}
          SetData={setCommuteandRelocation}
          dataOld={commuteandRelocation}
          question="10. Commute and Relocation. ?"
          placeholder="Enter Commute and Relocation"
          title="Commute and Relocation
          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip1content="Selecting your preferred commute time or openness to relocation can significantly impact your work-life balance and job search options."
          tip2content="Consider your tolerance for travel time, lifestyle preferences, and willingness to relocate when making your selection.

          "
        />
      </Col>
      
      
    </Row>

    {/* culturefit */}
    <h4 className="mt-2">Culture & Fit</h4>

    <Row className="align-items-end justify-content-end">
      <Col md={12} lg={6} >
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>
            1.Describe your ideal work environment and culture.
          </Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            as="textarea"
            rows={2}
            readOnly
            value={describeans}
            onClick={() => setIsFirstOpen1(true)}
            placeholder="Enter Your Answer"
          />
          <span className="err_msg">{errdescribeans}</span>
        </Form.Group>

        <ModalOptionMultiple1
          show={isFirstOpen1}
          OptionData={optionDataApi.workEnvironmentOption}
          close={() => setIsFirstOpen1(false)}
          SetData={setDescribeans}
          dataOld={describeans}
          question="1. Describe your ideal work environment and culture. ?"
          placeholder="Enter describe your ideal work environment and culture"
          title="Describe your ideal work environment and culture

          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip3="Options"
          tip1content="Identifying your preferred work environment can significantly impact your job satisfaction and overall well-being."
          tip2content="Consider your personality, work style, and needs when reflecting on your ideal environment.
          "
          tip3content="Collaborative: Thrive in teamwork, brainstorming, and open communication.
          "
          tip4content="  Independent: Appreciate autonomy, self-directed work, and minimal supervision."
          tip5content="   Innovative: Seek an environment that encourages creativity, problem-solving, and new ideas."
          tip6content=" Structured: Prefer clear expectations, well-defined processes, and organized workspaces.
          "
          tip7content="Supportive: Desire a culture that fosters learning, mentorship, and collaboration with colleagues."
          tip8content=" Flexible: Value adaptability, work-life balance options, and the ability to adjust schedules as needed."
          tip9content=" Other (please specify): If your ideal environment includes elements not listed above, please describe them in the provided field."

        />
      </Col>

      <Col md={12} lg={6} >
        <Form.Group className="mb-2" controlId="quetionsecond">
          <Form.Label>
            2.How do you prefer to receive feedback and recognition?
          </Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            as="textarea"
            rows={2}
            readOnly
            value={howprefer}
            onClick={(e) => setIsSecondOpen1(true)}
            placeholder="Enter Your Answer"
          />
          <span className="err_msg">{errhowprefer}</span>
        </Form.Group>
        <ModalOptionMultiple1
          readOnly
          show={isSecondOpen1}
          OptionData={optionDataApi.feedbackAndRecognitionOption}
          close={() => setIsSecondOpen1(false)}
          SetData={setHowprefer}
          dataOld={howprefer}
          question=" 2. How do you prefer to receive feedback and recognition ?"
          placeholder="Enter how do you prefer to receive feedback and recognition"
          title="How do you prefer to receive feedback and recognition?

          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip3="Options"
          tip1content="Understanding your preferred methods of receiving feedback and recognition can help you feel valued and motivated in your work environment.
          "
          tip2content="Consider your personality, communication style, and what motivates you when making your selection.
          "
          tip3content=" Regular Check-ins: Prefer frequent informal discussions about your progress and performance.
          "
          tip4content="  Formal Reviews: Value scheduled performance reviews with detailed feedback and goal setting."
          tip5content="     Real-Time Feedback: Appreciate immediate feedback on your work during the process."
          tip6content="   Private Acknowledgement: Prefer individual and personalized recognition in private settings."
          tip7content=" Public Recognition: Enjoy receiving acknowledgement for your achievements in front of colleagues."
          tip8content=" Other : If your preferences differ from the listed options, please describe them in the provided field"
        />
      </Col>
      <Col md={12} lg={6} >
        <Form.Group className="mb-2" controlId="quetionthird">
          <Form.Label>
            3.What work-life balance arrangements are you looking for?
          </Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            as="textarea"
            rows={2}
            readOnly
            value={whatworklife}
            onClick={(e) => setIsThreeOpen1(true)}
            placeholder="Enter Your Answer"
          />
          <span className="err_msg">{errwhatworklife}</span>
        </Form.Group>
        <ModalOptionMultiple1
          readOnly
          show={isThreeOpen1}
          OptionData={optionDataApi.workLifeBalanceArrangementsOption}
          close={() => setIsThreeOpen1(false)}
          SetData={setWhatworklife}
          dataOld={whatworklife}
          question="3. What work-life balance arrangements are you looking for ?"
          placeholder="Enter what work-life balance arrangements are you looking for"
          title="What work-life balance arrangements are you looking for?

          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip3="Options"
          tip1content="Selecting your desired work-life balance arrangements can significantly impact your well-being and ability to manage personal and professional commitments.
          "
          tip2content="Consider your lifestyle, family needs, and desired level of flexibility when making your selection.
          "
          tip3content=" Flexible Hours: Seek the ability to adjust your daily start and end times within certain parameters.
          "
          tip4content="  Remote Work: Prefer the option to work from a location outside of a traditional office setting."
          tip5content="     Compressed Workweek: Desire to work full-time hours within a condensed number of days (e.g., four 10-hour days).
          "
          tip6content="  Job Sharing: Open to sharing a single full-time job with another individual, splitting responsibilities and hours."
          tip7content=" Part-Time: Seek a reduced work schedule with fewer hours per week compared to full-time employment."
          tip8content="           Other: If your desired arrangement isn't listed, describe it in the provided field (e.g., specific work schedule preferences, seasonal work options, etc.)."
          

        />
      </Col>

      <Col md={12} lg={6} >
        <Form.Group className="mb-2" controlId="quetionfive">
          <Form.Label>
            4.What values are most important to you in a workplace?
          </Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            as="textarea"
            rows={2}
            readOnly
            value={importantworkplace}
            onClick={(e) => setIsFourOpen1(true)}
            placeholder="Enter Your Answer"
          />
          <span className="err_msg">{errimportantworkplace}</span>
        </Form.Group>
        <ModalOptionMultiple1
          show={isFourOpen1}
          OptionData={optionDataApi.workplaceValuesOption}
          close={() => setIsFourOpen1(false)}
          SetData={setImportantworkplace}
          dataOld={importantworkplace}
          question="4. What values are most important to you in a workplace ?"
          placeholder="Enter what values are most important to you in a workplace"
          title="What values are most important to you in a workplace?

          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip3="Options"
          tip1content="Identifying your core values helps you find a workplace environment that aligns with your personal beliefs and brings meaning to your work.
          "
          tip2content="Reflect on what's essential to you in a company culture and select the values that resonate most with you.
          "
          tip3content=" Integrity: Value honesty, ethical conduct, and transparency in the workplace.
          "
          tip4content=" Diversity & Inclusion: Appreciate a company culture that values respect, belonging, and the contribution of individuals from diverse backgrounds."
          tip5content="     Teamwork: Thrive in a collaborative environment where colleagues support and work effectively together.
          "
          tip6content="    Other : If values not listed are important to you, select Other and describe them in the provided field."
          tip7content="          Innovation: Seek an environment that encourages creativity, problem-solving, and new ideas.
          "
          tip8content="           Work-Life Balance: Prioritize a company that supports a healthy balance between work and personal life."
          tip9content="       Continuous Learning: Desire opportunities for professional development, skill-building, and staying current in your field."
          
        />
      </Col>

      <Col md={12} lg={6} >
        <Form.Group className="mb-2" controlId="quetionfive">
          <Form.Label>
            5.Preferred Commute Time/Distance (Leveraging job map feature)
          </Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            as="textarea"
            rows={2}
            readOnly
            value={preferredCommute}
            onClick={(e) => setIsFiveOpen1(true)}
            placeholder="Enter Your Answer"
          />
          <span className="err_msg">{errimportantworkplace}</span>
        </Form.Group>
        <ModalOptionMultiple1
          show={isFiveOpen1}
          OptionData={optionDataApi.preferredCommuteTimeDistanceOption}
          close={() => setIsFiveOpen1(false)}
          SetData={setPreferredCommute}
          dataOld={preferredCommute}
          question="5. Preferred Commute Time/Distance (Leveraging job map feature) ?"
          placeholder="Enter Preferred Commute Time/Distance (Leveraging job map feature)"
          title="Preferred Commute Time/Distance


          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip3="Options"
          tip1content="Selecting your preferred commute time/distance can significantly impact your work-life balance and overall satisfaction.

          "
          tip2content="Consider your tolerance for travel time, lifestyle preferences, and willingness to work remotely when making your selection.
          "
          tip3content=" Up to 15 minutes
          
          "
          tip4content="  30-60 minutes
         "
          tip5content="       15-30 minutes"
          tip6content="   Over an hour "
          tip7content="Flexible/Remote: Open to remote work opportunities regardless of location."
          tip8content=" Other: If your preferences don't fit the above categories, select Other and enter your desired commute details"
        />
      </Col>

     

      <Col md={12} lg={6}>
        <Form.Group className="mb-2" controlId="quetionfirst">
          <Form.Label>6. Company Culture Preference.</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            as="textarea"
            rows={2}
            value={companyCulturePreference}
            onClick={() => setIsSixOpen1(true)}
            placeholder="Enter Your Answer"
            readOnly
          />
        </Form.Group>

        <ModalOptionMultiple1
          show={isSixOpen1}
          OptionData={optionDataApi.Company}
          close={() => setIsSixOpen1(false)}
          SetData={setCompanyCulturePreference}
          dataOld={companyCulturePreference}
          question="6. Company Culture Preference. ?"
          placeholder="Enter Company Culture Preference"
          title="Company Culture Preference


          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip3="Options"
          tip1content="Identifying your preferred company culture can significantly impact your work experience and overall satisfaction.

          "
          tip2content="Consider your personality, work style, and what motivates you when choosing your ideal culture.

          "
          tip3content="  Innovative: Seek an environment that encourages creativity, problem-solving, and new ideas.
          "
          tip4content="   Competitive: Enjoy a fast-paced environment with opportunities to excel and push boundaries."
          tip5content="      Startup Vibes: Prefer a flexible, dynamic environment with a focus on quick iteration and growth.
          "
          tip6content="     Other : If your ideal culture includes elements not listed above, describe them in the provided field."
          tip7content="           Collaborative: Thrive in teamwork, brainstorming, and open communication.
          "
          tip8content="           Supportive: Desire a culture that fosters learning, mentorship, and collaboration with colleagues."
          tip9content="Corporate Structure: Appreciate a well-established organization with clear processes and defined career paths."
        />
      </Col>

      <Col md={12} lg={6} >
        <Form.Group className="mb-2" controlId="quetionfive">
          <Form.Label>7.Company Size Preference</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            as="textarea"
            rows={2}
            readOnly
            value={companySizePreference}
            onClick={(e) => setIsSevenOpen1(true)}
            placeholder="Enter Your Answer"
          />
          <span className="err_msg">{errimportantworkplace}</span>
        </Form.Group>
        <ModalOption1
          show={isSevenOpen1}
          OptionData={optionDataApi.companySizePreferenceOption}
          close={() => setIsSevenOpen1(false)}
          SetData={setCompanySizePreference}
          dataOld={companySizePreference}
          question="7. Company Size Preference ?"
          placeholder="Enter Company Size Preference"
          title="Company Size Preference



          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip3="Options"
          tip1content="Selecting your preferred company size can impact your work experience in various ways, such as team dynamics, career growth opportunities, and company culture.

          "
          tip2content="Consider your personality, work style, and career goals when choosing your preferred company size.
          "
          tip3content=" 
          15-30 minutesStartup (1-50 employees): Thrive in fast-paced, dynamic environments with opportunities to wear multiple hats and make a significant impact.
          "
          tip4content=" Startup (1-50 employees): Thrive in fast-paced, dynamic environments with opportunities to wear multiple hats and make a significant impact."
          tip5content="    Medium (201-1000 employees): Seek a balance between agility and structure, with opportunities for growth and specialization within departments."
          tip6content="     Large (1000+ employees): Prefer established organizations with well-defined processes, diverse career paths, and access to broader resources."
        />
      </Col>

      <Col md={12} lg={6} >
        <Form.Group className="mb-2" controlId="quetionfive">
          <Form.Label>8.Benefits & Perks Important to You</Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            as="textarea"
            rows={2}
            value={benefitsPerks}
            onClick={(e) => setEightOpen1(true)}
            readOnly
            placeholder="Enter Your Answer"
          />
          <span className="err_msg">{errimportantworkplace}</span>
        </Form.Group>
        <ModalOptionMultiple1
          show={isEightOpen1}
          OptionData={optionDataApi.benefitsPerksImportantOption}
          close={() => setEightOpen1(false)}
          SetData={setBenefitsPerks}
          dataOld={benefitsPerks}
          question="8. Benefits & Perks Important to You ?"
          placeholder="Enter Benefits & Perks Important to You"
          title="Benefits & Perks Important to You


          "
          tip1="Tip 1"
          tip2="Tip 2"
          tip3="Options"
          tip1content="Identifying your desired benefits and perks can help you prioritize job opportunities that offer comprehensive support for your well-being and professional development.

          "
          tip2content="Consider your lifestyle, financial needs, and career goals when selecting the benefits most important to you.
          "
          tip3content="  Health Insurance: Value comprehensive health insurance coverage for yourself and dependents (if applicable).
          "
          tip4content="  Paid Time Off: Desire generous paid time off policies, including vacation days, sick leave, and personal days.
        "
          tip5content="        Remote Work Options: Seek opportunities to work remotely, either full-time or on a hybrid basis.
         "
          tip6content="     Other : If benefits or perks not listed are important to you, select Other and describe them in the provided field "
          tip7content="           Retirement Plan: Seek an employer offering a retirement savings plan with employer contributions (e.g., 401(k) with matching).
          "
          tip8content="   Flexible Schedule: Value the ability to adjust your work hours to accommodate your personal needs."
          tip9content=" Wellness Programs: Appreciate access to employee wellness programs, such as gym memberships, health screenings, or mental health resources."
        />
      </Col>

      <Col xs={12}>
        <Form.Group className="mb-2" controlId="quetionfourth">
          <Form.Label>
            9.Can you share an example of how you've adapted to a new company
            culture in the past?
          </Form.Label>
          <Form.Control
            className="pointer readinputboccolor newsearchinputaddborderradius"
            as="textarea"
            rows={2}
            value={canyoushare}
            onChange={(e) => setCanyoushare(e.target.value)}
            placeholder="Enter Your Answer"
          />
          <span className="err_msg">{errcanyoushare}</span>
        </Form.Group>
      </Col>
      
     <Col lg={6} md={6} xs={12} className="d-lg-flex d-md-flex align-items-center ">
        <div className="form-check">
          <label className="form-check-label text-muted">
            <input
              type="checkbox"
              className="form-check-input "
              checked={checkBoxValue}
              onChange={(e) => setCheckBoxValue(!checkBoxValue)}
            />
            <i className="input-helper"></i>
            Job Feed Notifications{" "}
          </label>
        </div>
        {checkBoxValue ? (
          <Form.Group className="mt-2 mx-md-3 mx-lg-3">
            <select
              className="form-control borderdrop"
              id="Showby"
              value={jobUpdate}
              
              onChange={(e) => setJobUpdate(e.target.value)}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </Form.Group>
        ) : (
          ""
        )}
      </Col>
     
      <Col lg={6} md={6} xs={12} >
       
        <div className="text-right mt-2">
          <MyButton onClick={SubmitData}>Save</MyButton>
        </div>
      </Col>
    </Row> 
    </>
  );
}

export default Preference;

// preference
function ModalOption(props) {
  const [selectCollectionAll, setselectCollectionAll] = useState(props.dataOld);

  useEffect(() => {
    setselectCollectionAll(props.dataOld);
  }, [props.dataOld]);

  // ClickonCollection
  const ClickonCollection = (type) => {
    setselectCollectionAll([type]);
    props.SetData([type]);
    props.close();
    setawardsModalshow(false);
  };

  const [awardsModalshow, setawardsModalshow] = useState(false);

  const awardsModal = () => {
    setawardsModalshow(!awardsModalshow);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
        props.close();
        setawardsModalshow(false)}}
        className="contactinfomodel addnewchangesmodal"
        size="lg"
        centered
      >
      
        <Modal.Body className="p-0">
          <Row>
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
                <Col lg={12}>
                  <Row className="bg-white userdatapart  border-radiustip">
                    <div className="col-12 mt-3 tipsbuttonset mb-5">
                      <div className="row index_newcss">
                        <div className="col-10 contectinfoshowname text-left p-0 mb-2">
                          <div className="sizesetprefrence">{props.question}</div>
                        </div>
                        <div className="col-2 mb-2">
                          <span
                            onClick={awardsModal}
                            className="newdashbordbuttoncolor fix_tips"
                          >
                            {awardsModalshow ? <MdOutlineClose className="setcloseiconwidthwidth"/> :
                            "Tips" }
                          </span>
                        </div>
                      </div>
                    </div>
                    {props.OptionData?.map((e) => {
                      return (
                        <Col md={6}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="form-check">
                              <label className="form-check-label text-muted">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  checked={selectCollectionAll?.includes(e)}
                                  onClick={(ele) => ClickonCollection(e)}
                                />
                                <i className="input-helper"></i>
                                <div className="dash_folder"> {e}</div>
                              </label>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </div>
              <div className="position-relative">
                {awardsModalshow ? (
                  <Col
                    lg={12}
                    className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips"
                  >
                    <div className="contactinfo_tip awardsheightset border-radiustip">
                    

                      <div className="mt-2">
                        <div className="mt-2">
                          <b>{props.title}</b>
                        </div>

                        <div className="mt-2">
                          <div className="text-justify">
                            {props.tip1content}
                          </div>
                        </div>

                        <div className="mt-2">
                          <div className="text-justify">
                            {props.tip2content}
                          </div>
                        </div>

                        
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
   
    </>
  );
}

function ModalOptionMultiple(props) {
  const [selectCollectionAll, setselectCollectionAll] = useState([]);

  const [openCustomOption, setopenCustomOption] = useState(false);
  const [customoptionvalue, setcustomoptionvalue] = useState("");

  const [errorcustomoptionvalue, seterrorcustomoptionvalue] = useState("");

  const ClickonCollection = (type) => {
   
    if (selectCollectionAll?.find((ele) => ele == type)) {
      if (type?.includes("Other")) {
        setopenCustomOption(false);
        setcustomoptionvalue("");
      }
      setselectCollectionAll(selectCollectionAll?.filter((ele) => type != ele));
    } else {
      if (type?.includes("Other")) {
        setopenCustomOption(true);
      }
      setselectCollectionAll(selectCollectionAll?.concat([type]));
    }
  };
  

  useEffect(() => {
    setselectCollectionAll(props.dataOld);
    if (props.show) {
    
      const newdata = props.dataOld?.filter(
        (ele) => !props.OptionData?.includes(ele)
      );
      if (newdata?.length) {
        setopenCustomOption(true);
        setcustomoptionvalue(newdata);
        setselectCollectionAll(selectCollectionAll?.concat("Other"));
      } else {
        setcustomoptionvalue("");
        setopenCustomOption(false);
      }
    }
  }, [props.show, props.dataOld]);

  

  // addcustomvalue
  const addcustomvalue = (alltypeoptionValue) => {
    setselectCollectionAll(alltypeoptionValue);
    props.SetData(alltypeoptionValue);
    if (alltypeoptionValue?.includes("Other")) {
      var otherfilterdataset = alltypeoptionValue?.filter(
        (ele) => ele != "Other"
      );
      otherfilterdataset = otherfilterdataset?.filter((ele) =>
        props.OptionData?.includes(ele)
      );
      if (customoptionvalue?.toString()?.trim()) {
        setselectCollectionAll(
          otherfilterdataset?.concat([customoptionvalue?.toString()?.trim()])
        );
        props.SetData(otherfilterdataset?.concat([customoptionvalue?.toString()?.trim()]));
      } else {
        setselectCollectionAll(otherfilterdataset);
        props.SetData(otherfilterdataset);
      }
    } else {
      setselectCollectionAll(
        selectCollectionAll?.filter((ele) => props.OptionData?.includes(ele))
      );
      props.SetData(
        selectCollectionAll?.filter((ele) => props.OptionData?.includes(ele))
      );
    }
    props.close();
    setawardsModalshow(false);
  };

  const [awardsModalshow, setawardsModalshow] = useState(false);

  const awardsModal = () => {
    setawardsModalshow(!awardsModalshow);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
        props.close(); 
        setawardsModalshow(false)
        }}
        className="contactinfomodel addnewchangesmodal"
        size="lg"
        centered
      >
      
        <Modal.Body className="p-0">
          <Row>
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
                <Col lg={12}>
                  <Row className="bg-white userdatapart  border-radiustip">
                    <div className="col-12 mt-3 tipsbuttonset mb-4">
                      <div className="row index_newcss">
                        <div className="col-10 contectinfoshowname text-left p-0 mb-2">
                          <div className="sizesetprefrence">{props.question}</div>
                        </div>
                        <div className="col-2 mb-2">
                          <span
                            onClick={()=>awardsModal()}
                            className="newdashbordbuttoncolor fix_tips"
                          >
                             {awardsModalshow ? <MdOutlineClose className="setcloseiconwidthwidth"/> :
                               "Tips" }
                          </span>
                        </div>
                      </div>
                    </div>
                    {props.OptionData?.map((e) => {
                      return (
                        <Col md={6}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="form-check">
                              <label className="form-check-label text-muted">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectCollectionAll?.includes(e)}
                                  onClick={(ele) => ClickonCollection(e)}
                                />
                                <i className="input-helper"></i>
                                <div className="dash_folder"> {e}</div>
                              </label>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                    {openCustomOption ? (
                      <>
                        <input
                          className="form-control my-2"
                          type="text"
                          placeholder={props.placeholder}
                          value={customoptionvalue}
                          onChange={(e) => setcustomoptionvalue(e.target.value)}
                        />
                        <span className="eroorclass">
                          {errorcustomoptionvalue}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    <Col>
                      <div className="setmodalfooterbutton">
                        <button
                          className="btnjob text-white"
                          onClick={(e) => addcustomvalue(selectCollectionAll)}
                        >
                          Enter
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div className="position-relative">
                {awardsModalshow ? (
                  <Col
                    lg={12}
                    className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips"
                  >
                    <div className="contactinfo_tip awardsheightset border-radiustip">
                     

                      <div className="mt-2">
                        <div className="mt-2">
                          <b>{props.title}</b>
                        </div>

                        <div className="mt-2">
                          <div className="text-justify">
                            {props.tip1content}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div className="text-justify">
                            {props.tip2content}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div className="text-justify">
                            {props.tip3content}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div className="text-justify">
                            {props.tip4content}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div className="text-justify">
                            {props.tip5content}
                          </div>
                        </div>
                        <div className="mt-2">
                          <div className="text-justify">
                            {props.tip6content}
                          </div>
                        </div>

                       
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
     
    </>
  );
}

// culturefit
function ModalOption1(props) {
  const [selectCollectionAll, setselectCollectionAll] = useState(props.dataOld);
  useEffect(() => {
    setselectCollectionAll(props.dataOld);
  }, [props.dataOld]);

  const ClickonCollection = (type) => {
    setselectCollectionAll([type]);
    props.SetData([type]);
    props.close();
    setawardsModalshow(false)
  };

  const [awardsModalshow , setawardsModalshow] = useState(false);

  const awardsModal = () =>{
    setawardsModalshow(!awardsModalshow);
  }

  return (
    <>

<Modal
        show={props.show} 
        onHide={() =>{
          props.close();
          setawardsModalshow(false);
          }}
        className="contactinfomodel addnewchangesmodal"
        size="lg"
        centered
      >
       
        <Modal.Body className="p-0">
          <Row>
          <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
            <Col lg={12}>
              <Row className="bg-white userdatapart  border-radiustip">
              <div className="col-12 mt-3 tipsbuttonset mb-5">
                  <div className="row index_newcss">
                <div className="col-10 contectinfoshowname text-left p-0 mb-2">
                  <div className="sizesetprefrence">{props.question}</div>
                
                </div>
                 <div className="col-2 mb-2">
                 <span
                onClick={awardsModal}
                  className="newdashbordbuttoncolor fix_tips"
                >
                  {awardsModalshow ? <MdOutlineClose className="setcloseiconwidthwidth"/> :
                  "Tips" }
                </span>
                </div>
                
                </div>
                </div>
                {props.OptionData?.map((e) => {
            return (
              <Col md={6}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input
                        type="radio"
                        className="form-check-input"
                        checked={selectCollectionAll?.includes(e)}
                        onClick={(ele) => ClickonCollection(e)}
                      />
                      <i className="input-helper"></i>
                      <div className="dash_folder"> {e}</div>
                    </label>
                  </div>
                </div>
              </Col>
            );
          })}
              
              </Row>
            </Col>
                </div>
              <div className="position-relative">

            {awardsModalshow ?
            <Col lg={12} className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips">
              <div className="contactinfo_tip awardsheightset border-radiustip">

                <div className="mt-2">
                  
                  <div className="mt-2">
                    <b>{props.title}</b>
                  </div>

                  <div className="mt-2">
                          <div className="text-justify">
                          {props.tip1content}
                          </div>
                        </div>

                        <div className="mt-2">
                          <div className="text-justify">
                           {props.tip2content}
                          </div>
                        </div>
                  
                
                  <Accordion defaultActiveKey={['0']} alwaysOpen>
                  
                      <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              {props.tip3}:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div className="text-justify">
                                  {props.tip3content}
                                </div>
                                <div className="mt-2">{props.tip4content}</div>
                                <div className="mt-2">{props.tip5content}</div>
                                <div className="mt-2">{props.tip6content}</div>
                                <div className="mt-2">{props.tip7content}</div>
                                <div className="mt-2">{props.tip8content}</div>
                                <div className="mt-2">{props.tip9content}</div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                    
                  </Accordion>
                 
                </div>
              </div>
            </Col>
            : ""
            }
            </div>
            </div>

          </Row>
        </Modal.Body>
       
      </Modal>
    
    </>
  );
}

function ModalOptionMultiple1(props) {
  const [selectCollectionAll, setselectCollectionAll] = useState([]);

  const [openCustomOption, setopenCustomOption] = useState(false);
  const [customoptionvalue, setcustomoptionvalue] = useState("");

  const [errorcustomoptionvalue, seterrorcustomoptionvalue] = useState("");

  // ClickonCollection
  const ClickonCollection = (type) => {
    if (selectCollectionAll?.find((ele) => ele == type)) {
      if (type?.includes("Other")) {
        setopenCustomOption(false);
        setcustomoptionvalue("");
      }
      setselectCollectionAll(selectCollectionAll?.filter((ele) => type != ele));
    } else {
      if (type?.includes("Other")) {
        setopenCustomOption(true);
      }
      setselectCollectionAll(selectCollectionAll?.concat([type]));
    }
  };

  useEffect(() => {
    setselectCollectionAll(props.dataOld);
    if (props.show) {
      const newdata = props.dataOld?.filter(
        (ele) => !props.OptionData?.includes(ele)
      );
      if (newdata?.length) {
        setopenCustomOption(true);
        setcustomoptionvalue(newdata);
        setselectCollectionAll(selectCollectionAll?.concat("Other"));
      } else {
        setcustomoptionvalue("");
        setopenCustomOption(false);
      }
    }
  }, [props.show, props.dataOld]);


  // addcustomvalue
  const addcustomvalue = (alltypeoptionValue) => {
    setselectCollectionAll(alltypeoptionValue);
    props.SetData(alltypeoptionValue);
    if (alltypeoptionValue?.includes("Other")) {
      var otherfilterdataset = alltypeoptionValue?.filter(
        (ele) => ele != "Other"
      );
      otherfilterdataset = otherfilterdataset?.filter((ele) =>
        props.OptionData?.includes(ele)
      );
      if (customoptionvalue?.toString()?.trim()) {
        setselectCollectionAll(
          otherfilterdataset?.concat([customoptionvalue?.toString()?.trim()])
        );
        props.SetData(otherfilterdataset?.concat([customoptionvalue?.toString()?.trim()]));
      } else {
        setselectCollectionAll(otherfilterdataset);
        props.SetData(otherfilterdataset);
      }
    } else {
      setselectCollectionAll(
        selectCollectionAll?.filter((ele) => props.OptionData?.includes(ele))
      );
      props.SetData(
        selectCollectionAll?.filter((ele) => props.OptionData?.includes(ele))
      );
    }
    props.close();
    setawardsModalshow(false);
  };

  const [awardsModalshow, setawardsModalshow] = useState(false);

  const awardsModal = () => {
    setawardsModalshow(!awardsModalshow);
  }

  return (
    <>
      <Modal
        show={props.show} 
        onHide={() =>{
        props.close();
        setawardsModalshow(false);
        }}
        className="contactinfomodel addnewchangesmodal"
        size="lg"
        centered
      >
        <Modal.Body className="p-0">
          <Row>
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth " >
                <Col lg={12}>
                  <Row className="bg-white userdatapart  border-radiustip">
                    <div className="col-12 mt-3 tipsbuttonset mb-4">
                      <div className="row index_newcss">
                        <div className="col-10 contectinfoshowname text-left p-0 mb-2">
                          <div className="sizesetprefrence">{props.question}</div>

                        </div>
                        <div className="col-2 mb-2">
                          <span
                            onClick={()=>awardsModal()}
                            className="newdashbordbuttoncolor fix_tips"
                          >
                             {awardsModalshow ? <MdOutlineClose className="setcloseiconwidthwidth"/> :
                            "Tips" }
                          </span>
                        </div>

                      </div>
                    </div>
                    {props.OptionData?.map((e) => {
                      return (
                        <Col md={6}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="form-check">
                              <label className="form-check-label text-muted">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectCollectionAll?.includes(e)}
                                  onClick={(ele) => ClickonCollection(e)}
                                />
                                <i className="input-helper"></i>
                                <div className="dash_folder"> {e}</div>
                              </label>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                    {openCustomOption ? (
                      <>
                        <input
                          className="form-control my-2"
                          type="text"
                          placeholder={props.placeholder}
                          value={customoptionvalue}
                          onChange={(e) => setcustomoptionvalue(e.target.value)}
                        />
                        <span className="eroorclass">{errorcustomoptionvalue}</span>
                      </>
                    ) : (
                      ""
                    )}
                    <Col>
                      <div className="setmodalfooterbutton">
                        <button
                          className="btnjob text-white"
                          onClick={(e) => addcustomvalue(selectCollectionAll)}
                        >
                          Enter
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div className="position-relative">

                {awardsModalshow ?
                  <Col lg={12} className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips">
                    <div className="contactinfo_tip awardsheightset border-radiustip">
                    

                      <div className="mt-2">

                        <div className="mt-2">
                          <b>{props.title}</b>
                        </div>

                        <div className="mt-2">
                                <div className="text-justify">
                                  {props.tip1content}
                                </div>
                              </div>

                              <div className="mt-2">
                                <div className="text-justify">
                                  {props.tip2content}
                                </div>
                              </div>

                        

                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                         
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              {props.tip3}:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div className="text-justify">
                                  {props.tip3content}
                                </div>
                                <div className="mt-2">{props.tip4content}</div>
                                <div className="mt-2">{props.tip5content}</div>
                                <div className="mt-2">{props.tip6content}</div>
                                <div className="mt-2">{props.tip7content}</div>
                                <div className="mt-2">{props.tip8content}</div>
                                <div className="mt-2">{props.tip9content}</div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>

                        </Accordion>


                      </div>
                    </div>
                  </Col>
                  : ""
                }
              </div>
            </div>

          </Row>
        </Modal.Body>

      </Modal>
     
    </>
  );
}
