import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  Accordion,
  Collapse,
  Dropdown,
  Form,
  Modal,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { Trans } from "react-i18next";
import "./sidebar.css";
import { useAuth } from "../../context/AuthProvider";
import Avatar from "react-avatar";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import sidebarLogo from "../../assets/images/anim1.png";
import mediakit from "../../assets/images/mediakit-removebg-preview.png";
import candidate from "../../assets/images/TURN-TO (2).png";
import employer from "../../assets/images/TURN-TO (1).png";

import sidebarhoverdashbord from "../../Component/icontopng/sidebarhoverdashbord.png";
import sidebaruserlogin from "../../Component/icontopng/sidebaruserlogin.png";
import sidebaremployerlogin from "../../Component/icontopng/sidebaremployerlogin.png";
import historyclose1 from "../../Component/icontopng/historyclose.png";
import turntoBeta from "../../assets/images/loginpagenewlogo.png";
import sidebarlogout from "../../Component/icontopng/sidebarlogout.png";
import sidebarEmployerLogo from "../../assets/images/TURN-TO (1).png";
import sidebarUserLogo from "../../assets/images/TURN-TO (2).png";
import { useSocket } from "../../context/SocketProvider";
import { Store } from "react-notifications-component";
import Feedback from "../../Component/Footer-Page/Feedback";
import SearchHistroy from "../../Component/SearchHistroy/SeachHistroy";
import historyclose from "../../Component/icontopng/historyclose.png";
import logintwitter from "../../Component/icontopng/logintwitter.png";
import logininstagram from "../../Component/icontopng/logininstagram.png";
import loginreddit from "../../Component/icontopng/loginreddit.png";
import loginfacebook from "../../Component/icontopng/loginfacebook.png";
import loginlinedin from "../../Component/icontopng/footerlinkedin.png";
import { getJobList, postcompanyPublicViewStatus } from "../../Api/Api";
import NewUserLogin from "../candidate/components/common/CompanyDetail/NewUserLogin";
import Popularsearches from "../Landingpage/Popularsearches";
import Companieshiring from "../Landingpage/Companieshiring";
import Trendingsearche from "../Landingpage/Trendingsearche";
import Trendingjobs from "../Landingpage/Trendingjobs";
import Popularcities from "../Landingpage/Popularcities";
import Tabspopulardata from "../Landingpage/Tabspopulardata";
import Bulkupload from "../../Component/BulkUpload/Bulkupload";

const Sidebar = ({ location }) => {
  const { AuthData, setAuthData, isAuthenticated ,setProfileScores} = useAuth();
  const { socket } = useSocket();
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [isDashboardOpenTwo, setIsDashboardOpenTwo] = useState(false);
  const [isAccount, setIsAccount] = useState(false);
  const [userresources, setUserresources] = useState(false);
  const [employerprofile, setemployerprofile] = useState(false);
  const [menuState, setMenuState] = useState({});
  const [isPublicAts, setIsPublicAts] = useState(false);
  const [isPublicprofile, setIsPublicprofile] = useState(false);
  const [jobs, setJobs] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalShowLogin, setModalShowLogin] = React.useState(false);

  const [allSearchexploremenu, setAllSearchexploremenu] = useState(false);

  const [isBulk, setIsBulk] = useState(false);
  const CloseMOdalBulk = () => {
    setIsBulk(false);
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    const newState = Object.keys(menuState).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    setMenuState(newState);
  };

  var history = useHistory();
  const isPathActive = (path) => {
    return location.pathname == path;
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, [location]);

  useEffect(() => {
    setIsPublicAts(AuthData?.is_public_ats_resume);
  }, [AuthData]);
  const LogoutFun = (event) => {
    event.preventDefault();
    // console.log("Logout ")
    document.querySelector(".sidebar-offcanvas").classList.remove("active");
    Swal.fire({
      title: "Are you sure want to logout?",
      text: "",
      icon: "warning",
      imageUrl:
        isAuthenticated && AuthData.role === "user" ? candidate : employer,
      imageHeight: 50,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes, Log Out",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem("token");
        localStorage.removeItem("welcomeemp");
        localStorage.removeItem("welcomeuser");
        localStorage.removeItem("welcomelandingpage");

        setAuthData(null);
        setProfileScores({})

        history.push("/jobmap");
      }
    });
  };

  const AcccountDeactive = (event) => {
    event.preventDefault();
    // console.log("Logout ")
    document.querySelector(".sidebar-offcanvas").classList.remove("active");
    Swal.fire({
      title: "Are you sure want to deactivate?",
      text: "To reactivate your account, you must take action within the next 30 days. Failure to do so may result in permanent account closure and the loss of your data.",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deactivate",
      cancelButtonText: "No",
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        var data = {
          is_deactivated: true,
          token: localStorage.getItem("token"),
        };
        socket.emit("change_account_setting", data);
        socket.off("change_account_setting");
        socket.on("change_account_setting", (res) => {
          // console.log(res.status);
          if (res.status) {
            localStorage.removeItem("token");
            setAuthData(null);
            setProfileScores({})

            history.push("/");
          }
        });
      }
    });
  };
  const toggleOffcanvas = () => {
    document.body.classList.remove("sidebar-icon-only");
    var element = document.querySelector(".hover-open");
    if (element) {
      element.classList.remove("hover-open");
    }
  };
  const toggleremoeOffcanvas = () => {
    document.body.classList.add("sidebar-icon-only");
  };

  const AtsPublicPvt = (event) => {
    var data = {
      is_public_ats_resume: event,
      token: localStorage.getItem("token"),
    };
    socket.emit("change_account_setting", data);
    socket.off("change_account_setting");
    socket.on("change_account_setting", (res) => {
      Store.addNotification({
        title: res.message,
        // message: response.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        // animationIn: ["animate__animated", "animate__fadeIn"],
        // animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
    });
  };
  var iframelink = `<iframe style="height: 99vh;width: 100%" allow="geolocation; web-share; microphone;"  class="iframe" src="${process.env.REACT_APP_COMPANY_JOB_MAP}job-map-iframe/${AuthData?.username}"></iframe>`;

  const [show, setShow] = useState(false);
  const offcanvasRef = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    document.querySelector(".sidebar-offcanvas").classList.remove("active");
    setShow(true);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        offcanvasRef.current &&
        !offcanvasRef.current.contains(event.target)
      ) {
        handleClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [offcanvasRef]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [tabeventkey, setTabeventkey] = useState(false);

  const [exploroffcanvas, setexploroffcanvas] = useState(false);
  const exploroffcanvasRef = useRef();

  const exploroffcanvasshow = () => {
    document.querySelector(".sidebar-offcanvas").classList.remove("active");
    setexploroffcanvas(true);
  };
  const exploroffcanvasclose = () => setexploroffcanvas(false);

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the click occurred outside the offcanvas or the modal
      if (
        exploroffcanvasRef.current &&
        !exploroffcanvasRef.current.contains(event.target) &&
        !event.target.closest('.modal') &&
        !event.target.closest('.pac-container') 
      ) {
        exploroffcanvasclose();
      }
    }
  
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [exploroffcanvasRef, exploroffcanvasclose]);

  const [popularsearchoffcanvas, setpopularsearchoffcanvas] = useState(false);
  const popularsearchcanvasRef = useRef();

  const popularsearchoffshow = () => {
    setTabeventkey("popularsearches");
    setpopularsearchoffcanvas(true);
  };
  const popularsearchoffclose = () => setpopularsearchoffcanvas(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        popularsearchcanvasRef.current &&
        !popularsearchcanvasRef.current.contains(event.target)
      ) {
        popularsearchoffclose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [popularsearchcanvasRef]);

  const [popularCompaniesoffcanvas, setpopularCompaniesoffcanvas] =
    useState(false);
  const popularCompaniesoffRef = useRef();

  const popularCompaniesoffcanvasshow = () => {
    setTabeventkey("companieshiring");
    setpopularCompaniesoffcanvas(true);
  };
  const popularCompaniesoffcanvasclose = () =>
    setpopularCompaniesoffcanvas(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        popularCompaniesoffRef.current &&
        !popularCompaniesoffRef.current.contains(event.target)
      ) {
        popularCompaniesoffcanvasclose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [popularCompaniesoffRef]);

  const [trendingSearchesoffcanvas, settrendingSearchesoffcanvas] =
    useState(false);
  const trendingSearchesoffcanvasRef = useRef();

  const trendingSearchesoffcanvasshow = () => {
    setTabeventkey("trendingsearches");
    settrendingSearchesoffcanvas(true);
  };
  const trendingSearchesoffcanvasclose = () =>
    settrendingSearchesoffcanvas(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        trendingSearchesoffcanvasRef.current &&
        !trendingSearchesoffcanvasRef.current.contains(event.target)
      ) {
        trendingSearchesoffcanvasclose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [trendingSearchesoffcanvasRef]);

  const [trendingJobsoffcanvas, settrendingJobsoffcanvas] = useState(false);
  const trendingJobsoffcanvasRef = useRef();

  const trendingJobsoffcanvasshow = () => {
    setTabeventkey("trendingjobs");
    settrendingJobsoffcanvas(true);
  };
  const trendingJobsoffcanvasclose = () => settrendingJobsoffcanvas(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        trendingJobsoffcanvasRef.current &&
        !trendingJobsoffcanvasRef.current.contains(event.target)
      ) {
        trendingJobsoffcanvasclose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [trendingJobsoffcanvasRef]);

  const [popularCitiesoffcanvas, setpopularCitiesoffcanvas] = useState(false);
  const popularCitiesoffcanvasRef = useRef();

  const popularCitiesoffcanvasshow = () => setpopularCitiesoffcanvas(true);
  const popularCitiesoffcanvasclose = () => setpopularCitiesoffcanvas(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        popularCitiesoffcanvasRef.current &&
        !popularCitiesoffcanvasRef.current.contains(event.target)
      ) {
        popularCitiesoffcanvasclose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [popularCitiesoffcanvasRef]);

  const openmodalusernamenotset = (event) => {
    event.preventDefault();
    document.querySelector(".sidebar-offcanvas").classList.remove("active");
    Swal.fire({
      title: "Please Fill Your Profile Details",
      text: "",
      icon: "warning",
      // imageUrl: isAuthenticated && AuthData.role === "user" ? candidate : employer,
      // imageHeight: 50,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        history.push("/profile");
      } else {
        history.push("/jobmap");
      }
    });
  };

  const opensetyourcomapnyprofile = (event) => {
    event.preventDefault();
    document.querySelector(".sidebar-offcanvas").classList.remove("active");
    Swal.fire({
      title: "Please Fill Your Company Profile",
      text: "",
      icon: "warning",
      // imageUrl: isAuthenticated && AuthData.role === "user" ? candidate : employer,
      // imageHeight: 50,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        history.push("/employer/company-profile");
      } else {
        history.push("/employer/dashboard");
      }
    });
  };

  const copyToClipboard = () => {
    const urlToCopy =
      process.env.REACT_APP_COMPANY_JOB_MAP + "job-map/" + AuthData?.username; // Get the current URL
    navigator.clipboard
      .writeText(urlToCopy) // Use Clipboard API to copy text
      .then(() => {
        Store.addNotification({
          title: "copied clipboard",
          // message: "copied clipboard",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
      })
      .catch((error) => {
        // console.error('Failed to copy URL: ', error);
      });
  };
  const copyToClipboardmap = () => {
    const urlToCopy = iframelink; // Get the current URL
    navigator.clipboard
      .writeText(urlToCopy) // Use Clipboard API to copy text
      .then(() => {
        Store.addNotification({
          title: "copied clipboard",
          // message: "copied clipboard",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
      })
      .catch((error) => {
        // console.error('Failed to copy URL: ', error);
      });
  };

  const [checked, setChecked] = useState(
    localStorage.getItem("checked") === "true" || false
  );
  const [demoFileLink, setDemoFileLink] = useState("");

  // for check button
  useEffect(() => {
    localStorage.setItem("checked", checked);
  }, [checked]);

  const handleChange = (newChecked) => {
    setChecked(newChecked);
    var data = {
      show_profile: newChecked,
    };
    postcompanyPublicViewStatus(data)
      .then(async function (response) {
        Store.addNotification({
          title: response.data.message,
          // message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          // animationIn: ["animate__animated", "animate__fadeIn"],
          // animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
      })
      .catch(function (error) {
        // console.log(error.response.data.error);
      });
  };
  const getDatajobListApi = () => {
    getJobList()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        setDemoFileLink(response.data.demo_excel);
        setJobs(response.data.data);
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    if (isAuthenticated && AuthData.role == "employer") {
      getDatajobListApi();
    }
  }, []);

  // const MapViewNevigation = () => {
  //   localStorage.removeItem("SelecetedJob");
  //   localStorage.setItem("SelecetedJob", JSON.stringify(jobs));
  //   if (jobs.length > 0) {
  //     history.push("/employer/job-map");
  //   } else {
  //     Store.addNotification({
  //       title: "Please Wait Jobs Are Loading ",
  //       type: "danger",
  //       insert: "top",
  //       container: "top-right",
  //       animationIn: ["animate__animated", "animate__zoomIn"],
  //       animationOut: ["animate__animated", "animate__fadeOut"],
  //       dismiss: {
  //         duration: 2000,
  //         onScreen: false,
  //       },
  //     });
  //   }
  // };

  // logout
  const toggleCandidateDropdown = () => {
    setIsDashboardOpenTwo(!isDashboardOpenTwo);
    if (employerprofile) {
      setemployerprofile(false);
    }
    if (isDashboardOpen) {
      setIsDashboardOpen(false);
    }
    if (allSearchexploremenu) {
      setAllSearchexploremenu(false);
    }
  };

  const toggleEmployerDropdown = () => {
    setemployerprofile(!employerprofile);
    if (isDashboardOpenTwo) {
      setIsDashboardOpenTwo(false);
    }
    if (isDashboardOpen) {
      setIsDashboardOpen(false);
    }
    if (allSearchexploremenu) {
      setAllSearchexploremenu(false);
    }
  };

  const toggleOtherDropdown = () => {
    setIsDashboardOpen(!isDashboardOpen);
    if (isDashboardOpenTwo) {
      setIsDashboardOpenTwo(false);
    }
    if (employerprofile) {
      setemployerprofile(false);
    }
    if (allSearchexploremenu) {
      setAllSearchexploremenu(false);
    }
  };

  const toggleOtherExplordroupdown = () => {
    setAllSearchexploremenu(!allSearchexploremenu);
    if (isDashboardOpen) {
      setIsDashboardOpen(false);
    }
    if (isDashboardOpenTwo) {
      setIsDashboardOpenTwo(false);
    }
    if (employerprofile) {
      setemployerprofile(false);
    }
  };

  // candidate login
  const handleCandidateProfileClick = () => {
    setemployerprofile(!employerprofile);
    setIsDashboardOpen(false);
    setUserresources(false);
    setIsDashboardOpenTwo(false);
    setAllSearchexploremenu(false);
  };

  const handleCandidateProfileClick1 = () => {
    setIsDashboardOpen(!isDashboardOpen);
    setemployerprofile(false);
    setUserresources(false);
    setIsDashboardOpenTwo(false);
    setAllSearchexploremenu(false);
  };

  const handleCandidateProfileClick2 = () => {
    setUserresources(!userresources);
    setIsDashboardOpen(false);
    setemployerprofile(false);
    setIsDashboardOpenTwo(false);
    setAllSearchexploremenu(false);
  };

  const handleCandidateProfileClick3 = () => {
    setIsDashboardOpenTwo(!isDashboardOpenTwo);
    setIsDashboardOpen(false);
    setUserresources(false);
    setemployerprofile(false);
    setAllSearchexploremenu(false);
  };

  const handleCandidateexploremenu = () => {
    setAllSearchexploremenu(!allSearchexploremenu);
    setIsDashboardOpen(false);
    setIsDashboardOpenTwo(false);
    setUserresources(false);
    setemployerprofile(false);
  };

  // employer login
  const handleEmployerProfileClick = () => {
    setemployerprofile(!employerprofile);
    setIsAccount(false);
    setIsDashboardOpen(false);
  };
  const handleEmployerProfileClick1 = () => {
    setIsDashboardOpen(!isDashboardOpen);
    setemployerprofile(false);
    setIsAccount(false);
  };

  const handleEmployerProfileClick2 = () => {
    setIsAccount(!isAccount);
    setemployerprofile(false);
    setIsDashboardOpen(false);
  };

  return (
    <>
      <nav
        className="sidebar sidebar-offcanvas"
        id="sidebar"
        onMouseOver={(e) =>
          window.innerWidth > 991 ? toggleOffcanvas() : null
        }
        onMouseLeave={(e) =>
          window.innerWidth > 991 ? toggleremoeOffcanvas() : null
        }
      >
        <div className="addsidbarwidthnewscroll">
          <ul className="nav nav-custom-top pb-0 navset">
            {!isAuthenticated && (
              <>
                {/* <li className="d-block d-lg-none">
                  <Link className="nav-link text-center" to="/">
                    <span className="menu-title">
                      <Trans>
                        <img
                          src={sidebarLogo}
                          className="logosmallscreen"
                          alt="Sidebar Image"
                        ></img>
                      </Trans>
                    </span>
                  </Link>
                </li> */}

                <li
                  className={
                    isPathActive("/jobmap")
                      ? "nav-item active mainmenunamemarginleft"
                      : "nav-item mainmenunamemarginleft"
                  }
                >
                  <Link
                    className="nav-link sidbariconlogoutpadding"
                    to="/jobmap"
                  >
                    <div className="sidebarmapsearch menu-icon"></div>
                    <span className="menu-title">
                      <Trans>Job-Map</Trans>
                    </span>
                  </Link>
                </li>
                {/* 
                <li
                  className={
                    isDashboardOpen
                      ? "nav-item withoutloginsidebarsetwidth"
                      : "nav-item withoutloginsidebarsetwidth"
                  }
                >
                  <a
                    className="nav-link position-relative"
                    // onClick={() => setIsDashboardOpen(!isDashboardOpen)}
                    onClick={toggleOtherDropdown}
                  >
                    <div className="sidebarmapsearch menu-icon"></div>
                    <span className="menu-title  mdi  menu-icon">
                      <Trans>Map Search</Trans>
                    </span>
                    {isDashboardOpen ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${isDashboardOpen ? "show" : ""}`}
                    id="submenu-dashboard"
                  >

                  </div>
                </li> */}
                {/* <li
                      className={
                        isPathActive("/jobs")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link sidbariconlogoutpadding addnewwrapword" to="/jobs">
                        <div className="sidebarmapsearch menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Job Map</Trans>
                        </span>
                      </Link>
                    </li> */}

                <li
                  className={
                    isPathActive("#")
                      ? "nav-item setnewsidebarpadding"
                      : "nav-item setnewsidebarpadding"
                  }
                >
                  <div
                    className="nav-link sidbariconlogoutpadding addnewwrapword"
                    onClick={exploroffcanvasshow}
                  >
                    <div className="sidebarexploricon menu-icon"></div>
                    <span className="menu-title">
                      <Trans>Explorer</Trans>
                    </span>
                  </div>
                </li>

                {/* 
                <li
                  className={
                    allSearchexploremenu
                      ? "nav-item withoutloginsidebarsetwidth"
                      : "nav-item withoutloginsidebarsetwidth"
                  }
                >
                  <a
                    className="nav-link position-relative"
                    onClick={toggleOtherExplordroupdown}
                  >
                    <div className="sidebarexploricon menu-icon"></div>
                    <span className="menu-title  mdi  menu-icon">
                      <Trans>Explore</Trans>
                    </span>
                    {allSearchexploremenu ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${allSearchexploremenu ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link sidbariconlogoutpadding addnewwrapword" to="#" onClick={popularsearchoffshow}>
                        <div className="sidebarcompanysearch menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Popular Searches</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link sidbariconlogoutpadding addnewwrapword" to="#" onClick={popularCompaniesoffcanvasshow}>
                        <div className="sidebarpopularsearchicon menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Companies Hiring</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link sidbariconlogoutpadding addnewwrapword" to="#" onClick={trendingSearchesoffcanvasshow}>
                        <div className="sidebartrendingjob menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Trending Searches</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link sidbariconlogoutpadding addnewwrapword" to="#" onClick={trendingJobsoffcanvasshow}>
                        <div className="sidebartrendingsearchjob menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Trending Jobs</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link sidbariconlogoutpadding addnewwrapword" to="#" onClick={popularCitiesoffcanvasshow}>
                        <div className="sidebarpopluarcity menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Popular Cities</Trans>
                        </span>
                      </Link>
                    </li>

                  </div>
                </li> */}

                <li
                  className={
                    isPathActive("/user-login")
                      ? "nav-item active setnewsidebarpadding"
                      : "nav-item setnewsidebarpadding"
                  }
                >
                  <Link
                    className="nav-link sidbariconlogoutpadding addnewwrapword"
                    to="/user-login"
                  >
                    {/* <i className="mdi mdi-home menu-icon"></i> */}
                    <div className="sidebaruserlogin menu-icon"></div>
                    <span className="menu-title addnewcandidatelogincolor">
                      <Trans>Candidate Login</Trans>
                    </span>
                  </Link>
                </li>

                <li
                  // className={
                  //   isPathActive("/user-login")
                  //     ? "nav-item active "
                  //     : "nav-item "
                  // }
                  className={
                    isDashboardOpenTwo
                      ? "nav-item withoutloginsidebarsetwidth"
                      : "nav-item withoutloginsidebarsetwidth"
                  }
                >
                  <a
                    className="nav-link sidbariconlogoutpadding position-relative"
                    // to="/user-login"
                    // onClick={() => setIsDashboardOpenTwo(!isDashboardOpenTwo)}
                    onClick={toggleCandidateDropdown}
                  >
                    {/* <i className="mdi mdi-human-greeting menu-icon"></i> */}
                    <img
                      src={sidebaruserlogin}
                      alt="ApplyForJob"
                      className="menu-icon"
                      width={"22px"}
                      height={"22px"}
                    ></img>
                    <span className="menu-title">
                      <Trans>For Candidates</Trans>
                    </span>
                    {isDashboardOpenTwo ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${isDashboardOpenTwo ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                    <li
                      className={
                        isPathActive("/user-register")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        to="/user-register"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebaruserdashbord menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Create Free Account</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={
                        isPathActive("/innovative-candidate-solutions")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        to="/innovative-candidate-solutions"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarhowitswork menu-icon"></div>
                        <span className="menu-title">
                          <Trans>How it Works</Trans>
                        </span>
                      </Link>
                    </li>

                    {/* <li
                      className={
                        isPathActive("/jobs")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link sidbariconlogoutpadding addnewwrapword" to="/jobs">
                        <div className="sidebaruserserchjob menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Search Jobs</Trans>
                        </span>
                      </Link>
                    </li> */}

                    {/* <li
                      className={
                        isPathActive("/saved-jobmap")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link sidbariconlogoutpadding addnewwrapword" to="/saved-jobmap">
                        <div className="sidebarusersavejob menu-icon"></div>
                        <span className="menu-title">
                          <Trans>My Saved Jobs Map</Trans>
                        </span>
                      </Link>
                    </li> */}

                    <li
                      className={
                        isPathActive("/ats-resume-builder")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        to="/ats-resume-builder"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarpublicatsresume menu-icon"></div>
                        <span className="menu-title">
                          <Trans>ATS Resume Builder</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        to="#"
                        onClick={() => setModalShowLogin(true)}
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarprofilepage menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Resume / CV Download</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={
                        isPathActive("/culture-preference")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        to="/culture-preference"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarculture menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Job Feed</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <a
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        href="https://blog.turn-to.com/category/candidates/"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarblog menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Candidates Blog</Trans>
                        </span>
                      </a>
                    </li>
                    <li
                      className={
                        isPathActive("/faq-candidate")
                          ? "nav-item setnewsidebarpadding active"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <a
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        href="/faq-candidate"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarfaqdata menu-icon"></div>
                        <span className="menu-title">
                          <Trans>FAQ for Candidates</Trans>
                        </span>
                      </a>
                    </li>
                    {/* <li
                      className={
                        isPathActive("#")
                          ? "nav-item setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link sidbariconlogoutpadding addnewwrapword" to="/contact">
                        <div className="sidebarcontactus menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Contact Us</Trans>
                        </span>
                      </Link>
                    </li>
                     */}
                  </div>
                </li>

                <li
                  className={
                    isPathActive("/employer-login")
                      ? "nav-item active setnewsidebarpadding"
                      : "nav-item setnewsidebarpadding"
                  }
                >
                  <Link
                    className="nav-link sidbariconlogoutpadding addnewwrapword"
                    to="/employer-login"
                  >
                    {/* <i className="mdi mdi-home menu-icon"></i> */}
                    <div className="sidebaremployerlogin menu-icon"></div>
                    <span className="menu-title addnewcandidatelogincolor">
                      <Trans>Employer Login</Trans>
                    </span>
                  </Link>
                </li>

                <li
                  // className={
                  //   isPathActive("/employer-login")
                  //     ? "nav-item active mainmenunamemarginleft"
                  //     : "nav-item mainmenunamemarginleft"
                  // }
                  className={
                    employerprofile
                      ? "nav-item withoutloginsidebarsetwidth"
                      : "nav-item withoutloginsidebarsetwidth"
                  }
                >
                  <a
                    className="nav-link sidbariconlogoutpadding position-relative"
                    // to="/employer-login"
                    // onClick={() => setemployerprofile(!employerprofile)}
                    onClick={toggleEmployerDropdown}
                  >
                    {/* <i className="mdi mdi-lead-pencil menu-icon"></i> */}
                    <img
                      src={sidebaremployerlogin}
                      alt="PostJob"
                      className="menu-icon"
                      width={"22px"}
                      height={"22px"}
                    ></img>
                    <span className="menu-title">
                      <Trans>For Employers</Trans>
                    </span>
                    {employerprofile ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${employerprofile ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                    <li
                      className={
                        isPathActive("/employer-register")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        to="/employer-register"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebaruserdashbord menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Post a Job For Free</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={
                        isPathActive("/innovative-employer-solutions")
                          ? "nav-item setnewsidebarpadding active"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        to="/innovative-employer-solutions"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarhowitswork menu-icon"></div>
                        <span className="menu-title">
                          <Trans>How it Works</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={
                        isPathActive("/job-ads")
                          ? "nav-item setnewsidebarpadding active"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        to="/job-ads"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarjoblist menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Job Ads</Trans>
                        </span>
                      </Link>
                    </li>

                    
                    <li
                      className={
                        isPathActive("/jobmap-integration")
                          ? "nav-item setnewsidebarpadding active"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        to="/jobmap-integration"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebariframeicon menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Job Map Iframe</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <a
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        href="https://blog.turn-to.com/category/employer/"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarblog menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Employer Blog</Trans>
                        </span>
                      </a>
                    </li>
                    <li
                      className={
                        isPathActive("/faq-employer")
                          ? "nav-item setnewsidebarpadding active"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <a
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        href="/faq-employer"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarfaqdata menu-icon"></div>
                        <span className="menu-title">
                          <Trans>FAQ for Employers</Trans>
                        </span>
                      </a>
                    </li>

                    {/* <li
                      className={
                        isPathActive("#")
                          ? "nav-item setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link sidbariconlogoutpadding addnewwrapword" to="/contact">
                        <div className="sidebarcontactus menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Contact Us</Trans>
                        </span>
                      </Link>
                    </li>   */}
                  </div>
                </li>

                <li
                  className={
                    isPathActive("#")
                      ? "nav-item active mainmenunamemarginleft"
                      : "nav-item mainmenunamemarginleft"
                  }
                >
                  <div
                    className="nav-link sidbariconlogoutpadding"
                    onClick={handleShow}
                  >
                    {/* <i className="mdi mdi-account-card-details menu-icon"></i> */}
                    <div className="sidebarsendfeedback menu-icon"></div>
                    <span className="menu-title">
                      <Trans>Send Feedback</Trans>
                    </span>
                  </div>
                </li>

                <li
                  className={
                    isPathActive("/contact")
                      ? "nav-item active mainmenunamemarginleft"
                      : "nav-item mainmenunamemarginleft"
                  }
                >
                  <Link
                    className="nav-link sidbariconlogoutpadding"
                    to="/contact"
                  >
                    {/* <i className="mdi mdi-account-card-details menu-icon"></i> */}
                    <div className="sidebarcontactus menu-icon"></div>
                    <span className="menu-title">
                      <Trans>Contact Us</Trans>
                    </span>
                  </Link>
                </li>

                <div className="put_sidebarimg d-flex d-lg-none">
                  <img
                    src={turntoBeta}
                    className="width_set_logo sidebar_imgset smallscreennavbarlogoset"
                    alt="Navbar Logo"
                  ></img>
                </div>
              </>
            )}
            {isAuthenticated && AuthData.role === "user" && (
              <>
                <li className="nav-item nav-profile">
                  <Link
                    to="/profile"
                    className="nav-link overflow-hidden adduserprofilenamepadding"
                  >
                    <div className="nav-profile-image">
                      {AuthData.info.profile_image == "" ? (
                        <Avatar
                          size="30"
                          round="50px"
                          name={
                            AuthData?.first_name + " " + AuthData?.last_name
                          }
                        />
                      ) : (
                        <img
                          src={AuthData.info.profile_image}
                          alt="user"
                          className="userprofileimgsidbarset"
                        />
                      )}
                      <span className="login-status online"></span>{" "}
                    </div>
                    {/* <div className="sidebarprofilesetting menu-icon"></div> */}
                    <div className="nav-profile-text ml-0">
                      <span className="font-weight-bold add_new_word">
                        <Trans>
                          {AuthData?.first_name + " " + AuthData?.last_name}
                        </Trans>
                      </span>
                    </div>
                  </Link>
                </li>

                <li
                  className={
                    isPathActive("/jobmap")
                      ? "nav-item active setnewsidebarpadding"
                      : "nav-item setnewsidebarpadding"
                  }
                >
                  <Link className="nav-link addnewwrapword" to="/jobmap">
                    <div className="sidebarmapsearch menu-icon"></div>
                    <span className="menu-title mdi  menu-icon">
                      <Trans>Job Map</Trans>
                    </span>
                  </Link>
                </li>

                {/* <li
                  className={
                    employerprofile
                      ? "nav-item usersidemenuwidth"
                      : "nav-item usersidemenuwidth"
                  }
                >
                  <a
                    className="nav-link position-relative"
                    // onClick={() => setemployerprofile(!employerprofile)}
                    onClick={handleCandidateProfileClick}
                  >
                    <div className="sidebarmapsearch menu-icon"></div>
                    <span className="menu-title  mdi  menu-icon">
                      <Trans>Map Search</Trans>
                    </span>
                    {employerprofile ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                
                  <div
                    className={`collapse ${employerprofile ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                <li
                  className={
                    isPathActive("/jobs")
                      ? "nav-item active setnewsidebarpadding"
                      : "nav-item setnewsidebarpadding"
                  }
                >
                  <Link
                    className="nav-link addnewwrapword"
                    to="/jobs"
                  >
                    <div className="sidebarmapsearch menu-icon"></div>
                    <span className="menu-title mdi  menu-icon">
                      <Trans>Job Map</Trans>
                    </span>
                  </Link>
                </li>

                  </div>
                </li> */}

                <li
                  className={
                    isPathActive("#")
                      ? "nav-item active setnewsidebarpadding"
                      : "nav-item setnewsidebarpadding"
                  }
                >
                  <div
                    className="nav-link addnewwrapword"
                    onClick={exploroffcanvasshow}
                  >
                    <div className="sidebarexploricon menu-icon"></div>
                    <span className="menu-title">
                      <Trans>Explorer</Trans>
                    </span>
                  </div>
                </li>

                {/* <li
                  className={
                    allSearchexploremenu
                      ? "nav-item usersidemenuwidth"
                      : "nav-item usersidemenuwidth"
                  }
                >
                  <a
                    className="nav-link position-relative"
                    onClick={handleCandidateexploremenu}
                  >
                    <div className="sidebarexploricon menu-icon"></div>
                    <span className="menu-title  mdi  menu-icon">
                      <Trans>Explore</Trans>
                    </span>
                    {allSearchexploremenu ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${allSearchexploremenu ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link addnewwrapword" to="#" onClick={popularsearchoffshow}>
                        <div className="sidebarcompanysearch menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Popular Searches</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link addnewwrapword" to="#" onClick={popularCompaniesoffcanvasshow}>
                        <div className="sidebarpopularsearchicon menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Companies Hiring</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to="#"
                        onClick={trendingSearchesoffcanvasshow}
                      >
                        <div className="sidebartrendingjob menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Trending Searches</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to="#"
                        onClick={trendingJobsoffcanvasshow}
                      >
                        <div className="sidebartrendingsearchjob menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Trending Jobs</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to="#"
                        onClick={popularCitiesoffcanvasshow}
                      >
                        <div className="sidebarpopluarcity menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Popular Cities</Trans>
                        </span>
                      </Link>
                    </li>


                  </div>
                </li> */}

                {/* <li
                  className={
                    isPathActive("/jobs") ? "nav-item active dash" : "nav-item dash"
                  }
                >
                  <Link className="nav-link" id="homepage" to="/jobs">
                    <div className="sidebaruserdashbord menu-icon"></div>
                    <span className="menu-title">
                      <Trans>Job Map</Trans>
                    </span>
                  </Link>
                </li> */}

                <li
                  className={
                    isDashboardOpen
                      ? "nav-item usersidemenuwidth"
                      : "nav-item usersidemenuwidth"
                  }
                >
                  <a
                    className="nav-link position-relative"
                    // onClick={() => setIsDashboardOpen(!isDashboardOpen)}
                    onClick={handleCandidateProfileClick1}
                  >
                    <div className="sidebarusermaindashbord menu-icon"></div>
                    <span className="menu-title  mdi  menu-icon">
                      <Trans>Job Management</Trans>
                    </span>
                    {isDashboardOpen ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${isDashboardOpen ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                    {/* <ul className="nav flex-column sub-menu"> */}
                    <li
                      className={
                        isPathActive("/applied-job-list")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to="/applied-job-list"
                      >
                        <div className="sidebarappliedlist menu-icon"></div>
                        <span className="menu-title mdi  menu-icon">
                          <Trans>Applied Jobs List</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("/profile")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link addnewwrapword" to="/profile">
                        <div className="sidebarresumebuilder menu-icon"></div>
                        <span className="menu-title">
                          <Trans>My Resume Builder</Trans>
                        </span>
                      </Link>
                    </li>
                    {/* <li
                      className={
                        isPathActive("/saved-job")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link addnewwrapword" to="/saved-job">
                        <div className="sidebarusersavejob menu-icon"></div>
                        <span className="menu-title">
                          <Trans>My Saved Jobs Map</Trans>
                        </span>
                      </Link>
                    </li> */}
                    <li
                      className={
                        isPathActive(`/user/${AuthData.username}`)
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      {AuthData.username == "" ? (
                        <a
                          className="nav-link addnewwrapword"
                          onClick={openmodalusernamenotset}
                        >
                          <div className="sidebarprofilepage menu-icon"></div>
                          <span className="menu-title">
                            <Trans>Resume / CV Download</Trans>
                          </span>
                        </a>
                      ) : (
                        <Link
                          className="nav-link addnewwrapword"
                          to={`/user/${AuthData.username}`}
                        >
                          <div className="sidebarprofilepage menu-icon"></div>
                          <span className="menu-title">
                            <Trans>Resume / CV Download</Trans>
                          </span>
                        </Link>
                      )}
                    </li>
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <div
                        className="nav-link addnewwrapword"
                        onClick={(ele) => {
                          setIsPublicAts(!isPublicAts);
                          AtsPublicPvt(!isPublicAts);
                        }}
                      >
                        <div className="sidebarpublicatsresume menu-icon"></div>
                        <span className="menu-title">
                          <Trans>ATS Resume</Trans>
                        </span>
                        <div className="isatsswitch text-right">
                          <div className="container p-0 addswitchoptionlineheight">
                            <div
                              className="toggle-switch addsetswitchsidebar"
                              // onClick={(ele) => {
                              //   setIsPublicAts(!isPublicAts)
                              //   AtsPublicPvt(!isPublicAts)
                              // }}
                            >
                              <input
                                type="checkbox"
                                checked={isPublicAts}
                                className="checkbox"
                                name="isAts"
                              />
                              <label
                                className="label addatsswitchlable"
                                htmlFor="isAts"
                              >
                                <span className="atsswitchcustom" />
                                <span className="switch" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      className={
                        isPathActive("/culture-preferences")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to="/culture-preferences"
                      >
                        <div className="sidebarculture menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Job Feed</Trans>
                        </span>
                      </Link>
                    </li>
                    {/* <li
                      className={
                        isPathActive("/culture-preferences")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to="/culture-preferences"
                      >
                        <div className="sidebarculture menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Culture & Preferences</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("/change-password")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to="/change-password"
                      >
                        <div className="sidebarchangepasswordpage menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Change Password</Trans>
                        </span>
                      </Link>
                    </li> */}
                    {/* </ul> */}
                  </div>
                </li>

                <li
                  className={
                    userresources
                      ? "nav-item usersidemenuwidth"
                      : "nav-item usersidemenuwidth"
                  }
                >
                  <a
                    className="nav-link position-relative"
                    // onClick={() => setUserresources(!userresources)}
                    onClick={handleCandidateProfileClick2}
                  >
                    <div className="sidebarresources menu-icon"></div>
                    <span className="menu-title  mdi  menu-icon">
                      <Trans>Resources</Trans>
                    </span>
                    {userresources ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${userresources ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                    <li
                      className={
                        isPathActive("/course")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link addnewwrapword" to="/course">
                        <div className="sidebarcertificate menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Certificates</Trans>
                        </span>
                      </Link>
                    </li>
                    {/* </ul> */}
                  </div>
                </li>

                <li
                  className={
                    isDashboardOpenTwo
                      ? "nav-item usersidemenuwidth"
                      : "nav-item usersidemenuwidth"
                  }
                >
                  <a
                    className="nav-link position-relative"
                    // onClick={() => setIsDashboardOpenTwo(!isDashboardOpenTwo)}
                    onClick={handleCandidateProfileClick3}
                  >
                    <div className="sidebaruseraccountsetting menu-icon"></div>
                    <span className="menu-title  mdi  menu-icon">
                      <Trans>Account Settings</Trans>
                    </span>
                    {isDashboardOpenTwo ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${isDashboardOpenTwo ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                    {/* <ul className="nav flex-column sub-menu"> */}

                    {/* <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link addnewwrapword" to="#" onClick={handleShow}>
                        <div className="sidebarsearchhistory menu-icon"></div>
                        <span className="menu-title" >
                          <Trans>Search History</Trans>
                        </span>
                      </Link>
                    </li> */}
                    <li
                      className={
                        isPathActive("/contact")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link addnewwrapword" to="/contact">
                        <div className="sidebarcontactus menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Contact Us</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("/change-password")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to="/change-password"
                      >
                        <div className="sidebarchangepasswordpage menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Change Password</Trans>
                        </span>
                      </Link>
                    </li>
                    {/* <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link addnewwrapword" to="#">
                        <div className="sidebarpublicprofile menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Public Profile</Trans>
                        </span>
                      </Link>
                    </li> */}
                    {/* <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <div className="nav-link addnewwrapword">
                        <div className="sidebarpublicatsresume menu-icon"></div>
                        <span className="menu-title">
                          <Trans>ATS Resume</Trans>
                        </span>
                        <div className="isatsswitch text-right">
                          <div className="container p-0 addswitchoptionlineheight">
                            <div
                              className="toggle-switch addsetswitchsidebar"
                              onClick={(ele) => {
                                setIsPublicAts(!isPublicAts)
                                AtsPublicPvt(!isPublicAts)
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={isPublicAts}
                                className="checkbox"
                                name="isAts"
                              />
                              <label className="label addatsswitchlable" htmlFor="isAts">
                                <span className="atsswitchcustom" />
                                <span className="switch" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li> */}

                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                      onClick={AcccountDeactive}
                    >
                      <Link className="nav-link addnewwrapword" to="#">
                        <div className="sidebardeactivateaccount menu-icon"></div>
                        <span className="menu-title" onClick={AcccountDeactive}>
                          <Trans>Deactivate Account</Trans>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <div
                        className="nav-link addnewwrapword"
                        onClick={handleShow}
                      >
                        <div className="sidebarsendfeedback menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Send Feedback</Trans>
                        </span>
                      </div>
                    </li>

                    {/* </ul> */}
                  </div>
                </li>
                <li
                  className={
                    isPathActive("#")
                      ? "nav-item active setnewsidebarpadding"
                      : "nav-item setnewsidebarpadding"
                  }
                  onClick={LogoutFun}
                >
                  <div className="nav-link" to="/jobmap">
                    <img
                      src={sidebarlogout}
                      alt="logout"
                      className="menu-icon"
                      width={"22px"}
                      height={"22px"}
                    ></img>
                    <span className="menu-title" onClick={LogoutFun}>
                      <Trans>Log Out</Trans>
                    </span>
                  </div>
                </li>

                {/* <li className={isPathActive("#") ? "nav-item active" : "nav-item"}>
            <Link className="nav-link" to="#">
            <div className="sidebarcontactus menu-icon"></div>
                  <span className="menu-title">
                    <Trans>Public Profile</Trans>
                  </span>
            </Link>
          </li>
          <li className={isPathActive("#") ? "nav-item active" : "nav-item"}>
            <Link className="nav-link" to="#">
            <div className="sidebarcontactus menu-icon"></div>
                  <span className="menu-title">
                    <Trans>Public ATS Resume</Trans>
                  </span>
            </Link>
          </li>
          <li className={isPathActive("/jobs") ? "nav-item" : "nav-item"} onClick={LogoutFun}>
            <div className="nav-link" to="/jobs">
            <img
                    src={sidebarlogout}
                    alt="logout"
                    className="menu-icon"
                    width={"22px"}
                    height={"22px"}
                  ></img>
                  <span className="menu-title" onClick={LogoutFun}>
                    <Trans>Log Out</Trans>
                  </span>
            </div>
          </li> */}
                <div className="put_sidebarimg d-flex d-lg-none">
                  <img
                    src={sidebarUserLogo}
                    className="width_set_logo sidebar_imgset smallscreennavbarlogoset"
                    alt="Navbar Logo"
                  ></img>
                </div>
              </>
            )}
            {isAuthenticated && AuthData.role == "employer" && (
              <>
                {/* <li className="nav-item nav-profile">
                <a
                  href="!#"
                  className=""
                  onClick={(evt) => evt.preventDefault()}
                >
                  <Link
                    to="/employer/company-profile"
                    className="nav-link overflow-hidden bg-none"
                  >
                    <div className="nav-profile-image">
                      {AuthData.info.company_image == "" ? (
                        <Avatar
                          size="44"
                          round="50px"
                          name={AuthData.info.company_name}
                        />
                      ) : (
                        <img src={AuthData.info.company_image} alt="user" />
                      )}
                      <span className="login-status online"></span>{" "}
                    </div>
                    <div className="nav-profile-text ml-0 ml-sm-3">
                      <span className="font-weight-bold add_new_word">
                        <Trans>{AuthData.info.company_name}</Trans>
                      </span>
                    </div>
                  </Link>
                </a>
              </li> */}

                <li className="nav-item employersidebarwidth">
                  <a
                    className="nav-link addnewiconemployerpadding position-relative"
                    // onClick={() => setemployerprofile(!employerprofile)}
                    onClick={handleEmployerProfileClick}
                  >
                    <div className="nav-profile-image addemployercomapnynamewithimg">
                      {AuthData.info.company_image == "" ? (
                        <Avatar
                          size="30"
                          round="50px"
                          name={AuthData.info.company_name}
                        />
                      ) : (
                        <img
                          src={AuthData.info.company_image}
                          alt="user"
                          width={"35px"}
                          height={"35px"}
                          className="setprofilesidebarimg"
                        />
                      )}
                    </div>
                    <div className="sidebarprofilesetting menu-icon"></div>
                    <span className="font-weight-bold add_new_word menu-title  mdi  menu-icon">
                      <Trans>{AuthData.info.company_name}</Trans>
                    </span>
                    {employerprofile ? (
                      <div className="sidbaruparrowopotivie menu-icon setemployerprofilenamedroupicon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon setemployerprofilenamedroupicon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${employerprofile ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                    {/* <ul className="nav flex-column sub-menu"> */}
                    <li
                      className={
                        isPathActive("/employer/company-profile")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to="/employer/company-profile"
                      >
                        {AuthData.info.company_image == "" ? (
                          <i className="mdi mdi-account-circle menu-icon"></i>
                        ) : (
                          <div className="sidebarcompanyprofile menu-icon">
                            {/* {console.log(AuthData)}
                            <img
                              src={AuthData.info.company_image}
                              alt="companyimage"
                              className="sidebar_cmpprofile"
                            ></img> */}
                          </div>
                        )}
                        <span className="menu-title">
                          <Trans>Company Profile</Trans>
                        </span>
                      </Link>
                    </li>
                    {/* <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <div className="nav-link">
                        <div className="sidebaremployeraccount menu-icon"></div>
                        <span className="menu-title">
                          <Trans>User Management</Trans>
                        </span>
                      </div>
                    </li> */}
                    {/* <li
                      className={
                        isPathActive("/jobs")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to={`/jobs`}
                      >
                        <div className="sidebaremployerjobmap menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Public Jobmap</Trans>
                        </span>
                      </Link>
                    </li> */}
                    <li
                      className={
                        isPathActive("/employer/mediakit")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        // onClick={() => {
                        //   setShowModal(true)
                        //   document.querySelector("#sidebar").classList.remove("active");

                        // }}
                        to={`/employer/mediakit`}
                      >
                        <div className="sidebariframeicon menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Media Kit</Trans>
                        </span>
                      </Link>
                    </li>
                    
                    <li
                      className={
                        isPathActive("/company-iframe")
                          ? "nav-item setnewsidebarpadding active"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link sidbariconlogoutpadding addnewwrapword"
                        to="/company-iframe"
                      >
                        {/* <i className="mdi mdi-home menu-icon"></i> */}
                        <div className="sidebarjoblist menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Iframe Demo</Trans>
                        </span>
                      </Link>
                    </li>
                    {/* <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to={`/company-iframe`}
                      >
                        <div className="sidebariframeicon menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Iframe View</Trans>
                        </span>
                      </Link>
                    </li> */}
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <div className="nav-link addnewwrapword">
                        <div className="sidebaremployerpublicprofile menu-icon"></div>
                        <Link
                          to={"/company/" + AuthData?.username}
                          className="menu-title"
                        >
                          <Trans>Public Profile</Trans>
                        </Link>
                        <div className="isatsswitch text-right">
                          <div className="container p-0 addswitchoptionlineheight">
                            <div
                              className="toggle-switch addsetswitchsidebar"
                              onClick={() => {
                                setChecked(!checked);
                                handleChange(!checked);
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={checked}
                                // checked={isPublicprofile}
                                className="checkbox"
                                name="ispublicprofile"
                              />
                              <label
                                className="label addatsswitchlable"
                                htmlFor="ispublicprofile"
                              >
                                <span className="atsswitchcustom" />
                                <span className="switch" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* </ul> */}
                  </div>
                </li>

                {/* <li
                className={
                  isPathActive("/employer/dashboard")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/employer/dashboard">
                  <div className="sidebarusermaindashbord menu-icon"></div>
                  <span className="menu-title  mdi  menu-icon">
                    <Trans>Dashboard</Trans>
                  </span>
                </Link>
              </li> */}

                <li
                  className={
                    isPathActive("")
                      ? "nav-item employersidebarwidth"
                      : "nav-item employersidebarwidth"
                  }
                >
                  <a
                    className="nav-link addnewiconemployerpadding position-relative"
                    to="#"
                    // onClick={() => setIsDashboardOpen(!isDashboardOpen)}
                    onClick={handleEmployerProfileClick1}
                  >
                    <div className="sidebarusermaindashbordinsight menu-icon"></div>
                    <span className="menu-title  mdi  menu-icon">
                      <Trans>Jobs</Trans>
                    </span>
                    {isDashboardOpen ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${isDashboardOpen ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                    {/* <ul className="nav flex-column sub-menu"> */}
                    <li
                      className={
                        isPathActive("/employer/dashboard")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link" to="/employer/dashboard">
                        <div className="sidebarusermaindashbord menu-icon"></div>
                        <span className="menu-title">
                          {/* <Trans>Manage Job Ads</Trans> */}
                          <Trans>Dashboard</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("/employer/post-job")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link" to="/employer/post-job">
                        <div className="sidebarpostjob menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Post A Job Ad</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("/employer/job/bulk-upload")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link" to="/employer/job/bulk-upload">
                        <div className="sidebarpostjob menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Bulk Upload</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("/employer/jobs")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link" to="/employer/jobs">
                        <div className="sidebarjoblist menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Job Ads</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("/employer/all-application")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link" to="/employer/all-application">
                        <div className="sidebarallapplication menu-icon"></div>
                        <span className="menu-title">
                          <Trans>All Applicants</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive(
                          `/employer/job-map/${AuthData.info.company_name}`
                        )
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      {AuthData.info.company_name == "" ? (
                        <a
                          className="nav-link"
                          onClick={opensetyourcomapnyprofile}
                        >
                          <div className="sidebarjobmapview menu-icon"></div>
                          <span className="menu-title">
                            <Trans>Job Ads Map</Trans>
                          </span>
                        </a>
                      ) : (
                        <Link
                          className="nav-link"
                          to={`/employer/job-map/${AuthData.info.company_name}`}
                        >
                          <div className="sidebarjobmapview menu-icon"></div>
                          <span className="menu-title">
                            <Trans>Job Ads Map</Trans>
                          </span>
                        </Link>
                      )}
                    </li>
                    {/* <li
                      className={
                        isPathActive("/employer/reported-job-list")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link"
                        to="/employer/reported-job-list"
                      >
                        <div className="sidebarreportjob menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Reported Jobs</Trans>
                        </span>
                      </Link>
                    </li> */}
                    {/* </ul> */}
                  </div>
                </li>

                {/* <li
                className={
                  isPathActive("/jobs") ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/jobs">
                  <div className="sidebaruserdashbord menu-icon"></div>
                  <span className="menu-title">
                    <Trans>Jobmap</Trans>
                  </span>
                </Link>
              </li> */}

                {/* <li
                className={
                  isPathActive("/employer/post-job")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/employer/post-job">
                  <div className="sidebarpostjob menu-icon"></div>
                  <span className="menu-title">
                    <Trans>Post Job</Trans>
                  </span>
                </Link>
              </li>
              <li
                className={
                  isPathActive("/employer/jobs")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/employer/jobs">
                  <div className="sidebarjoblist menu-icon"></div>
                  <span className="menu-title">
                    <Trans>Job List</Trans>
                  </span>
                </Link>
              </li>
              <li
                className={
                  isPathActive("/employer/all-application")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/employer/all-application">
                  <div className="sidebarallapplication menu-icon"></div>
                  <span className="menu-title">
                    <Trans>All Applicant</Trans>
                  </span>
                </Link>
              </li>
              <li
                className={
                  isPathActive("/employer/reported-job-list")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/employer/reported-job-list">
                  <div className="sidebarreportjob menu-icon"></div>
                  <span className="menu-title">
                    <Trans>Reported Jobs</Trans>
                  </span>
                </Link>
              </li> */}
                {/* <li
                className={
                  isPathActive("/employer/payment-history")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/employer/payment-history">
                  <div className="sidebarpayment menu-icon"></div>
                  <span className="menu-title">
                    <Trans>Payment Histroy</Trans>
                  </span>
                </Link>
              </li> */}

                {/* <li
                className={
                  isPathActive("/employer/company-profile")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/employer/company-profile">
                  {AuthData.info.company_image == "" ? 
                  
                   <i className="mdi mdi-account-circle menu-icon"></i> 
                   :
                  <div className="sidebarcompanyprofile menu-icon">
                    {console.log(AuthData)}
                    <img
                      src={AuthData.info.company_image}
                      alt="companyimage"
                      className="sidebar_cmpprofile"
                    ></img>
                  </div>
                 }
                  <span className="menu-title">
                    <Trans>Company Profile</Trans>
                  </span>
                </Link>
              </li> */}

                {/* <li
                className={
                  isPathActive("/employer/company-profile")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/employer/company-profile">
                  <div className="sidebarprofilesetting menu-icon"></div>
                  <span className="menu-title">
                    <Trans>Profile Setting</Trans>
                  </span>
                </Link>
              </li> */}
                {/* <li
                className={
                  isPathActive("/employer/change-password")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/employer/change-password">
                  <div className="sidebarchangepasswordpage menu-icon"></div>
                  <span className="menu-title">
                    <Trans>Change Password</Trans>
                  </span>
                </Link>
              </li> */}
                <li
                  className={
                    isPathActive("")
                      ? "nav-item employersidebarwidth"
                      : "nav-item employersidebarwidth"
                  }
                >
                  <a
                    className="nav-link addnewiconemployerpadding position-relative"
                    to="#"
                    // onClick={() => setIsAccount(!isAccount)}
                    onClick={handleEmployerProfileClick2}
                  >
                    <div className="sidebarmanagement menu-icon"></div>
                    <span className="menu-title  mdi  menu-icon">
                      <Trans>Account</Trans>
                    </span>
                    {isAccount ? (
                      <div className="sidbaruparrowopotivie menu-icon addusersidedroupicon"></div>
                    ) : (
                      <div className="sidebardrouparrow menu-icon addusersidedroupicon"></div>
                    )}
                  </a>

                  <div
                    className={`collapse ${isAccount ? "show" : ""}`}
                    id="submenu-dashboard"
                  >
                    <li
                      className={
                        isPathActive("/employer/payment-history")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link className="nav-link" to="/employer/payment-history">
                        <div className="sidebarpayment menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Payment History</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("/employer/change-password")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                    >
                      <Link
                        className="nav-link addnewwrapword"
                        to="/employer/change-password"
                      >
                        <div className="sidebarchangepasswordpage menu-icon"></div>
                        <span className="menu-title">
                          <Trans>Change Password</Trans>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={
                        isPathActive("#")
                          ? "nav-item active setnewsidebarpadding"
                          : "nav-item setnewsidebarpadding"
                      }
                      onClick={AcccountDeactive}
                    >
                      <div className="nav-link addnewwrapword">
                        <div className="sidebardeactivateaccount menu-icon"></div>
                        <span className="menu-title" onClick={AcccountDeactive}>
                          <Trans>Deactivate Account</Trans>
                        </span>
                      </div>
                    </li>
                  </div>
                </li>
                <li
                  className={
                    isPathActive("/contact") ? "nav-item active" : "nav-item"
                  }
                >
                  <Link
                    className="nav-link addnewiconemployerpadding"
                    to="/contact"
                  >
                    {/* <i className="mdi mdi-account-card-details menu-icon"></i> */}
                    <div className="sidebarcontactus menu-icon"></div>
                    <span className="menu-title">
                      <Trans>Contact Us</Trans>
                    </span>
                  </Link>
                </li>
                <li
                  className={isPathActive("/jobmap") ? "nav-item" : "nav-item"}
                  onClick={LogoutFun}
                >
                  <div className="nav-link addnewiconemployerpadding">
                    {/* <i className="mdi mdi-login-variant menu-icon"></i> */}
                    <img
                      src={sidebarlogout}
                      alt="logout"
                      className="menu-icon"
                      width={"22px"}
                      height={"22px"}
                    ></img>
                    <span className="menu-title">
                      <Trans>Log Out</Trans>
                    </span>
                  </div>
                </li>
                <div className="put_sidebarimg d-flex d-lg-none">
                  <img
                    src={sidebarEmployerLogo}
                    className="width_set_logo smallscreennavbarlogoset sidebar_imgset"
                    alt="Navbar Logo"
                  ></img>
                </div>
              </>
            )}
          </ul>
        </div>
      </nav>
      {!isAuthenticated && (
        <Modal
          show={modalShowLogin}
          onHide={() => setModalShowLogin(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="remove_modelback"
        >
          <Modal.Body className="p-0">
            <div className="">
              <NewUserLogin setModalShowLogin={setModalShowLogin} />
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Modal size="lg" show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title className="model_side">
            <img src={mediakit} alt="turnto" className="imglandingpage" />
            <img
              src={historyclose1}
              alt="close"
              className="close_model set_close wlcmsignupclosepng"
              onClick={handleCloseModal}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-3 mb-3">
            <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
              {window.innerWidth < 992 ? (
                <div className="">
                  <div className="d-flex justify-content-center meduakitnewcss">
                    <a
                      href={"https://twitter.com/TurnToJobSearch"}
                      className="p-2 loginpageicon"
                      target="_blank"
                    >
                      <img
                        src={logintwitter}
                        alt="twitter"
                        className="closepngiocnwidth"
                      />
                    </a>
                    <a
                      href={"https://www.instagram.com/turn__to/"}
                      className="p-2 loginpageicon"
                      target="_blank"
                    >
                      <img
                        src={logininstagram}
                        alt="instagram"
                        className="closepngiocnwidth"
                      />
                    </a>
                    <a
                      href={"https://www.linkedin.com/"}
                      className="p-2 loginpageicon"
                      target="_blank"
                    >
                      <img
                        src={loginlinedin}
                        className="closepngiocnwidth"
                        alt="facebook"
                      />
                    </a>
                    <a
                      href={"https://www.reddit.com/r/jobmap/"}
                      className="p-2 loginpageicon"
                      target="_blank"
                    >
                      <img
                        src={loginreddit}
                        alt="reddit"
                        className="closepngiocnwidth"
                      />
                    </a>
                    <a
                      href={"https://www.facebook.com/jobsearchmap"}
                      className="p-2 loginpageicon"
                      target="_blank"
                    >
                      <img
                        src={loginfacebook}
                        alt="facebook"
                        className="loginfacebookpng"
                      />
                    </a>
                  </div>
                </div>
              ) : (
                ""
              )}
              <Accordion.Item eventKey="0" className="my-2">
                <div className="d-flex flex-wrap">
                  <Accordion.Header className="fontsetAccordion wlcmlandingpopupbold">
                    1. Attract Top Talent Through Your Network:
                  </Accordion.Header>
                  {window.innerWidth > 992 ? (
                    <div className="ml-3">
                      <div className="d-flex justify-content-center">
                        <a
                          href={"https://twitter.com/TurnToJobSearch"}
                          className="p-2 loginpageicon"
                          target="_blank"
                        >
                          <img
                            src={logintwitter}
                            alt="twitter"
                            className="closepngiocnwidth"
                          />
                        </a>
                        <a
                          href={"https://www.instagram.com/turn__to/"}
                          className="p-2 loginpageicon"
                          target="_blank"
                        >
                          <img
                            src={logininstagram}
                            alt="instagram"
                            className="closepngiocnwidth"
                          />
                        </a>
                        <a
                          href={"https://www.linkedin.com/"}
                          className="p-2 loginpageicon"
                          target="_blank"
                        >
                          <img
                            src={loginlinedin}
                            className="closepngiocnwidth"
                            alt="facebook"
                          />
                        </a>
                        <a
                          href={"https://www.reddit.com/r/jobmap/"}
                          className="p-2 loginpageicon"
                          target="_blank"
                        >
                          <img
                            src={loginreddit}
                            alt="reddit"
                            className="closepngiocnwidth"
                          />
                        </a>
                        <a
                          href={"https://www.facebook.com/jobsearchmap"}
                          className="p-2 loginpageicon"
                          target="_blank"
                        >
                          <img
                            src={loginfacebook}
                            alt="facebook"
                            className="loginfacebookpng"
                          />
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Easily share your Turn-To AI Job Map Listing on social
                      media platforms for increased visibility.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Row>
                <div className="col-lg-10 pl-0 addcopylinkbuttonsidebar1">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="m-0">
                      <h4 className="headingfont ">
                        <b>Link to share</b>
                      </h4>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="newsearchinputaddborderradius"
                      value={
                        process.env.REACT_APP_COMPANY_JOB_MAP +
                        "job-map/" +
                        AuthData?.username
                      }
                      // onChange={(e) => setReasonDes(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-2 p-0 addcopylinkbuttonsidebar">
                  <button className=" copyiframebtn" onClick={copyToClipboard}>
                    Copy Link
                  </button>
                </div>
              </Row>

              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion wlcmlandingpopupbold">
                  2. Enhance Your Employer Brand:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Embed our AI Job Map on your career page, showcasing your
                      innovative approach to hiring and attracting Qualified
                      candidates.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Row>
                <div className="col-lg-10 pl-0 addcopylinkbuttonsidebar1">
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label className="m-0">
                      <h4 className="headingfont ">
                        <b>Embed Our Job Map</b>
                      </h4>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="newsearchinputaddborderradius"
                      value={iframelink}
                      // onChange={(e) => setReasonDes(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-2 p-0 addcopylinkbuttonsidebar">
                  <button
                    className="copyiframebtn"
                    onClick={copyToClipboardmap}
                  >
                    Copy iframe
                  </button>
                </div>
              </Row>
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-1 footeriframe">
          {/* <div>

            <div className="d-flex justify-content-center">
              <a
                href={"https://twitter.com/TurnToJobSearch"}
                className="p-2 loginpageicon"
                target="_blank"
              >
                <img
                  src={logintwitter}
                  alt="twitter"
                  className="closepngiocnwidth"
                />
              </a>
              <a
                href={"https://www.instagram.com/turn__to/"}
                className="p-2 loginpageicon"
                target="_blank"
              >
                <img
                  src={logininstagram}
                  alt="instagram"
                  className="closepngiocnwidth"
                />
              </a>
              <a
                href={"https://www.linkedin.com/"}
                className="p-2 loginpageicon"
                target="_blank"
              >

                <img
                  src={loginlinedin}
                  className="closepngiocnwidth"
                  alt="facebook"
                />

              </a>
              <a
                href={"https://www.reddit.com/r/jobmap/"}
                className="p-2 loginpageicon"
                target="_blank"
              >
                <img
                  src={loginreddit}
                  alt="reddit"
                  className="closepngiocnwidth"
                />
              </a>
              <a
                href={"https://www.facebook.com/jobsearchmap"}
                className="p-2 loginpageicon"
                target="_blank"
              >
                <img
                  src={loginfacebook}
                  alt="facebook"
                  className="loginfacebookpng"
                />
              </a>
            </div>
          </div> */}
        </Modal.Footer>
      </Modal>
      <div className={show ? "offcanvas-footer open" : "offcanvas-footer"}>
        <Offcanvas
          backdrop={false}
          target={offcanvasRef.current}
          show={show}
          onHide={handleClose}
          placement="end"
          scroll={true}
          className="footer_offcanvas"
        >
          <Offcanvas.Body>
            <div className="" ref={offcanvasRef}>
              <Feedback setShow={setShow} />
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <div
        className={
          exploroffcanvas ? "offcanvas-footer open" : "offcanvas-footer"
        }
      >
        <Offcanvas
          backdrop={false}
          target={exploroffcanvasRef.current}
          show={exploroffcanvas}
          onHide={exploroffcanvasclose}
          placement="end"
          scroll={true}
          className="footer_offcanvas"
        >
          <Offcanvas.Body>
            <div className="" ref={exploroffcanvasRef}>
              <Tabspopulardata
                tabeventkey={tabeventkey}
                exploroffcanvasclose={exploroffcanvasclose}
              />
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div
        className={
          popularCompaniesoffcanvas
            ? "offcanvas-footer open"
            : "offcanvas-footer"
        }
      >
        <Offcanvas
          backdrop={false}
          target={popularCompaniesoffRef.current}
          show={popularCompaniesoffcanvas}
          onHide={popularCompaniesoffcanvasclose}
          placement="end"
          scroll={true}
          className="footer_offcanvas"
        >
          <Offcanvas.Body>
            <div className="" ref={popularCompaniesoffRef}>
              <Tabspopulardata
                tabeventkey={tabeventkey}
                popularsearchoffclose={popularCompaniesoffcanvasclose}
              />
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <div
        className={
          trendingSearchesoffcanvas
            ? "offcanvas-footer open"
            : "offcanvas-footer"
        }
      >
        <Offcanvas
          backdrop={false}
          target={trendingSearchesoffcanvasRef.current}
          show={trendingSearchesoffcanvas}
          onHide={trendingSearchesoffcanvasclose}
          placement="end"
          scroll={true}
          className="footer_offcanvas"
        >
          <Offcanvas.Body>
            <div className="" ref={trendingSearchesoffcanvasRef}>
              <Tabspopulardata
                tabeventkey={tabeventkey}
                popularsearchoffclose={trendingSearchesoffcanvasclose}
              />
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <div
        className={
          trendingJobsoffcanvas ? "offcanvas-footer open" : "offcanvas-footer"
        }
      >
        <Offcanvas
          backdrop={false}
          target={trendingJobsoffcanvasRef.current}
          show={trendingJobsoffcanvas}
          onHide={trendingJobsoffcanvasclose}
          placement="end"
          scroll={true}
          className="footer_offcanvas"
        >
          <Offcanvas.Body>
            <div className="" ref={trendingJobsoffcanvasRef}>
              <Tabspopulardata
                tabeventkey={tabeventkey}
                popularsearchoffclose={trendingJobsoffcanvasclose}
              />
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <div
        className={
          popularCitiesoffcanvas ? "offcanvas-footer open" : "offcanvas-footer"
        }
      >
        <Offcanvas
          backdrop={false}
          target={popularCitiesoffcanvasRef.current}
          show={popularCitiesoffcanvas}
          onHide={popularCitiesoffcanvasclose}
          placement="end"
          scroll={true}
          className="footer_offcanvas"
        >
          <Offcanvas.Body>
            <div className="" ref={popularCitiesoffcanvasRef}>
              <Popularcities />
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default withRouter(Sidebar);
