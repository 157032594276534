import React, { useEffect, useState } from "react";
import "./SearchHistroy.css";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { PostDeleteAllHistory, PostDeleteHistory, PostDeleteNotInterstedHistory, getSearchHistory } from "../../Api/Api";
import { useAuth } from "../../context/AuthProvider";
import { Store } from "react-notifications-component";
import RecentHistory from "./RecentHistory";
import NotIntersted from "./NotIntersted";
import logoimg from "./../../assets/images/loginpagenewlogo.png"
import turntologo from './../../assets/images/loginpagenewlogo.png'
import deletepng from "./../../Component/icontopng/deleteapppng.png";
import historyclose from "./../../Component/icontopng/historyclose.png";





const SearchHistroy = (props) => {

  const [dropDownSelectMenu, setDropDownSelectMenu] = useState("Recent Search")

  const [dropDownSelectMenuData, setDropDownSelectMenuData] = useState([
    "Recent Search",
    "Not Interested"
  ]);

  const [keyword, setKeyword] = useState([]);
  const GetLocationrecent = () => {
    getSearchHistory()
      .then(async function (response) {
        // console.log(response.data?.data?.notInterestedJobs, "get ");
        setKeyword(response.data?.data?.recentSearches);
      })
      .catch(function (error) {
        // console.log(error.response.data?.data?.error);
      });
  };
  const [filterText, setFilterText] = useState("");
  const filteredData = keyword.filter((item) => {
    return (
      item.keyword?.toLowerCase().includes(filterText?.toLowerCase())
    );
  });
  const DeleteAllHistory = () => {

    PostDeleteAllHistory()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        // console.log(response);
        // handle success
        GetLocationrecent()

        Store.addNotification({
          title: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        // setKeyword(keyword.filter((e) => e._id !== id));
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  };

  const [notintersted, setNotIntersted] = useState([]);
  const DeleteNotInterstedHistory = () => {

    PostDeleteNotInterstedHistory()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        // console.log(response);
        // handle success
        GetLocationrecent();

        Store.addNotification({
          title: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        // setNotIntersted(notintersted.filter((e) => e._id !== id));
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  };

  return (
    <>
      <div className="terms-container3 shadow newoffcanverallserch">
        {/* <Tabs
          defaultActiveKey="recentsearch"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="recentsearch" title="Recent Search">
            <RecentHistory 
            handleCloseHistory={props.handleCloseHistory}
                setSearch={props.setSearch}
                setSearchValue={props.setSearchValue}    
setLatitude={props.setLatitude}
setLongitude={props.setLongitude}
SetLocationGetAutoComplet={props.SetLocationGetAutoComplet}
setLatitudeValue={props.setLatitudeValue}
setLongitudeValue={props.setLongitudeValue}  />
          </Tab>
          <Tab eventKey="notintersted" title="Not Intersted">
            <NotIntersted/>
          </Tab>

        </Tabs> */}
        <Row>
          <Col lg={12} className="d-flex justify-content-between align-items-center ">
            <div><img
                src={historyclose}
                alt="close"
                className="cursor-pointer reportclosepng"
                onClick={props.handleCloseHistory}
              /></div>
            <Form.Group className="giverecentzindex">
              <select
                className="form-control w-auto dashnewcode searchnewhistory shadow"
                id="Showby"
                value={dropDownSelectMenu}
                onChange={(e) => setDropDownSelectMenu(e.target.value)}
              >
                {dropDownSelectMenuData.map((e) => {
                  return (
                    <option value={e} className="dashnewcode"><b>{e}</b></option>
                  )
                })}
              </select>
            </Form.Group>
            {dropDownSelectMenu == "Recent Search" ?
              <div className="d-flex align-items-center cursor-pointer " onClick={DeleteAllHistory}> <img
                src={deletepng}
                alt="delete"
                className=" newrecentsearchhistory"
              /></div > : ""}
            {dropDownSelectMenu == "Not Interested" ?
              <div className="d-flex align-items-center cursor-pointer " onClick={DeleteNotInterstedHistory}> <img
                src={deletepng}
                alt="delete"
                className=" newrecentsearchhistory"
              /></div > : ""}
          </Col>
        </Row>

        {dropDownSelectMenu == "Recent Search" && (
          <RecentHistory
            handleCloseHistory={props.handleCloseHistory}
            setSearch={props.setSearch}
            setSearchValue={props.setSearchValue}
            setLatitude={props.setLatitude}
            setLongitude={props.setLongitude}
            SetLocationGetAutoComplet={props.SetLocationGetAutoComplet}
            setLatitudeValue={props.setLatitudeValue}
            setLongitudeValue={props.setLongitudeValue}
            keyword={keyword} />
        )}
        {dropDownSelectMenu == "Not Interested" && (
          <NotIntersted notintersted={notintersted} />
        )}
        <div className="offcanvasbootamtuntologoset1">
          <img
            src={turntologo}
            alt="turn-to"
            className="contactsuslogoimg"
          />
        </div>

      </div>
    </>
  );
};

export default SearchHistroy;
