import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSocket } from "../../context/SocketProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Trendingsearche = (props) => {


    const history = useHistory();
    //       (position) => {
    //         const location = {
    //           lat: position.coords.latitude,
    //           lng: position.coords.longitude,
    //         };
    //         setCurrentLocation(location);
    //         console.log("location", location.lat);
    //       },
    //       (error) => {
    //         // setLocationmsg(true);
    //         console.error("Error getting current location:", error);
    //       }
    //     );
    //   }, []);

      // useEffect(() => {
      //   // console.log("searchLocation", searchLocation);
      //   if (socket && currentLocation.lat && currentLocation.lng) {
      //   //   setIsLoading(true);
      //     socket.emit("tranding-search", currentLocation);
      //     socket.off("tranding-search");
      //     socket.on("tranding-search", (res) => {       
      //       // setIsLoading(false);
      //       console.log("res.data.trending_companies.length", res.data);
      //       // if (res.data.trending_companies.length) {
    
      //       //   setpopularCompanies(res.data.trending_companies);
      //       // } else {
      //       //   const setddata = [
      //       //     "Accountable Healthcare Staffing",
      //       //     "Medline Industries, Inc.",
      //       //     "SitePro Solutions",
      //       //     "Palm Health Resources",
      //       //     "Integrity Healthcare.",
      //       //     "AB Staffing Solutions, LLC",
      //       //     "Confidential",
      //       //     "Staff Care",
      //       //     "Taylor Truck Line",
      //       //     "SitePro Solutions",
      //       //     "Tenet Healthcare",
      //       //     "Western Partitions, Inc.",
      //       //     "Knight Transportation",
      //       //     "TLC Travel Staff",
      //       //     "CGL Transport",
      //       //     "OptumCare",
      //       //     "Marten Transport",
      //       //     "Blackhawk Transport",
      //       //     "InSync Healthcare Recruiters",
      //       //     "ArchWell Health",
      //       //     "Arthur Marshall, InC.",
      //       //     "National Staffing Solutions",
      //       //     "Genesis Healthcare",
      //       //   ];
      //       //   setpopularCompanies(setddata.map((e) => ({ keyword: e })));
      //       // }
      //       // if (res.data.trending_jobs.length) {
      //       //   settrendingJobs(res.data.trending_jobs);
      //       // } else {
      //       //   const setddata = [
      //       //     "Case Manager",
      //       //     "Travel Registered Nurse Case Manager",
      //       //     "Operating Room Nurse",
      //       //     "Registered Nurse (RN)",
      //       //     "Project Engineer",
      //       //     "Entry Level: Field Services Technician",
      //       //     "Safety Coordinator",
      //       //     "Post Surgery Nurse",
      //       //     "Electrical Superintendent",
      //       //     "Project Engineer",
      //       //     "Physician / Ophthalmology / Arizona / Permanent / Glaucoma Surgeon",
      //       //     "AVP, Operational Risk Program Governance",
      //       //     "Registered Nurse Post-Surgical",
      //       //     "Emergency Medicine Physicians needed for our Honor Health",
      //       //   ];
      //       //   settrendingJobs(setddata.map((e) => ({ keyword: e })));
      //       // }
      //       if (res.data.trending_search.length) {
      //         settrendingSearches(res.data.trending_search);
      //       } else {
      //         const setddata = [
      //           "Banner Health",
      //           "Cardiologist ",
      //           "Technician ",
      //           "Coordinator ",
      //           "Nurse",
      //           "Health",
      //           "Otolaryngology ",
      //           "Surgical",
      //           "Arizona",
      //           "Permanent ",
      //           "Trauma ",
      //           "Locum Tenens",
      //           "Electrical ",
      //           "Opportunity ",
      //           "Family Practice ",
      //           "Anesthesiology ",
      //           "OBGyn Group",
      //           "Field Services",
      //           "Otolaryngology ",
      //         ];
      //         settrendingSearches(setddata.map((e) => ({ keyword: e })));
      //       }
      //       // if (res.data.popular_search.length) {
      //       //   setsearchdata(res.data.popular_search);
      //       // } else {
      //       //   setsearchdata([
      //       //     { keyword: "Northlight Counseling Associates" },
      //       //     { keyword: "AB Staffing Solutions, LLC" },
      //       //     { keyword: "Palm Health Resources" },
      //       //     { keyword: "InSync Healthcare Recruiters" },
      //       //     { keyword: "Labor and Delivery RN Job in Phoenix, AZ" },
      //       //     { keyword: "New Western" },
      //       //     { keyword: "MedStar Health" },
      //       //     { keyword: "Travel Nurse Across America" },
      //       //     { keyword: "B.E.L. & Associates, Inc." },
      //       //     { keyword: "George Washington University Hospital" },
      //       //     { keyword: "KA Recruiting Inc." },
      //       //     { keyword: "Sales" },
      //       //     { keyword: "Fusion Medical Staffing" },
      //       //     { keyword: "TeamHealth" },
      //       //   ]);
      //       // }
      //     });
      //   }
      // }, [currentLocation]);

      const popularSearchsetData = (data) => {
        // setSearch(data.keyword);
        history.push(
          `/jobmap?distance=20&location=${data.location}&latitude=${data.latitude}&longitude=${data.longitude}&search=${data.keyword}`
        );
        window.location.reload()
      };

return(
    <div className="py-2 px-3">
                        {/* <h4>
                        <b>Trending Searches</b>
                        </h4>
                        <hr></hr> */}
            <div className="addallserchdatasetoffcavas">    
                <Row>          
                {props.trendingSearches.map((item, index) => (
                        <Col
                            key={index}
                            xs={12}
                            className="mt-1 d-flex justify-content-between align-items-center pointer "
                            onClick={(e) => popularSearchsetData(item)}
                        >
                            <div className="form-check pointer my-1">
                                <label className="form-check-label text-muted newcssformclass pointer">
                                    <p className="input-helper pointer mb-0"  
                                   
                                    >
                                        {index + 1 }. {item.keyword} 
                                    </p>
                                </label>
                            </div>
                            
                            <p className="input-helper mb-0">
                                {item.job_count} 
                            </p>
                        </Col>
                    ))}
                </Row>
            </div>
    </div>
)
}
export default Trendingsearche;