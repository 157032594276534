import Reaact from "react";
import "./NewAddPage.css";
import { Accordion, Col, Nav, Navbar } from "react-bootstrap";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";

const Hiring = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  return (
    <>
      <div>
        <HeaderNew />
        {/* first section */}
        <div className="container new_pagesmargin" onClick={RemoveSlider}>
          <h3 className="text-center">
            <b>
              Turn-To: Revolutionizing Online Hiring with Precision and
              Personalization
            </b>
          </h3>
          <hr></hr>

          <div>
            <h5>
              <b>Introducing Turn-To’s Hiring Products:</b>
            </h5>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. The Job Map Search Engine:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      A game-changer for both employers and candidates, offering
                      a dynamic way to post and discover jobs. Leveraging
                      cutting-edge AI, this tool provides a visual exploration
                      of job opportunities, enhancing the job search and posting
                      experience.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. One Wallet System:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Streamline your hiring expenses with Turn-To's simplified
                      financial management system. Allocate budgets efficiently
                      to various job postings and track your spending without
                      hassle.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Pay As You Go:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Embrace financial flexibility with our pay-as-you-go
                      model, eliminating the need for subscriptions. Invest in
                      clicks and applications as per your need, ensuring a
                      cost-effective approach to hiring.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  4. Pricing:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Embrace the flexibility of Turn-To's Pay As You Go model,
                      ensuring cost-effective recruitment with no subscription
                      fees.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  5. AI-Enhanced Job Matching
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Get ready for an unmatched level of precision in job
                      matching. Our forthcoming AI technology will intuitively
                      connect employers with the ideal candidates, taking into
                      account both job requirements and the nuanced needs of all
                      parties.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  6. Eco Routes:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Promote eco-friendly hiring practices with Eco Routes,
                      encouraging candidates to consider employment
                      opportunities with an awareness of their environmental
                      impact.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  7. ATS Resume Builder:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Enable candidates to craft resumes optimized for Applicant
                      Tracking Systems, boosting their visibility and chances of
                      being noticed by potential employers.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  8. Custom iFrame for Employers:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Enhance your company’s career page with Turn-To’s
                      customizable iFrame, showcasing your job map directly on
                      your site for maximum exposure.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div>
            <h5>
              <b>Why Choose Turn-To?</b>
            </h5>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Efficient Budget Management:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      With our one-wallet system and pay-as-you-go model,
                      control your hiring expenses with unparalleled precision.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Efficient Budget Management:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      With our one-wallet system and pay-as-you-go model,
                      control your hiring expenses with unparalleled precision.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Innovative Hiring Tools:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      From our job map search engine to the upcoming AI-driven
                      matching capabilities, Turn-To is at the forefront of
                      hiring technology.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  4. Eco-Conscious Hiring:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Align your recruitment practices with your environmental
                      values through our unique Eco Routes feature.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Ready to Transform Your Hiring Process?</b>
            </h4>
            <div className="text-justify">
              Embrace Turn-To’s innovative solutions for a seamless, effective,
              and personalized hiring experience. Join us in setting new
              standards for online recruitment.
            </div>
          </div>
        </div>
        <div>
          <LandingPageFooter />
        </div>
      </div>
    </>
  );
};

export default Hiring;
