import Reaact, { useState } from "react";
import "./NewAddPage.css";
import { Accordion, Col, Modal, Nav, Navbar, Row } from "react-bootstrap";
import footerlogo from "../../assets/images/loginpagenewlogo.png";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";
import NewEmployerLogin from "../candidate/components/common/CompanyDetail/NewEmployerLogin";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewUserLogin from "../candidate/components/common/CompanyDetail/NewUserLogin";
import firstsecimg from "../../Component/AboutPageimg/firstatsimg.png";
import forthsecimg from "../../Component/AboutPageimg/atsresumebuilderimg.jpeg";

import firstthirdimg from "../../Component/AboutPageimg/secindatsimg.png";
import fisthforthimg from "./../../Component/AboutPageimg/thirdatsimg-removebg-preview.png";



import turntologo from "../../assets/images/loginpagenewlogo.png";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const ATSResumeBuilder = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const [employerloginmodal, setEmployerloginmodal] = useState(false);
  const [modalShowLogin, setModalShowLogin] = useState(false);

  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  const history = useHistory();
  const postjobbtn = () => {
    if (isAuthenticated && AuthData.role == "user") {
      history.push("/profile");
    } else {
      setModalShowLogin(true);
    }
  };
  return (
    <>
      <div>

        <div className=" new_pagesmargin" onClick={RemoveSlider}>
          <div className="text-center mb-3">
            <img
              src={turntologo}
              alt="turn-to"
              className="text-center turntologocssnew"
            />
          </div>
          <div className="about_second py-5">
            <div className="container">
              <div className="row">
                <Col md={7} className="d-flex align-items-center">
                  <div>
                    <h2 className="text-left">
                      <b>
                        Build Your Future: Turn-To's ATS Resume Builder Simplifies Your
                        Job Search
                      </b>
                    </h2>

                    <div className="mt-3 mb-3 text-left">
                      <div>
                        <b>
                          Effortlessly create an ATS-optimized resume with Turn-To's Resume
                          Builder. Tailor your resume for success, download it, and apply it
                          anywhere. Start streamlining your job applications today.
                        </b>
                      </div>
                    </div>
                    <div className="mt-3 mb-3 text-left">
                      Embarking on your job search journey requires more than just
                      determination; it demands the right tools. At Turn-To, we understand
                      the importance of standing out in a competitive job market. That's
                      why we've developed the ATS Resume Builder – a tool designed to
                      simplify the resume creation process, ensuring your skills and
                      experiences shine through ATS filters.
                    </div>
                  </div>
                </Col>
               
                <Col md={5} className="d-flex">
                   <img src={forthsecimg} alt="img" className="backsecondsectionimg"/>
                  </Col>
              </div>
            </div>
          </div>

          <div className=" py-5">
            <div className="container">
              
              <Row>
                <Col lg={5} md={5} className="setnewpageflex">
                  <div>
                    <img
                      src={firstsecimg}
                      alt="firstsecimg"
                      className="firstsecimg"
                    ></img>
                  </div>
                </Col>
                <Col lg={7} md={7} >
                  <div class="about_colfive">
                    <h4>
                      <b>Why Use an ATS Resume Builder?</b>
                    </h4>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Optimization</b>
                    </div>
                    <div className="text-justify">
                      Our builder is designed to enhance your resume's
                      compatibility with Applicant Tracking Systems (ATS),
                      increasing your visibility to potential employers.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Simplicity</b>
                    </div>
                    <div className="text-justify">
                      Navigate the resume-building process with ease. Our
                      user-friendly interface guides you every step of the way.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Customization</b>
                    </div>
                    <div className="text-justify">
                      Tailor your resume to highlight the qualifications and
                      experiences that align with your desired roles.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Accessibility</b>
                    </div>
                    <div className="text-justify">
                      Once completed, download your optimized resume and apply
                      to jobs anywhere, confident that your application will
                      make it to the hiring manager's desk.
                    </div>


                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="about_second py-5">
            <div className="container">
              
              <Row>
                <Col lg={7} md={7}>
                  <div class="about_colfive1">
                    <h4><b>How It Works?</b></h4>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Start with Basics</b>
                    </div>
                    <div className="text-justify">
                      Enter your personal information, including contact
                      details, to kick off your resume.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Highlight Your Experience</b>
                    </div>
                    <div className="text-justify">
                      Add your work history, focusing on achievements and
                      responsibilities that showcase your capabilities.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Showcase Your Skills</b>
                    </div>
                    <div className="text-justify">
                      Emphasize the skills that make you a standout candidate,
                      with suggestions based on industry standards.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Education and Certifications</b>
                    </div>
                    <div className="text-justify">
                      Detail your academic background and any additional
                      certifications that bolster your qualifications.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Optimize and Customize</b>
                    </div>
                    <div className="text-justify">
                      Our ATS Resume Builder suggests improvements and keywords
                      to ensure your resume is ATS-friendly.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Download and Apply</b>
                    </div>
                    <div className="text-justify">
                      With your resume ready, download it in a universally
                      accessible format and apply to your dream jobs.
                    </div>
                  </div>
                </Col>
                <Col lg={5} md={5} className="setnewpageflex">
                  <div>
                    <img
                      src={firstthirdimg}
                      alt="firstthirdimg"
                      className="firstsecimg"
                    ></img>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className=" py-5">
            <div className="container">
              
              <Row>
                <Col lg={5} md={5} className="d-flex">
                  <div className="setnewpageflex">
                    <img
                      src={fisthforthimg}
                      alt="firthforthimg"
                      className="setnewpageimg"
                    ></img>
                  </div>
                </Col>
                <Col lg={7} md={7}>
                  <div class="about_colfive">
                    <h4>
                      <b>Benefits of Turn-To's ATS Resume Builder</b>
                    </h4>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                      <b>Efficiency</b>
                    </div>
                    <div className="text-justify">
                      Save time with a streamlined resume-building process.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Competitiveness</b>
                    </div>
                    <div className="text-justify">
                      {" "}
                      Increase your chances of landing an interview by
                      optimizing your resume for ATS.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Flexibility</b>
                    </div>
                    <div className="text-justify">
                      Edit and update your resume anytime, adapting it for
                      different job applications.
                    </div>
                    <div className="mt-2">
                      <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Insight</b>
                    </div>
                    <div className="text-justify">
                      Gain valuable insights into what employers seek in
                      candidates like you.
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="about_second py-5">
            <div className=" container ">
              <Col lg={12} md={12} className="p-0 ">

                <h4 className=" text-center"><b>Conclusion</b></h4>

                <div className="text-center">
                  In today's job market, an ATS-optimized resume is your ticket to
                  getting noticed. With Turn-To's ATS Resume Builder, you're not
                  just preparing a document but crafting a key to unlock new
                  career opportunities. Let us help you present your best self to
                  potential employers.
                </div>
              </Col>
            </div>
          </div>
          <div className="addhowitsfirstsectionbackcolorsetsec ">
            <div className="container">
              <div className="row text-left">
                <Col lg={9} md={8}>
                  <h5 className="">
                    <b>  Ready to build a resume that opens doors? </b>
                  </h5>
                  <h5 className="">Start using
                    Turn-To's ATS Resume Builder today and take the first step
                    toward your dream job.</h5>

                </Col>
                <Col lg={3} md={4} className="jobadsbtnflex">
                  <div>
                    <button class="about_ads mt-2" onClick={(e) => postjobbtn()}>
                      ATS Resume Builder
                    </button>
                  </div>
                </Col>

              </div>
            </div>
          </div>








        </div>
        <div>
          <LandingPageFooter />
        </div>
        {!isAuthenticated ? (
          <Modal
            show={modalShowLogin}
            onHide={() => setModalShowLogin(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="remove_modelback"
          >
            <Modal.Body className="p-0">
              <div className="">
                <NewUserLogin setModalShowLogin={setModalShowLogin} />
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          AuthData?.role === "employer" && (
            <Modal
              show={employerloginmodal}
              onHide={() => setEmployerloginmodal(false)}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="remove_modelback"
            >
              <Modal.Body className="p-0">
                <div className="">
                  <NewEmployerLogin
                    setEmployerloginmodal={setEmployerloginmodal}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )
        )}
      </div>
    </>
  );
};

export default ATSResumeBuilder;
