import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useSocket } from "../../context/SocketProvider";
import { Store } from "react-notifications-component";
import UserProfileResumeInfo from "./UserProfileResumeInfo";
import { useAuth } from "../../context/AuthProvider";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ResumeDownload = () => {
  const [allResume, setAllResume] = useState([]);
  const { socket } = useSocket();
  const { AuthData } = useAuth();
  const [activeProfileId, setActiveProfileId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProfileList, setIsLoadingProfileList] = useState(true);
  const history = useHistory();
  useEffect(() => {
    if (socket) {
      var data = {
        token: localStorage.getItem("token"),
      };
      setIsLoadingProfileList(true);
      socket.emit("get-profile-list", data);
      socket.off("get-profile-list");
      socket.on("get-profile-list", (res) => {
        console.log("res-titlwe", res);
        setIsLoadingProfileList(false);
        if (!res.status) {
          Store.addNotification({
            title: res.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
          
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false;
        }
        if (res.data.length) {
          setAllResume(res.data);
        }
      });
    }
  }, []);

  const SetActiveId = () => {
    setActiveProfileId(allResume[0]._id);
  };
  useEffect(() => {
    if (allResume.length) {
      SetActiveId();
    }
  }, [isLoading]);
  return (
    <>
      <Row>
        <Col md={6} className="p-0 mb-2 mt-1">
          <h4>Resume / CV Download</h4>
        </Col>
        <Col md={6} className="text-end"></Col>
      </Row>
      <div className="user-profile">
      <Container
        className={
          true
            ? "bg-white cardnews radiusset new_userspace tabremoveborder"
            : 
              "bg-white cardnews radiusset new_userspace px-0"
        }
      >
        {isLoadingProfileList ? (
          <Row>
            <Col>
              <Skeleton count={10} />
            </Col>
          </Row>
        ) : (
          <>
            {allResume.length ? (
              <>
                <Row>
                  <Col>
                    <div className="tabsetmobile align-items-center">
                      {allResume.map((e) => {
                        return (
                          <>
                            <div className="m-1 widthsetprofile">
                              <div
                                className={
                                  activeProfileId == e._id
                                    ? "activetabprofile d-flex align-items-center p-1 row shadow"
                                    : "unactivetabrofile d-flex align-items-center p-1 row shadow"
                                }
                                onClick={(ele) => setActiveProfileId(e._id)}
                              >
                                <div className="p-0 col-12">
                                  <h5 className="exp_color set_accordionfont text-left wordbrak">
                                    {" "}
                                    {e.title}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                <Row className="d-block">
                  <Tabs
                    activeKey={activeProfileId}
                    onSelect={(data) => {
                      setActiveProfileId(data);
                    }}
                    id="multipleresume"
                    className="mb-1 multipleresumetab border-0 d-none"
                  >
                    {allResume.map((e) => {
                      return (
                        <Tab
                          eventKey={e._id}
                          title={
                            <div className="d-flex align-items-center p-1 row">
                              <div className="p-0 col-12">
                                <h5 className="exp_color set_accordionfont text-left wordbrak">
                                  {e.title}
                                </h5>
                              </div>
                            </div>
                          }
                        >
                          <UserProfileResumeInfo
                            username={AuthData?.username}
                            profile_slug={e.slug}
                            setIsLoading={setIsLoading}
                          />
                        </Tab>
                      );
                    })}
                  </Tabs>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <Card className="border-0">
                      <Card.Body>
                        <div className="text-center">
                          <h2>
                            You have not added any resume, so please add one
                          </h2>
                          <button
                            className="btnApyJob5 w-auto text-white"
                            onClick={() => history.push("/profile")}
                          >
                            {" "}
                            Add Resume
                          </button>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Container>
      </div>
    </>
  );
};

export default ResumeDownload;
