import React, { useState } from "react";
import "./LandingpageFooter.css";
import footerlogo from "../../assets/images/loginpagenewlogo.png";
import footersendicon from "../../Component/icontopng/footersendicon.png";

import { Accordion, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../context/AuthProvider";
import NewUserLogin from "../candidate/components/common/CompanyDetail/NewUserLogin";
import NewEmployerLogin from "../candidate/components/common/CompanyDetail/NewEmployerLogin";
import { useSocket } from "../../context/SocketProvider";
import { Store } from "react-notifications-component";
import footerpagepng from "../../Component/icontopng/footerpagepng.png";

const LandingPageFooter = () => {
  const { socket } = useSocket();
  const { AuthData, isAuthenticated } = useAuth();
  const [modalShowLogin, setModalShowLogin] = React.useState(false);

  const [employerloginmodal, setEmployerloginmodal] = useState(false);
  const [emailAdd, setEmailAdd] = useState("");
  const [emailError, setEmailError] = useState("");

  // validation and send email socket
  const SendEmail = () => {
    setEmailError("");
    if (emailAdd == "") {
      setEmailError("Please Enter Email");
      return false;
    } else {
      const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(emailAdd);
      if (!emailRegex) {
        setEmailError("Please Enter Valid Email Address");
        return false;
      }
    }
    socket.emit("newsletter-email", { email: emailAdd });
    socket.off("newsletter-email");
    socket.on("newsletter-email", (res) => {
      setEmailAdd("");
      Store.addNotification({
        title: res.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
    });
  };

  // submit api
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div class="pt-4">
        <div class="footerfirst_section">
          <div class="tf-container">
            <div class="row">
              <div class="col-lg-2 col-md-4 land_tour textcenterphoto p-0">
                <div class="footer-logo">
                  <a href="/">
                  <img
                    src={footerlogo}
                    alt="images"
                    className="footerturntologo"
                    width="640"
                    height="360"
                  />
                  </a>
                </div>
              </div>
              <div class="col-lg-10 col-md-8 land_tour p-0">
                <div class="footersocialiconend d-flex align-items-center">
                  <span className="follow_usfooter">Follow Us:</span>
                  <ul class="footerlist-social d-flex align-items-center mb-0 pl-0">
                    <li>
                      <a
                        className="footeralliocn"
                        href={"https://www.facebook.com/jobsearchmap"}
                        target="_blank"
                      >
                        <div className="footerpagefacebook"> </div>
                      </a>
                    </li>
                    <li>
                      <a
                        className="footeralliocn"
                        href={"https://www.linkedin.com/company/turn-to"}
                        target="_blank"
                      >
                        <div className="footerpagelinkedin"></div>
                      </a>
                    </li>
                    <li>
                      <a
                        className="footeralliocn"
                        href={"https://twitter.com/TurnToJobSearch"}
                        target="_blank"
                      >
                        <div className="footerpagetwitter"></div>
                      </a>
                    </li>
                   
                    <li>
                      <a
                        className="footeralliocn"
                        href={"https://www.instagram.com/turn__to/"}
                        target="_blank"
                      >
                        <div className="footerpageinstagram"></div>
                      </a>
                    </li>
                    <li>
                      <a
                        className="footeralliocn"
                        href={"https://www.youtube.com/@turn-to5312"}
                        target="_blank"
                      >
                        <div className="footerpageyoutube"></div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="inner-footer">
          <div class="tf-container">
            <Row>
              <div class="col-lg-4 col-md-6 land_tour p-0">
                <div class="responsivespacebottom">
                  
                  <p className="footer_data textcentertext mb-0">
                   Join Turn-To newsletter for updates!
                  </p>
                 
                  <form className="footermainsectioninput mt-4" onSubmit={handleSubmit}>
                    <input
                      type="email"
                      placeholder="Your email address"
                      required=""
                      id="subscribe-email"
                      className="footeremailinput"
                      value={emailAdd}
                      onChange={(e) => setEmailAdd(e.target.value)}
                    />
                    <button
                      class="footersendbutton cursor-pointer"
                      id="subscribe-button"
                      onClick={SendEmail}
                    >
                      <img
                        src={footersendicon}
                        alt="send"
                        width={"15px"}
                        height={"15px"}
                      ></img>
                    </button>
                  </form>
                  <span className="eroorclass">{emailError}</span>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-xs-12 land_tour p-0">
                {window.innerWidth > 767 ? (
                  <div class="responsivespacebottom pl-0 pl-md-3">
                    <h6 class="ft-title">Quick Links</h6>
                    <ul class="footerlistdata">
                      <li className="mb-1">
                        {isAuthenticated && AuthData.role == "user" ? (
                          <a href="/" className="allfooterlistdatacolor">
                            Job Map AI Search
                          </a>
                        ) : (
                          <span
                            onClick={() => setModalShowLogin(true)}
                            className="allfooterlistdatacolor"
                          >
                            Job Map AI Search
                          </span>
                        )}
                      </li>
                     
                      <li className="mb-1">
                        <a href="/about" className="allfooterlistdatacolor">
                          About Turn-To
                        </a>
                      </li>
                      <li className="mb-1">
                        <a href="/contact" className="allfooterlistdatacolor">
                          Contact us
                        </a>
                      </li>
                      <li className="mb-1">
                        <a className="allfooterlistdatacolor" href="https://blog.turn-to.com/?e-filter-a15ac9e-category=general">
                          General Blog
                        </a>
                      </li>
                      <li className="mb-1"></li>
                    </ul>
                  </div>
                ) : (
                  <Accordion defaultActiveKey="">
                    <Accordion.Item eventKey="0" className="my-2">
                      <Accordion.Header className="footerpagetrending">
                        Quick Links
                        <img
                          className="mx-2 footerexpandbuttonset"
                          src={footerpagepng}
                          alt="open"
                          width="15px"
                          height="15px"
                        />
                      </Accordion.Header>
                      <Accordion.Body className="">
                        <ul class="footerlistdata">
                          <li className="mb-1">
                            {isAuthenticated && AuthData.role == "user" ? (
                              <a href="/" className="allfooterlistdatacolor">
                                Job Map AI Search
                              </a>
                            ) : (
                              <span
                                onClick={() => setModalShowLogin(true)}
                                className="allfooterlistdatacolor"
                              >
                                Job Map AI Search
                              </span>
                            )}
                          </li>
                         
                          <li className="mb-1">
                            <a href="/about" className="allfooterlistdatacolor">
                              About Turn-To
                            </a>
                          </li>
                          <li className="mb-1">
                            <a href="/contact" className="allfooterlistdatacolor">
                              Contact us
                            </a>
                          </li>
                          <li className="mb-1">
                            <a className="allfooterlistdatacolor" href="https://blog.turn-to.com/?e-filter-a15ac9e-category=general">
                              General Blog
                            </a>
                          </li>
                          <li className="mb-1"></li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </div>

              <div class="col-lg-2 col-md-4 col-xs-12 land_tour p-0">
                {window.innerWidth > 767 ? (
                  <div class="responsivespacebottom">
                    <h6 class="ft-title">For Candidates</h6>
                    <ul class="footerlistdata">
                    <li className="mb-1">
                            <a className="allfooterlistdatacolor" onClick={() => setModalShowLogin(true)}>
                            Create Free Account
                            </a>
                          </li>
                          <li className="mb-1">
                            <a className="allfooterlistdatacolor" href="/jobmap">
                            Search Jobs
                            </a>
                          </li>
                      <li className="mb-1">

                        <a className="allfooterlistdatacolor" href="/ats-resume-builder">ATS Resume Builder</a>
                      </li>
                      <li className="mb-1">
                        <a className="allfooterlistdatacolor" href="https://blog.turn-to.com/category/candidates/">
                        Candidate Blog
                        </a>
                      </li>
                      <li className="mb-1">
                            <a className="allfooterlistdatacolor" href="/faq-candidate">
                            FAQ for Candidates
                            </a>
                          </li>
                         
                      
                    </ul>
                  </div>
                ) : (
                  <Accordion defaultActiveKey="">
                    <Accordion.Item eventKey="0" className="my-2">
                      <Accordion.Header className="footerpagetrending">
                        For Candidates
                        <img
                          className="mx-2 footerexpandbuttonset"
                          src={footerpagepng}
                          alt="open"
                          width="15px"
                          height="15px"
                        />
                      </Accordion.Header>
                      <Accordion.Body className="">
                        <ul class="footerlistdata">
                         
                          
                          <li className="mb-1">
                            <a className="allfooterlistdatacolor" onClick={() => setModalShowLogin(true)}>
                            Create Free Account
                            </a>
                          </li>
                          <li className="mb-1">
                            <a className="allfooterlistdatacolor" href="/jobmap">
                            Search Jobs
                            </a>
                          </li>
                          <li className="mb-1">
                            {isAuthenticated && AuthData.role == "user" ? (
                              <a
                                href="/profile"
                                className="allfooterlistdatacolor"
                              >
                                ATS Resume Builder
                              </a>
                            ) : (
                              <span
                                onClick={() => setModalShowLogin(true)}
                                className="allfooterlistdatacolor"
                              >
                                ATS Resume Builder
                              </span>
                            )}
                          </li>
                          <li className="mb-1">
                            <a className="allfooterlistdatacolor" href="https://blog.turn-to.com/category/candidates/">
                            Candidate Blog
                            </a>
                          </li>
                          <li className="mb-1">
                            <a className="allfooterlistdatacolor" href="/faq-candidate">
                            FAQ for Candidates
                            </a>
                          </li>
                          
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </div>
              <div class="col-lg-2 col-md-4 col-xs-12 land_tour p-0">
                {window.innerWidth > 767 ? (
                  <div class="responsivespacebottom">
                    <h6 class="ft-title">For Employers</h6>
                    <ul class="footerlistdata">
                    <li className="mb-1">
                        {" "}
                        <a className="allfooterlistdatacolor" onClick={() => setEmployerloginmodal(true)}>
                        Post a Job For Free
                        </a>{" "}
                      </li>
                      <li className="mb-1">
                        {" "}
                        <a className="allfooterlistdatacolor" href="https://blog.turn-to.com/?e-filter-a15ac9e-category=employer">
                          Employer Blog
                        </a>{" "}
                      </li>
                      <li className="mb-1">
                        {isAuthenticated && AuthData.role == "employer" ? (
                          <a
                            href="/employer/post-job"
                            className="allfooterlistdatacolor"
                          >
                            Post a Job
                          </a>
                        ) : (
                          <span
                            onClick={() => setEmployerloginmodal(true)}
                            className="allfooterlistdatacolor"
                          >
                            Post a Job
                          </span>
                        )}
                      </li>
                      <li className="mb-1">
                        {isAuthenticated && AuthData.role == "employer" ? (
                          <a
                            href="/employer/dashboard"
                            className="allfooterlistdatacolor"
                          >
                            Employer Dashboard
                          </a>
                        ) : (
                          <span
                            onClick={() => setEmployerloginmodal(true)}
                            className="allfooterlistdatacolor"
                          >
                            Employer Dashboard
                          </span>
                        )}
                      </li>
                      <li className="mb-1">
                        {" "}
                        <a className="allfooterlistdatacolor" href="/job-pricing">
                        Job Pricing
                        </a>{" "}
                      </li>
                      <li className="mb-1">
                        {" "}
                        <a className="allfooterlistdatacolor" href="/faq-employer">
                        FAQ for Employers
                        </a>{" "}
                      </li>
                      <li className="mb-1">
                        {" "}
                        <a className="allfooterlistdatacolor" href="/contact">
                        Contact Us
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <Accordion defaultActiveKey="">
                    <Accordion.Item eventKey="0" className="my-2">
                      <Accordion.Header className="footerpagetrending">
                        For Employers
                        <img
                          className="mx-2 footerexpandbuttonset"
                          src={footerpagepng}
                          alt="open"
                          width="15px"
                          height="15px"
                        />
                      </Accordion.Header>
                      <Accordion.Body className="">
                        <ul class="footerlistdata">
                        <li className="mb-1">
                        {" "}
                        <a className="allfooterlistdatacolor" onClick={() => setEmployerloginmodal(true)}>
                        Post a Job For Free
                        </a>{" "}
                      </li>
                          <li className="mb-1">
                            {" "}
                            <a className="allfooterlistdatacolor" href="https://blog.turn-to.com/?e-filter-a15ac9e-category=employer">
                              Employer Blog
                            </a>{" "}
                          </li>
                          <li className="mb-1">
                            {isAuthenticated && AuthData.role == "employer" ? (
                              <a
                                href="/employer/post-job"
                                className="allfooterlistdatacolor"
                              >
                                Post a Job
                              </a>
                            ) : (
                              <span
                                onClick={() => setEmployerloginmodal(true)}
                                className="allfooterlistdatacolor"
                              >
                                Post a Job
                              </span>
                            )}
                          </li>
                          <li className="mb-1">
                            {isAuthenticated && AuthData.role == "employer" ? (
                              <a
                                href="/employer/dashboard"
                                className="allfooterlistdatacolor"
                              >
                                Employer Dashboard
                              </a>
                            ) : (
                              <span
                                onClick={() => setEmployerloginmodal(true)}
                                className="allfooterlistdatacolor"
                              >
                                Employer Dashboard
                              </span>
                            )}
                          </li>
                          <li className="mb-1">
                        {" "}
                        <a className="allfooterlistdatacolor" href="/faq-employer">
                        FAQ for Employers
                        </a>{" "}
                      </li>
                      <li className="mb-1">
                        {" "}
                        <a className="allfooterlistdatacolor" href="/contact">
                        Contact Us
                        </a>{" "}
                      </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </div>

              
            </Row>
          </div>
        </div>
        <div class="bottom">
          <div class="tf-container">
            <div class="row">
              <div class="col-lg-6 col-md-6 land_tour p-0">
                <div class="">
                  <div class="landingfootercopyright textcenterphoto">
                    ©2024 Turn-To. All Rights Reserved.
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 p-0 land_tour p-0">
                <ul class="menu-bottom d-flex align-items-center footerlistdata">
                  <li>
                    <a
                      href="/privacy-and-cookies"
                      className="mr-3 allfooterlistdatacolor footerallpagessetsize"
                    >
                      Privacy Policy
                    </a>{" "}
                  </li>
                  <li>
                    <a
                      href="/legal"
                      className="mr-3 allfooterlistdatacolor footerallpagessetsize"
                    >
                      Legal
                    </a>{" "}
                  </li>
                  <li>
                    <a
                      href="/help"
                      className="mr-3 allfooterlistdatacolor footerallpagessetsize"
                    >
                      Help
                    </a>{" "}
                  </li>
                  
                  <li>
                    <a
                      href="/about"
                      className="mr-3 allfooterlistdatacolor footerallpagessetsize"
                    >
                      About
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isAuthenticated ? (
        <Modal
          show={modalShowLogin}
          onHide={() => setModalShowLogin(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="remove_modelback"
        >
          <Modal.Body className="p-0">
            <div className="">
              <NewUserLogin setModalShowLogin={setModalShowLogin} />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        AuthData?.role === "employer" && (
          <Modal
            show={modalShowLogin}
            onHide={() => setModalShowLogin(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="remove_modelback"
          >
            <Modal.Body className="p-0">
              <div className="">
                <NewUserLogin setModalShowLogin={setModalShowLogin} />
              </div>
            </Modal.Body>
          </Modal>
        )
      )}

      {!isAuthenticated ? (
        <Modal
          show={employerloginmodal}
          onHide={() => setEmployerloginmodal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="remove_modelback"
        >
          <Modal.Body className="p-0">
            <div className="">
              <NewEmployerLogin setEmployerloginmodal={setEmployerloginmodal} />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        AuthData?.role === "user" && (
          <Modal
            show={employerloginmodal}
            onHide={() => setEmployerloginmodal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="remove_modelback"
          >
            <Modal.Body className="p-0">
              <div className="">
                <NewEmployerLogin
                  setEmployerloginmodal={setEmployerloginmodal}
                />
              </div>
            </Modal.Body>
          </Modal>
        )
      )}
    </>
  );
};

export default LandingPageFooter;
