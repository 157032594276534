import React, { useEffect, useState } from "react";
import "./footerpage.css";
import {  Form } from "react-bootstrap";
import { Col,  Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useSocket } from "../../context/SocketProvider";
import { setMetatags } from "../../SeoHelper/SeoHelper";
import { postuserfeedback } from "../../Api/Api";
import { Store } from "react-notifications-component";
import turntologo from "../../assets/images/turntofeedbacklogo.png";

const Feedback = (props) => {
  const[feedback, setFeedback] = useState("")
  const[errfeedback, setErrFeedback] = useState("")
  const[accpet, setAccept] = useState(false)
  const[erraccpet, setErrAccept] = useState(false)
  const[feedBackEmail,setFeedBackEmail] = useState("")

// validation
  const validation = (e) => {
    var isvalid = true;
    Errorclear();

    if (feedback.trim() === "") {
      setErrFeedback("Please Describe your Feedback");
      isvalid = false;
    }
    if (accpet) {
      if(feedBackEmail.trim() == ""){
        setErrAccept("Please Enter Email");
        isvalid = false;
      } else {
        const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(
          feedBackEmail
        );
        if (!emailRegex) {
          setErrAccept("Please Enter Vaild Email");
          isvalid = false;
        }
      }
    }
    return isvalid;
    }

    // Errorclear
    const Errorclear = ()=>{
      setErrFeedback("")
      setErrAccept("")
    }

    
    const formData = new FormData();

    // submit feedback
    const feedbackSubmit =() =>{
      if (!validation()) {
        return false;
      }
  
      formData.append("feedback", feedback);
      formData.append("file", acceptedFiles);
      formData.append("feedBackEmail", feedBackEmail);
  
      postuserfeedback(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
         if (response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          props.setShow(false)
        } else {
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
        }
      })
      .catch(function (error) {
      });
  
    }

  const thumbsContainer = {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };
  const { socket } = useSocket();
  useEffect(() => {
    if (socket) {
      socket.emit("seo", { page: "feedback" });
      socket.off("seo");
      socket.on("seo", (seoData) => {
        var title = "Feedback | Turn-To";
        var description = "Feedback | Turn-To";
        if (seoData.status) {
          title = seoData.data.meta_title;
          description = seoData.data.meta_description;
        }
        setMetatags(title, description);
      });
    }
    return () => {
      socket.off("seo");
    };
  }, []);

  

  const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    }, 
    multiple: true, 

  });

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleFileClick = (file) => {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  const [feedbackType, setFeedbackType] = useState("General Comment");

  const handleFeedbackTypeChange = (event) => {
    setFeedbackType(event.target.value);
  };

  const [feedbackCategory, setFeedbackCategory] =
    useState("Website Experience");

  const handleFeedbackCategoryChange = (event) => {
    setFeedbackCategory(event.target.value);
  };

  const [overallExperience, setOverallExperience] = useState("Excellent");

  const handleOverallExperienceChange = (event) => {
    setOverallExperience(event.target.value);
  };

  return (
    <>
      <div className="newoffcanverallserch">
        <div className="user-profile addnewfedbackformscroll feedback_padding">
          <div>
            <div>
              <div className="mt-2">
                <h4>
                  <b>Send feedback to Turn-To</b>
                </h4>
                <hr></hr>
                <Row>
                  <Col lg={12} className="p-0">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                        {" "}
                        Describe your feedback
                      </Form.Label>
                      <Form.Control as="textarea"  
                       value={feedback}
                       className="newsearchinputaddborderradius"
                        onChange={(e) => setFeedback(e.target.value)}
                        rows={3}
                         placeholder="Tell us what prompted this feedback..."/>
                        <span className="eroorclass">{errfeedback}</span>
              

                    </Form.Group>
                    <div className="feedback_font">Please don’t include any sensitive information</div>
                  </Col>
                </Row>
              </div>

              <div className="feed_top1">
                <div className=" ">
                  <b>Permission to Contact:</b>
                  <div className=" feed_top">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={accpet}
                          onChange={(e) => setAccept(!accpet)}
                        />
                        <i className="input-helper"></i>I agree to be contacted
                        regarding my feedback.
                       
                      </label>
                    </div>
                    
                  </div>
                </div>
              </div>
              {accpet && (
              <Col lg={12} className="p-0">
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>
                        {" "}
                      Enter Email
                      </Form.Label>
                      <Form.Control type="email"  
                       value={feedBackEmail}
                       className="newsearchinputaddborderradius"
                        onChange={(e) => setFeedBackEmail(e.target.value)}
                         placeholder="Enter Email"/>
                        <span className="eroorclass">{erraccpet}</span>
                    </Form.Group>
                    <div className="feedback_font">Please don’t include any sensitive information</div>
                  </Col>
              )} 

              <div className="mt-2">
                <div className=" ">
                  <b>Document Upload (optional):</b>
                  <p className="mt-2">
                    If you have any relevant screenshots that can
                    help us understand your feedback better, please upload them
                    here
                  </p>
                  <div className="feed_top2">
                  <section className="container col-lg-12 p-0">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="text-center border_dashed">
                              Upload Photo<br></br>
                              <br></br>
                              <span className="upload_photo">
                                {" "}
                                Upload a Jpg/png/Jpeg
                              </span>
                            </div>
                          </div>
                          <aside style={thumbsContainer}>
                            <div className="mb-2">{acceptedFileItems}</div>
                          </aside>
                        </section>
                   
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <b>
                  <p>
                    Thank you for taking the time to provide your feedback. We
                    take all comments seriously and will use them to enhance our
                    services.
                  </p>
                </b>
              </div>
              <hr></hr>
              <div className="d-flex justify-content-center">
                <button className="buttonclass mb-2" onClick={feedbackSubmit}> Submit </button>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvasbootamtuntologoset">
          <img src={turntologo} alt="turn-to" className="contactsuslogoimg" />
        </div>
      </div>
    </>
  );
};

export default Feedback;
