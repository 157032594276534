import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import Spinner from "../app/shared/Spinner";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {

  const [socket, setSocket] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function connectSocket() {
      const socketIns = io(process.env.REACT_APP_SOCKET_URL);
      setSocket(socketIns)
      setIsLoading(false)
    }
    connectSocket();
    setIsLoading(false);

    return () => {
      setSocket(null);
    };
  }, []);

  if (isLoading) {
    return <><Spinner /></>;
  }
  else {
    return (
      <SocketContext.Provider
        value={{
          socket
        }}
      >
        {children}
      </SocketContext.Provider>
    );
  }

};

export const useSocket = () => useContext(SocketContext);
