import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const Companieshiring = (props) => {
  const history = useHistory();

  // set companies data
  const popularCompaniesSetData = (data) => {
    console.log(data.is_third_party);
    if (data.is_third_party) {
      history.push(
        `/jobmap?distance=20&location=${data.location}&latitude=${data.latitude}&longitude=${data.longitude}&company-info-third-party=${data.keyword}`
      );
    } else {
      history.push(
        `/jobmap?distance=20&location=${data.location}&latitude=${data.latitude}&longitude=${data.longitude}&company-info=${data.keyword}`
      );
    }
    window.location.reload();
  };

  const [isLoading, setIsLoading] = useState(false);
  
  const [filterText, setFilterText] = useState("");

  // for search
  const filteredData = props.popularCompanies?.filter((item) => {
      return (


          item.keyword.toLowerCase().includes(filterText?.toLowerCase())

      );
  });
  const handleFilterChange = (e) => {
      setFilterText(e.target.value);
  };

  return (
    <div className="py-2 px-3">
    
      <div className="">
        {isLoading ? (
          <Skeleton count={50} />
        ) : (
          <Row className="d-block">
            <Col lg={12} md={12}  className="mb-1 mt-2">
            <Form.Group controlId="searchhistory">
              <Form.Control
                type="text"
                placeholder="Search history"
                name="searchhistory"
                className="search_newhistory"
                value={filterText}
              onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          <div className="addallserchdatasetoffcavas">
            {filteredData.map((item, index) => (
              <Col
                key={index}
                xs={12}
                className="mt-1 d-flex justify-content-between align-items-center pointer  hobvereffect"
                onClick={(e) => popularCompaniesSetData(item)}
              >
                <div className="form-check pointer my-1">
                  <label className="form-check-label text-muted newcssformclass pointer">
                    <p
                      className="input-helper pointer mb-0"
                     
                    >
                      {index + 1}. {item.keyword}
                    </p>
                  </label>
                </div>
              </Col>
            ))}
            </div>
          </Row>
        )}
      </div>
    </div>
  );
};
export default Companieshiring;
