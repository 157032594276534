import React, { useEffect, useRef, useState } from "react";
import { GetCoursesData } from "../../Api/Api";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import "./Course.css";
import Autocomplete from "react-autocomplete";
import logo from "../imges/logocerrtificatebackremove.png";
import Skeleton from "react-loading-skeleton";
import searchiconset from "../../Component/icontopng/jobsearch.png";
import { Store } from "react-notifications-component";

const Course = () => {
  const [courses, setCourses] = useState([]);
  const [jobtitle, setJobTitle] = useState("");
  const [jobTitleApi, setJobTitleApi] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [titleInput, setTitleInput] = useState(useRef(""));

  useEffect(() => {
    GetData();
  }, []);

  // GetCoursesData
  const GetData = () => {
    GetCoursesData()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        setCourses(response.data.data);
        setJobTitleApi(response.data.data);
        setfilteredData(response.data.data);
      })
      .catch(function (error) {
      });
  };
  const OnFouceOut = () => {
    if (titleInput?.current) {
      titleInput?.current.blur();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", OnFouceOut);
    return () => {
      window.removeEventListener("scroll", OnFouceOut);
    };
  }, []);
  useEffect(() => {
    if (jobtitle.trim() !== "") {
      setfilteredData(
        courses.filter((item) => {
          return item.name?.toLowerCase().includes(jobtitle?.toLowerCase());
        })
      );
    } else {
      setfilteredData(courses);
    }
  }, [jobtitle]);

  return (
    <Row>
      <Col xs={12} className="d-flex justify-content-center mt-4">
        <img className=" courseanotherlog" src={logo} />
      </Col>

      <Col lg={12} className="pb-2">
        <div className="d-flex justify-content-center align-items-center">
          <div className="coursSerch">
            <Form.Group className="">
              <Autocomplete
                ref={titleInput}
                inputProps={{
                  placeholder: "Search Courses...",
                }}
                wrapperProps={{
                  className: "auto-input",
                }}
                wrapperStyle={{
                  display: "block",
                  zIndex: "1",
                }}
                getItemValue={(item) => item.label}
                items={jobTitleApi
                  .filter((ele) =>
                    ele.name.toLowerCase().includes(jobtitle?.toLowerCase())
                  )
                  .map((ele) => {
                    return { label: ele.name };
                  })}
                renderItem={(item, isHighlighted) => (
                  <div
                    className="jobposttitlewidthset"
                    style={{
                      background: isHighlighted ? "lightgray" : "white",
                    }}
                  >
                    {item.label}
                  </div>
                )}
                value={jobtitle}
                onChange={(e) => setJobTitle(e.target.value)}
                onSelect={(e) => setJobTitle(e)}
              />
            </Form.Group>
          </div>
          <button className="btnsetSearch">
            <img
              src={searchiconset}
              alt="Turn-To"
              // className="new_img"
              width={"25"}
              height={"23"}
            ></img>
          </button>
        </div>
      </Col>
      <Row className="hightsetCoursscroll pb-5">
        {!filteredData.length
          ? [0, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((e) => {
              return (
                <Col className="p-2" md={6} lg={4}>
                  <Skeleton height={200}></Skeleton>
                </Col>
              );
            })
          : filteredData.map((e) => {
              return (
                <Col
                  className="mt-2 p-md-0 pl-md-2 "
                  xl={4}
                  lg={4}
                  md={6}
                  sm={6}
                >
                  <Card.Link
                    target="#"
                    href={e.link}
                    className="courselink text-dark"
                  >
                    <div className="landing_cmp card coursecard">
                      <Row>
                        <Col
                          xl={4}
                          lg={4}
                          md={3}
                          sm={12}
                          className="image_col_center cmpview_flex p-lg-0 p-md-0"
                        >
                          <img
                            className=" course_img"
                            variant="top"
                            src={e.image}
                          />
                        </Col>
                        <Col
                          xl={8}
                          lg={8}
                          md={9}
                          sm={12}
                          className="d-flex  landing_setcenter"
                        >
                          <div className="w-100 mt-1">
                            <div className="landing_cmpfont  coursename">
                              {e.name}
                            </div>
                            <div className="course_font textoverflow text-justify">
                              {e.description}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card.Link>
                </Col>
              );
            })}
      </Row>
    </Row>
  );
};

export default Course;
