import React, { useEffect, useRef, useState } from "react";
import "./footerpage.css";
import { Accordion, Col, Form, Row,Spinner } from "react-bootstrap";
import MyButton from "../../app/candidate/components/common/Button/MyButton";
import { useSocket } from "../../context/SocketProvider";
import { useAuth } from "../../context/AuthProvider";
import { Store } from "react-notifications-component";
import Tooltip from "react-tooltip-lite";
import ReCAPTCHA from 'react-google-recaptcha';

const Contactus = () => {

  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const { socket } = useSocket();

  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [number, SetNumber] = useState("");
  const [message, SetMessage] = useState("");

  const [nameError, SetNameError] = useState("");
  const [emailError, SetEmailError] = useState("");
  const [numberError, SetNumberError] = useState("");
  const [messageError, SetMessageError] = useState("");
  const [iscontactLoading, setContactIsLoading] = useState(false);
  const [isRecaptcha,SetIsRecaptcha] = useState(false)

  const recaptchaRef = useRef();
  // Handle the reCAPTCHA onChange event
  const handleRecaptchaChange = (value) => {
    if(value){
      SetIsRecaptcha(true)
    }else
    {
      SetIsRecaptcha(false)
    }
    // console.log("Recaptcha value:", value);
  }
  const ClearError = () => {
    SetNameError("");
    SetEmailError("");
    SetNumberError("");
    SetMessageError("");
  };
  const Validation = () => {
    ClearError();
    var isValid = true;

    if (name.trim() == "") {
      SetNameError("Please Enter Name ");
      isValid = false;
    }else if (!/^[a-zA-Z]/.test(name)) {
      SetNameError("Name should only contain letters");
      isValid = false;
    }
    if (email.trim() == "") {
      SetEmailError("Please Enter Email ");
      isValid = false;
    }else {
      const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(
        email
      );
      if (!emailRegex) {
        SetEmailError("Please Enter Valid Email Address");
        isValid = false;
      }
    }
    if (number.trim() === "") {
      SetNumberError("Please Enter Mobile Number");
      isValid = false;
    } else if (!/^\d+$/.test(number)) {
      SetNumberError("Mobile Number should contain only digits");
      isValid = false;
    } else if (number.length > 15) {
      SetNumberError("Mobile Number should not exceed 15 characters");
      isValid = false;
    }
   
    if (message.trim() == "") {
      SetMessageError("Please Enter Message ");
      isValid = false;
    }

    return isValid;
  };
  const SubmitEvent = () => {
    if (!Validation()) {
      return false;
    }
    if(!isRecaptcha){
      return false;
    }
    setContactIsLoading(true);
    var data = {
      token: localStorage.getItem("token"),
      name: name,
      email: email,
      number: number,
      message: message,
    };
    // console.log("213213");
    socket.emit("contact_us", data);
    socket.off("contact_us");
    socket.on("contact_us", (res) => {
      // console.log(res);
      SetName("");
      SetEmail("");
      SetNumber("");
      SetMessage("");
      setContactIsLoading(false);
      Store.addNotification({
        // title: "Please Login as a User",
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        // animationIn: ["animate__animated", "animate__fadeIn"],
        // animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
      SetIsRecaptcha(false)

    });
  };
  return (
    <>
    <div className="user-profile">

      <div className="terms-container marginundersmallscreen" style={{ height: "100vh" }}>
        <h1 className="termofuse text-center">Contact us</h1>
        <hr></hr>

        <Row>
            <Col lg={12}>
              <Form.Group className="mb-3" controlId="Name">
                <Form.Label>Name: </Form.Label>
                <Form.Control
                  type="text"
                  className="newsearchinputaddborderradius"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => SetName(e.target.value)}
                />
                <span className="err_msg"> {nameError} </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="Name">
                <Form.Label>Email: </Form.Label>
                <Form.Control
                  type="email"
                  className="newsearchinputaddborderradius"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => SetEmail(e.target.value)}
                />
                <span className="err_msg"> {emailError} </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="Name">
                <Form.Label>Mobile Number: </Form.Label>
                <Form.Control
                  type="number"
                  className="newsearchinputaddborderradius"
                  placeholder="Enter Mobile Number"
                  value={number}
                  onChange={(e) => SetNumber(e.target.value)}
                />
                <span className="err_msg"> {numberError} </span>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  className="newsearchinputaddborderradius"
                  rows={3}
                  value={message}
                  onChange={(e) => SetMessage(e.target.value)}
                />
                <span className="err_msg"> {messageError} </span>
              </Form.Group>
            </Col>
          </Row>
          <Col>
          <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={handleRecaptchaChange}
            />
          </Col>
          <Col lg={12}>
            <div
              className="d-flex justify-content-center mb-3"
              onClick={(e) => SubmitEvent(e)}
            >
 <Tooltip content="Send Message">
              <MyButton>
                {iscontactLoading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    className="spinneerset"
                  >
                    <span className="visually-hidden"></span>
                  </Spinner>
                ) : (
                  "Send Message"
                )}
              </MyButton>
              </Tooltip>
            </div>
          </Col>
      </div>
      </div>
    </>
  );
};

export default Contactus;
