import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import "suneditor/dist/css/suneditor.min.css";
import "./experience.css";
import Card from "../user-profile-card/Card";
import MyButton from "../../common/Button/MyButton";
import companydataremove from "../../../../../Component/icontopng/companydataremove.png";

import { MdOutlineClose } from "react-icons/md";
import {
  GetJobTitle,
  PostdeleteExperience,
  getExperienceLists,
  getJobLocationTypeApi,
  getProfileEmploymentType,
  getSearchCompanies,
  postUpdateExpApi,
  postaddExperience,
} from "../../../../../Api/Api";
import Autocompletemap from "react-google-autocomplete";
import Autocomplete from "react-autocomplete";
import Swal from "sweetalert2";
import { Store } from "react-notifications-component";
import addcarddata from "../../../../../Component/icontopng/addcarddata.png";
import footerpagepng from "../../../../../Component/icontopng/footerpagepng.png";

import "react-select-search/style.css";
import { Modal } from "react-bootstrap";
import Tooltip from "react-tooltip-lite";

const month = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};


function Experience(props) {
  const [data, setData] = useState([]); // data
  const [employmentTypeApi, setEmploymentTypeApi] = useState([]);

  //  State
  const [locationGetAutoComplet, SetLocationGetAutoComplet] = useState("");
  const [jobtitle, setJobtitle] = useState("");
  const [EmploymentType, setEmploymentType] = useState("");
  const [companyname, setcompanyname] = useState("");
  const [LocationType, setLocationType] = useState("");
  const [startmonth, setstartmonth] = useState("");
  const [startyear, setstartyear] = useState("");
  const [endmonth, setendmonth] = useState("");
  const [endyear, setendyear] = useState("");
  const [JobDescription, setJobDescription] = useState([]);
  const [iscurrentilyWorking, setIscurrentilyWorking] = useState(false);
  const [companylistapi, setCompanylistapi] = useState([]);
  const [experience_Id, setExperienceId] = useState(null);

  // validation
  const [errlocationGetAutoComplet, seterrLocationGetAutoComplet] =
    useState("");
  const [errjobtitle, seterrJobtitle] = useState("");
  const [errEmploymentType, seterrEmploymentType] = useState("");
  const [errcompanyname, seterrCompanyname] = useState("");
  const [errLocationType, seterrLocationType] = useState("");
  const [errstartmonth, seterrStartmonth] = useState("");
  const [errstartyear, seterrStartyear] = useState("");
  const [errendmonth, seterrEndmonth] = useState("");
  const [errendyear, seterrEndyear] = useState("");
  const [errJobDescription, seterrJobDescription] = useState("");
  const [erriscurrentilyWorking, seterrIscurrentilyWorking] = useState(false);

  // for border
  const [checklocationGetAutoComplet, setCheckLocationGetAutoComplet] =
    useState(false);
  const [checkjobtitle, setCheckJobtitler] = useState(false);
  const [checkEmploymentType, setCheckEmploymentType] = useState(false);
  const [checkcompanyname, setCheckcompanyname] = useState(false);
  const [checkLocationType, setCheckLocationType] = useState(false);
  const [checkstartmonth, setCheckstartmonth] = useState(false);
  const [checkstartyear, setCheckstartyear] = useState(false);
  const [checkendmonth, setCheckendmonth] = useState(false);
  const [checkendyear, setCheckendyear] = useState(false);
  const [checkJobDescription, setCheckJobDescription] = useState(false);
  const [locationTypeApiData, setLocationTypeApiData] = useState([]);

  // experience
  const [showExperience, setShowExperience] = useState(false);

  const handleCloseExperience = () => {
    setShowExperience(false);
    setexperienceModalShow(false);
    clearError();
    inputValueBlank();
    setExperienceId(null);
  };
  const handleShowExperience = () => {
    setShowExperience(true);
    setJobDescription([""])
  }
  const [experienceModalShow, setexperienceModalShow] = useState(false);

  const isAnyJobDescriptionEmpty = () => {
    return JobDescription.some(description => description.trim() === '');
  };

  const startDate = new Date(startyear, startmonth - 1); 
  const endDate = new Date(endyear, endmonth - 1);

  // validation
  const errMsg = () => {
    const d = new Date();
    let year = d.getFullYear();
    let currentMonth = d.getMonth() + 1;

    clearError();
    var isValid = true;
    if (locationGetAutoComplet.trim() === "") {
      setCheckLocationGetAutoComplet(true);
      seterrLocationGetAutoComplet("Please Type Location");
      isValid = false;
    }

    if (jobtitle.trim() == "") {
      setCheckJobtitler(true);
      seterrJobtitle("Please Enter JobTitle");
      isValid = false;
    } else if (jobtitle.length > 100) {
      seterrJobtitle("JobTitle should not exceed 100 characters");
      isValid = false;
    }
    if (EmploymentType == "") {
      setCheckEmploymentType(true);
      seterrEmploymentType("Please Select Employment Type");
      isValid = false;
    }
    if (companyname.trim() == "") {
      setCheckcompanyname(true);
      seterrCompanyname("Please Enter CompanyName");
      isValid = false;
    } else if (companyname.length > 100) {
      seterrCompanyname("CompanyName should not exceed 100 characters");
      isValid = false;
    }
    if (LocationType == "") {
      setCheckLocationType(true);
      seterrLocationType("Please Select Location Type");
      isValid = false;
    }
    if (startmonth == "") {
      setCheckstartmonth(true);
      seterrStartmonth("Please Select Start Month");
      isValid = false;
    }

    // Validate start year
    if (startyear === "") {
      setCheckstartyear(true);
      seterrStartyear("Please Select Start Year");
      isValid = false;
    } else if (startyear === "" || startyear === "0000") {
      setCheckstartyear(true);
      seterrStartyear("Please enter a valid Start Year");
      isValid = false;
    } else if (!/^\d{4}$/.test(startyear)) {
      setCheckstartyear(true);
      seterrStartyear(
        "Please enter a valid 4-digit non-negative number for Start Year"
      );
      isValid = false;
    } else if (parseInt(startyear) > year) {
      setCheckstartyear(true);
      seterrStartyear("Start Year cannot be in the future");
      isValid = false;
    }

    // Validate start month
    if (startyear == year && startmonth > currentMonth) {
      seterrStartmonth("Start Month cannot be in the future");
      isValid = false;
    } else if (startyear == year && startmonth == currentMonth) {
      isValid = true;
    }

    // Validate end year and month if not currently working
    if (!iscurrentilyWorking) {
      if (endmonth == "") {
        setCheckendmonth(true);
        seterrEndmonth("Please Select End Month");
        isValid = false;
      }
      if (endyear === "") {
        setCheckendyear(true);
        seterrEndyear("Please Select End Year");
        isValid = false;
      } else if (parseInt(endyear) > year) {
        seterrEndyear("End Year cannot be in the future");
        isValid = false;
      } else if (parseInt(endyear) === year && endmonth > currentMonth) {
        seterrEndmonth("End Month cannot be in the future");
        isValid = false;
      } 
     

      else if (startDate >= endDate) {
        seterrEndmonth("End date should be after the Start date");
        isValid = false;
      }

      if (
        startyear === endyear &&
        startmonth === endmonth &&
        startyear !== "" &&
        startmonth !== "" &&
        endyear !== "" &&
        endmonth !== ""
      ) {
        seterrEndmonth("End Month and Start Month cannot be the same");
        isValid = false;
      }
    }

    if (
      startyear === endyear &&
      startmonth === endmonth &&
      startyear !== "" &&
      startmonth !== "" &&
      endyear !== "" &&
      endmonth !== ""
    ) {
      seterrEndmonth("End Month and Start Month cannot be the same");
      isValid = false;
    }
    
  
    const totalLength = JobDescription.reduce(
      (acc, curr) => acc + curr.length,
      0
    );
    if (isAnyJobDescriptionEmpty()) {
      seterrJobDescription('Please fill in all job descriptions');
      isValid = false; 
    } else if (totalLength > 550) {
      setCheckJobDescription(true);
      seterrJobDescription("Job Description should not exceed 550 characters");
      isValid = false;
    }
    if (iscurrentilyWorking) {
      setendmonth("");
      setendyear("");
    }

    return isValid;
  };

  useEffect(() => {
  }, [iscurrentilyWorking]);

  // balck input value
  const inputValueBlank = (e) => {
    SetLocationGetAutoComplet("");
    setJobtitle("");
    setEmploymentType("");
    setcompanyname("");
    setLocationType("");
    setstartmonth("");
    setstartyear("");
    setendmonth("");
    setendyear("");
    setJobDescription([]);
    setIscurrentilyWorking(false);
  };

  // error clear
  const clearError = (e) => {
    seterrLocationGetAutoComplet("");
    seterrJobtitle("");
    seterrEmploymentType("");
    seterrCompanyname("");
    seterrLocationType("");
    seterrStartmonth("");
    seterrStartyear("");
    seterrEndmonth("");
    seterrEndyear("");
    seterrJobDescription("");
    seterrIscurrentilyWorking("");

    setCheckJobtitler(false);
    setCheckLocationGetAutoComplet(false);
    setCheckEmploymentType(false);
    setCheckcompanyname(false);
    setCheckLocationType(false);
    setCheckstartmonth(false);
    setCheckstartyear(false);
    setCheckendmonth(false);
    setCheckendyear(false);
    setCheckJobDescription(false);
  };

  //  Add Experience Api
  const AddExperienceApi = () => {
    var data = {
      profile_id:props.profileId,
      company_name: companyname,
      job_title: jobtitle,
      job_type: EmploymentType,
      location_type: LocationType,
      currently_worked: iscurrentilyWorking,
      start_month: startmonth,
      start_year: startyear,
      end_month: iscurrentilyWorking ? "" : endmonth,
      end_year: iscurrentilyWorking ? "" : endyear,
      descriptionArray: JobDescription,
      location: locationGetAutoComplet,
    };
    postaddExperience(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
       
        ExperienceDataList();
        if (response.data) {
          Store.addNotification({
            title: response.data.message,
           
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          ExperienceDataList();
        }
    
      })
      .catch(function (error) {
      
      });
  };
  //

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!errMsg()) {
      return false;
    }
    if (experience_Id) {
      UpdateExpApi();
    } else {
      AddExperienceApi();
    }
    handleClose();
    handleCloseExperience();
  };

  // update exp api
  const UpdateExpApi = () => {
    var data = {
      profile_id:props.profileId,
      experience_id: experience_Id,
      company_name: companyname,
      job_title: jobtitle,
      job_type: EmploymentType,
      location_type: LocationType,
      currently_worked: iscurrentilyWorking,
      start_month: startmonth,
      start_year: startyear,
      end_month: iscurrentilyWorking ? "" : endmonth,
      end_year: iscurrentilyWorking ? "" : endyear,
      descriptionArray: JobDescription,
      location: locationGetAutoComplet,
    };
    postUpdateExpApi(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
           
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        setExperienceId(null);
        ExperienceDataList();
        if (response.data) {
          Store.addNotification({
            title: "Saved Successfully",
            
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        setExperienceId(null);
        ExperienceDataList();
        if (response.data) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
        }
      })
      .catch(function (error) {
      
      });
  };
  const handleClose = () => {
    inputValueBlank();
    clearError();
  };

  // get employment type profile
  useEffect(() => {
    getProfileEmploymentType()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
           
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
       
        setEmploymentTypeApi(response.data.data);
      })
      .catch(function (error) {
      });
    GetjobLocation();
  }, [experience_Id]);

  useEffect(() => {
    if(props.profileId != "") {
      ExperienceDataList();
    }
  }, [props.profileId]);

  // search company list
  const SearchCompanyList = (data) => {
    getSearchCompanies(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
           
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        setCompanylistapi(response.data.data);
      })
      .catch(function (error) {
      });
  };

  // experiencedatalist
  const ExperienceDataList = () => {
    getExperienceLists({profile_id: props.profileId})
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
          
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        
        setData(response.data.data);
        props.setExperienceCount(response.data.data);
      })
      .catch(function (error) {
       
      });
  };

  // delete api
  const DeleteApi = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true

    }).then((result) => {
      if (result.value) {
        var data = {
          profile_id:props.profileId,
          experience_id: id,
        };
        PostdeleteExperience(data)
          .then(function (response) {
            if(!response.data.status){
              Store.addNotification({
                title: response.data.message,
               
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__zoomIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
               
                dismiss: {
                  duration: 2000,
                  onScreen: false,
                },
              });
              return false
            }
           
            ExperienceDataList();
            Store.addNotification({
              title: response.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__zoomIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false,
              },
            });
          })
          .catch(function (error) {
          });
      }
    });
  };

  // edit api
  const EditApi = (id) => {
    setShowExperience(true);
    var editdata = data.find((e) => e._id == id);
    setExperienceId(editdata._id);
    setJobtitle(editdata.job_title);
    setEmploymentType(editdata.job_type);
    setcompanyname(editdata.company_name);
    SetLocationGetAutoComplet(editdata.location);
    setLocationType(editdata.location_type);
    setstartmonth(editdata.start_month);
    setstartyear(editdata.start_year);
    if (!editdata.currently_worked) {
      setendmonth(editdata.end_month);
      setendyear(editdata.end_year);
    } else {
      setendmonth("");
      setendyear("");
    }
    setIscurrentilyWorking(editdata.currently_worked);
    if(editdata.descriptionArray.length) {
      setJobDescription(editdata.descriptionArray);
    }
    else {
      setJobDescription([""]);
    }
  };

  // get job location
  const GetjobLocation = () => {
    getJobLocationTypeApi()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
           
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        setLocationTypeApiData(response.data.data);
      })
      .catch(function (error) {
      });
  };
  // Job Title

  const [searchJobtitle, setSearchJobtitle] = useState([]);

  // get title api
  const GetTitleApi = () => {
    var data = {
      type: "job_title",
    };
    GetJobTitle(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
           
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
          
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
      
        setSearchJobtitle(response.data.data);
      })
      .catch(function (error) {
       
      });
  };

  useEffect(() => {
    GetTitleApi();
  }, []);
  const [hideClass, setHideClass] = useState("auto-input hideClass");

  const experienceModal = () => {
    setexperienceModalShow(!experienceModalShow);
  };

// add field
  const handleAddFields = () => {
    setJobDescription(JobDescription.concat([""]))
  };

  // remove field
  const handleRemoveField = (id) => {
    setJobDescription(JobDescription.filter((ele, index) =>  {
      return id != index
    }))
  };

  // input change for discription
  const handleInputChange = (e, index) => {
    const newJobDescriptions = [...JobDescription]; 
    newJobDescriptions[index] = e.target.value; 
    setJobDescription(newJobDescriptions)
  };
  return (
    <>
      {data.length > 0 ? (
        <Row>
          {data.map((item, index) => {
            return (
              <Col className="p-0" md={12}>
                <Card
                  month={month}
                  subTitle={item.company_name}
                  title={item.job_title}
                  sMonth={item.start_month}
                  sYear={item.start_year}
                  eMonth={item.end_month}
                  eYear={item.end_year}
                  desc={item.description}
                  _id={item._id}
                  DeleteApi={DeleteApi}
                  EditApi={EditApi}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <></>
      )}
      {data.length < 2 ? (
        <div
          className="
          mb-2 d-flex justify-content-end"
        >
          <Tooltip content="Add Experience">
            <MyButton
              onClick={handleShowExperience}
              className={"p-2 exp_width"}
            >
              <img src={addcarddata} alt="add" className="notsaveiocn" />
              <div>Add Experience</div>
            </MyButton>
          </Tooltip>
        </div>
      ) : (
        ""
      )}

      <Modal
        show={showExperience}
        onHide={handleCloseExperience}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        centered
      >
        <Modal.Body className="p-0">
          <Row className="justify-content-center">
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
                <Col lg={12}>
                  <Row className="bg-white userdatapart border-radiustip">
                    <div className="col-12 mt-3 tipsbuttonset tipsbuttonset">
                      <div className="index_newcss">
                        <div className="contectinfoshowname mb-2">
                          <div>Experiences</div>
                        </div>
                        <div className="mb-2">
                          <span
                            onClick={experienceModal}
                            className="newdashbordbuttoncolor atstipsbuttonset"
                          >
                            {experienceModalShow ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                          </span>
                        </div>
                      </div>
                    </div>

                    <Col lg={12}>
                      <Form.Group className="mt-2">
                        <Form.Label className="label_class">
                          Job Title
                        </Form.Label>

                        <div className="jobcategory">
                          <Autocomplete
                            wrapperProps={{
                              className: `${hideClass} addnewcssautocompletedataset`,
                            }}
                            wrapperStyle={{
                              display: "block",
                             
                              position:"relative"
                            }}
                            className={
                              checkjobtitle
                                ? "form-control borderdrop "
                                : "form-control borderdrop  input_radius"
                            }
                            getItemValue={(item) => item.label}
                            items={[{ label: jobtitle }].concat(
                              searchJobtitle
                                .filter((ele) =>
                                  ele.name
                                    .toLowerCase()
                                    .includes(jobtitle?.toLowerCase())
                                )
                                .map((ele) => {
                                  return { label: ele.name };
                                })
                            )}
                            renderItem={(item, isHighlighted) => (
                              <div
                                style={{
                                  background: isHighlighted
                                    ? "lightgray"
                                    : "white",
                                }}
                              >
                                {item.label}
                              </div>
                            )}
                            value={jobtitle}
                            inputProps={{
                              placeholder:
                                "What Position Are You Looking For ?",
                              onBlur: (e) => {
                                setJobtitle(e.target.value);
                              },
                            }}
                            onChange={(e) => {
                              setJobtitle(e.target.value);
                              if (e.target.value == "") {
                                setHideClass("auto-input hideClass");
                              } else {
                                var hasSuggestion = searchJobtitle.find((ele) =>
                                  ele.name
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase())
                                );
                                if (hasSuggestion) {
                                  setHideClass("auto-input");
                                } else {
                                  setHideClass("auto-input hideClass");
                                }
                              }
                            }}
                            onSelect={(e) => {
                              setJobtitle(e);
                            }}
                            types={["(regions)"]}
                          />
                        </div>

                        <span className="err_msg">{errjobtitle}</span>
                      </Form.Group>
                    </Col>
                   
                    <Col lg={6} className="mb-1">
                      <Form.Group controlId="companyname">
                        <Form.Label className="mt-1 label_class">
                          Company Name
                        </Form.Label>
                        <Autocomplete
                          wrapperProps={{
                            className: checkcompanyname
                              ? " auto-input addnewcssautocompletedataset"
                              : "auto-input input_radius addnewcssautocompletedataset",
                          }}
                          wrapperStyle={{
                            display: "block",
                            position:"relative"
                          }}
                          getItemValue={(item) => item.label}
                          items={
                            companyname.length > 0
                              ? companylistapi
                                  .filter((ele) =>
                                    ele.company_name
                                      .toLowerCase()
                                      .includes(companyname.toLowerCase())
                                  )
                                  .map((ele) => {
                                    return { label: ele.company_name };
                                  })
                              : []
                          }
                          renderItem={(item, isHighlighted) => (
                            <div
                              style={{
                                background: isHighlighted
                                  ? "lightgray"
                                  : "white",
                              }}
                            >
                              {item.label}
                            </div>
                          )}
                          value={companyname}
                          onChange={(e) => {
                            SearchCompanyList(e.target.value);
                            setcompanyname(e.target.value);
                          }}
                          onSelect={(e) => setcompanyname(e)}
                          inputProps={{
                            placeholder:
                              "Enter Company Name",
                          }}
                        />

                        <span className="err_msg">{errcompanyname}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                      <Form.Group>
                        <label
                          className="mt-1 custom label_class"
                          htmlFor="Employment"
                        >
                          <div>Employment Type</div>
                        </label>
                        <select
                          className={
                            checkEmploymentType
                              ? "form-control borderdrop"
                              : "form-control borderdrop  input_radius"
                          }
                          id="Employment"
                          value={EmploymentType}
                          onChange={(e) => setEmploymentType(e.target.value)}
                        >
                          {" "}
                          <option value="">Select</option>
                          {employmentTypeApi.map((e) => {
                            return <option value={e.name}>{e.name}</option>;
                          })}
                        </select>
                        <span className="err_msg">{errEmploymentType}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                      <Form.Group>
                        <label htmlFor="Location" className="mt-1 label_class">
                          Location
                        </label>
                        <Autocompletemap
                          className={
                            checklocationGetAutoComplet
                              ? "form-control newsearchinputaddborderradius"
                              : "form-control  input_radius newsearchinputaddborderradius"
                          }
                          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                          options={{ types: ["geocode"] }}
                          value={locationGetAutoComplet}
                          onChange={(e) =>
                            SetLocationGetAutoComplet(e.target.value)
                          }
                          onPlaceSelected={(place) => {
                            SetLocationGetAutoComplet(place.formatted_address);
                          }}
                        />
                        <span className="err_msg">
                          {errlocationGetAutoComplet}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                      <Form.Group>
                        <label
                          className="custom label_class mt-1"
                          htmlFor="Employment"
                        >
                          <div>Location Type</div>
                        </label>
                        <select
                          className={
                            checkLocationType
                              ? "form-control borderdrop "
                              : "form-control borderdrop  input_radius"
                          }
                          id="Employment"
                          value={LocationType}
                          onChange={(e) => setLocationType(e.target.value)}
                        >
                          {" "}
                          <option value=""> Select</option>
                          {locationTypeApiData.map((e) => {
                            return <option value={e}>{e}</option>;
                          })}
                        </select>
                        <span className="err_msg">{errLocationType}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                      <Form.Group controlId="startmonth">
                        <Form.Label className="mt-1 label_class">
                          Start Month
                        </Form.Label>
                        <Form.Control
                          as="select"
                          className={
                            checkstartmonth
                              ? " borderdrop"
                              : "  borderdrop input_radius"
                          }
                          name="startmonth"
                          value={startmonth}
                          onChange={(e) => setstartmonth(e.target.value)}
                        >
                          <option value="">Select</option>
                          {Object.keys(month).map((option, index) => (
                            <option key={option} value={index + 1}>
                              {month[option]}
                            </option>
                          ))}
                        </Form.Control>
                        <span className="err_msg">{errstartmonth}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                      <Form.Group controlId="startyear">
                        <Form.Label className="mt-1 label_class">
                          Start Year
                        </Form.Label>
                        <Form.Control
                          className={
                            checkstartyear
                              ? " newsearchinputaddborderradius"
                              : " input_radius newsearchinputaddborderradius"
                          }
                          type="text"
                          placeholder="Enter Start Year"
                          name="startyear"
                          value={startyear}
                          onChange={(e) => setstartyear(e.target.value)}
                        />
                        <span className="err_msg">{errstartyear}</span>
                      </Form.Group>
                    </Col>
                    
                      <div className="form-check mx-3">
                        <label className="form-check-label text-muted label_class">
                          <input
                            type="checkbox"
                            className="form-check-input  input_radius"
                            checked={iscurrentilyWorking}
                            onChange={(e) =>
                              setIscurrentilyWorking(!iscurrentilyWorking)
                            }
                          />
                          <i className="input-helper"></i>I am currently working
                          in this role
                        </label>
                        <span className="err_msg">
                          {" "}
                          {erriscurrentilyWorking}
                        </span>
                      </div>
                    
                    <Col lg={12}></Col>
                    {iscurrentilyWorking ? (
                      ""
                    ) : (
                      <>
                        <Col lg={6} className="mb-1">
                          <Form.Group controlId="endmonth">
                            <Form.Label className="mt-1 label_class">
                              End Month
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="endmonth"
                              className={
                                checkendmonth
                                  ? " borderdrop"
                                  : " borderdrop input_radius"
                              }
                              value={endmonth}
                              onChange={(e) => setendmonth(e.target.value)}
                            >
                              <option value="">Select</option>
                              {Object.keys(month).map((option, index) => (
                                <option key={option} value={index + 1}>
                                  {month[option]}
                                </option>
                              ))}
                            </Form.Control>
                            <span className="err_msg">{errendmonth}</span>
                          </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-1">
                          <Form.Group controlId="endyear">
                            <Form.Label className="mt-1 label_class">
                              End Year
                            </Form.Label>
                            <Form.Control
                              className={
                                checkendyear
                                  ? " newsearchinputaddborderradius"
                                  : " input_radius newsearchinputaddborderradius"
                              }
                              type="text"
                              placeholder="Enter End Year"
                              name="endyear"
                              value={endyear}
                              onChange={(e) => setendyear(e.target.value)}
                            />
                            <span className="err_msg">{errendyear}</span>
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    
                    <Col lg={12} className="mt-2">
                      <b>What were your responsibilites and accomplishments?</b>
                    </Col>
                    <Col xs={12}>
                        {JobDescription?.map((e, index) => {
                          return (
                            <Row>
                              <Col lg={9} md={9} xs={8} className="px-0">
                                <Form.Group className="my-1">
                                  <Form.Control
                                    type="text"
                                    placeholder="Please type here.."
                                    value={JobDescription[index]}
                                    className={
                                      checkJobDescription
                                        ? " newsearchinputaddborderradius"
                                        : " input_radius newsearchinputaddborderradius"
                                    }
                                    onChange={(e) => {
                                      handleInputChange(e, index)
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                            {JobDescription.length < 3 && (
                              <Col lg={1} md={1} xs={1} className="my-2 mr-2">
                                <img
                                  src={addcarddata}
                                  alt="add"
                                  className="plus_iconexp addphotopng"
                                  onClick={handleAddFields}
                                />
                              </Col>
                            ) }

                              {JobDescription.length > 1 && (
                              <Col lg={1} md={1} xs={1} className="my-2 ">
                                <img
                                  src={companydataremove}
                                  alt="remove"
                                  className="minus_iconexp addphotopng "
                                  onClick={() => handleRemoveField(index)}
                                />
                              </Col>
                              )}
                            </Row>
                          );
                        })}
                      <span className="err_msg">{errJobDescription}</span>
                    </Col>

                    <Col>
                      <div className="setmodalfooterbutton">
                        <Button
                          variant="danger"
                          className="contact_radius mr-2"
                          onClick={handleCloseExperience}
                        >
                          Cancel
                        </Button>
                        <MyButton type={"submit"} onClick={handleSubmit}>
                          Save
                        </MyButton>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div className="position-relative">
                {experienceModalShow ? (
                  <Col
                    lg={12}
                    className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips"
                  >
                    <div className="info_tip3 experienctipsheightset border-radiustip">
                      {/* <h5 className="tips">Tips</h5>
                      <hr className="m-0"></hr> */}

                      <div className="mt-2">
                        <div>
                          <b>
                            Maximizing Impact with Your Experience Section on
                            Turn-To
                          </b>
                        </div>
                        <div className="mt-3 mb-3">
                          The experience section is where you get to demonstrate
                          your professional journey and accomplishments. On
                          Turn-To, detailing your work history effectively can
                          set you apart from other candidates by highlighting
                          how you add value in your roles.
                        </div>
                        <div>
                          <b>
                            Tips for Crafting a Compelling Experience Section
                          </b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1', '2', '3', '4']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Emphasize Achievements:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Beyond listing job responsibilities, showcase
                                  specific achievements. Use quantifiable
                                  results where possible, such as "Increased
                                  sales by 20%" or "Reduced operational costs by
                                  15%.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Detail Your Role:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  For each position, briefly describe your main
                                  responsibilities, emphasizing how your work
                                  contributed to the organization's goals.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Relevance is Key:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Tailor your experience entries to the job
                                  you're applying for. Highlight the most
                                  relevant experiences that align with the
                                  role's requirements.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Use Action Verbs:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Start each point with a strong action verb
                                  such as "Led," "Managed," "Developed," or
                                  "Implemented" to make your contributions stand
                                  out.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Keep It Concise:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  While it's important to be detailed, ensure
                                  your descriptions are concise and to the
                                  point. Aim for clear and brief bullet points
                                  for each role.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div>
                          <b> Project Manager, ABC Corp, Jan 2020 - Present</b>
                        </div>
                        <div className="text-justify">
                          - Led a team of 10 in delivering a complex software
                          development project, completing it 2 months ahead of
                          schedule.
                        </div>
                        <div className="text-justify">
                          - Managed project budgets, achieving a 10% cost saving
                          by optimizing resources.
                        </div>
                        <div className="text-justify">
                          - Enhanced client satisfaction by 30% through
                          strategic improvements in the customer feedback
                          process.
                        </div>
                        <div className="mt-2">
                          <b>
                            {" "}
                            Marketing Specialist, XYZ Inc, May 2017 - Dec 2019
                          </b>
                        </div>
                        <div className="text-justify">
                          - Developed and executed a digital marketing strategy
                          that increased online engagement by 40%.
                        </div>
                        <div className="text-justify">
                          - Organized trade shows and events, resulting in a 25%
                          increase in lead generation.
                        </div>
                        <div className="text-justify">
                          - Collaborated with the sales team to create targeted
                          marketing materials, boosting conversion rates by 15%.
                        </div>
                        <div className="mt-3">
                          <b>
                            Remember, the experience section is your opportunity
                            to showcase how you've made a difference in your
                            past roles. Tailoring this section to reflect your
                            achievements and how they align with the job you're
                            applying for on Turn-To can significantly enhance
                            your visibility to potential employers.
                          </b>
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Experience;
