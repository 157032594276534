import React from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import Card from "../user-profile-card/Card";
import MyButton from "../../common/Button/MyButton";
import Swal from "sweetalert2";
import Autocompletemap from "react-google-autocomplete";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import footerpagepng from "../../../../../Component/icontopng/footerpagepng.png";
import companydataremove from "../../../../../Component/icontopng/companydataremove.png";
import { MdOutlineClose } from "react-icons/md";
import {
  PostdeleteEducation,
  getEducationList,
  geteducationLevel,
  getfieldofstudy,
  postUpdateEducation,
  postaddEducation,
} from "../../../../../Api/Api";
import { Store } from "react-notifications-component";
import addcarddata from "../../../../../Component/icontopng/addcarddata.png";
import Autocomplete from "react-autocomplete";
import { useSocket } from "../../../../../context/SocketProvider";
import Tooltip from "react-tooltip-lite";

const month = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

function Education(props) {
  const { socket } = useSocket();
  const [data, setData] = useState([]);
  const formData = new FormData();

  //  State
  
  const [schoolCollegeName, setschoolCollegeName] = useState("");
  const [fieldOfStudyName, setfieldOfStudyName] = useState("");

  const [degreeTitle, setDegreeTitle] = useState("");
  const [fieldOfStudy, setFieldOfStudy] = useState([]);
  const [startmonth, setstartmonth] = useState("");
  const [startyear, setstartyear] = useState("");
  const [endmonth, setendmonth] = useState("");
  const [endyear, setendyear] = useState("");
  const [educationDescription, setEducationDescription] = useState([]);
  const [iscurrentilyStudy, setIscurrentilyStudy] = useState(false);
  const [educationTypeApiData, setEducationTypeApiData] = useState([]);
  const [educationId, seteducationId] = useState(null);
  const [locationGetAutoComplet, SetLocationGetAutoComplet] = useState("");
  const [schoolCollegeNameData, setschoolCollegeNameData] = useState([]);
  const [iscontactLoading, setContactIsLoading] = useState(false);

  const isAnyJobDescriptionEmpty = () => {
    return educationDescription.some(description => description.trim() === '');
  };

  // validation
  const [errschoolCollegeName, seterrSchoolCollegeName] = useState("");
  const [errdegreeTitle, seterrDegreeTitle] = useState("");
  const [errfieldOfStudy, seterrFieldOfStudy] = useState("");
  const [errstartmonth, seterrStartmonth] = useState("");
  const [errstartyear, seterrStartyear] = useState("");
  const [errendmonth, seterrEndmonth] = useState("");
  const [errendyear, seterrEndyear] = useState("");
  const [erreducationDescription, seterrEducationDescription] = useState("");
  const [erriscurrentilyStudy, seterrIscurrentilyStudy] = useState("");
  const [errlocationGetAutoComplet, seterrLocationGetAutoComplet] =
    useState("");
  const [documentLink,setDocumentLink] = useState("")
  const [showEducation, setShowEducation] = useState(false);

  // close model
  const handleCloseEdication = () => {
    setShowEducation(false);
    seteduactionModalShow(false);
    setDocumentLink("")
    setschoolCollegeName("");
    setDegreeTitle("");
    // setFieldOfStudy("");
    setstartmonth("");
    setstartyear("");
    setendmonth("");
    setendyear("");
    setEducationDescription([]);
    setIscurrentilyStudy(false);
    SetLocationGetAutoComplet("");
    seteducationId(null);
    clearError()
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
  };
  const handleShowEducation = () => {
    setEducationDescription([""]) 
    setShowEducation(true);
    setfieldOfStudyName("")
  }
  // for border
  const [checkdegreeTitle, setCheckDegreeTitle] = useState(false);
  const [checkfieldOfStudy, setCheckFieldOfStudy] = useState(false);
  const [checkstartmonth, setCheckstartmonth] = useState(false);
  const [checkstartyear, setCheckstartyear] = useState(false);
  const [checkendmonth, setCheckendmonth] = useState(false);
  const [checkendyear, setCheckendyear] = useState(false);
  const [checkeducationDescription, setCheckEducationDescription] =
    useState(false);

  const [eduactionModalShow, seteduactionModalShow] = useState(false);

  const [checklocationGetAutoComplet, setCheckLocationGetAutoComplet] =
    useState(false);
  const [titleInput, setTitleInput] = useState(useRef(""));
  const OnFouceOut = () => {
    if (titleInput?.current) {
      titleInput?.current.blur();
    }
  };

  useEffect(()=>{
    window.addEventListener("scroll", OnFouceOut);
    return () => {
      window.removeEventListener("scroll", OnFouceOut);
    };
  },[])
  const startDate = new Date(startyear, startmonth - 1); 
  const endDate = new Date(endyear, endmonth - 1);

  // validation
  const errMsg = () => {
    const d = new Date();
    let year = d.getFullYear();
    let currentMonth = d.getMonth() + 1;

    clearError();
    var isValid = true;
    if (locationGetAutoComplet.trim() === "") {
      setCheckLocationGetAutoComplet(true);
      seterrLocationGetAutoComplet("Please Type Location");
      isValid = false;
    }
    if (schoolCollegeName.trim() == "") {
      seterrSchoolCollegeName("Please Enter School Name");
      isValid = false;
    } else if (schoolCollegeName.length > 100) {
      seterrSchoolCollegeName("School Name should not exceed 100 characters");
      isValid = false;
    }

    if (degreeTitle == "") {
      setCheckDegreeTitle(true);
      seterrDegreeTitle("Please Select DegreeTitle");
      isValid = false;
    } else if (degreeTitle.length > 100) {
      seterrDegreeTitle("DegreeTitle should not exceed 100 characters");
      isValid = false;
    }
    if (fieldOfStudyName.trim() == "") {
      setCheckFieldOfStudy(true);
      seterrFieldOfStudy("Please Enter Field Of Study");
      isValid = false;
    } else if (fieldOfStudyName.length > 100) {
      seterrFieldOfStudy("Field Of Study should not exceed 100 characters");
      isValid = false;
    }
    if (startmonth == "") {
      setCheckstartmonth(true);
      seterrStartmonth("Please Select Start Month");
      isValid = false;
    }
    if (startyear === "") {
      setCheckstartyear(true);
      seterrStartyear("Please Select Start Year");
      isValid = false;
    }else if (startyear === "" || startyear === "0000") {
      setCheckstartyear(true);
      seterrStartyear("Please Select a valid Start Year");
      isValid = false;
    } else if (!/^\d{4}$/.test(startyear)) {
      setCheckstartyear(true);
      seterrStartyear(
        "Please enter a valid 4-digit non-negative number for Start Year"
      );
      isValid = false;
    } else {
      if (parseInt(startyear) > year) {
        setCheckstartyear(true);
        seterrStartyear("Start Year cannot be in the future");
        isValid = false;
      } else if (parseInt(startyear) === year && startmonth > currentMonth) {
        seterrStartmonth("Start Month cannot be in the future");
        isValid = false;
      } else if (startyear == year && startmonth == currentMonth) {
        isValid = true;
      }
    }
    
    if (!iscurrentilyStudy) {
      if (endmonth == "") {
        setCheckendmonth(true);
        seterrEndmonth("Please Select End Month");
        isValid = false;
      }
      if (endyear === "") {
        setCheckendyear(true);
        seterrEndyear("Please Select End Year");
        isValid = false;
      } else if (parseInt(endyear) > year) {
        seterrEndyear("End Year cannot be in the future");
        isValid = false;
      } else if (parseInt(endyear) === year && endmonth > currentMonth) {
        seterrEndmonth("End Month cannot be in the future");
        isValid = false;
      }
    
     

    else if (startDate >= endDate) {
        seterrEndmonth("End date should be after the Start date");
        isValid = false;
      }
    
      if (
        startyear === endyear &&
        startmonth === endmonth &&
        startyear !== "" &&
        startmonth !== "" &&
        endyear !== "" &&
        endmonth !== ""
      ) {
        seterrEndmonth("End Month and Start Month cannot be the same");
        isValid = false;
      }
    }
    
    
    const totalLength = educationDescription.reduce((acc, curr) => acc + curr.length, 0);
    if (isAnyJobDescriptionEmpty()) {
      seterrEducationDescription('Please Enter EducationDescription');
      isValid = false; 
    } else if (totalLength > 550) {
      setCheckEducationDescription(true);
      seterrEducationDescription("EducationDescription should not exceed 550 characters");
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
  }, [iscurrentilyStudy]);

  // balck input value
  const inputValueBlank = (e) => {
    setschoolCollegeName("");
    setfieldOfStudyName("")
    setDegreeTitle("");
    // setFieldOfStudy("");
    setstartmonth("");
    setstartyear("");
    setendmonth("");
    setendyear("");
    setEducationDescription([]);
    setIscurrentilyStudy(false);
    SetLocationGetAutoComplet("");
  };

  // clear error
  const clearError = (e) => {
    seterrLocationGetAutoComplet("");
    seterrSchoolCollegeName("");
    seterrDegreeTitle("");
    seterrFieldOfStudy("");
    seterrStartmonth("");
    seterrStartyear("");
    seterrEndmonth("");
    seterrEndyear("");
    seterrEducationDescription("");
    seterrIscurrentilyStudy("");

    setCheckLocationGetAutoComplet(false);
    setCheckDegreeTitle(false);
    setCheckFieldOfStudy(false);
    setCheckstartmonth(false);
    setCheckstartyear(false);
    setCheckendmonth(false);
    setCheckendyear(false);
    setCheckEducationDescription(false);
  };

  // Add Education Api

  const AddEducationApi = () => {
    formData.append("profile_id", props.profileId)
    formData.append("institude_name", schoolCollegeName);
    formData.append("degree", degreeTitle);
    formData.append("field_of_study", fieldOfStudyName);
    formData.append("currently_study", iscurrentilyStudy);
    formData.append("start_month", startmonth);
    formData.append("start_year", startyear);
    formData.append("end_month", endmonth);
    formData.append("end_year", endyear);
    formData.append("file", acceptedFiles[0]);
    educationDescription.map((e)=>{
      formData.append("descriptionArray[]", e);
    })
    formData.append("location", locationGetAutoComplet);
    postaddEducation(formData, {
      headers: {
        "Content-Type": "multipart/form-data", 
      },
    })
      .then(function (response) {
        setContactIsLoading(false);
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        getedulist();

       
        clearState();
        if (response.data) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          handleCloseEdication();
          
        }
      })
      .catch(function (error) {
        setContactIsLoading(false);
      });
  };

  // update education
  const updateEducation = () => {
    formData.append("profile_id", props.profileId)
    formData.append("institude_name", schoolCollegeName);
    formData.append("degree", degreeTitle);
    formData.append("field_of_study", fieldOfStudyName);
    formData.append("currently_study", iscurrentilyStudy);
    formData.append("start_month", startmonth);
    formData.append("start_year", startyear);
    formData.append("end_month", endmonth);
    formData.append("end_year", endyear);
    formData.append("file", acceptedFiles[0]);
    educationDescription.map((e)=>{
      formData.append("descriptionArray[]", e);
    })
    formData.append("location", locationGetAutoComplet);
    formData.append("education_id", educationId);

    postUpdateEducation(formData, {
      headers: {
        "Content-Type": "multipart/form-data", 
      },
    })
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        getedulist();
       
                seteducationId(null);
        clearState();

        handleCloseEdication();
                seteducationId(null);
        if (response.data) {
          Store.addNotification({
            title: "Saved Successfully",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
        }
        setContactIsLoading(false);
        
      })
      .catch(function (error) {
        setContactIsLoading(false);
      });
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path} className="file_decoration mt-3">
      {file.path}
    </li>
  ));

  // clear state 
  const clearState = () => {
    setschoolCollegeName("");
    setfieldOfStudyName("")
    setDegreeTitle("");
    // setFieldOfStudy("");
    setstartmonth("");
    setstartyear("");
    setendmonth("");
    setendyear("");
    setEducationDescription([]);
  };

  // for submit
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!errMsg()) {
      return false;
    }
    setContactIsLoading(true);
    if (educationId) {
      updateEducation();
    } else {
      AddEducationApi();
    }
    handleClose();
    handleCloseEdication();
    clearState();
  };

  // handleclose
  const handleClose = () => {
    inputValueBlank();
    clearError();
  };

  useEffect(() => {
    localStorage.setItem("education", JSON.stringify(data));
  }, [data]);

  // get education list
  function getedulist() {
    getEducationList({profile_id: props.profileId})
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
       
        setData(response.data.data);
        props.setEducationCount(response.data.data)
      })
      .catch(function (error) {
      });
  }

  useEffect(() => {
    if(props.profileId != "") {
      getedulist();
      geteducationLevelApi();
    }
  }, [props.profileId]);

  // deleteApi
  const DeleteApi = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true

    }).then((result) => {
      if (result.value) {
        var data = {
          education_id: id,
          profile_id: props.profileId
        };
        PostdeleteEducation(data)
          .then(function (response) {
            if(!response.data.status){
              Store.addNotification({
                title: response.data.message,
                
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__zoomIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                
                dismiss: {
                  duration: 2000,
                  onScreen: false,
                },
              });
              return false
            }
            
            getedulist();
            Store.addNotification({
              title: response.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__zoomIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false,
              },
            });
          })
          .catch(function (error) {
          });
      }
    });
  };

  // geteducationLevelApi
  const geteducationLevelApi = () => {
    geteducationLevel()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        setEducationTypeApiData(response.data.data);
      })
      .catch(function (error) {
      });
  };

  // field of study

  useEffect(() => {
    getfieldofstudyApi()
  }, []);

  const getfieldofstudyApi = () => {
    getfieldofstudy()
      .then(function (response) {
        setFieldOfStudy(response.data.data)
      })
      .catch(function (error) {
      });
  };

  // edit
  const EditApi = (id) => {
    handleShowEducation();
    var editdata = data.find((e) => e._id == id);
    setschoolCollegeName(editdata.institude_name);
    setDegreeTitle(editdata.degree);
    setfieldOfStudyName(editdata.field_of_study);
    setstartmonth(editdata.start_month);
    setstartyear(editdata.start_year);
    setstartyear(editdata.start_year);
    SetLocationGetAutoComplet(editdata.location);
    if(editdata.descriptionArray.length) {
      setEducationDescription(editdata.descriptionArray);
    }
    else {
      setEducationDescription([""]);
    }
    seteducationId(editdata._id);
    if (!editdata.currently_study) {
      setendmonth(editdata.end_month);
      setendyear(editdata.end_year);
    } else {
      setendmonth("");
      setendyear("");
    }
    setIscurrentilyStudy(editdata.currently_study);
    setDocumentLink(editdata.file)
  };

  useEffect(() => {
    socket.emit("school-college",{},(res) => {
      setschoolCollegeNameData(res.data);
    });
  }, []);

  

  const educationModal = () => {
    seteduactionModalShow(!eduactionModalShow);
  };

  // add fields
  const handleAddFields = () => {
    setEducationDescription(educationDescription.concat([""]))
  };

  // remove field
  const handleRemoveField = (id) => {
    setEducationDescription(educationDescription.filter((ele, index) =>  {
      return id != index
    }))
  };

  // educationDescriptions input change
  const handleInputChange = (e, index) => {
    const neweducationDescriptions = [...educationDescription]; 
    neweducationDescriptions[index] = e.target.value; 
    setEducationDescription(neweducationDescriptions)
  };


  return (
    <>
      {data.length > 0 ? (
        <Row>
          {data.map((item, index) => {
            return (
              <Col className="p-0" md={12}>
                <Card
                  month={month}
                  subTitle={item.institude_name}
                  title={item.degree}
                  sMonth={item.start_month}
                  sYear={item.start_year}
                  eMonth={item.end_month}
                  eYear={item.end_year}
                  desc={item.description}
                  file={item.file}
                  _id={item._id}
                  DeleteApi={DeleteApi}
                  EditApi={EditApi}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <></>
      )}
      {data.length < 2 ?
      <div
        className="
          mb-2 d-flex justify-content-end"
      >
 <Tooltip content="Add Education">
        <MyButton onClick={handleShowEducation} className={"p-2 exp_width"}>
          <img src={addcarddata} alt="add" className="notsaveiocn" />
          <div>Add Education</div>
        </MyButton>
        </Tooltip>
      </div>
      :""}

      <Modal
        show={showEducation}
        onHide={handleCloseEdication}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        centered
      >
        <Modal.Body className="p-0">
          <Row className="justify-content-center">
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
                <Col lg={12}>
                  <Row className="bg-white userdatapart border-radiustip">
                  <div className="col-12 mt-3 tipsbuttonset tipsbuttonset">
                      <div className="index_newcss">
                    <div className="contectinfoshowname mb-2">
                      <div>Education</div>
                    
                    </div>
                    <div className="mb-2">
                    <span
                        onClick={educationModal}
                        className="newdashbordbuttoncolor atstipsbuttonset"
                      >
                      {eduactionModalShow ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                      </span>
                    </div>
                    
                    </div>
                    </div>
                
                  
                    <Col lg={6} className="mb-2 mt-2">
                      <Form.Group controlId="schoolclgname">
                        <Form.Label>Trade School/College Name/University</Form.Label>
                        <div className="jobcategory">
                          <Autocomplete
                            wrapperProps={{ className: "auto-input addnewcssautocompletedataset" }}
                            wrapperStyle={{
                              display: "block",
                              position:"relative"
                            }}
                            getItemValue={(item) => item.label}
                            items={schoolCollegeNameData
                              .filter((ele) =>
                                ele.name
                                  .toLowerCase()
                                  .includes(schoolCollegeName.toLowerCase())
                              )
                              .map((ele) => {
                                return { label: ele.name };
                              })}
                            renderItem={(item, isHighlighted) => (
                              <div
                                style={{
                                  background: isHighlighted ? "lightgray" : "white",
                                }}
                              >
                                {item.label}
                              </div>
                            )}
                            value={schoolCollegeName}
                            onChange={(e) => setschoolCollegeName(e.target.value)}
                            onSelect={(e) => setschoolCollegeName(e)}
                            inputProps={{
                              placeholder:
                                "Enter Trade School/College Name/University",
                            }}
                          />
                        </div>
                        <span className="err_msg">{errschoolCollegeName}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-2 mt-lg-2">
                      <Form.Group controlId="degreetitle">
                        <Form.Label>Degree Title</Form.Label>
                        <Form.Control
                          as="select"
                          className={
                            checkdegreeTitle
                              ? "borderdrop "
                              : "borderdrop  input_radius"
                          }
                          name="degreeTitle"
                          value={degreeTitle}
                          onChange={(e) => setDegreeTitle(e.target.value)}
                        >
                          <option value="">Select</option>

                          {educationTypeApiData.map((e) => {
                            return <option value={e.name}>{e.name}</option>;
                          })}
                        </Form.Control>
                        <span className="err_msg">{errdegreeTitle}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-2">
                      <Form.Group controlId="FieldOfStudy">
                        <Form.Label>Field Of Study </Form.Label>
                        <div className="jobcategory">
                          <Autocomplete
                            wrapperProps={{ className: "auto-input addnewcssautocompletedataset" }}
                            wrapperStyle={{
                              display: "block",
                              position:"relative"
                            }}
                            getItemValue={(item) => item.label}
                            items={fieldOfStudy?.filter((ele) =>
                                ele.name
                                  .toLowerCase()
                                  .includes(fieldOfStudyName.toLowerCase())
                              )
                              .map((ele) => {
                                return { label: ele.name };
                              })}
                            renderItem={(item, isHighlighted) => (
                              <div
                                style={{
                                  background: isHighlighted ? "lightgray" : "white",
                                }}
                              >
                                {item.label}
                              </div>
                            )}
                            value={fieldOfStudyName}
                            onChange={(e) => setfieldOfStudyName(e.target.value)}
                            onSelect={(e) => setfieldOfStudyName(e)}
                            inputProps={{
                              placeholder:
                                "Enter Field Of Study",
                            }}
                          />
                        </div>
                        <span className="err_msg">{errfieldOfStudy}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                      <Form.Group>
                        <label htmlFor="Location" className="mt-1 label_class">
                          Location
                        </label>
                        <Autocompletemap
                          className={
                            checklocationGetAutoComplet
                              ? "form-control newsearchinputaddborderradius"
                              : "form-control  input_radius newsearchinputaddborderradius"
                          }
                          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                          options={{ types: ["geocode"] }}
                          value={locationGetAutoComplet}
                          onChange={(e) =>
                            SetLocationGetAutoComplet(e.target.value)
                          }
                          onPlaceSelected={(place) => {
                            SetLocationGetAutoComplet(place.formatted_address);
                          }}
                        />
                        <span className="err_msg">{errlocationGetAutoComplet}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-2">
                      <Form.Group controlId="startmonth">
                        <Form.Label>Start Month</Form.Label>
                        <Form.Control
                          as="select"
                          className={
                            checkstartmonth
                              ? "borderdrop "
                              : "borderdrop  input_radius"
                          }
                          name="startMonth"
                          value={startmonth}
                          onChange={(e) => setstartmonth(e.target.value)}
                        >
                          <option value="">Select</option>
                          {Object.keys(month).map((option, index) => (
                            <option key={option} value={index + 1}>
                              {month[option]}
                            </option>
                          ))}
                        </Form.Control>
                        <span className="err_msg">{errstartmonth}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-2">
                      <Form.Group controlId="startyear">
                        <Form.Label>Start Year</Form.Label>
                        <Form.Control
                          className={
                            checkstartyear ? " newsearchinputaddborderradius" : " input_radius newsearchinputaddborderradius"
                          }
                          type="text"
                          placeholder="Enter Start Year"
                          name="startYear"
                          value={startyear}
                          onChange={(e) => setstartyear(e.target.value)}
                        />
                        <span className="err_msg">{errstartyear}</span>
                      </Form.Group>
                    </Col>
                    
                      <div className="form-check mx-3">
                        <label className="form-check-label text-muted">
                          <input
                            type="checkbox"
                            className="form-check-input  input_radius"
                            checked={iscurrentilyStudy}
                            onChange={(e) =>
                              setIscurrentilyStudy(!iscurrentilyStudy)
                            }
                          />
                          <i className="input-helper"></i>I am currently studying in
                          this role
                        </label>
                        <span className="err_msg"> {erriscurrentilyStudy}</span>
                      </div>
                      <Col lg={12}></Col>
                    {iscurrentilyStudy ? (
                      ""
                    ) : (
                      <>
                        <Col lg={6} className="mb-2">
                          <Form.Group controlId="endmonth">
                            <Form.Label>End Month</Form.Label>
                            <Form.Control
                              as="select"
                              className={
                                checkendmonth
                                  ? "borderdrop "
                                  : "borderdrop  input_radius"
                              }
                              name="endMonth"
                              value={endmonth}
                              onChange={(e) => setendmonth(e.target.value)}
                            >
                              <option value="">Select</option>
                              {Object.keys(month).map((option, index) => (
                                <option key={option} value={index + 1}>
                                  {month[option]}
                                </option>
                              ))}
                            </Form.Control>
                            <span className="err_msg">{errendmonth}</span>
                          </Form.Group>
                        </Col>
                        <Col lg={6} className="mb-2">
                          <Form.Group controlId="endyear">
                            <Form.Label>End Year</Form.Label>
                            <Form.Control
                              className={
                                checkendyear ? " newsearchinputaddborderradius" : " input_radius newsearchinputaddborderradius"
                              }
                              type="text"
                              placeholder="Enter End Year"
                              name="endYear"
                              value={endyear}
                              onChange={(e) => setendyear(e.target.value)}
                            />
                            <span className="err_msg">{errendyear}</span>
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    <Col lg={12} className="mt-2"> <b>What are your academic achievements?</b></Col>
                    <Col xs={12}>
                    {educationDescription?.map((e, index) => {
                          return (
                            <Row>
                              <Col lg={9} md={9} xs={8} className="px-0">
                                <Form.Group className="my-1">
                                  <Form.Control
                                    type="text"
                                    placeholder="Please type here.."
                                    value={educationDescription[index]}
                                    className={
                                      checkeducationDescription
                                        ? " newsearchinputaddborderradius"
                                        : " input_radius newsearchinputaddborderradius"
                                    }
                                    onChange={(e) => {
                                      handleInputChange(e, index)
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                            {educationDescription.length < 3 && (
                              <Col lg={1} md={1} xs={1} className="my-2 mr-2">
                                <img
                                  src={addcarddata}
                                  alt="add"
                                  className="plus_iconexp addphotopng"
                                  onClick={handleAddFields}
                                />
                              </Col>
                            ) }

                              {educationDescription.length > 1 && (
                              <Col lg={1} md={1} xs={1} className="my-2 ">
                                <img
                                  src={companydataremove}
                                  alt="remove"
                                  className="minus_iconexp addphotopng "
                                  onClick={() => handleRemoveField(index)}
                                />
                              </Col>
                              )}
                            </Row>
                          );
                        })}
                       <span className="err_msg">{erreducationDescription}</span>
                    </Col>
                    <Col md={12}>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="text-center border_dashed">
                          Upload Degree <br></br>
                          <br></br>
                          <span className="upload_photo"> .doc,.docx,.pdf</span>
                        </div>
                      </div>
                      <aside>
                        {files == "" ?
                        documentLink == "" ? "":
                        <ul> <a target="#" href={documentLink}> • Uploaded File</a></ul>:
                        <ul>{files}</ul>
                      
                      }
                      </aside>
                    </Col>
                    <Col>
                      <div className="setmodalfooterbutton">
                        <Button
                          variant="danger"
                          className="contact_radius mr-2"
                          onClick={handleCloseEdication}
                        >
                          Cancel
                        </Button>
                        <MyButton type={"submit"} onClick={handleSubmit}>
                          {iscontactLoading ? (
                            <Spinner
                              animation="border"
                              role="status"
                              className="spinneerset"
                            >
                              <span className="visually-hidden"></span>
                            </Spinner>
                          ) : (
                            "Save"
                          )}
                        </MyButton>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div className="position-relative"> 
                {eduactionModalShow ? (
                  <Col
                    lg={12}
                    className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips"
                  >
                    <div className="info_tip4 border-radiustip educationtipsheight" >
                     
                      <div className="mt-2">
                        <div>
                          <b>
                            Maximizing Impact with Your Experience Section on
                            Turn-To
                          </b>
                        </div>
                        <div className="mt-3 mb-3">
                          The experience section is where you get to demonstrate
                          your professional journey and accomplishments. On Turn-To,
                          detailing your work history effectively can set you apart
                          from other candidates by highlighting how you add value in
                          your roles.
                        </div>
                        <div>
                          <b>Tips for Crafting a Compelling Experience Section</b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1', '2', '3', '4']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Emphasize Achievements:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Beyond listing job responsibilities, showcase
                                  specific achievements. Use quantifiable results
                                  where possible, such as "Increased sales by 20%"
                                  or "Reduced operational costs by 15%.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Detail Your Role:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  For each position, briefly describe your main
                                  responsibilities, emphasizing how your work
                                  contributed to the organization's goals.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Relevance is Key:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Tailor your experience entries to the job you're
                                  applying for. Highlight the most relevant
                                  experiences that align with the role's
                                  requirements.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Use Action Verbs:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Start each point with a strong action verb such as
                                  "Led," "Managed," "Developed," or "Implemented" to
                                  make your contributions stand out.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Keep It Concise:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  While it's important to be detailed, ensure your
                                  descriptions are concise and to the point. Aim for
                                  clear and brief bullet points for each role.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div>
                          <b> Project Manager, ABC Corp, Jan 2020 - Present</b>
                        </div>
                        <div className="text-justify">
                          - Led a team of 10 in delivering a complex software
                          development project, completing it 2 months ahead of
                          schedule.
                        </div>
                        <div className="text-justify">
                          - Managed project budgets, achieving a 10% cost saving by
                          optimizing resources.
                        </div>
                        <div className="text-justify">
                          - Enhanced client satisfaction by 30% through strategic
                          improvements in the customer feedback process.
                        </div>
                        <div className="mt-2">
                          <b> Marketing Specialist, XYZ Inc, May 2017 - Dec 2019</b>
                        </div>
                        <div className="text-justify">
                          - Developed and executed a digital marketing strategy that
                          increased online engagement by 40%.
                        </div>
                        <div className="text-justify">
                          - Organized trade shows and events, resulting in a 25%
                          increase in lead generation.
                        </div>
                        <div className="text-justify">
                          - Collaborated with the sales team to create targeted
                          marketing materials, boosting conversion rates by 15%.
                        </div>
                        <div className="mt-3">
                          <b>
                            Remember, the experience section is your opportunity to
                            showcase how you've made a difference in your past
                            roles. Tailoring this section to reflect your
                            achievements and how they align with the job you're
                            applying for on Turn-To can significantly enhance your
                            visibility to potential employers.
                          </b>
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </div>

            </div>

          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Education;
