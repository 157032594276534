import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSocket } from "../../context/SocketProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Autocompletemap from "react-google-autocomplete";


const Popularcities = (props) => {
    const [searchpopularcities, setsearchpopularcities] = useState("");
    const { socket } = useSocket();
    const history = useHistory();

    // set popularcities data
    const popularCitiessetData = (data) => {

        history.push(
            `/jobmap?distance=20&location=${data.cityvalue}&latitude=${data.latitude}&longitude=${data.longitude}`
        );
        window.location.reload()
    };

    // for search
    const filteredCities = props.popularCities.filter(item => {
        const searchedCity = searchpopularcities.toLowerCase();
        const popularCity = item.cityname.toLowerCase();
        return popularCity.includes(searchedCity) || popularCity.includes(searchedCity);
    });
    const Listtomove = (data) => {
        history.push(
            `/jobmap?distance=20&location=${data.formatted_address}&latitude=${data.geometry.location.lat()}&longitude=${data.geometry.location.lng()}`
        );
        window.location.reload()
    };
    return (
        <div className="py-2 px-3">

            <div className="">
                <Row className="d-block">
                    <Col lg={4} md={4} className="d-flex justify-content-end mt-1"
                    >
                    </Col>
                    <Col lg={12} md={12} className="mb-1 mt-2">

                        <Autocompletemap
                            placeholder="Search history"
                            onPlaceSelected={(place) => {
                                setsearchpopularcities(place?.formatted_address);
                                Listtomove(place)
                            }}
                            value={searchpopularcities}
                            className="form-control search_newhistory  cursor-pointer"
                            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                            onChange={(e) => {
                                setsearchpopularcities(e.target.value)                               
                            }}


                        />
                    </Col>
                    <div className="addallserchdatasetoffcavas">
                        
                        {filteredCities.map((item, index) => (
                            <Col
                                key={index}
                                xs={12}
                                className="mt-1 d-flex justify-content-between align-items-center pointer hobvereffect"
                                onClick={(e) => popularCitiessetData(item)}
                            >
                                <div className="form-check pointer my-1">
                                    <label className="form-check-label text-muted newcssformclass pointer">
                                        <p className="input-helper pointer mb-0">
                                            {index + 1}. {item.cityname}
                                        </p>
                                    </label>
                                </div>
                            </Col>
                        ))}
                    </div>
                </Row>
            </div>
        </div>
    )
}
export default Popularcities;