import React, { useEffect, useState } from "react";
import "./SearchHistroy.css";
import { Col, Form, Row } from "react-bootstrap";
import {  PostDeleteNotInterstedHistory, PostNotInterstedHistory, getSearchHistory } from "../../Api/Api";
import { useAuth } from "../../context/AuthProvider";
import { Store } from "react-notifications-component";
import Skeleton from "react-loading-skeleton";

const NotIntersted = (props) => {
  const [notintersted, setNotIntersted] = useState([]);
  const { AuthData, isAuthenticated, setAuthData } = useAuth();
  const [isLoading , setIsLoading] = useState(true)

// get location recent
  const GetLocationrecent = () => {
    setIsLoading(true)

    getSearchHistory()
      .then(async function (response) {
        setNotIntersted(response.data?.data?.notInterestedJobs);
        setIsLoading(false)
      })
      .catch(function (error) {
      });
  };

  useEffect(() => {
      GetLocationrecent();
  }, []);

  const DeleteHistory = (job_name , id) => {
    var data = {
        job_name: job_name,
    };
    PostNotInterstedHistory(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        
        Store.addNotification({
          title: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        setNotIntersted(notintersted.filter((e) => e._id !== id));
      })
      .catch(function (error) {
        
      });
  };
  const [filterText, setFilterText] = useState("");

  // for search
  const filteredData = notintersted.filter((item) => {
    return (
      item.job_name?.toLowerCase().includes(filterText?.toLowerCase()) 
    );
  });
  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const DeleteNotInterstedHistory = () => {
    
    PostDeleteNotInterstedHistory()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        
        GetLocationrecent();

        Store.addNotification({
          title: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
      })
      .catch(function (error) {
       
      });
  };

  

  return (
    <>
    <div className="px-3 cursor-pointer">
      <div className="">                
       {isLoading ? 
       <Row className="addallserchdatasetoffcavas">
        <Col>
          <Skeleton count={50} />
        </Col>
       </Row> 
       :
        <Row className="d-block">
        <Col lg={12} md={12} className="mb-1 mt-3 newclassofnotintersted1">
            <Form.Group controlId="searchhistory">
              <Form.Control
                type="text"
                placeholder="Search history"
                name="searchhistory"
                className="search_newhistory"
                value={filterText}
              onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          <div className="addallserchdatasetoffcavas">
          {filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <Col
              xs={12}
              className="d-flex justify-content-between align-items-center  cursor-pointer"
            >
              <div className="form-check pointer formcheckremove">
                <label className="form-check-label text-muted label_class newcssformclass">
                 
                  <p className="input-helper mb-0">{index + 1}. {item.job_name}</p>
                </label>
              </div>
             
            </Col>
          )) ) :  (
            <Col lg={12} className="text-center mt-4">
            <i className="input-helper text-muted ">No Records Found</i>
            </Col>
          )}
          </div>
         
        </Row>
      }
      </div>
      </div>
    </>
  );
};

export default NotIntersted;
