import React from 'react'
import "./skill.css";

function Skill({children}) {
  return (
    <span className='skill-label'>
        <p>{children}</p>
    </span>
  )
}

export default Skill
