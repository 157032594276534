import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import historyclose from "../../../../../Component/icontopng/historyclose.png";
import { useSocket } from "../../../../../context/SocketProvider";
const InfoCvDownlord = () => {
    const { socket } = useSocket();

    const [show, setShow] = useState(false);
    // console.log("120");
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);
    const SoketCall = () =>{

        // socket.emit("hats_build_popup", {ats_build_popup:true});
        // socket.off("hats_build_popup");
        // socket.on("hats_build_popup", (res) => {
        //     console.log("10");
        // });
    }
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <img
            src={historyclose}
            alt="close"
            className="reportclosepng close_model"
            onClick={SoketCall}
          />
        </Modal.Header>
        <Modal.Body>

        "Ready to take your resume on the go? In the 'Download Resume/CV' section, you can easily download a copy of your ATS-optimized resume. Use it to apply anywhere, ensuring you're always prepared for new opportunities. Download your resume now and bring your job search to the next level!"
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };

export default InfoCvDownlord