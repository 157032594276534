import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import historyclose from "../../../../../Component/icontopng/historyclose.png";

const InfoCulture = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title></Modal.Title>
        <img
          src={historyclose}
          alt="close"
          className="reportclosepng close_model"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
      "Welcome to Turn-To! Our 'Culture & Preferences' section is designed to understand you better. Here, you can share what matters most to you in your ideal job and work environment. This information helps us tailor job recommendations that match not only your skills but also your values and lifestyle. Let's get started!"
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleClose}>
          Close
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default InfoCulture;
