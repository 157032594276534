import React, { useEffect, useState } from "react";
import "./SearchHistroy.css";
import { Col, Form, Row } from "react-bootstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { PostDeleteAllHistory, PostDeleteHistory, getSearchHistory } from "../../Api/Api";
import { useAuth } from "../../context/AuthProvider";
import { Store } from "react-notifications-component";
import Skeleton from "react-loading-skeleton";

const RecentHistory = (props) => {
  const [keyword, setKeyword] = useState([]);
  const { AuthData, isAuthenticated, setAuthData } = useAuth();
  const [isLoading , setIsLoading] = useState(true)
  const history = useHistory();

// get recent location
  const GetLocationrecent = () => {
    setIsLoading(true)
    getSearchHistory()
      .then(async function (response) {
        setKeyword(response.data?.data?.recentSearches);
        setIsLoading(false)
      })
      .catch(function (error) {
      });
  };

  useEffect(() => {
      GetLocationrecent();
  }, []);

  const DeleteHistory = (id) => {
    var data = {
      _id: id,
    };
    PostDeleteHistory(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
     
        Store.addNotification({
          title: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        setKeyword(keyword.filter((e) => e._id !== id));
      })
      .catch(function (error) {
      });
  };

  const DeleteAllHistory = () => {
    
    PostDeleteAllHistory()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        
      GetLocationrecent();

        Store.addNotification({
          title: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        
      })
      .catch(function (error) {
        
      });
  };
  const [filterText, setFilterText] = useState("");
  // for search
  const filteredData = keyword.filter((item) => {
    return (
      item.keyword?.toLowerCase().includes(filterText?.toLowerCase()) 
    );
  });
  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };
  const setLocationdata  = (data) =>{
    console.log("data", data);
    history.push(
      `/jobmap?distance=20&location=${data.location}&latitude=${data.latitude}&longitude=${data.longitude}&search=${data.keyword}`
    );
    window.location.reload();

  }
  return (
    <>
    <div className="px-3 py-2">
      <div className="">
       {isLoading ? 
       <Row className="addallserchdatasetoffcavas">
        <Col>
          <Skeleton count={50} />
        </Col>
       </Row>
   :
        <Row className="d-block">
          <Col lg={12} md={12}  className="mb-1 mt-2">
            <Form.Group controlId="searchhistory">
              <Form.Control
                type="text"
                placeholder="Search history"
                name="searchhistory"
                className="search_newhistory"
                value={filterText}
              onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
         
          <div className="addallserchdatasetoffcavas">
          {filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <>
            <Col
              xs={12}
              className=" d-flex justify-content-between align-items-center pointer  hobvereffect"
              onClick={()=>setLocationdata(item)}
             
             >
              <div className="form-check pointer formcheckremove"  onClick={()=>setLocationdata(item)}>
                <label className="form-check-label text-muted label_class newcssformclass pointer" onClick={()=>setLocationdata(item)}>
                  <p className="input-helper pointer mb-0" >{index + 1}. {item.keyword}</p>
                </label>
              </div>
              
            </Col>
           
            </>
          )) ) :  (
            <Col lg={12} className="text-center mt-4">
            <i className="input-helper text-muted ">No Records Found</i>
            </Col>
          )}
          </div>
          
        </Row>
       }
      </div>
      </div>
    </>
  );
};

export default RecentHistory;
