import React, { useEffect, useState } from 'react'
import { Spinner,Row,Col } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BulkUplordJob } from '../../Api/Api';
import { Store } from 'react-notifications-component';

const Bulkupload = (props) => {
    const formData = new FormData();
    let history = useHistory();
    const [isBulkUpload, setIsBulkUpload] = useState(false);
    const [downloadLink,setdownloadLink] = useState("")
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            "file/xlsx": [".xlsx"],
        },
        multiple: false,
    });
    const [files, setFiles] = useState([]);
    const acceptedFileItems = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    const JobBulikPost = () => {
        if (!acceptedFiles.length) {
            return false;
        }
        setIsBulkUpload(true);

        formData.append("files", acceptedFiles[0]);
        BulkUplordJob(formData)
            .then(function (response) {
                if(!response.data.status){
                    Store.addNotification({
                      title: response.data.message,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__zoomIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 2000,
                        onScreen: false,
                      },
                    });
                    return false
                  }
                setIsBulkUpload(false);
                if (response.data.status) {
                    acceptedFiles.splice(0, acceptedFiles.length);
                    props.setIsAnyUpdate(props.isAnyUpdate + 1)
                    Store.addNotification({
                        title: response.data.message,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__zoomIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                            onScreen: false,
                        },
                    });
                } else {
                    Store.addNotification({
                        title: response.data.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__zoomIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                            onScreen: false,
                        },
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    };

    // upload file api call
    useEffect(() => {
        JobBulikPost();
    }, [acceptedFiles]);

    // demo link set
    useEffect(()=>{
        setdownloadLink(props.demoFileLink)
    },[props.demoFileLink])
    
    return (
        <>
        <Row>
            <Col xs={12}>
            <div className="">
                    <section className="container col-lg-12 p-0">
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="text-center border_jobs hightsetdrop">
                                <b className='my-2'>Drag And Drop</b>
                                <br></br>
                                <br></br>
                                <span className="jobs_photo"> Upload a Excel</span>
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </section>
                </div>

                <div className='text-center mt-2'>
                    {downloadLink !== "" && (
                        <button className="my-btn calanderzindex  mx-2">
                            <div className="text-center">
                                <a href={downloadLink} target="_blank" download="demoFileName" className='text-white'> 
                                    Download Demo
                                </a>
                            </div>
                        </button>
                    )}
                </div>
                {isBulkUpload && (
                    <div className="text-center my-2">
                        <Spinner className="spinneerset" animation="border" role="status">
                            <span className="visually-hidden"></span>
                        </Spinner>
                    </div>
                )}
            </Col>
        </Row>
        </>


    )
}

export default Bulkupload