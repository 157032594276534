import React, { useEffect } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useState } from "react";
import Experience from "./experience/Experience";
import Education from "./education/Education";
import MyButton from "../common/Button/MyButton";
import Skill from "../common/Skill/Skill";
import footerpagepngred from "../../../../../src/Component/icontopng/redarrow.png";
import footerpagepng from "../../../../../src/Component/icontopng/footerpagepng.png";

import footerpagepnggreen from "../../../../../src/Component/icontopng/greenarrow.png";

import "./profile.css";

import {
  GetProfileList,
  getAllSkill,
  postupdateSkill,
} from "../../../../Api/Api";
import Autosuggest from "react-autosuggest";
import Skeleton from "react-loading-skeleton";
import { Store } from "react-notifications-component";
import DocumentUpload from "./documentupload/DocumentUpload";
import Languagesknow from "./Languages-Know/Languagesknow";
import OtherSection from "./other-section/OtherSection";
import Awards from "./Awards/Awards";
import { useSocket } from "../../../../context/SocketProvider";
import Tooltip from "react-tooltip-lite";
import { MdOutlineClose } from "react-icons/md";

const ProfileComponent = (props) => {
  const { socket } = useSocket();

  const [profileData, setProfileData] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillShow, setSkillShow] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerError, setHeaderError] = useState("");
  const [editProfile, setEditProfile] = useState(false);
  const [profileId, setProfileId] = useState("")
  // Summary
  const [showSummary, setShowSummary] = useState(false);

  useEffect(() => {
    setProfileId(props.profile_id)
    getProfileData()
  }, [props.profile_id])

  console.log("props", props);


  const summaryModelClose = () => {
    clearSummry();
    setShowSummary(false);
    setsummaryModalshow(false);
  };
  const summaryModelShow = () => setShowSummary(true);

  // headertitle
  const [showheader, setShowheader] = useState(false);

  // header model close
  const headerModelClose = () => {
    setShowheader(false);
    setheaderModalshow(false);
    clearHeader();
  };
  const headerModelShow = () => setShowheader(true);

  const [skeletonscheduleUserName, setSkeletonscheduleUserName] =
    useState(true);

    // model close
  const handleClose = () => {
    setSkillShow(false);
    setupdateSkillModalShow(false);
    skillError();
  };

  // model show
  const handleShow = () => {
    GetAllSkillApi();
    SetDataSupplementalPayforSkill(profileData?.skills);
    setSkillShow(true);
  };

  //modal open state

  const [summaryModalshow, setsummaryModalshow] = useState(false);
  const [headerModalshow, setheaderModalshow] = useState(false);

  const [updateSkillModalShow, setupdateSkillModalShow] = useState(false);

  // edit code
  const [bio, setBio] = useState("");

  const [isupdateLoading, setupdateIsLoading] = useState(false);

  //  validation State
  const [bioError, setBioError] = useState("");
  const [errSugesstion, setErrorSugesstion] = useState("");
  const [experienceCount, setExperienceCount] = useState([]);
  const [educationCount, setEducationCount] = useState([]);
  const [certificateCount, setCertificateCount] = useState([]);
  const [awrdCount, setawrdCount] = useState([]);
  const [languagecount, setlanguagecount] = useState([]);
  const [languagecountssss, setlanguagecountssss] = useState([]);

  const summaryValidation = () => {
    var isValid = true;
    if (bio.trim() == "") {
      setBioError("Please Enter Bio");
      isValid = false;
    } else if (bio.length > 500) {
      setBioError("Bio should not exceed 500 characters");
      isValid = false;
    }
    return isValid;
  };

  const clearSummry = () => {
    setBioError("");
  };

  // header validation
  const headerValidation = () => {
    var isValid = true;
    if (headerTitle.trim() == "") {
      setHeaderError("Please Enter Header Title");
      isValid = false;
    } else if (headerTitle.length > 110) {
      setHeaderError("Header Title should not exceed 110 characters");
      isValid = false;
    }
    return isValid;
  };

  const clearHeader = () => {
    setHeaderError("");
  };

  // edit bio
  const editBio = () => {
    clearSummry();
    if (!summaryValidation()) {
      return false;
    }
    var data = {
      token: localStorage.getItem("token"),
      bio: bio,
      profile_id: props.profile_id
    };

    socket.emit("update-profile-bio", data);
    socket.off("update-profile-bio",);
    socket.on("update-profile-bio", (response) => {
      if (!response.status) {
        Store.addNotification({
          title: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        return false
      }
      Store.addNotification({
        title: "Saved Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
      setEditProfile(false);
      getProfileData();
      summaryModelClose();
    });
  };

  // edit header
  const editHeader = () => {
    clearHeader();
    if (!headerValidation()) {
      return false;
    }
    var data = {
      token: localStorage.getItem("token"),
      header_title: headerTitle,
      profile_id: props.profile_id
    };

    socket.emit("update-profile-header-title", data);
    socket.off("update-profile-header-title",);
    socket.on("update-profile-header-title", (response) => {
      if (!response.status) {
        Store.addNotification({
          title: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        return false
      }
      Store.addNotification({
        title: "Saved Successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
      setEditProfile(false);
      getProfileData()
      headerModelClose();
    });
  };

  // get allskill api
  const GetAllSkillApi = () => {
    getAllSkill()
      .then(function (response) {
        setSelectedSkills(response.data.data);
      })
      .catch(function (error) {
        // handle error
      });
  };

  const skillValidation = () => {
    skillError();

    let isValid = true;
    if (!dataSupplementalPayforSkill.length) {
      setErrorSugesstion("Please Add Skill");
      isValid = false;
    }
    if (dataSupplementalPayforSkill.length > 10) {
      setErrorSugesstion("You can only add a maximum of 10 skills");
      isValid = false;
    }

    return isValid;
  };

  const skillError = () => {
    setErrorSugesstion("");
  };

  // skill update api
  const SkillUpdateApi = () => {
    if (!skillValidation()) {
      return false;
    }
    setupdateIsLoading(true);
    var data = {
      skills: dataSupplementalPayforSkill,
      profile_id: profileId,
    };
    postupdateSkill(data)
      .then(function (response) {
        if (response.data.status === true) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
           
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
        }
        setupdateIsLoading(false);
        handleClose();
        getProfileData();
      })
      .catch(function (error) {
        Store.addNotification({
          title: error.response.data.error.skills,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
         
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        setupdateIsLoading(false);
      });
  };

  // --------------------------------------------------------------------------------------

  // suggestion api
  // const getSuggestionsforSkill = (valueforSkill) => {
    
  //   const inputValueforSkill = valueforSkill.trim().toLowerCase();
  //   const inputLengthforSkill = inputValueforSkill.length;
    
  //   var formattedValue = dataSupplementalPayforSkill.map((ele) =>ele.toLowerCase());
    
  //   return inputLengthforSkill < 0
  //     ? []
  //     : selectedSkills.filter(
  //       (lang) =>
  //         lang.name.toLowerCase().includes(inputValueforSkill) &&
  //         !formattedValue.includes(lang.name.toLowerCase())
  //     );
  // };
  const getSuggestionsforSkill = (valueforSkill) => {
    
    const inputValueforSkill = valueforSkill.trim().toLowerCase();
    const inputLengthforSkill = inputValueforSkill.length;
    
    // Lowercase versions of dataSupplementalPayforSkill
    var formattedValue = dataSupplementalPayforSkill.map((ele) => ele.toLowerCase());

    if (inputLengthforSkill > 0) {
        // Check if valueforSkill is in selectedSkills or dataSupplementalPayforSkill
        const skillExistsInSelected = selectedSkills.some((lang) =>
            lang.name.toLowerCase() === inputValueforSkill
        );
        const skillExistsInData = formattedValue.includes(inputValueforSkill);

        // If the skill does not exist in either, add it to selectedSkills
        if (!skillExistsInSelected && !skillExistsInData) {
            selectedSkills.push({ name: valueforSkill });
        }
    }

    // Return filtered suggestions
    return inputLengthforSkill < 1
      ? []
      : selectedSkills.filter(
        (lang) =>
          lang.name.toLowerCase().includes(inputValueforSkill) &&
          !formattedValue.includes(lang.name.toLowerCase())
      );
};


  const getSuggestionValueforSkill = (suggestion) => suggestion.name;

  const renderSuggestionforSkill = (suggestionforSkill) => (
    <div>{suggestionforSkill.name}</div>
  );
  const [valueforSkill, setValueforSkill] = useState("");
  const [suggestionsforSkill, setSuggestionsforSkill] = useState([]);
  const [dataSupplementalPayforSkill, SetDataSupplementalPayforSkill] =
    useState([]);

  const onChangeforSkill = (event, { newValue }) => {
    setValueforSkill(newValue);
  };

  const onSuggestionsFetchRequestedforSkill = ({ value }) => {
    setSuggestionsforSkill(getSuggestionsforSkill(value));
  };

  const onSuggestionsClearRequestedforSkill = () => {
    setSuggestionsforSkill([]);
  };

  const inputPropsforSkill = {
    placeholder: "Type and click enter to add a new one",
    value: valueforSkill,
    onChange: onChangeforSkill,
  };

  const onSuggestionSelectedforSkill = (
    event,
    { suggestion, suggestionValue }
  ) => {
    // Here, you can access the selected suggestion and its value
    SetDataSupplementalPayforSkill((dataSupplementalPayforSkill) => [
      ...dataSupplementalPayforSkill,
      suggestionValue,
    ]);
    setValueforSkill("");
    setSuggestionsforSkill([]);
  };

  const deleteSupplementforSkill = (item) => {
    SetDataSupplementalPayforSkill(
      dataSupplementalPayforSkill.filter((ele) => ele != item)
    );
  };

  useEffect(() => {
    onSuggestionsClearRequestedforSkill();
  }, [dataSupplementalPayforSkill]);
  // --------------------------------------------------------------------------------------

  useEffect(() => {
    document.title = "Profile | Turn-To";
  }, []);

  const summaryTipsModal = () => {
    setsummaryModalshow(!summaryModalshow);
  };
  const headerTipsModal = () => {
    setheaderModalshow(!headerModalshow);
  };

  const updateSkillModal = () => {
    setupdateSkillModalShow(!updateSkillModalShow);
  };
  const maxLength = 500;

  // bio change
  const handleBioChange = (e) => {
    const inputBio = e.target.value;
    if (inputBio.length <= maxLength) {
      setBio(inputBio);
      setBioError("");
    } else {
      setBioError("Bio should not exceed 500 characters");
    }
  };

  const [profileList, setProfileList] = useState([]);
  const [activeProfileId, setActiveProfileId] = useState("");

  // get profile list api
  const GetProfileListApi = () => {
    GetProfileList()
      .then(function (response) {
        if (!response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: response.data.status ? "success" : "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false;
        }
        setProfileList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetProfileListApi();
  }, []);

  useEffect(() => {
    if (profileList.length) {
      setActiveProfileId(profileList[0]._id);
    }
  }, [profileList]);

  // get profile data
  const getProfileData = () => {
    if (socket && props.profileId !== "") {
      var data = {
        token: localStorage.getItem("token"),
        profile_id: props.profile_id,
      };
      socket.emit("get-profile", data);
      socket.off("get-profile-" + data.profile_id);
      socket.on("get-profile-" + data.profile_id, (res) => {
        if (res.status) {
          setBio(res.data.bio);
          setProfileData(res.data);
          setHeaderTitle(res.data.header_title);
          setSkeletonscheduleUserName(false);
        }
      });
    }
  }


  return (
    <>
      <Accordion.Item eventKey="9" className="my-2">
        <Accordion.Header className="set_Accordion">
          <div className="d-flex align-items-center">
            {profileData?.header_title === "" ?
              <img
                src={footerpagepngred}
                alt="open"
                width="15px"
                height="15px"
              /> :
              <img
                src={footerpagepnggreen}
                alt="open"
                width="15px"
                height="15px"
              />
            }
            <h5 className="exp_color set_accordionfont">Header Title</h5>
          </div>
          <div>
            <div className="" onClick={headerModelShow}>
              <div className="addcontactinfoedit"></div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div>
            {skeletonscheduleUserName ? (
              <div className="userskillsection">
                <Skeleton containerClassName="about-me-skeleton" />
              </div>
            ) : (
              <>
                {editProfile ? (
                  <>
                    <Form.Group controlId="firstname">
                      <Form.Control
                        type="text"
                        placeholder="About"
                        as="textarea"
                        rows={3}
                        value={headerTitle}
                        onChange={(e) => setHeaderTitle(e.target.value)}
                      />
                      <span className="err_msg">{headerError}</span>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: profileData?.header_title,
                      }}
                    ></div>
                  </>
                )}
              </>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <hr></hr>
      <Accordion.Item eventKey="1" className="my-2">
        <Accordion.Header className="set_Accordion">
          <div className="d-flex align-items-center">
          {profileData?.bio === "" ?
              <img
                src={footerpagepngred}
                alt="open"
                width="15px"
                height="15px"
              /> :
              <img
                src={footerpagepnggreen}
                alt="open"
                width="15px"
                height="15px"
              />
            }
            <h5 className="exp_color set_accordionfont">
              Professional Summary
            </h5>
          </div>
          <div>
            <div className="" onClick={summaryModelShow}>
              <div className="addcontactinfoedit"></div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div>
            {skeletonscheduleUserName ? (
              <div className="userskillsection">
                <Skeleton containerClassName="about-me-skeleton" />
              </div>
            ) : (
              <>
                {editProfile ? (
                  <>
                    <Form.Group controlId="firstname">
                      <Form.Control
                        type="text"
                        placeholder="About"
                        as="textarea"
                        rows={3}
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                      />
                      <span className="err_msg">{bioError}</span>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: profileData?.bio,
                      }}
                    ></div>
                  </>
                )}
              </>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <hr></hr>

      <Accordion.Item eventKey="3" className="my-2">
        <Accordion.Header className="setAccordion">
        {experienceCount?.length <= 0 ?
              <img
                src={footerpagepngred}
                alt="open"
                width="15px"
                height="15px"
              /> :
              <img
                src={footerpagepnggreen}
                alt="open"
                width="15px"
                height="15px"
              />
            }
          <h5 className="exp_color set_accordionfont setCountexp">
            Experience
            <div>{experienceCount?.length} / 2</div>
          </h5>
        </Accordion.Header>
        <Accordion.Body>
          <Experience profileId={profileId} setExperienceCount={setExperienceCount} />
        </Accordion.Body>
      </Accordion.Item>
      <hr></hr>
      <Accordion.Item eventKey="4" className="my-2">
        <Accordion.Header className="setAccordion">
        {educationCount?.length <= 0 ?
              <img
                src={footerpagepngred}
                alt="open"
                width="15px"
                height="15px"
              /> :
              <img
                src={footerpagepnggreen}
                alt="open"
                width="15px"
                height="15px"
              />
            }
          <h5 className="exp_color set_accordionfont setCountexp">
            Education
            <div>{educationCount?.length} / 2</div>
          </h5>
        </Accordion.Header>
        <Accordion.Body>
          <Education profileId={profileId} setEducationCount={setEducationCount} />
        </Accordion.Body>
      </Accordion.Item>
      <hr></hr>
      <Accordion.Item eventKey="5" className="my-2">
        <Accordion.Header className="setAccordion">
        {certificateCount?.length <= 0 ?
              <img
                src={footerpagepngred}
                alt="open"
                width="15px"
                height="15px"
              /> :
              <img
                src={footerpagepnggreen}
                alt="open"
                width="15px"
                height="15px"
              />
            }
          <h5 className="exp_color set_accordionfont setCountexp">
            Certificates
            <div>{certificateCount?.length} / 2</div>
          </h5>
        </Accordion.Header>
        <Accordion.Body>
          <DocumentUpload profileId={profileId} setCertificateCount={setCertificateCount} />
        </Accordion.Body>
      </Accordion.Item>
      <hr></hr>
      <Accordion.Item eventKey="2" className="my-2">
        <Accordion.Header className="set_Accordion">
          <div className="d-flex align-items-center">
          {profileData?.skills?.length <= 0 ?
              <img
                src={footerpagepngred}
                alt="open"
                width="15px"
                height="15px"
              /> :
              <img
                src={footerpagepnggreen}
                alt="open"
                width="15px"
                height="15px"
              />
            }
            <h5 className="exp_color set_accordionfont">
              Skills
            </h5>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <h5 className="exp_color set_accordionfont setCountexp mr-3">
                <div>{profileData?.skills?.length} / 8</div>
              </h5>
              <div className="" onClick={handleShow}>
                <div className="addcontactinfoedit"></div>
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {profileData?.skills?.map((e) => {
            return (
              <>
                <Skill>{e}</Skill>
              </>
            );
          })}
        </Accordion.Body>
      </Accordion.Item>
      <hr></hr>
      <Accordion.Item eventKey="6" className="my-2">
        <Accordion.Header className="setAccordion">
        {languagecountssss?.length <= 0 ?
              <img
                src={footerpagepngred}
                alt="open"
                width="15px"
                height="15px"
              /> :
              <img
                src={footerpagepnggreen}
                alt="open"
                width="15px"
                height="15px"
              />
            }
          <h5 className="exp_color set_accordionfont setCountexp">
            Professional Affiliations
            <div>{languagecountssss?.length} / 3</div>
          </h5>
        </Accordion.Header>
        <Accordion.Body>
          <OtherSection profileId={profileId} setlanguagecountssss={setlanguagecountssss} />
        </Accordion.Body>
      </Accordion.Item>
      <hr></hr>
      <Accordion.Item eventKey="7" className="my-2">
        <Accordion.Header className="setAccordion">
        {languagecount?.length <= 0 ?
              <img
                src={footerpagepngred}
                alt="open"
                width="15px"
                height="15px"
              /> :
              <img
                src={footerpagepnggreen}
                alt="open"
                width="15px"
                height="15px"
              />
            }
          <h5 className="exp_color set_accordionfont setCountexp">
            Languages
            <div>{languagecount?.length} / 3</div>
          </h5>
        </Accordion.Header>
        <Accordion.Body>
          <Languagesknow profileId={profileId} setlanguagecount={setlanguagecount} />
        </Accordion.Body>
      </Accordion.Item>
      <hr></hr>
      <Accordion.Item eventKey="8" className="my-2">
        <Accordion.Header className="setAccordion">
        {awrdCount?.length <= 0 ?
              <img
                src={footerpagepngred}
                alt="open"
                width="15px"
                height="15px"
              /> :
              <img
                src={footerpagepnggreen}
                alt="open"
                width="15px"
                height="15px"
              />
            }
          <h5 className="exp_color set_accordionfont setCountexp">
            Awards
            <div>{awrdCount?.length} / 5</div>
          </h5>
        </Accordion.Header>
        <Accordion.Body>
          <Awards profileId={profileId} setawrdCount={setawrdCount} />
        </Accordion.Body>
      </Accordion.Item>
      <div className="d-flex justify-content-center btncenter">
        <Tooltip content="Preview">
          <MyButton
            onClick={(e) =>
              props.setUserProfile(profileData.slug)
            }
          >
            Preview
          </MyButton>
        </Tooltip>
      </div>
      <Modal
        show={showheader}
        onHide={headerModelClose}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        backdrop="static"
        centered
      >
        <Modal.Body className="p-0">
          <Row className="justify-content-center">
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
                <Col lg={12}>
                  <Row className="bg-white userdatapart border-radiustip">
                    <div className="col-12 mt-3 tipsbuttonset">
                      <div className="index_newcss ">
                        <div className="contectinfoshowname mb-2">
                          <div>Header Title</div>
                        </div>
                        <div className="mb-2">
                          <span
                            onClick={headerTipsModal}
                            className="newdashbordbuttoncolor atstipsbuttonset"
                          >
                             {headerModalshow ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                          </span>
                        </div>
                      </div>
                    </div>

                    <Col xs={12}>
                      <div className="mb-2">
                      </div>

                      <Form.Group controlId="discription" className="mt-1">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Please type here.."
                          value={headerTitle}
                          onChange={(e) => setHeaderTitle(e.target.value)}
                          maxLength={110}
                        />

                        <span className="err_msg">{headerError}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={12} className="d-flex justify-content-end">
                      <div>{headerTitle ? headerTitle.length : 0}/110</div>
                    </Col>
                    <Col>
                      <div className="setmodalfooterbutton">
                        <Button
                          variant="danger"
                          className="contact_radius mr-2"
                          onClick={headerModelClose}
                        >
                          Cancel
                        </Button>
                        <MyButton type={"submit"} onClick={editHeader}>
                          Save
                        </MyButton>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div className="position-relative">
                {headerModalshow ? (
                  <Col
                    lg={12}
                    className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips"
                  >
                    <div className="info_tip  border-radiustip">
                     

                      <div className="mt-2">
                        <div class="mt-2">
                          <b>Tips for a Compelling Header Title:</b>
                        </div>
                        <Accordion
                          defaultActiveKey={[
                            "0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                          ]}
                          alwaysOpen
                        >
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Front and Center:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Like a summary, your header title deserves a
                                  prime spot at the very top of your resume.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Tailor It:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Adapt your header title to each position you
                                  apply for, emphasizing the most relevant
                                  skills and aligning yourself with the job
                                  requirements.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Spotlight Value:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Briefly encapsulate the key benefit you bring
                                  to an employer (e.g., years of experience,
                                  specialized skillset, or a track record of
                                  results).
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Utilize Keywords:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  When possible, subtly weave in keywords from
                                  the job description that accurately represent
                                  your experience.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Absolute Clarity:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Your full name should be the most prominent
                                  element. Ensure your intended job title or
                                  area of expertise is instantly identifiable.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Concise and Catchy:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Keep the title short and snappy. A good
                                  general guide is under 12 words.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div class="mt-2">
                          <b>Additional Considerations:</b>
                        </div>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Consider a tagline format:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Try a two-part title. The first part is your
                                  job title, followed by a "|" or "–", and then
                                  a short phrase highlighting your strength.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Example:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  "Graphic Designer | Creative Problem Solver"
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Don't Be Afraid to Be Bold:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  If you have an impressive achievement or
                                  specific metric, work it in!
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Example:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  "Project Manager | Led 3 On-Time, Under-Budget
                                  Launches"
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSummary}
        onHide={summaryModelClose}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        backdrop="static"
        centered
      >
        <Modal.Body className="p-0">
          <Row className="justify-content-center">
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
                <Col lg={12}>
                  <Row className="bg-white userdatapart border-radiustip">
                    <div className="col-12 mt-3 tipsbuttonset">
                      <div className="index_newcss ">
                        <div className="contectinfoshowname mb-2">
                          <div className="professionalwidth">Professional Summary</div>
                        </div>
                        <div className="mb-2">
                          <span
                            onClick={summaryTipsModal}
                            className="newdashbordbuttoncolor affiliationstips"
                          >
                             {summaryModalshow ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                          </span>
                        </div>
                      </div>
                    </div>

                    <Col xs={12}>
                      <div className="mb-2">
                      </div>

                      <Form.Group controlId="discription" className="mt-1">
                        <Form.Control
                          as="textarea"
                          rows={10}
                          placeholder="Please type here.."
                          value={bio}
                          onChange={handleBioChange}
                          maxLength={500}
                        />

                        <span className="err_msg">{bioError}</span>
                      </Form.Group>
                    </Col>
                    <Col lg={12} className="d-flex justify-content-end">
                      <div>
                        {bio.length}/{maxLength}
                      </div>
                    </Col>
                    <Col>
                      <div className="setmodalfooterbutton">
                        <Button
                          variant="danger"
                          className="contact_radius mr-2"
                          onClick={summaryModelClose}
                        >
                          Cancel
                        </Button>
                        <MyButton type={"submit"} onClick={editBio}>
                          Save
                        </MyButton>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div className="position-relative">
                {summaryModalshow ? (
                  <Col
                    lg={12}
                    className="tipsmodalsetmobilescreen tipsmobilescreenzindex addnewsettipopenmodalwidth setpaddingmobiletips"
                  >
                    <div className="info_tip2 summarytipscolheightset border-radiustip">
                     

                      <div className="mt-2">
                        <div className="mt-2">
                          <b>Crafting Your Professional Summary with Turn-To</b>
                        </div>
                        <div className="">
                          Your professional summary is a crucial component of
                          your resume or Turn-To profile. It's your first
                          opportunity to make a lasting impression on potential
                          employers. This brief section should highlight your
                          key professional skills, achievements, and the unique
                          qualities that make you the ideal candidate for the
                          job. Tailoring this summary to the specific role
                          you're applying for can significantly increase your
                          chances of standing out.
                        </div>
                        <div class="mt-2">
                          <b>Tips for a Compelling Summary:</b>
                        </div>
                        <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Position at the Top:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Your summary should be prominently placed at
                                  the beginning of your resume or Turn-To
                                  profile for maximum visibility.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Construct Afterward:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  If you're struggling, consider drafting the
                                  rest of your resume or profile first. This can
                                  help you identify your most notable
                                  accomplishments to highlight.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Highlight Your Candidacy:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Use the summary to explain why you're an
                                  exceptional candidate. This is your chance to
                                  make a strong first impression.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Utilize Keywords:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Incorporate relevant keywords from the job
                                  listing. Focus on those terms that are
                                  repeated, listed as essential qualifications,
                                  or emphasized in the job description.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Conciseness is Key:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Aim for three to five sentences for your
                                  summary. If it extends longer, evaluate if
                                  some details would be better suited for the
                                  Experience section or elsewhere.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Overview, Not Autobiography:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  This section should succinctly convey why
                                  you're a worthy candidate, encouraging the
                                  employer to continue reading your profile.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-3">
                          <div>
                            Remember, the professional summary is your elevator
                            pitch to potential employers. It's about presenting
                            a concise snapshot of your professional persona that
                            aligns with the job you're pursuing.
                          </div>
                          <div className="mt-2">
                            <b>
                              By following these guidelines, you can create a
                              summary that captures attention and positions you
                              as a compelling candidate, making employers eager
                              to learn more about you on Turn-To.
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={skillShow}
        onHide={handleClose}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        centered
      >
        <Modal.Body className="p-0">
          <div className="d-flex justify-content-center">
            <div className="modalsetwidth">
              <Col lg={12}>
                <Row className="bg-white userdatapart border-radiustip">
                  <div className="col-12 mt-3 tipsbuttonset">
                    <div className="index_newcss">
                      <div className="contectinfoshowname mb-2">
                        <div>Skills</div>
                      </div>
                      <div className="mb-2">
                        <span
                          onClick={updateSkillModal}
                          className="newdashbordbuttoncolor atstipsbuttonset"
                        >
                          {updateSkillModalShow ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                        </span>
                      </div>
                    </div>
                  </div>

                  <Col>
                    <Form.Group controlId="">
                      <Form.Label className="titlename mt-2">
                        <b>Choose Up To 8</b>
                      </Form.Label>
                      <Col xs={12} className="p-0">
                        <Row>
                          {dataSupplementalPayforSkill.map((item) => {
                            return (
                              <>
                                <div
                                  className="mx-1 my-1 cursor-pointer"
                                  onClick={(e) =>
                                    deleteSupplementforSkill(item)
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    <Skill>{item}</Skill>
                                    <i className="mdi mdi-close"></i>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </Row>
                      </Col>

                      <Autosuggest
                        suggestions={suggestionsforSkill}
                        onSuggestionsFetchRequested={
                          onSuggestionsFetchRequestedforSkill
                        }
                        alwaysRenderSuggestions={true}
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequestedforSkill
                        }
                        onSuggestionSelected={onSuggestionSelectedforSkill}
                        getSuggestionValue={getSuggestionValueforSkill}
                        renderSuggestion={renderSuggestionforSkill}
                        inputProps={inputPropsforSkill}
                      />
                      <span className="err_msg">{errSugesstion}</span>
                    </Form.Group>
                    <div className="setmodalfooterbutton">
                      <Button
                        variant="danger"
                        onClick={handleClose}
                        className="contact_radius mr-2"
                      >
                        Cancel
                      </Button>
                      {dataSupplementalPayforSkill.length < 9 && (
                        <button
                          variant="primary"
                          className="my-btn "
                          onClick={SkillUpdateApi}
                        >
                          {isupdateLoading ? (
                            <Spinner
                              animation="border"
                              role="status"
                              className="spinneerset"
                            >
                              <span className="visually-hidden"></span>
                            </Spinner>
                          ) : (
                            "Update"
                          )}
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
            <div className="position-relative">
              {updateSkillModalShow ? (
                <Col
                  lg={12}
                  className="tipsmodalsetmobilescreen addnewsettipopenmodalwidth setpaddingmobiletips"
                >
                  <div className="contactinfo_tip updateSkillscrollheight border-radiustip">
                    <div className="mt-2">
                      <div>
                        For Turn-To, here's a guide to help users optimize their
                        skills section in their profiles or resumes:
                      </div>
                      <div className="mt-2">
                        <b>Showcasing Your Skills on Turn-To</b>
                      </div>
                      <div>
                        Your skills section is a vital part of your profile or
                        resume, offering a snapshot of your abilities and
                        setting you apart from other candidates. On Turn-To,
                        this is your chance to shine and make a memorable
                        impression on potential employers by succinctly listing
                        your work-related talents.
                      </div>
                      <div className="mt-2">
                        <b>Tips for an Impactful Skills Section:</b>
                      </div>
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        <Accordion.Item eventKey="0" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Keep Punctuation Minimal:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Let your skills speak for themselves without the
                                need for periods or exclamation marks. Bullet
                                points create a cleaner look.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
                        <Accordion.Item eventKey="0" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Emphasize Transferable Skills:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                If you find your role-specific skills are
                                limited, highlight universal skills such as
                                "Effective Communication," "Team Collaboration,"
                                or "Strategic Planning."
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Draw From the Job Description:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Align your listed skills with those mentioned in
                                the job you’re applying for. Employers
                                appreciate candidates who reflect the qualities
                                they’re seeking.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion
                        defaultActiveKey={["0", "1", "2", "3"]}
                        alwaysOpen
                      >
                        <Accordion.Item eventKey="0" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Customize for Each Application:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Tailor your skills section for every job
                                application to demonstrate how you’re the
                                perfect fit for each unique role.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Format with Clarity:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Capitalize the first letter of each skill and
                                stick to concise, 2-3 word phrases for easy
                                reading.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Optimal Length:
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Your skills section should not dominate your
                                resume or profile. Aim for it to occupy no more
                                than a quarter of the page.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Skill Quantity
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                Limit yourself to 3-4 skills per column,
                                ensuring you showcase a total of 9-12 of your
                                most relevant skills.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className="my-2">
                          <Accordion.Header className="fontsetAccordion">
                            <img
                              src={footerpagepng}
                              alt="open"
                              width="15px"
                              height="15px"
                            />{" "}
                            Example Skills Layout for Turn-To
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>- Effective Leadership</div>
                              <div>- Agile Methodologies</div>
                              <div>- Creative Problem-Solving</div>
                              <div>- Customer Engagement</div>
                              <div>- Data Analysis Proficiency</div>
                              <div>- Cross-Functional Teamwork</div>
                              <div>- Innovative Thinking</div>
                              <div>- Tech Savviness</div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="mt-2">
                        Remember, the skills section is an opportunity to
                        illustrate the unique talents that make you the ideal
                        candidate for the position. By focusing on relevance,
                        clarity, and brevity, you can effectively capture the
                        attention of employers on Turn-To.
                      </div>
                      <div className="mt-2">
                        <b>
                          This guide aims to assist Turn-To users in crafting a
                          compelling skills section that showcases their talents
                          and aligns with potential employers' needs, enhancing
                          their visibility in the job market.
                        </b>
                      </div>
                    </div>

                  </div>
                </Col>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default ProfileComponent;
