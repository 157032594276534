import Reaact from "react";
import "./NewAddPage.css";
import { Accordion, Col, Nav, Navbar } from "react-bootstrap";
import footerlogo from "../../assets/images/loginpagenewlogo.png";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";

const Partnership = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  return (
    <>
      <div>
        <HeaderNew />
        {/* first section */}
        <div className="container new_pagesmargin" onClick={RemoveSlider}>
          <h3 className="text-center">
            <b>
              Turn-To Partnerships: Elevate Your Business with Innovative Hiring
              Solutions
            </b>
          </h3>
          <hr></hr>
          <div className="row my-3 howtoworkemp">
            <h5 className="mt-1 mb-1 text-justify">
              <b>Empower Your Platform with Turn-To</b>
            </h5>
            <div className="mt-3 mb-3 text-justify">
              Discover the transformative power of partnering with Turn-To, a
              forefront in job search innovation. Through our partnership,
              leverage cutting-edge hiring technologies designed to simplify and
              enhance the job matching process.
            </div>
          </div>
          <div>
            <h5>
              <b>Exclusive Partnership Opportunities with Turn-To:</b>
            </h5>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. ATS Integration:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Streamline recruitment by integrating your Applicant
                      Tracking System with Turn-To’s seamless job posting and
                      application management features.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Business Growth:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Elevate your service offerings by incorporating Turn-To’s
                      digital hiring solutions, designed to optimize employer
                      and job seeker connections.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Product Enhancement:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Collaborate with Turn-To to offer your unique solutions
                      within our platform, improving outcomes for employers and
                      job seekers alike.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  4. Content Collaboration:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Engage your audience with high-quality job-related content
                      powered by Turn-To, driving both traffic and value.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div>
            <h5>
              <b>Why Partner with Turn-To?</b>
            </h5>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Expand Your Reach:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Tap into our extensive network of employers and job
                      seekers to grow your user base.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Innovate Together:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Benefit from the latest in job search technology and
                      AI-driven matching tools.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Strengthen Your Brand:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Align with Turn-To’s reputation for excellence and
                      innovation in the job market.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="mt-4">
            <b>
              {" "}
              Join us at Turn-To to revolutionize the job search and hiring
              landscape. Together, we can create unparalleled value for your
              business and customers.
            </b>
          </div>
        </div>
        <div>
          <LandingPageFooter />
        </div>
      </div>
    </>
  );
};

export default Partnership;
