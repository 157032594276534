import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import logintwitter from "../../Component/icontopng/logintwitter.png";
import logininstagram from "../../Component/icontopng/logininstagram.png";
import loginreddit from "../../Component/icontopng/loginreddit.png";
import loginfacebook from "../../Component/icontopng/loginfacebook.png";
import loginlinedin from "../../Component/icontopng/footerlinkedin.png";
import { useAuth } from "../../context/AuthProvider";
import { Store } from "react-notifications-component";
import mediakit from "../../assets/images/mediakit-removebg-preview.png";


const MediaKit = () => {
    const { AuthData, setAuthData, isAuthenticated } = useAuth();

    //job map link copy funcation
    const copyToClipboard = () => {
        const urlToCopy = process.env.REACT_APP_COMPANY_JOB_MAP + "jobmap?company-info="+ AuthData?.info?.company_name;
        navigator.clipboard
            .writeText(urlToCopy) 
            .then(() => {
                Store.addNotification({
                    title: "copied clipboard",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__zoomIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: false,
                    },
                });
            })
            .catch((error) => {
                // console.error('Failed to copy URL: ', error);
            });

    };

    //iframe link copy funcation
    const copyToClipboardmap = () => {
        const urlToCopy = iframelink; 
        navigator.clipboard
            .writeText(urlToCopy)
            .then(() => {
                Store.addNotification({
                    title: "copied clipboard",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__zoomIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: false,
                    },
                });
            })
            .catch((error) => {
                // console.error('Failed to copy URL: ', error);
            });

    };
    const [iframelink,setIframelink] =useState(`<iframe style="height: 99vh;width: 100%" allow="geolocation; web-share; microphone;"  class="iframe" src="${process.env.REACT_APP_COMPANY_JOB_MAP}job-map-iframe/${AuthData?.username}"></iframe>`)
    return (
        <>
            <div className="user-profile">

                <Row>
                    <Col md={6} className="p-0 mb-2 mt-1">
                        <h4>Media Kit</h4>
                    </Col>
                </Row>


                <div className="addnewcontactusback mediakitmb">
                    <div className="row">
                        <Col lg={12} className="p-0 d-flex justify-content-center">
                            <div className="contactuselogocenter">
                                <img
                                    src={mediakit}
                                    alt="mediakit"
                                    className="contactsuslogoimg"
                                />
                            </div>

                        </Col>
                      

                        <div>
                          <h5 className="mt-4"> <b> Social Sharing UVPs for Employers:</b></h5>
                            <div>
                               
                                <div className="text-justify mb-1">1.Accelerate Hiring with AI*: Speed up your recruitment process with Turn-To's AI-powered job map, which smartly matches you with the ideal candidates. </div>
                                <div className="text-justify mb-1">2.Eco-Conscious Talent Attraction*: Enhance your brand appeal by advertising job openings on Turn-To, showcasing your commitment to sustainable hiring practices.</div>
                                <div className="text-justify mb-1">3. Efficient Candidate Targeting*: Eliminate the noise of unqualified applicants. Use Turn-To to focus on candidates who truly fit your needs and streamline your hiring process</div>
                            </div>
                            <Row>
                                <div className="col-lg-10 pl-0 addcopylinkbuttonsidebar1 mt-2">
                                    <Form.Group
                                        className=""
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label className="m-0 mb-1"><div className="d-flex "><h4 className="headingfont mb-0"><div><b>Link to share:</b></div></h4>
                                        <div className="ml-1">

                                            <div className="d-flex justify-content-center align-items-center">
                                                <a
                                                    href={"https://twitter.com/TurnToJobSearch"}
                                                    className="px-2 loginpageicon"
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={logintwitter}
                                                        alt="twitter"
                                                        className="closepngiocnwidth"
                                                    />
                                                </a>
                                                <a
                                                    href={"https://www.instagram.com/turn__to/"}
                                                    className="px-2 loginpageicon"
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={logininstagram}
                                                        alt="instagram"
                                                        className="closepngiocnwidth"
                                                    />
                                                </a>
                                                <a
                                                    href={"https://www.linkedin.com/"}
                                                    className="px-2 loginpageicon"
                                                    target="_blank"
                                                >

                                                    <img
                                                        src={loginlinedin}
                                                        className="closepngiocnwidth"
                                                        alt="facebook"
                                                    />

                                                </a>
                                                <a
                                                    href={"https://www.reddit.com/r/jobmap/"}
                                                    className="px-2 loginpageicon"
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={loginreddit}
                                                        alt="reddit"
                                                        className="closepngiocnwidth"
                                                    />
                                                </a>
                                                <a
                                                    href={"https://www.facebook.com/jobsearchmap"}
                                                    className="px-2 loginpageicon"
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={loginfacebook}
                                                        alt="facebook"
                                                        className="loginfacebookpng"
                                                    />
                                                </a>
                                            </div>
                                            
                                        </div>
                                        </div>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="newsearchinputaddborderradius"
                                            value={process.env.REACT_APP_COMPANY_JOB_MAP + "jobmap?company-info="+ AuthData?.info?.company_name}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-2 p-0 addcopylinkbuttonsidebar">
                                    <button className=" copyiframebtn" onClick={copyToClipboard}>
                                        Copy Link
                                    </button>
                                </div>
                            </Row>
                            <h5 className=" mt-4"> <b> Iframe UVPs for Career Pages:</b></h5>
                            <div>
                               
                                <div className="text-justify mb-1">1.Enhance Your Career Page*: Integrate Turn-To's intuitive AI job map to provide a seamless search experience that impresses prospective candidates.  </div>
                                <div className="text-justify mb-1">2.Showcase Innovation*: Stand out as a forward-thinking employer by embedding Turn-To's cutting-edge job map, attracting tech-savvy talent.</div>
                                <div className="text-justify mb-1">3. Increase Candidate Engagement*: Offer a modern job search experience with Turn-To’s map, enhancing ease of exploration and delivering personalized results</div>
                            </div>
                            <Row>
                                <div className="col-lg-10 pl-0 addcopylinkbuttonsidebar1 mt-2">
                                    <Form.Group
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label className="m-0"><h4 className="headingfont "><b>Embed Our Job Map</b></h4></Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="newsearchinputaddborderradius"
                                            value={iframelink}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-lg-2 p-0 addcopylinkbuttonsidebar">
                                    <button className="copyiframebtn" onClick={copyToClipboardmap}>
                                        Copy iframe
                                    </button>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MediaKit;