// import React, { Component, Suspense, lazy, useEffect, useState } from "react";
// import { Switch, Route, Redirect } from "react-router-dom";
// import { useAuth } from "../context/AuthProvider";
// import Spinner from "./shared/Spinner";
// import ChangePassword from "./candidate/components/profile/change-password/ChangePassword";
// import Contactus from "../Component/Footer-Page/Contactus";
// import ProfileSetting from "../Component/Footer-Page/ProfileSetting";
// import EmployerChangePassword from "./candidate/components/profile/change-password/EmployerChangePassword";
// import ContactUs from "./candidate/components/profile/Contact Us New/Contactus";
// import NewContactUs from "./candidate/components/profile/Contact Us New/Contactus";
// import Cultureandpreferences from "./candidate/components/profile/culturepreferences/Cultureandpreferences";
// import Productpage from "../Component/Productpage/Productpage";
// import AboutPage from "../Component/AboutPage/AboutPage";
// import UploadResume from "../Component/UploadResume/UploadResume";
// import SearchHistroy from "../Component/SearchHistroy/SeachHistroy";
// import HowToWorkEmployer from "./Landingpage/HowToWorkEmployer";
// import FaqEmployer from "./Landingpage/FaqEmployer";
// import FaqCandidate from "./Landingpage/FaqCandidate";
// import JobAds from "./Landingpage/JobAds";
// import JobMapIntigration from "./Landingpage/JobMapIntigration";
// import SavedJobMaps from "./Landingpage/SavedJobMaps";
// import HowToWorkCandidate from "./Landingpage/HowToWorkCandidate";
// import CultureandPreferenceDropdown from "./Landingpage/CultureandPreferenceDropdown";
// import ATSResumeBuilder from "./Landingpage/ATSResumeBuilder";
// import NewRefundPolicy from "./Landingpage/NewRefundPolicy";
// import Partnership from "./Landingpage/Partenrship";
// import OfferingPage from "./Landingpage/OfferingPage";
// import Hiring from "./Landingpage/Hiring";
// import CandidateProduct from "./Landingpage/CandidateProduct";
// import EmployerProduct from "./Landingpage/EmployerProduct";
// import BillingRecruitment from "./Landingpage/BillingRecruitment";
// import BulkUplord from "../NewPaymentModalFlow/BulkUplord";
// import Course from "../Component/CoursePage/Course";
// import CompanyInfoCard from "./candidate/components/dashboard/CompanyInfoCard";
// // import CompanyJobMapInternal from "./candidate/components/dashboard/CompanyJobMapInternal";
// import MediaKit from "./shared/MediaKit";
// import MultipleResume from "./candidate/components/profile/MultipleResume";
// import DMCA from "./Landingpage/DMCA";
// import ADA from "./Landingpage/ADA";
// import ResumeDownload from "../Component/ComapanyInfo/ResumeDownload";
// import IframeDemo from "./IframeDemo";
// import { useSocket } from "../context/SocketProvider";
// // import CompanyJobMap from "./candidate/components/dashboard/CompanyJobMap";

// const CompanyJobMapInternal = lazy(() => import("./candidate/components/dashboard/CompanyJobMapInternal"));
// const CompanyJobMap = lazy(() => import("./candidate/components/dashboard/CompanyJobMap"));

// const UserRegister = lazy(() => import("../Component/UserRegister"));
// const EmployerRegister = lazy(() => import("../Component/EmployerRegister"));
// const UserLogin = lazy(() => import("../Component/UserLogin"));
// const EmployerLogin = lazy(() => import("../Component/EmployerLogin"));
// const Employerdashbord = lazy(() => import("../Component/Employerdashbord"));
// const Jobsnamewithtable = lazy(() => import("../Component/Jobs/Jobsnamewithtable"));
// // const Jobs = lazy(() => import("../Component/Jobs/Jobs"));
// const Createjob = lazy(() => import("../Component/Jobs/Form/Createjob"));
// const TermOfUse = lazy(() => import("../Component/policy_and_tc/TermOfUse"));
// const Comapnyprofile = lazy(() =>
//   import("../Component/Comapanyprofile/Compnyprofile")
// );
// // const AllEmployerappliaction = lazy(() =>
// //   import("../Component/AllEmployerappliaction/AllEmployerappliaction")
// // );
// const Allapplicationnamewithtable = lazy(() =>
//   import("../Component/AllEmployerappliaction/Allapplicationnamewithtable")
// );
// const Companyview = lazy(() => import("../Component/Companyview/Companyview"));
// const ReportJoblist = lazy(() =>
//   import("../Component/ReportJoblist/ReportJoblist")
// );
// const UserprofileInfo = lazy(() =>
//   import("../Component/ComapanyInfo/UserprofileInfo")
// );
// const UserProfileResumeInfo = lazy(() =>
//   import("../Component/ComapanyInfo/UserProfileResumeInfo")
// );
// const AppliedJobList = lazy(() =>
//   import("../Component/AppliedJobList/AppliedJobList")
// );
// const PrivacyandCookies = lazy(() =>
//   import("../Component/Footer-Page/PrivacyandCookies")
// );
// const Help = lazy(() => import("../Component/Footer-Page/Help"));
// const Legal = lazy(() => import("../Component/Footer-Page/Legal"));
// const Feedback = lazy(() => import("../Component/Footer-Page/Feedback"));
// const Viewjobdetails = lazy(() =>
//   import("../Component/Viewjobdetails/Viewjobdetails")
// );
// const JobDetail = lazy(() => import("../Component/Job-Detail/JobDetail"));
// const Allpage = lazy(() => import("../Component/Footer-Page/Allpage"));
// const PaymentSuccess = lazy(() =>
//   import("../Component/Payment Pages/PaymentSuccess")
// );
// const PaymentSuccessNew = lazy(() =>
//   import("../Component/Payment Pages/PaymentSuccessNew")
// );
// const PaymentUnSuccess = lazy(() =>
//   import("../Component/Payment Pages/PaymentUnSuccess")
// );
// const PaymentHistory = lazy(() =>
//   import("../Component/Payment Pages/PaymentHistory")
// );
// const ReviewOnMap = lazy(() =>
//   import("./candidate/components/save-job/ReviewJobOnMap/ReviewOnMap")
// );
// const ReviewOnMapAllJobs = lazy(() =>
//   import("./candidate/components/save-job/ReviewOnMapAllJobs")
// );

// const Dashboard = lazy(() =>
//   import("./candidate/components/dashboard/Dashboard")
// );
// const DashboardTwo = lazy(() =>
//   import("./candidate/components/dashboard/DashboardTwo")
// );

// const LandingPage = lazy(() => import("../app/Landingpage/LandingPage"));
// const Profile = lazy(() => import("./candidate/components/profile"));
// const SaveJob = lazy(() => import("./candidate/components/save-job/SaveJob"));

// // const Advertise = lazy(() => import("../Component/Footer-Page/Adervertise"));
// // const CompanyInfo = lazy(() =>import("../Component/ComapanyInfo/UserprofileInfo"));
// // const Easyapply = lazy(() => import("../Component/Easyapply/Easyapply"));
// // const PrivacyPolicy = lazy(() => import("../Component/policy_and_tc/PrivacyPolicy"));
// // const CookiesPlicy = lazy(() =>import("../Component/Footer-Page/CookiesPlicy"));
// // const TermsandConditions = lazy(() =>import("../Component/Footer-Page/TermsandConditions"));
// // const RefundPolicy = lazy(() =>import("../Component/Footer-Page/RefundPolicy"));
// // const Trademarks = lazy(() => import("../Component/Footer-Page/Trademarks"));
// // const Coppa = lazy(() => import("../Component/Footer-Page/Coppa"));
// // const Welcome = lazy(() => import("../Component/Footer-Page/Welcome"));
// // const Contactus = lazy(() => import("../Component/Footer-Page/Contactus"));
// // const CopyRightPolicy = lazy(() =>import("../Component/Footer-Page/CopyRightPolicy"));
// // const PrivacyRights = lazy(() =>import("../Component/Footer-Page/PrivacyRights"));
// // const DigitalAdvertisingServices = lazy(() =>import("../Component/Footer-Page/DigitalAdvertisingServices"));

// // URL change applied-joblist to applied-job-list
// // URL change employer/report-joblist to employer/reported-job-list
// // URL change employer/user-profile-info/{name} to employer/user/{name}
// // URL change job-detail/{id} to job/{id}
// // URl change selected-job-map-view to employer/job-map



// function AppRoutes() {
//   const { AuthData, isAuthenticated } = useAuth();

  
//   const [companyNameIframe, setCompanyNameIframe] = useState("");
//   const { socket } = useSocket();
//   useEffect(()=>{
//     if (socket) {
//       socket.off("iframe-company-profile");
//       socket.emit("iframe-company-profile",{});
//       socket.on("iframe-company-profile", (res) => {
//         console.log("res",res);
//         if(res.status){
//           setCompanyNameIframe(res.data.username)
//         }
//       });
//     }
//   },[])
//   return (
//     <Suspense fallback={<Spinner />}>
//       <Switch>
//         <Route exact path="/dmca-page" component={DMCA} />
//         <Route exact path="/ada-page" component={ADA} />
//         <Route exact path="/" component={LandingPage} />
//         <Route exact path="/innovative-employer-solutions" component={HowToWorkEmployer} />
//         <Route exact path="/innovative-candidate-solutions" component={HowToWorkCandidate} />
//         <Route exact path="/faq-employer" component={FaqEmployer} />
//         <Route exact path="/partnership" component={Partnership} />
//         <Route exact path="/offering-page" component={OfferingPage} />
//         <Route exact path="/hiring-process" component={Hiring} />
//         <Route exact path="/candidiate-product" component={CandidateProduct} />
//         <Route exact path="/employer-product" component={EmployerProduct} />
//         <Route exact path="/employer/job/bulk-upload" component={BulkUplord} />
//         <Route exact path="/course" component={Course} />
//         <Route exact path="/companyinfocard" component={CompanyInfoCard} />
        






//         <Route exact path="/faq-candidate" component={FaqCandidate} />
//         <Route exact path="/job-ads" component={JobAds} />
//         <Route exact path="/jobmap-integration" component={JobMapIntigration} />
//         <Route exact path="/saved-jobmap" component={SavedJobMaps} />
//         <Route exact path="/culture-preference" component={CultureandPreferenceDropdown } />
//         <Route exact path="/ats-resume-builder" component={ATSResumeBuilder } />
//         <Route exact path="/new-refund-policy" component={NewRefundPolicy } />
//         <Route exact path="/job-pricing" component={BillingRecruitment } />
//         <Route exact path="/multiple-resume" component={MultipleResume } />









//         <Route exact path="/jobmap" component={Dashboard} />
//         <Route exact path="/job-map-iframe/:company_name" component={CompanyJobMap} />
//         <Route exact path="/company-iframe" render={() => <CompanyJobMap company_name={companyNameIframe} />} />
//         <Route exact path="/dashboardtest" component={DashboardTwo} />
//         <Route exact path="/landing-page" component={LandingPage} />
//         <Route exact path="/user-register" component={UserRegister} />
//         <Route exact path="/employer-register" component={EmployerRegister} />
//         <Route exact path="/user-login" component={UserLogin} />
//         <Route exact path="/term-of-use" component={TermOfUse} />
//         <Route exact path="/company/:company_name" component={Companyview} />
//         <Route exact path="/contact" component={NewContactUs} />

//         <Route exact path="/productpage" component={Productpage} />
//         <Route exact path="/aboutpage" component={AboutPage} />
//         {/* <Route exact path="/upload-resume" component={UploadResume} /> */}
//         <Route exact path="/searchhistory" component={SearchHistroy} />
       
//                <Route exact
//           path="/user/:username/:profile_slug"
//           component={UserProfileResumeInfo}
//         />
//         <Route
//           exact
//           path="/privacy-and-cookies"
//           component={PrivacyandCookies}
//         />
//         <Route exact path="/help" component={Help} />
//         <Route exact path="/legal" component={Legal} />
//         <Route exact path="/feedback" component={Feedback} />
//         <Route exact path="/viewjobdetails" component={Viewjobdetails} />
//         <Route exact path="/job/:job_id" component={JobDetail} />
//         <Route exact path="/about" component={Allpage} />
//         <Route exact path="/employer-login" component={EmployerLogin} />
//         <Route exact path="/contactus" component={Contactus} />

//         <Route exact path="/profile-setting" component={ProfileSetting} />

//         {/* <Route exact path="/disclaimer" component={Advertise} /> */}
//         {/* <Route exact path="/cookies-policy" component={CookiesPlicy} /> */}
//         {/* <Route exact path="/easyapply" component={Easyapply} /> */}
//         {/* <Route exact path="/privacy-policy" component={PrivacyPolicy} /> */}
//         {/* <Route exact path="/welcome" component={Welcome} /> */}
//         {/* <Route exact path="/copyright-policy" component={CopyRightPolicy} /> */}
//         {/* <Route exact path="/privacyrights" component={PrivacyRights} /> */}
//         {/* <Route exact path="/digital-advertising-services" component={DigitalAdvertisingServices}/> */}
//         {/* <Route exact path="/terms-and-conditions" component={TermsandConditions}/> */}
//         {/* <Route exact path="/refundPolicy" component={RefundPolicy} /> */}
//         {/* <Route exact path="/trademarks" component={Trademarks} /> */}
//         {/* <Route exact path="/coppa" component={Coppa} /> */}
//         {isAuthenticated && AuthData.role == "employer" && (
//           <>
//             <Route
//               exact
//               path="/employer/all-application"
//               component={Allapplicationnamewithtable}
//             />
//             <Route
//               exact
//               path="/employer/dashboard"
//               component={Employerdashbord}
//             />
//             <Route exact path="/employer/jobs" component={Jobsnamewithtable} />
//             <Route exact path="/employer/post-job" component={Createjob} />
//             <Route
//               exact
//               path="/employer/company-profile"
//               component={Comapnyprofile}
//             />
//             <Route
//               exact
//               path="/employer/mediakit"
//               component={MediaKit}
//             />
//             <Route
//               exact
//               path="/employer/reported-job-list"
//               component={ReportJoblist}
//             />
//             <Route
//               exact
//               path="/employer/jobs/success/:payment_id"
//               component={PaymentSuccess}
//             />
//                    <Route
//               exact
//               path="/employer/jobs/payment/success/:payment_id"
//               component={PaymentSuccessNew}
//             />
//             <Route
//               exact
//               path="/employer/jobs/error"
//               component={PaymentUnSuccess}
//             />
//             <Route
//               exact
//               path="/employer/payment-history"
//               component={PaymentHistory}
//             />
//             <Route
//               exact
//               path="/employer/jobs/error"
//               component={PaymentUnSuccess}
//             />
//             <Route
//               exact
//               path="/employer/change-password"
//               component={EmployerChangePassword}
//             />
//             <Route
//               exact
//               path="/employer/view-on-map/:_id"
//               component={ReviewOnMap}
//             />
//             <Route
//               exact
//               path="/employer/job-map/:company_name"
//               component={CompanyJobMapInternal}
//             />
//             <Route exact path="/jobs/:search_query" component={Dashboard} />
//           </>
//         )}
//         {isAuthenticated && AuthData.role == "user" && (
//           <>
//             <Route exact path="/saved-job" component={SaveJob} />
//             <Route exact path="/profile" component={MultipleResume} />
//             <Route exact path="/applied-job-list" component={AppliedJobList} />
//             <Route exact path="/change-password" component={ChangePassword} />
//             <Route exact path="/jobs/:search_query" component={Dashboard} />
//             <Route exact path="/user/:username" component={ResumeDownload} // component={UserprofileInfo}
//             />

//             <Route
//               exact
//               path="/culture-preferences"
//               component={Cultureandpreferences}
//             />
//           </>
//         )}
//         <Route exact path="/jobs/:search_query" component={Dashboard} />
//         <Redirect to="/" />
//       </Switch>
//     </Suspense>
//   );
// }

// export default AppRoutes;






//-------------------------------------------------------------------------------------------------------------------------------- Above Old -------------------------------------------------------------------------------------------------------------- 
//-------------------------------------------------------------------------------------------------------------------------------- new --------------------------------------------------------------------------------------------------------------------
import React, { Component, Suspense, lazy, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import Spinner from "./shared/Spinner";
import ChangePassword from "./candidate/components/profile/change-password/ChangePassword";
import Contactus from "../Component/Footer-Page/Contactus";
import ProfileSetting from "../Component/Footer-Page/ProfileSetting";
import EmployerChangePassword from "./candidate/components/profile/change-password/EmployerChangePassword";
import ContactUs from "./candidate/components/profile/Contact Us New/Contactus";
import NewContactUs from "./candidate/components/profile/Contact Us New/Contactus";
import Cultureandpreferences from "./candidate/components/profile/culturepreferences/Cultureandpreferences";
import Productpage from "../Component/Productpage/Productpage";
import AboutPage from "../Component/AboutPage/AboutPage";
import UploadResume from "../Component/UploadResume/UploadResume";
import SearchHistroy from "../Component/SearchHistroy/SeachHistroy";
import HowToWorkEmployer from "./Landingpage/HowToWorkEmployer";
import FaqEmployer from "./Landingpage/FaqEmployer";
import FaqCandidate from "./Landingpage/FaqCandidate";
import JobAds from "./Landingpage/JobAds";
import JobMapIntigration from "./Landingpage/JobMapIntigration";
import SavedJobMaps from "./Landingpage/SavedJobMaps";
import HowToWorkCandidate from "./Landingpage/HowToWorkCandidate";
import CultureandPreferenceDropdown from "./Landingpage/CultureandPreferenceDropdown";
import ATSResumeBuilder from "./Landingpage/ATSResumeBuilder";
import NewRefundPolicy from "./Landingpage/NewRefundPolicy";
import Partnership from "./Landingpage/Partenrship";
import OfferingPage from "./Landingpage/OfferingPage";
import Hiring from "./Landingpage/Hiring";
import CandidateProduct from "./Landingpage/CandidateProduct";
import EmployerProduct from "./Landingpage/EmployerProduct";
import BillingRecruitment from "./Landingpage/BillingRecruitment";
import BulkUplord from "../NewPaymentModalFlow/BulkUplord";
import Course from "../Component/CoursePage/Course";
import CompanyInfoCard from "./candidate/components/dashboard/CompanyInfoCard";
// import CompanyJobMapInternal from "./candidate/components/dashboard/CompanyJobMapInternal";
import MediaKit from "./shared/MediaKit";
import MultipleResume from "./candidate/components/profile/MultipleResume";
import DMCA from "./Landingpage/DMCA";
import ADA from "./Landingpage/ADA";
import ResumeDownload from "../Component/ComapanyInfo/ResumeDownload";
import IframeDemo from "./IframeDemo";
import { useSocket } from "../context/SocketProvider";
// import CompanyJobMap from "./candidate/components/dashboard/CompanyJobMap";

const CompanyJobMapInternal = lazy(() => import("./candidate/components/dashboard/CompanyJobMapInternal"));
const CompanyJobMap = lazy(() => import("./candidate/components/dashboard/CompanyJobMap"));

const UserRegister = lazy(() => import("../Component/UserRegister"));
const EmployerRegister = lazy(() => import("../Component/EmployerRegister"));
const UserLogin = lazy(() => import("../Component/UserLogin"));
const EmployerLogin = lazy(() => import("../Component/EmployerLogin"));
const Employerdashbord = lazy(() => import("../Component/Employerdashbord"));
const Jobsnamewithtable = lazy(() => import("../Component/Jobs/Jobsnamewithtable"));
// const Jobs = lazy(() => import("../Component/Jobs/Jobs"));
const Createjob = lazy(() => import("../Component/Jobs/Form/Createjob"));
const TermOfUse = lazy(() => import("../Component/policy_and_tc/TermOfUse"));
const Comapnyprofile = lazy(() =>
  import("../Component/Comapanyprofile/Compnyprofile")
);
// const AllEmployerappliaction = lazy(() =>
//   import("../Component/AllEmployerappliaction/AllEmployerappliaction")
// );
const Allapplicationnamewithtable = lazy(() =>
  import("../Component/AllEmployerappliaction/Allapplicationnamewithtable")
);
const Companyview = lazy(() => import("../Component/Companyview/Companyview"));
const ReportJoblist = lazy(() =>
  import("../Component/ReportJoblist/ReportJoblist")
);
const UserprofileInfo = lazy(() =>
  import("../Component/ComapanyInfo/UserprofileInfo")
);
const UserProfileResumeInfo = lazy(() =>
  import("../Component/ComapanyInfo/UserProfileResumeInfo")
);
const AppliedJobList = lazy(() =>
  import("../Component/AppliedJobList/AppliedJobList")
);
const PrivacyandCookies = lazy(() =>
  import("../Component/Footer-Page/PrivacyandCookies")
);
const Help = lazy(() => import("../Component/Footer-Page/Help"));
const Legal = lazy(() => import("../Component/Footer-Page/Legal"));
const Feedback = lazy(() => import("../Component/Footer-Page/Feedback"));
const Viewjobdetails = lazy(() =>
  import("../Component/Viewjobdetails/Viewjobdetails")
);
const JobDetail = lazy(() => import("../Component/Job-Detail/JobDetail"));
const Allpage = lazy(() => import("../Component/Footer-Page/Allpage"));
const PaymentSuccess = lazy(() =>
  import("../Component/Payment Pages/PaymentSuccess")
);
const PaymentSuccessNew = lazy(() =>
  import("../Component/Payment Pages/PaymentSuccessNew")
);
const PaymentUnSuccess = lazy(() =>
  import("../Component/Payment Pages/PaymentUnSuccess")
);
const PaymentHistory = lazy(() =>
  import("../Component/Payment Pages/PaymentHistory")
);
const ReviewOnMap = lazy(() =>
  import("./candidate/components/save-job/ReviewJobOnMap/ReviewOnMap")
);
const ReviewOnMapAllJobs = lazy(() =>
  import("./candidate/components/save-job/ReviewOnMapAllJobs")
);

const Dashboard = lazy(() =>
  import("./candidate/components/dashboard/Dashboard")
);
const DashboardTwo = lazy(() =>
  import("./candidate/components/dashboard/DashboardTwo")
);

const LandingPage = lazy(() => import("../app/Landingpage/LandingPage"));
const Profile = lazy(() => import("./candidate/components/profile"));
const SaveJob = lazy(() => import("./candidate/components/save-job/SaveJob"));

// const Advertise = lazy(() => import("../Component/Footer-Page/Adervertise"));
// const CompanyInfo = lazy(() =>import("../Component/ComapanyInfo/UserprofileInfo"));
// const Easyapply = lazy(() => import("../Component/Easyapply/Easyapply"));
// const PrivacyPolicy = lazy(() => import("../Component/policy_and_tc/PrivacyPolicy"));
// const CookiesPlicy = lazy(() =>import("../Component/Footer-Page/CookiesPlicy"));
// const TermsandConditions = lazy(() =>import("../Component/Footer-Page/TermsandConditions"));
// const RefundPolicy = lazy(() =>import("../Component/Footer-Page/RefundPolicy"));
// const Trademarks = lazy(() => import("../Component/Footer-Page/Trademarks"));
// const Coppa = lazy(() => import("../Component/Footer-Page/Coppa"));
// const Welcome = lazy(() => import("../Component/Footer-Page/Welcome"));
// const Contactus = lazy(() => import("../Component/Footer-Page/Contactus"));
// const CopyRightPolicy = lazy(() =>import("../Component/Footer-Page/CopyRightPolicy"));
// const PrivacyRights = lazy(() =>import("../Component/Footer-Page/PrivacyRights"));
// const DigitalAdvertisingServices = lazy(() =>import("../Component/Footer-Page/DigitalAdvertisingServices"));

// URL change applied-joblist to applied-job-list
// URL change employer/report-joblist to employer/reported-job-list
// URL change employer/user-profile-info/{name} to employer/user/{name}
// URL change job-detail/{id} to job/{id}
// URl change selected-job-map-view to employer/job-map



function AppRoutes() {
  const { AuthData, isAuthenticated } = useAuth();

  
  const [companyNameIframe, setCompanyNameIframe] = useState("");
  const { socket } = useSocket();
  useEffect(()=>{
    if (socket) {
      socket.off("iframe-company-profile");
      socket.emit("iframe-company-profile",{});
      socket.on("iframe-company-profile", (res) => {
        console.log("res",res);
        if(res.status){
          setCompanyNameIframe(res.data.username)
        }
      });
    }
  },[])


  const userRoutes = [
    { path: "/saved-job", component: SaveJob },
    { path: "/profile", component: MultipleResume },
    { path: "/applied-job-list", component: AppliedJobList },
    { path: "/change-password", component: ChangePassword },
    { path: "/jobs/:search_query", component: Dashboard },
    { path: "/user/:username", component: ResumeDownload },
    { path: "/culture-preferences", component: Cultureandpreferences },
  ];

  const employerRoutes = [
    { path: '/employer/all-application', component: Allapplicationnamewithtable },
    { path: '/employer/dashboard', component: Employerdashbord },
    { path: '/employer/jobs', component: Jobsnamewithtable },
    { path: '/employer/post-job', component: Createjob },
    { path: '/employer/company-profile', component: Comapnyprofile },
    { path: '/employer/mediakit', component: MediaKit },
    { path: '/employer/reported-job-list', component: ReportJoblist },
    { path: '/employer/jobs/success/:payment_id', component: PaymentSuccess },
    { path: '/employer/jobs/payment/success/:payment_id', component: PaymentSuccessNew },
    { path: '/employer/jobs/error', component: PaymentUnSuccess },
    { path: '/employer/payment-history', component: PaymentHistory },
    { path: '/employer/change-password', component: EmployerChangePassword },
    { path: '/employer/view-on-map/:_id', component: ReviewOnMap },
    { path: '/employer/job-map/:company_name', component: CompanyJobMapInternal },
    { path: '/jobs/:search_query', component: Dashboard },
  ];


  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/dmca-page" component={DMCA} />
        <Route exact path="/ada-page" component={ADA} />
        <Route exact path="/innovative-employer-solutions" component={HowToWorkEmployer} />
        <Route exact path="/innovative-candidate-solutions" component={HowToWorkCandidate} />
        <Route exact path="/faq-employer" component={FaqEmployer} />
        <Route exact path="/partnership" component={Partnership} />
        <Route exact path="/offering-page" component={OfferingPage} />
        <Route exact path="/hiring-process" component={Hiring} />
        <Route exact path="/candidiate-product" component={CandidateProduct} />
        <Route exact path="/employer-product" component={EmployerProduct} />
        <Route exact path="/employer/job/bulk-upload" component={BulkUplord} />
        <Route exact path="/course" component={Course} />
        <Route exact path="/companyinfocard" component={CompanyInfoCard} />
        <Route exact path="/faq-candidate" component={FaqCandidate} />
        <Route exact path="/job-ads" component={JobAds} />
        <Route exact path="/jobmap-integration" component={JobMapIntigration} />
        <Route exact path="/saved-jobmap" component={SavedJobMaps} />
        <Route exact path="/culture-preference" component={CultureandPreferenceDropdown } />
        <Route exact path="/ats-resume-builder" component={ATSResumeBuilder } />
        <Route exact path="/new-refund-policy" component={NewRefundPolicy } />
        <Route exact path="/job-pricing" component={BillingRecruitment } />
        <Route exact path="/multiple-resume" component={MultipleResume } />
        <Route exact path="/jobmap" component={Dashboard} />
        <Route exact path="/job-map-iframe/:company_name" component={CompanyJobMap} />
        <Route exact path="/company-iframe" render={() => <CompanyJobMap company_name={companyNameIframe} />} />
        <Route exact path="/dashboardtest" component={DashboardTwo} />
        <Route exact path="/landing-page" component={LandingPage} />
        <Route exact path="/user-register" component={UserRegister} />
        <Route exact path="/employer-register" component={EmployerRegister} />
        <Route exact path="/user-login" component={UserLogin} />
        <Route exact path="/term-of-use" component={TermOfUse} />
        <Route exact path="/company/:company_name" component={Companyview} />
        <Route exact path="/contact" component={NewContactUs} />
        <Route exact path="/productpage" component={Productpage} />
        <Route exact path="/aboutpage" component={AboutPage} />
        {/* <Route exact path="/upload-resume" component={UploadResume} /> */}
        <Route exact path="/searchhistory" component={SearchHistroy} />
        <Route exact path="/user/:username/:profile_slug" component={UserProfileResumeInfo}/>
        <Route exact path="/privacy-and-cookies" component={PrivacyandCookies}/>
        <Route exact path="/help" component={Help} />
        <Route exact path="/legal" component={Legal} />
        <Route exact path="/feedback" component={Feedback} />
        <Route exact path="/viewjobdetails" component={Viewjobdetails} />
        <Route exact path="/job/:job_id" component={JobDetail} />
        <Route exact path="/about" component={Allpage} />
        <Route exact path="/employer-login" component={EmployerLogin} />
        <Route exact path="/contactus" component={Contactus} />
        <Route exact path="/profile-setting" component={ProfileSetting} />

        {/* <Route exact path="/disclaimer" component={Advertise} /> */}
        {/* <Route exact path="/cookies-policy" component={CookiesPlicy} /> */}
        {/* <Route exact path="/easyapply" component={Easyapply} /> */}
        {/* <Route exact path="/privacy-policy" component={PrivacyPolicy} /> */}
        {/* <Route exact path="/welcome" component={Welcome} /> */}
        {/* <Route exact path="/copyright-policy" component={CopyRightPolicy} /> */}
        {/* <Route exact path="/privacyrights" component={PrivacyRights} /> */}
        {/* <Route exact path="/digital-advertising-services" component={DigitalAdvertisingServices}/> */}
        {/* <Route exact path="/terms-and-conditions" component={TermsandConditions}/> */}
        {/* <Route exact path="/refundPolicy" component={RefundPolicy} /> */}
        {/* <Route exact path="/trademarks" component={Trademarks} /> */}
        {/* <Route exact path="/coppa" component={Coppa} /> */}
        {isAuthenticated && AuthData.role === "employer" ? (
          employerRoutes.map(({ path, component }) => (
            <Route key={path} exact path={path} component={component} />
          ))
        ) : (
          employerRoutes.map(({ path }) => (
            <Route key={path} exact path={path} render={() => <Redirect to="/employer-login" />} />
          ))
        )}

        {isAuthenticated && AuthData.role === "user" ? (
          userRoutes.map(({ path, component }) => (
            <Route key={path} exact path={path} component={component} />
          ))
        ) : (
          userRoutes.map(({ path }) => (
            <Route key={path} exact path={path} render={() => <Redirect to="/user-login" />} />
          ))
        )}


        <Route exact path="/jobs/:search_query" component={Dashboard} />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}

export default AppRoutes;
