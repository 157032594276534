import React, { useState } from "react";
import "./NewAddPage.css";
import { Accordion, Col, Modal, Nav, Navbar, Row } from "react-bootstrap";
import turntologo from "../../assets/images/loginpagenewlogo.png";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";
import NewEmployerLogin from "../candidate/components/common/CompanyDetail/NewEmployerLogin";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MyButton from "../candidate/components/common/Button/MyButton";
import NewUserLogin from "../candidate/components/common/CompanyDetail/NewUserLogin";


const HowToWorkEmployer = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  const [employerloginmodal, setEmployerloginmodal] = useState(false);
  const [modalShowLogin, setModalShowLogin] = useState(false);
  const history = useHistory();
  const postjobbtn = () => {
    if (isAuthenticated && AuthData.role == "employer") {
      history.push("/employer/dashboard");
    } else {
      setEmployerloginmodal(true);
    }
  };
  return (
    <>
      <div>
        <div className="container new_pagesmargin" onClick={RemoveSlider}>
          <div className="text-center mb-3">
            <img
              src={turntologo}
              alt="turn-to"
              className="text-center turntologocssnew"
            />
          </div>
          <h3 className="text-center">
            <b>
              Unleash the Power of Recruitment with Turn-To's AI-Driven Job Map
            </b>
          </h3>
          <hr></hr>
          <div className="mt-3 mb-3 text-justify">
            <b>
              Turn-To revolutionizes recruitment with a unique AI-powered job
              map designed to connect you with top talent efficiently and
              cost-effectively.
            </b>
          </div>
          <div>
            <h5>
              <b>Here's how Turn-To streamlines your hiring process:</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Effortless Job Posting:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Ditch the tedious process of individual postings. Bulk
                      upload your jobs from a simple spreadsheet template,
                      saving you precious time.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Targeted Visibility:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Turn-To's magic lies in its intelligent job map. Our AI
                      analyzes your job descriptions and cultural indicators to
                      pinpoint the ideal candidate profile. This goes beyond
                      basic keyword matching, ensuring your listings reach the
                      most relevant talent pool.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Seamless Social Sharing:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Amplify your reach by effortlessly sharing your Turn-To
                      job postings directly on social media platforms.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  4. Effortless Employer Branding
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Showcase your innovative approach to recruitment by
                      integrating our free iFrame directly on your career page.
                      This allows candidates to seamlessly search for
                      opportunities within your organization, enhancing your
                      employer brand.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div>
            <h5>
              <b>
                Turn-To doesn't stop at just job placement. We empower you with
                advanced features as well:
              </b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Flat-Rate Pricing:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Predictability is key! Unlike traditional job boards with
                      complex pricing models, Turn-To offers a flat daily rate
                      for showcasing your jobs on the map. Choose between 15-day
                      or 30-day placements, giving you flexibility based on your
                      hiring needs. This distinctive feature enhances your
                      employer brand and offers candidates a dynamic way to
                      explore opportunities within your organization.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. AI-Powered Matching:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Our intelligent technology goes beyond just keywords. It
                      analyzes the essence of your job descriptions, ensuring a
                      higher probability of finding candidates who align not
                      only with the required skills, but also with your company
                      culture.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Seamless Integration:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      The Turn-To platform integrates effortlessly with your
                      existing workflows. Bulk upload your jobs, easily select
                      placement duration, and manage everything from a
                      centralized employer dashboard.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="mt-3">
            <h5>
              <b>Benefits for Employers:</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2", "3", "4", "5"]} alwaysOpen>
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Cost-Effective Recruitment:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Flat daily rates ensure you know exactly what your budget
                      is upfront. No hidden fees, no surprises.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Targeted Visibility:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Reach the right candidates with our AI-powered matching
                      and job map search.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Time Savings:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Bulk upload your jobs and easily select your preferred
                      duration, streamlining the posting process.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  4. Boosted Employer Brand:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Showcase your commitment to innovation with our unique job
                      map integration.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  5. Increased Visibility:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Effortlessly share your job listings on social media,
                      expanding your reach even further.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  6. Improved Candidate Quality:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Our focus on semantic search helps reduce applications
                      from unqualified candidates.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="mt-3 text-justify">
            <h5>
              <b>
                Ready to transform your recruitment strategy and attract top
                talent?
              </b>
            </h5>
          </div>
          <div className="row mt-3 howtoworkemp">
            <Col lg={12} md={12} className="p-0 ">
              <h5>
                <b>Get started with Turn-To today!</b>
              </h5>
              <div className="text-justify">
                Simply sign up, bulk upload your jobs, and choose your desired
                placement duration. Our AI will take care of the rest, ensuring
                your open positions reach the perfect candidates.
              </div>

              <div className="d-flex justify-content-center mt-3"><MyButton onClick={(e) => postjobbtn()}>Get Started</MyButton></div>
            </Col>

          </div>
        </div>
        <div>
          <LandingPageFooter />
        </div>
        {!isAuthenticated ? (
          <Modal
            show={employerloginmodal}
            onHide={() => setEmployerloginmodal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="remove_modelback"
          >
            <Modal.Body className="p-0">
              <div className="">
                <NewEmployerLogin
                  setEmployerloginmodal={setEmployerloginmodal}
                />
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          AuthData?.role === "user" && (
            <Modal
            show={modalShowLogin}
            onHide={() => setModalShowLogin(false)}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="remove_modelback"
            >
              <Modal.Body className="p-0">
                <div className="">
                <NewUserLogin setModalShowLogin={setModalShowLogin} />
                </div>
              </Modal.Body>
            </Modal>
          )
        )}
      </div>
    </>
  );
};

export default HowToWorkEmployer;
