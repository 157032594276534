import React from "react";
import "./card.css";
import Tooltip from "react-tooltip-lite";

import MyButton from "../../common/Button/MyButton";
import Avatar from "react-avatar";
import {
  PostdeleteEducation,
  PostdeleteExperience,
} from "../../../../../Api/Api";
import editdatapng from "../../../../../Component/icontopng/editdata.png";
import downlordimg from "../../../../../Component/icontopng/hoverdownload.png";

import deletecarddatapng from "../../../../../Component/icontopng/deletecarddata.png";

function Card(props) {
  // console.log("props", props);
  return (
    <div className="menu-card position-relative">
      <div className="stretch-card  my-2 p-0 " style={{ minHeight: "120px" }}>
        <div className="card shadow-lg exp_margin">
          <div className="card-body exp_padding">
            <div className="d-flex justify-content-between h-100">
              <div className="left-div d-block d-md-flex">
                <div className="mr-0 mr-md-3">
                  <p>
                    <Avatar size="50" round="8px" name={props.subTitle} />
                  </p>
                </div>
                <div>
                  <h5>
                    <b>{props.subTitle}</b>
                  </h5>
                  <h6> {props.title}</h6>
                  <div>
                    {props.month[props.sMonth]} {props.sYear} -{" "}
                    {props.eMonth
                      ? props.month[props.eMonth] + " " + props.eYear
                      : "Present"}
                  </div>
                  {/* <div
                    className="cardcontentbreack"
                    dangerouslySetInnerHTML={{ __html: props.desc }}
                  ></div> */}
                </div>
              </div>
              {/* <div className="right-div d-flex flex-column mt-auto mb-auto"> */}
              <div className="right-div d-flex flex-column">
              <Tooltip content="Edit">
                <MyButton
                  className="p-1 mb-1"
                  onClick={(e) => props.EditApi(props._id)}
                >
                  <img
                    src={editdatapng}
                    alt="edit"
                    className="closepngiocnwidth"
                  />
                </MyButton>
                </Tooltip>
                {props.file !== undefined 
                ? props.file !== "" ? 
                <Tooltip content="Download">
                <MyButton
                  className="p-1 "
                  onClick={(e) => window.open(props.file)}
                >
                  <img
                    src={downlordimg}
                    alt="download"
                    className="closepngiocnwidth"
                  />
                </MyButton> 
                </Tooltip>
                : "" 
                :""
                }
              <Tooltip content="Delete">
                <MyButton
                  className="p-1 mt-1 deletebtn"
                  onClick={(e) => props.DeleteApi(props._id)}
                >
                  <img
                    src={deletecarddatapng}
                    alt="delete"
                    className="closepngiocnwidth"
                    set="bold"
                  />
                </MyButton>
                </Tooltip>

        

         
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
