import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './context/AuthProvider';
import { SocketProvider } from './context/SocketProvider';

ReactDOM.render(
  <BrowserRouter>
    <SocketProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </SocketProvider>
  </BrowserRouter>
, document.getElementById('root'));

serviceWorker.unregister();