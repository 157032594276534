import Reaact, { useState } from "react";
import "./NewAddPage.css";
import { Accordion, Col, Modal, Nav, Navbar, Row } from "react-bootstrap";
import footerlogo from "../../assets/images/loginpagenewlogo.png";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewEmployerLogin from "../candidate/components/common/CompanyDetail/NewEmployerLogin";
import NewUserLogin from "../candidate/components/common/CompanyDetail/NewUserLogin";

const SavedJobMaps = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const history = useHistory();
  const [employerloginmodal, setEmployerloginmodal] = useState(false);
  const [modalShowLogin, setModalShowLogin] = useState(false);

  const postjobbtn = () => {
    if (isAuthenticated && AuthData.role == "user") {
      history.push("/saved-job");
    } else {
      setModalShowLogin(true);
    }
  };

  return (
    <>
      <div>
        <div className="container new_pagesmargin">
          <h3 className="text-center">
            <b>Optimize Your Job Search with Turn-To’s Save Jobs Map</b>
          </h3>
          <hr></hr>
          <div className="mt-3 mb-3 text-justify">
            <b>
              Discover how Turn-To’s Save Jobs Map revolutionizes your job
              search by bookmarking and visualizing your preferred job
              opportunities on a personalized map. Navigate your future career
              with ease today.
            </b>
          </div>
          <div className="mt-3 mb-3 text-justify">
            In today’s dynamic job market, staying organized and strategic in
            your job search is crucial. Turn-To introduces an innovative
            solution: the Save Jobs Map. This feature is designed exclusively
            for candidates to streamline their job-hunting process by
            bookmarking and visualizing selected job opportunities on a
            dedicated map. Here’s how to leverage this unique tool to enhance
            your job search strategy.
          </div>
          <div>
            <h5>
              <b>How It Works:</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2", "3", "4"]} alwaysOpen>
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Bookmark Your Favorite Jobs:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      As you explore various opportunities on Turn-To’s job map,
                      you can bookmark jobs that interest you. These jobs are
                      automatically added to your Save Jobs Map for easy access.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Access Your Personalized Save Jobs Map:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Navigate to your personalized Save Jobs Map via your
                      candidate dashboard. Here, you’ll find all your bookmarked
                      jobs pinpointed on a separate map tailored just for you.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Explore Job Details with Precision:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Click on any job marker to view detailed information about
                      the role, including job description, requirements, and how
                      to apply. This feature ensures you have all the necessary
                      information at your fingertips.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  4. Distance and Commute Insights:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      The Save Jobs Map shows you where each job is located and
                      provides insights into the distance from your current
                      location. This helps you consider commute times and
                      potential relocation needs as part of your decision-making
                      process.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  5. Streamline Your Application Process:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      With direct links to apply for each job, the Save Jobs Map
                      simplifies the application process. You can track which
                      jobs you’ve applied to and plan your next steps, all
                      within the same platform.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div>
            <h5>
              <b>Benefits for Candidates:</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Enhanced Job Search Organization:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Keep track of jobs you’re interested in without the hassle
                      of external notes or spreadsheets.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Geographical Insights:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Make informed decisions about potential job opportunities
                      based on their location and your commute preferences.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Efficient Application Process:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Apply directly from your Save Jobs Map, saving time and
                      streamlining your job search efforts.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="row mt-3 howtoworkemp">
            <Col lg={12} md={12} className="p-0 ">
              <h5>
                <b>Getting Started:</b>
              </h5>
              <div className="text-justify">
                To start using the Save Jobs Map, log in to your Turn-To
                candidate account. Explore the job map, bookmark your favorite
                listings, and they will automatically appear on your
                personalized Save Jobs Map. It’s that easy!
              </div>
            </Col>
          </div>

          <div className="row mt-3 howtoworkemp">
            <Col lg={12} md={12} className="p-0 ">
              <h5>
                <b>Conclusion:</b>
              </h5>
              <div className="text-justify">
                Turn-To’s Save Jobs Map is more than just a tool; it’s your
                companion in navigating the complexities of the job market. By
                providing a personalized and geographical perspective on your
                job search, we empower you to make informed decisions and move
                closer to your ideal career. Start leveraging the power of the
                Save Jobs Map and transform your job search journey today.
              </div>
            </Col>
          </div>
          <div className="mt-3 row">
            <Row>
              <Col md={12} className="p-0">
                <h4 className="discoversize text-justify">
                  Ready to simplify your job search? Log in to your Turn-To
                  account now and discover your dream job with the Save Jobs
                  Map.
                </h4>
              </Col>
              <Col
                md={12}
                className="d-flex align-items-center justify-content-center newcssofflex p-0"
              >
                <button
                  className="landingpagepostjobbutton mt-2"
                  onClick={(e) => postjobbtn()}
                >
                  Saved Job Map
                </button>
              </Col>
            </Row>
          </div>

          {!isAuthenticated ? (
            <Modal
              show={modalShowLogin}
              onHide={() => setModalShowLogin(false)}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="remove_modelback"
            >
              <Modal.Body className="p-0">
                <div className="">
                  <NewUserLogin setModalShowLogin={setModalShowLogin} />
                </div>
              </Modal.Body>
            </Modal>
          ) : (
            AuthData?.role === "employer" && (
              <Modal
                show={employerloginmodal}
                onHide={() => setEmployerloginmodal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="remove_modelback"
              >
                <Modal.Body className="p-0">
                  <div className="">
                    <NewEmployerLogin
                      setEmployerloginmodal={setEmployerloginmodal}
                    />
                  </div>
                </Modal.Body>
              </Modal>
            )
          )}
        </div>
        <div>
          <LandingPageFooter />
        </div>
      </div>
    </>
  );
};

export default SavedJobMaps;
