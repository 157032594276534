import React, { useEffect,  useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import footerlogo from "../../assets/images/loginpagenewlogo.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Dropdown,
} from "react-bootstrap";
import candidate from "../../assets/images/TURN-TO (2).png";
import employer from "../../assets/images/TURN-TO (1).png";
import "../Landingpage/HeaderNew.css";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useAuth } from "../../context/AuthProvider";
import NewEmployerLogin from "../candidate/components/common/CompanyDetail/NewEmployerLogin";
import landingjoblogout from "../../Component/icontopng/landingpagelogoutimg.png";
import bottomlogo from "../../Component/icontopng/bottomarrow.png";

import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Avatar from "react-avatar";
import NewUserLogin from "../candidate/components/common/CompanyDetail/NewUserLogin";
const HeaderNew = () => {
  const { AuthData, setAuthData, isAuthenticated,setProfileScores} = useAuth();
  const [modalShowLogin, setModalShowLogin] = React.useState(false);
  const [employerloginmodal, setEmployerloginmodal] = useState(false);

  // post job
  const postjobbtn = () => {
    if (isAuthenticated && AuthData.role == "employer") {
      history.push("/employer/post-job");
    } else {
      setEmployerloginmodal(true);
    }
  };
  const loginUserlandinnavbar = () => {
    setModalShowLogin(true);
  }

  // navbar navigation link
  const setnavbarinnavigatelink = () => {
    if (isAuthenticated && AuthData.role == "employer") {
      history.push("/employer/dashboard");
    } else if (isAuthenticated && AuthData.role == "user") {
      history.push("/profile");
    }
  }
  const [locationmsg, setLocationmsg] = useState(false);
  const history = useHistory();
  const locationmodalclose = () => {
    setLocationmsg(false);
  };

  // logout 
  const landingLogOutPage = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure want to logout?",
      text: "",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      imageUrl: isAuthenticated && AuthData.role === "user" ? candidate : employer,
      imageHeight: 50,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Log Out",
      cancelButtonText: "No",
      showCancelButton: true,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem("token");
        setAuthData(null);
        setProfileScores({})
        history.push("/jobmap");
      }
    });
  };
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };

  useEffect(() => {
    window.addEventListener("scroll", RemoveSlider);
    return () => {
      window.removeEventListener("scroll", RemoveSlider);
    };
  }, [window.addEventListener("scroll", RemoveSlider)]);
  return ( 

    <>
      <div >
        {/* navbar */}
        <div className="land_backwhite">
          <img
            src={footerlogo}
            className="mobile_icon  turntolandicon"
            alt="Turn-To"
          />
        </div>
        <div className="d-none d-md-block">
          <Navbar
            collapseOnSelect
            expand="md"
            bg="light"
            variant="light"
            style={{ width: "100%", zIndex: "1001" }}
            className="land_nav pr-0 cssaddheaderzindexset"
            fixed="top"
          >
            <Navbar.Brand>
              {" "}
              <a href="/">
                <img
                  src={footerlogo}
                  className="search_icon land_icon"
                  alt="Turn-To"
                />
              </a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            {isAuthenticated ? (
              <Navbar.Collapse id="responsive-navbar-nav" className="ml-4">
                <Nav className="">
                  <div className="d-flex align-items-center">
                  {AuthData.role === "user" ? (
                  AuthData.info.profile_image == "" ? (
                    <Avatar
                      size="30"
                      round="50px"
                      name={AuthData?.first_name + " " + AuthData?.last_name}
                      onClick={setnavbarinnavigatelink}
                      className="pointer"
                    />
                  ) :  (
                     <img src={AuthData.info.profile_image} alt="Profile" className="employerlandingpageimageheader"   onClick={setnavbarinnavigatelink}/>        
                  ) ): 
                  AuthData.info.company_image == "" ? (
                    <Avatar
                      size="30"
                      round="50px"
                      name={AuthData?.first_name + " " + AuthData?.last_name}
                      onClick={setnavbarinnavigatelink}
                      className="pointer"
                    />
                  ) :  (
                     <img src={AuthData.info.company_image} alt="Profile" className="employerlandingpageimageheader"   onClick={setnavbarinnavigatelink}/>        
                  )}

                    <div className="mx-2 text-capitalize pointer" onClick={setnavbarinnavigatelink}>
                      {AuthData.first_name + " " + AuthData.last_name}
                    </div>

                    <Dropdown>
                      <Dropdown.Toggle
                        className="p-0 bg-transparent border-0 shadow-none"
                        variant="primary"
                      >
                        <img
                          src={landingjoblogout}
                          className="landingjoblogoutclass"
                          alt="logOut"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        className="p-2 addlandingpagelogoutdroupdown"
                        style={{ borderRadius: "8px" }}
                      >
                        <Dropdown.Item
                          className="addhoverlogoutclass"
                          onClick={landingLogOutPage}
                        >
                          <div className="d-flex">
                            <div className="addlogoutbuttonset mr-2"></div>
                            Logout
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Nav>
              </Navbar.Collapse>
            ) : (
              <>
                <Navbar.Collapse id="setdroupdownincenter" className="ml-4">
                  <Nav className="">
                    <div className="d-flex align-items-center">
                    <div class="dropdown">
                      <button class="dropbtn "> For Candidates</button>
                      <div class="dropdown-content p-2">
                        <a className="px-2 py-1 new_fontcss cursor-pointer" onClick={() => setModalShowLogin(true)}>Create Free Account</a>
                        <a href="/innovative-candidate-solutions" className="px-2 py-1 new_fontcss cursor-pointer">How it Works</a>
                        <a href="/jobmap" className="px-2 py-1 new_fontcss cursor-pointer">Search Jobs</a>
                        <a href="/saved-jobmap" className="px-2 py-1 new_fontcss cursor-pointer">My Saved Jobs Map</a>

                        <a className="px-2 py-1 new_fontcss cursor-pointer" href="/ats-resume-builder">ATS Resume Builder</a>

                        <a className="px-2 py-1 new_fontcss cursor-pointer" onClick={() => setModalShowLogin(true)}>Resume /  CV Download</a>

                        <a className="px-2 py-1 new_fontcss cursor-pointer" href="/culture-preference">Culture & Preferences</a>

                        <a href="https://blog.turn-to.com/category/candidates/" className="px-2 py-1 new_fontcss cursor-pointer">Candidates Blog</a>

                        <a href="/faq-candidate" className="px-2 py-1 new_fontcss cursor-pointer">FAQ for Candidates</a>

                        <a href="/contact" className="px-2 py-1 new_fontcss cursor-pointer">Contact Us</a>




                      </div>
                    </div>
                    <div>
                    <img
                    src={bottomlogo}
                    alt="images"
                    className="headerarrowturntologo"
                    width="640"
                    height="360"
                  />
                    </div>
                    </div>

                    <div className="d-flex align-items-center ml-2">
                    <div class="dropdown">
                      <button class="dropbtn "> For Employers</button>
                      <div class="dropdown-content p-2">
                        <a className="px-2 py-1 new_fontcss cursor-pointer"  onClick={() => setEmployerloginmodal(true)}>Post a Job For Free</a>
                        <a href="/innovative-employer-solutions" className="px-2 py-1 new_fontcss cursor-pointer">How it Works</a>
                        <a href="/job-ads" className="px-2 py-1 new_fontcss cursor-pointer">Job Ads</a>
                        <a href="/jobmap-integration" className="px-2 py-1 new_fontcss cursor-pointer">Job Map Iframe</a>

                        <a className="px-2 py-1 new_fontcss cursor-pointer" href="https://blog.turn-to.com/category/employer/">Employer Blog</a>

                        <a className="px-2 py-1 new_fontcss cursor-pointer" href="/faq-employer">FAQ for Employers</a>

                        <a className="px-2 py-1 new_fontcss cursor-pointer" href="/contact">Contact Us</a>

                      

                      </div>
                    </div>
                    <div>
                    <img
                    src={bottomlogo}
                    alt="images"
                    className="headerarrowturntologo"
                    width="640"
                    height="360"
                  />
                    </div>
                    </div>


                    
                  </Nav>
                </Navbar.Collapse>

              </>
            )}

            {!isAuthenticated ?
              <Nav className="">
                
                <button
                  className="landingpagepostjobbutton mr-3"
                  onClick={(e) => postjobbtn()}
                >
                  Employer Login
                </button>
                <button
                  className="landingpagepostjobbutton mr-3"
                  onClick={(e) => loginUserlandinnavbar()}
                >
                  Candidate Login
                </button>
                
              </Nav>
              : ""
            }
          </Navbar>
        </div>
       







      </div>

      <Modal
        className="zindexcust"
        show={locationmsg}
        onHide={locationmodalclose}
        centered
      >
        <Modal.Header>
          <Modal.Title className="model_side">
            Notice
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your current location can't be found. Please refresh Turn-to website
          tab otherwise some functionalities will not be working.
        </Modal.Body>
        <Modal.Footer>
          <button className="btnclose text-white" onClick={locationmodalclose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {!isAuthenticated ? (
        <Modal
          show={employerloginmodal}
          onHide={() => setEmployerloginmodal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="remove_modelback"
        >
          <Modal.Body className="p-0">
            <div className="">
              <NewEmployerLogin url="employer/dashboard" setEmployerloginmodal={setEmployerloginmodal} />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        AuthData?.role === "user" && (
          <Modal
            show={employerloginmodal}
            onHide={() => setEmployerloginmodal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="remove_modelback"
          >
            <Modal.Body className="p-0">
              <div className="">
                <NewEmployerLogin
                 url={"/employer/dashboard"}
                  setEmployerloginmodal={setEmployerloginmodal}
                />
              </div>
            </Modal.Body>
          </Modal>
        )
      )}
      {!isAuthenticated && (
        <Modal
          show={modalShowLogin}
          onHide={() => setModalShowLogin(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="remove_modelback"
        >
          <Modal.Body className="p-0">
            <div className="">
              <NewUserLogin url={"/jobmap"} setModalShowLogin={setModalShowLogin} />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default HeaderNew;


