import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSocket } from "../../context/SocketProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Skeleton from "react-loading-skeleton";

const Popularsearches = (props) => {
  const history = useHistory();

  const populardatasearch = (data) => {
    // setSearch(data.keyword);
    history.push(
      `/jobmap?distance=20&location=${data.location}&latitude=${data.latitude}&longitude=${data.longitude}&search=${data.keyword}`
    );
    window.location.reload();
  };
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   if (props.searchdata.length) {
  //     setIsLoading(false);
  //   }
  // }, [props.searchdata]);
  return (
    <div className="py-2 px-3">
      {/* <h4>
                        <b>Popular Searches</b>
                        </h4> */}
      <div className="addallserchdatasetoffcavas">
        {isLoading ? (
          <Skeleton count={50} />
        ) : (
          <Row>
            {props.searchdata.map((item, index) => (
              <Col
                key={index}
                xs={12}
                className="mt-1 d-flex justify-content-between align-items-center pointer "
                onClick={(e) => populardatasearch(item)}
              >
                <div className="form-check pointer my-1">
                  <label className="form-check-label text-muted newcssformclass pointer">
                    <p
                      className="input-helper pointer mb-0"
                      
                    >
                      {index + 1}. {item.keyword}
                    </p>
                  </label>
                </div>

                <p className="input-helper mb-0">{item.job_count}</p>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
};
export default Popularsearches;
