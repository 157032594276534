import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import historyclose from "../../../../../Component/icontopng/historyclose.png";
const InfoAtsBuilder = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <img
            src={historyclose}
            alt="close"
            className="reportclosepng close_model"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
        "Welcome to Turn-To's ATS Resume Builder! Here, you can effortlessly create a resume that's optimized for Applicant Tracking Systems, increasing your chances of getting noticed by top employers. Simply fill in your details, and we'll guide you through each step to ensure your resume shines. Ready to get started and make your job application stand out?"        
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };

export default InfoAtsBuilder