var base_url = process.env.REACT_APP_API_URL;
// var base_url = "https://turn-to.com:3000/api/v1/";
var base_url_employer = base_url + "employer/";

const api = {
  sendOTP: base_url + "auth/sendOTP",
  verifyOTP: base_url + "auth/verifyOTP",
  userRegister: base_url + "auth/registerUser",
  userLogin: base_url + "auth/loginUser",
  profile: base_url + "auth/profile",
  employerRegister: base_url + "auth/registerEmployer",
  employerLogin: base_url + "auth/loginEmployer",
  getJobFormData: base_url_employer + "job/get-form-data",
  getDashboardFilterData: base_url + "user/job/filter-data",
  getLocationData: base_url_employer + "job/get-location-data",
  jobPostBrief: base_url_employer + "job/post",
  jobPostSalary: base_url_employer + "job/post-salary",
  jobPostDetalis: base_url_employer + "job/post-detail",
  jobPublished: base_url_employer + "job/change-status",
  joblist: base_url_employer + "job/list",
  applicantsJobList: base_url_employer + "job/applicants-list",
  getUserDashbord: base_url + "user/job/lat-long",
  userFeedback: base_url + "user/feedback/add",

  profileEmploymentType: base_url + "user/job-types",
  addExperience: base_url + "user/experience/add-profile",
  getExperienceList: base_url + "user/experience/list-profile",
  deleteExperience: base_url + "user/experience/delete-profile",
  profiledat: base_url + "user/profile",
  fieldofstudy: base_url + "user/field-of-study",

  addEducation: base_url + "user/education/add-profile",
  changePassword: base_url + "user/change-password",
  educationList: base_url + "user/education/list-profile",
  deleteEducation: base_url + "user/education/delete-profile",
  profileUpdate: base_url + "user/profile-update",
  getAllSkill: base_url + "user/skills",
  updateSkill: base_url + "user/skill/update-profile",
  profileImgUpdate: base_url + "user/profile-image-update",
  coverImgUpdate: base_url + "user/cover-image-update",
  updateResume: base_url + "user/resume",
  deleteResume: base_url + "user/resume",
  getJobs: base_url + "user/job/list",
  savejobs: base_url + "user/job/save",
  reportJob: base_url + "user/job/report",
  notInterestedjob: base_url + "user/job/not-interested",
  companyProfile: base_url + "employer/profile",
  updateBasicDetail: base_url + "employer/profile/update-basic-detail",
  updateContactDetail: base_url + "employer/profile/update-contact-detail",
  updateSocialNetwork: base_url + "employer/profile/update-social-detail",
  preferenceUpdate: base_url + "user/preference/update",
  searchCompanies: base_url + "user/companies",
  getJobLocationType: base_url + "user/job-location-type",
  educationLevel: base_url + "user/get-education-level",
  uploadPhoto: base_url + "employer/profile/add-gallary-image",
  uploadVidio: base_url + "employer/profile/add-gallary-video",
  searchCompanies: base_url + "user/companies",
  userSavedJobList: base_url + "user/job/saved",
  uploadVidiolink: base_url + "employer/profile/add-gallary-video-links",
  searchCompanies: base_url + "user/companies",
  videoDeleteCompanyProfile: base_url + "employer/profile/delete-gallary-video",
  gallaryimageDeleteCompanyProfile:
    base_url + "employer/profile/delete-gallary-image",
  updateEduation: base_url + "user/education/update-profile",
  updateProfile: base_url + "employer/profile/update-company-image",
  updateExp: base_url + "user/experience/update-profile",
  updateEduation: base_url + "user/education/update-profile",
  updateDocument: base_url + "user/certificate/update-profile",
  updateBio: base_url + "user/bio-update",
  updateheader: base_url + "user/headline-update",


  companyDetail: base_url + "user/company/getCompanyDetail",
  companyDetailjob: base_url + "user/company/getCompanyJobs",
  forgetPassword: base_url + "auth/forgot-password",
  resetPassword: base_url + "auth/reset-password",
  getjobCategory: base_url + "user/job-category",
  esayappliyjob: base_url + "user/job/apply",
  companyPublicViewStatus: base_url + "employer/profile/show-profile-status",
  userAppliedList: base_url_employer + "job/get-applied-user-list",
  userprofileInfo: base_url + "user/view/",
  applicationStatus: base_url_employer + "job/change-application-status",
  userprofileInfo: base_url + "user/view",
  getJobDetalis: base_url + "user/job",
  getJobDetalis1: base_url + "user/job",
  getRelatedJobs: base_url + "user/job/related-jobs",
  allapplicationJobList: base_url_employer + "job/get-all-job-list",
  jobclick: base_url + "user/job/click",
  applyJobClick: base_url + "user/job/apply-click",
  checkusername: base_url + "auth/checkUsername",
  usergooglelogin: base_url + "auth/userGoogleLogin",
  empgooglelogin: base_url + "auth/employerGoogleLogin",
  userApplelogin: base_url + "auth/userAppleLogin",
  empApplelogin: base_url + "auth/employerAppleLogin",
  getappliedjob: base_url + "user/job/applied",
  getsearchhistory: base_url + "user/job/search-preferences ",
  getjobdetalisemp: base_url + "employer/job/",
  checkpublicurl: base_url + "auth/checkPublicUrl",
  getclosedjob: base_url + "employer/job/closed-list",
  getemploydashboard: base_url + "employer/profile/dashboard-count",
  reportjoblistemployer: base_url_employer + "job/get-reported-jobs",
  getjobdetail: base_url + "employer/job",
  deletesearchhistory: base_url + "user/job/delete-search-history",
  deleteallsearchhistory: base_url + "user/job/clear-search-history ",
  deleteallnotinterstedhistory: base_url + "user/job/clear-not-interestd-jobs ",


  deletenotinterstedhistory: base_url + "user/job/delete-not-interestd-job",

  searchTitle: base_url + "user/job/get-search-job-title",
  locationtodata:
    "https://maps.googleapis.com/maps/api/js/GeocodeService.Search",
  bookMarkClear: base_url + "user/job/clear-save-jobs",
  appliedJobClearApi: base_url + "user/job/clear-applied-jobs",
  createSession: base_url_employer + "payment/create-checkout-session",
  createSessionNew: base_url_employer + "payment/publish-Job",
  createSessionNewBulk: base_url_employer + "payment/publish-bulk-job",
  getPaymentDetalis: base_url + "employer/payment/",
  getPaymentDetalisNew: base_url + "employer/payment/transaction-detail/",
  getAllFolderList: base_url + "user/folder/list",
  savejobInFolder: base_url + "user/folder/save-job",
  userSaveCollection: base_url + "user/folder/saved-jobs",
  addCollectionFolder: base_url + "user/folder/add",
  paymentHistory: base_url + "employer/payment/get-transaction-history",
  getPlans: base_url_employer + "payment/get-plans",
  getJobPlans: base_url_employer + "payment/get-job-plans",
  refundRequest: base_url_employer + "payment/payment-refund-request",
  refundList: base_url_employer + "payment/payment-refund-request-list",
  uploadDocument: base_url + "user/certificate/add-profile",
  documentDelete: base_url + "user/certificate/delete-profile",
  certificateList: base_url + "user/get-certificate",
  licenceList: base_url + "user/get-license",
  updateBudget: base_url_employer + "job/change-payment_method",
  updateBankdetails: base_url_employer + "payment/add-bank-account",
  getDoumentList: base_url + "user/certificate/list-profile",
  languageData: base_url + "user/language/list-profile",
  languageDataAdd: base_url + "user/language/add-profile",
  languageDataDelete: base_url + "user/language/delete-profile",
  AffiliationsUpdate: base_url + "user/professional-affiliations/update-profile",
  LanguageUpdate: base_url + "user/language/update-profile",

  degreeList: base_url + "user/get-degree",

  professionalAffiliationsData:
    base_url + "user/professional-affiliations/list-profile",
  professionalAffiliationsDataAdd:
    base_url + "user/professional-affiliations/add-profile",
  professionalAffiliationsDataDelete:
    base_url + "user/professional-affiliations/delete-profile",

  employerPasswordChange: base_url + "employer/profile/change-password",
  employerPostjobQuestion: base_url + "employer/job/post-job-question",
  changePositionStatusJob: base_url + "employer/job/change-position-status",
  refundRequest:base_url + "employer/payment/refund-request",
  bulkUplordJob: base_url + "employer/job/job-bulk-upload-new",
  NotificationAll:base_url + "employer/job/notification",
  getbulkData:base_url + "employer/job/get-job-bulk-list",
  clearBulkJobAll :base_url + "employer/job/delete-bulk-jobs",
  getProfileList:base_url + "user/profile-list",




  getCoursesData:base_url+"/user/courses"



};

export default api;
