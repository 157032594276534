import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSocket } from "../../context/SocketProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Trendingjobs = (props) => {

    const history = useHistory();

      const popularJobssetData = (data) => {
        // setSearch(data.keyword);
        history.push(
          `/jobmap?distance=20&location=${data.location}&latitude=${data.latitude}&longitude=${data.longitude}&search=${data.keyword}`
        );
        window.location.reload()
      };

return(
    <div className="py-2 px-3">
                        {/* <h4>
                        <b>Trending Jobs</b>
                        </h4>
                        <hr></hr> */}
            <div className="addallserchdatasetoffcavas">    
                <Row>
                {props.trendingJobs.map((item, index) => (
                        <Col
                            key={index}
                            xs={12}
                            className="mt-1 d-flex justify-content-between align-items-center pointer "
                            onClick={(e) => popularJobssetData(item)}
                        >
                            <div className="form-check pointer my-1">
                                <label className="form-check-label text-muted newcssformclass pointer">
                                    <p className="input-helper pointer mb-0"  
                                   
                                    >
                                        {index + 1 }. {item.keyword} 
                                    </p>
                                </label>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
    </div>
)
}
export default Trendingjobs;