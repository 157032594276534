import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Modal,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import Autocomplete from "react-autocomplete";
import { useSocket } from "../../../../../context/SocketProvider";
import historyclose from "../../../../../Component/icontopng/historyclose.png";
import { MdOutlineClose } from "react-icons/md";

import footerpagepng from "../../../../../Component/icontopng/footerpagepng.png";

import {
  LanguageDataAdd,
  LanguageDataApi,
  LanguageDataDelete,
  postUpdatelanguage,
} from "../../../../../Api/Api";
import { Store } from "react-notifications-component";
import Avatar from "react-avatar";
import editdatapng from "../../../../../Component/icontopng/editdata.png";
import deletecarddatapng from "../../../../../Component/icontopng/deletecarddata.png";
import MyButton from "../../common/Button/MyButton";
import addcarddata from "../../../../../Component/icontopng/addcarddata.png";
import Swal from "sweetalert2";
import Tooltip from "react-tooltip-lite";

const Languagesknow = (props) => {
  const { socket } = useSocket();
  const [titleInput, setTitleInput] = useState(useRef(""));
  const [languagesknow, setLanguagesknow] = useState("");
  const [proficiencyLevels, setProficiencyLevels] = useState("");
  const [language_id, setlanguage_id] = useState("");
  const [languagesData, setLanguagesData] = useState([]);
  const [languagesDataList, setLanguagesDataList] = useState([]);
  const [languageError, setLanguagesError] = useState("");
  const [proficiencyError, setProficiencyError] = useState("");

  const [checkProficiency, setCheckProficiency] = useState(false);
  const [iscontactLoading, setContactIsLoading] = useState(false);

  const [show, setShow] = useState(false);

  const [languageModalShow, setlanguageModalShow] = useState(false);

  const handleClose = () => {
  setShow(false)
  setlanguageModalShow(false);
  setLanguagesknow("");
  setProficiencyLevels("");
  setlanguage_id(null);
  Otpclear()
  };

  const OnFouceOut = () => {
    if (titleInput?.current) {
      titleInput?.current.blur();
    }
  };
  useEffect(()=>{
    window.addEventListener("scroll", OnFouceOut);
    return () => {
      window.removeEventListener("scroll", OnFouceOut);
    };
  },[])

  useEffect(() => {
    if(props.profileId != "") {
      LanguageDataGetApi();
    }
    socket.emit("languages", {} ,(res) => {
      setLanguagesData(res.data);
    });
  }, [props.profileId]);

  // LanguageDataGetApi
  const LanguageDataGetApi = () => {
    LanguageDataApi({profile_id: props.profileId})
      .then((response) => {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
          
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        setLanguagesDataList(response.data.data);
        props.setlanguagecount(response.data.data)
      })
      .catch((error) => {
      });
  };

  // Otpclear
  const Otpclear = () => {
    setProficiencyError("");
    setLanguagesError("");

    setCheckProficiency(false);
  };

  // Otpvalidation
  const Otpvalidation = () => {
    Otpclear();
    var isvalid = true;
    if (languagesknow.trim() == "") {
      setLanguagesError("Please Select Language");
      isvalid = false;
    }
    if (proficiencyLevels == "" || proficiencyLevels == "Select") {
      setCheckProficiency(true);
      setProficiencyError("Please Select Proficiency Levels");
      isvalid = false;
    }
    return isvalid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!Otpvalidation()) {
      return false;
    }
    setContactIsLoading(true);
    if (language_id) {
      updateLanguage();
    } else {
      LanguageDataAddApi();
    }
    setShow(false);
  };

  // LanguageDataAddApi
  const LanguageDataAddApi = () => {
   
    setContactIsLoading(true);
    var data = {
      profile_id: props.profileId,
      id: language_id,
      name: languagesknow,
      level: proficiencyLevels,
    };
    LanguageDataAdd(data)
      .then((response) => {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        if (response.data.status) {
          Store.addNotification({
            title: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          setLanguagesknow("");
          setProficiencyLevels("");
          setContactIsLoading(false);
          setShow(false);
          LanguageDataGetApi();
        }
      })
      .catch((error) => {
        setContactIsLoading(false);
      });
  };

  // EditApi
  const EditApi = (id) => {
    setShow(true);
    var editdata = languagesDataList.find((e) => e._id == id);
    setLanguagesknow(editdata.name);
    setProficiencyLevels(editdata.level);
    setlanguage_id(editdata._id);
  };

  // updateLanguage
  const updateLanguage = () => {
    var data = {
      profile_id: props.profileId,
      language_id: language_id,
      name: languagesknow,
      level: proficiencyLevels,
    };
    postUpdatelanguage(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
       
        setlanguage_id(null);
        if (response.data) {
          Store.addNotification({
            title: "Saved Successfully",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          setLanguagesknow("");
          setProficiencyLevels("");
          setContactIsLoading(false);
          setShow(false);
          LanguageDataGetApi();
        }
      })
      .catch(function (error) {
        setContactIsLoading(false);
      });
  };

  // LanguageDataDeleteApi
  const LanguageDataDeleteApi = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true

    }).then((result) => {
      if (result.value) {
        var data = {
          profile_id: props.profileId,
          language_id: id,
        };
        LanguageDataDelete(data)
          .then((response) => {
            if(!response.data.status){
              Store.addNotification({
                title: response.data.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__zoomIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                
                dismiss: {
                  duration: 2000,
                  onScreen: false,
                },
              });
              return false
            }
            if (response.data.status) {
              Store.addNotification({
                title: response.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__zoomIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                  onScreen: false,
                },
              });
              LanguageDataGetApi();
            }
          })
          .catch((error) => {
          });
      }
    });
  };

  const languageModal = () =>{
    setlanguageModalShow(!languageModalShow);
  }

  return (
    <>
      {languagesDataList.map((e) => {
        return (
          <CardDetalis data={e} LanguageDataDeleteApi={LanguageDataDeleteApi}   EditApi={EditApi}   />
        );
      })}
      <Row className="align-items-end justify-content-end">
      {languagesDataList.length < 3 ?
      
       <Tooltip content="Add">
       <Col className="text-right p-0" xs={12}>
          <button className="my-btn" onClick={() => setShow(true)}>
            {" "}
            <img src={addcarddata} alt="add" className="notsaveiocn" />
            Add{" "}
          </button>
        </Col>
       </Tooltip>
       
   
      :""}
      </Row>

      <Modal
        show={show}
        onHide={handleClose}
        className="contactinfomodel addnewchangesmodal"
        size="xl"
        centered
      >
        <Modal.Body className="p-0">
          <Row className="justify-content-center">
            <div className="d-flex justify-content-center">
              <div className="modalsetwidth">
                <Col lg={12}>
                  <Row className="bg-white userdatapart setheightlanguage border-radiustip">
                  <div className="col-12 mt-3 tipsbuttonset">
                      <div className="index_newcss">
                    <div className="contectinfoshowname mb-2">
                      <div>Language Proficiency</div>
                    
                    </div>
                    <div className="mb-2">
                    <span
                    onClick={languageModal}
                      className="newdashbordbuttoncolor atstipsbuttonset"
                    >
                      {languageModalShow ? <MdOutlineClose className="setcloseiconwidthwidth"/> : "Tips" }
                    </span>
                    </div>
                    
                    </div>
                    </div>
                
                    <Col md={6}>
                      <Form.Group className="">
                        <Form.Label className="label_class">
                          Select Languages
                        </Form.Label>
                        <div className="jobcategory">
                          <Autocomplete
                            wrapperProps={{ className: "auto-input addnewcssautocompletedataset" }}
                            wrapperStyle={{
                              display: "block",
                              position:"relative"
                            }}
                            getItemValue={(item) => item.label}
                            items={languagesData
                              .filter((ele) =>
                                ele.name
                                  .toLowerCase()
                                  .includes(languagesknow.toLowerCase())
                              )
                              .map((ele) => {
                                return { label: ele.name };
                              })}
                            renderItem={(item, isHighlighted) => (
                              <div
                                style={{
                                  background: isHighlighted ? "lightgray" : "white",
                                }}
                              >
                                {item.label}
                              </div>
                            )}
                            value={languagesknow}
                            onChange={(e) => setLanguagesknow(e.target.value)}
                            onSelect={(e) => setLanguagesknow(e)}
                            inputProps={{
                              placeholder:
                                "Select Languages",
                            }}
                          />
                        </div>
                        <span className="err_msg">{languageError}</span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="startmonth">
                        <Form.Label className="label_class">
                          Proficiency Levels
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="startmonth"
                          className={
                            checkProficiency
                              ? "borderdrop "
                              : "borderdrop  input_radius"
                          }
                          value={proficiencyLevels}
                          onChange={(e) => setProficiencyLevels(e.target.value)}
                        >
                          <option>Select</option>
                          {[
                            { name: "Fundamental Awareness " },
                            { name: "Novice " },
                            { name: "Intermediate  " },
                            { name: "Advanced  " },
                            { name: "Expert  " },
                          ].map((e) => (
                            <option value={e.name}>{e.name} </option>
                          ))}
                        </Form.Control>
                        <span className="err_msg">{proficiencyError}</span>
                      </Form.Group>
                    </Col>
                    <Col>
                  <div className="setmodalfooterbutton">
                    <Button
                      variant="danger"
                      className="contact_radius mr-2"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <MyButton
                      type={"submit"}
                      onClick={handleSubmit}
                    >
                      {iscontactLoading ? (
                        <Spinner animation="border" role="status" className="spinneerset">
                          <span className="visually-hidden"></span>
                        </Spinner>
                      ) : (
                        "Save"
                      )}
                    </MyButton>
                  </div>
                    </Col>
                  </Row>
                </Col>
              </div>
              <div className="position-relative">
                  {languageModalShow ? 
                  <Col lg={12} className="tipsmodalsetmobilescreen addnewsettipopenmodalwidth setpaddingmobiletips">
                    <div className="contactinfo_tip languagestipsheightset border-radiustip">
                      

                      <div className="mt-2">
                        <div>
                          Maximizing the Impact of Language Skills on Your Turn-To
                          Profile:
                        </div>
                        <div className="mt-2">
                          <b>Highlighting Multilingual Proficiency on Turn-To</b>
                        </div>
                        <div className="mt-2">
                          <b>Strategic Language Proficiency Display:</b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Prioritize Relevance:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Focus on languages that are most relevant to the
                                  jobs or industries you are interested in. If the job
                                  market you're targeting values specific languages,
                                  make sure those are prominently featured on your
                                  profile.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Proficiency Levels
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Clearly indicate your proficiency level for each
                                  language (e.g., basic, conversational, fluent,
                                  native). This helps employers quickly understand
                                  your ability to communicate in different languages.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-2">
                          <b>Enhancing Your Profile with Language Skills:</b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Cultural Competence:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Highlighting language skills can also showcase your
                                  ability to navigate and appreciate diverse cultures,
                                  an increasingly valued trait in globalized business
                                  environments.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Professional Versatility:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Multilingualism can open doors to roles in
                                  international companies, customer service,
                                  translation, and more. It positions you as a
                                  candidate with broad capabilities and adaptability.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-2">
                          <b>Example Language Layout for Turn-To</b>
                        </div>
                        <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
                          <Accordion.Item eventKey="0" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              Spanish Fluent:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Professional proficiency in both written and verbal
                                  communication, with experience in customer service
                                  settings.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              French Conversational:
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Capable of basic business conversations and
                                  correspondence, continuous improvement through
                                  formal courses.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="2" className="my-2">
                            <Accordion.Header className="fontsetAccordion">
                              <img
                                src={footerpagepng}
                                alt="open"
                                width="15px"
                                height="15px"
                              />{" "}
                              <b> Why It Matters</b>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <div>
                                  Language skills on your Turn-To profile serve as a
                                  testament to your communication capabilities and
                                  cultural awareness. In a global job market, being
                                  multilingual can significantly enhance your
                                  attractiveness to potential employers, especially in
                                  roles requiring interaction with diverse populations
                                  or international stakeholders.
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="mt-2">
                          <b>
                            By effectively showcasing your language skills on your
                            Turn-To profile, you not only demonstrate your linguistic
                            abilities but also highlight your readiness to engage in a
                            multicultural work environment. This can significantly
                            enhance your appeal to employers looking for versatile and
                            culturally adept candidates.
                          </b>
                        </div>
                      </div>
                    </div>
                  </Col>
                  : ""
                  }
              </div>

            </div>


          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Languagesknow;

function CardDetalis(props) {
  return (
    <div className="menu-card position-relative">
      <div className="stretch-card  my-2 p-0 " style={{ minHeight: "80px" }}>
        <div className="card shadow-lg exp_margin">
          <div className="card-body exp_padding">
            <div className="d-flex justify-content-between h-100">
              <div className="left-div d-block d-md-flex">
                <div className="mr-0 mr-md-3">
                  <p>
                    <Avatar size="50" round="8px" name={props.data.name} />
                  </p>
                </div>
                <div>
                  <h5 className="text-break">
                    <b>{props.data.name}</b>
                  </h5>
                  <h6 className="text-break"> {props.data.level}</h6>
                  <div></div>
                </div>
              </div>
              <div className="right-div d-flex flex-column">


                <Tooltip content="Edit">
                <MyButton
                    className="p-1"
                    onClick={(e) => props.EditApi(props.data._id)}
                  >
                    <img
                      src={editdatapng}
                      alt="edit"
                      className="closepngiocnwidth"
                    />
                  </MyButton>
                  </Tooltip>
                  <Tooltip content="Delete">
                <MyButton
                  className="p-1 mt-1 deletebtn"
                  onClick={(e) => props.LanguageDataDeleteApi(props.data._id)}
                >
                  <img
                    src={deletecarddatapng}
                    alt="delete"
                    className="closepngiocnwidth"
                    set="bold"
                  />
                </MyButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
