import React, { useEffect } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import logoimg from "../../../../../assets/images/TURN-TO (2).png";
import mainimg from "../../../../../assets/images/imgpsh_fullsize_anim.png";

import "../../../../../Component/Component.css";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import MyButton from "../Button/MyButton";
import {
  postForgetpassword,
  postResetpassword,
  postusergooglelogin,
  postuserlogin,
  userAppleLoginAPI,
} from "../../../../../Api/Api";
import { useAuth } from "../../../../../context/AuthProvider";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import AppleSignin from "react-apple-signin-auth";
import jwt_decode from "jwt-decode";
import { Store } from "react-notifications-component";
import historyclose from "../../../../../Component/icontopng/historyclose.png";
import passwodshow from "../../../../../Component/icontopng/pshow.png";
import passwodnotshow from "../../../../../Component/icontopng/pnotshow.png";
import logintwitter from "../../../../../Component/icontopng/logintwitter.png";
import logininstagram from "../../../../../Component/icontopng/logininstagram.png";
import loginreddit from "../../../../../Component/icontopng/loginreddit.png";
import loginfacebook from "../../../../../Component/icontopng/loginfacebook.png";

function NewUserLogin(props) {
  const history = useHistory();
  const { getUserProfile } = useAuth();

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setModelEmail("");
    setOTP("");
    setNewPassword("");
  };
  const handleShow = () => setShow(true);

  const [emailaddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const [emailaddresserror, setEmailAddressError] = useState("");
  const [passworderror, setPasswordError] = useState("");

  // modeldetails
  const [modelEmail, setModelEmail] = useState("");
  const [Otp, setOTP] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [modelEmailerror, setModelEmailerror] = useState("");
  const [modelOtperror, setOTPerror] = useState("");
  const [modelPassworderror, setmodelPassworderror] = useState("");

  const Errorclear = (e) => {
    setEmailAddressError("");
    setPasswordError("");
    setModelEmailerror("");
    setOTPerror("");
    setmodelPassworderror("");
  };

  const validation = () => {
    Errorclear();
    var isvalid = true;
    if (emailaddress == "") {
      setEmailAddressError("Please Enter EmailAddress");
      isvalid = false;
    }
    if (password == "") {
      setPasswordError("Please Enter Password");
      isvalid = false;
    }
    return isvalid;
  };

  const openmodelValidation = () => {
    Errorclear();
    var isvalid = true;
    if (modelEmail == "") {
      setModelEmailerror("Please Enter EmailAddress");
      isvalid = false;
    } else {
      const emailRegex =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(modelEmail);
      if (!emailRegex) {
        setModelEmailerror("Please Enter Vaild Email");
        isvalid = false;
      }
    }
    return isvalid;
  };
  const closemodelValidation = () => {
    Errorclear();
    var isvalid = true;
    if (Otp == "") {
      setOTPerror("Please Enter OTP");
      isvalid = false;
    }
    if (newPassword == "") {
      setmodelPassworderror("Please Enter Password");
      isvalid = false;
    } else if (!isStrongPassword(newPassword)) {
      setmodelPassworderror("Password must be strong");
      isvalid = false;
    }
    return isvalid;
  };

  function isStrongPassword(password) {
    // Define the criteria for a strong password
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

    // Check if the password meets the criteria
    return (
      password.length >= minLength &&
      hasUppercase &&
      hasLowercase &&
      hasDigit &&
      hasSpecialChar
    );
  }

  const Signin = (e) => {
    e.preventDefault();
    if (!validation()) {
      return false;
    }
    
    setIsLoading(true);
    var data = {
      email: emailaddress,
      password: password,
    };

    postuserlogin(data)
      .then(async function (response) {
        setIsLoading(false);

        // console.log("user", response);
        if (response.data.status) {
          localStorage.setItem("token", response.data.token);
          await getUserProfile();
          if(props.url){
            history.push(props.url);
          }
          // setIsLoading(false);
          props.setModalShowLogin(false)
        }
      })
      .catch(function (error) {
        setIsLoading(false);

        // console.log(error.response.data.error);
        props.setModalShowLogin(false);
        if (error.response.data.error.email) {
          Swal.fire(error.response.data.error.email, "", "error");
        } else {
          Swal.fire(error.response.data.error.password, "", "error");
        }
      });
  };

  //model ------------------------------------------------
  const [modalShow, setModalShow] = React.useState(false);
  const [anothermodalShow, setAnotherModalShow] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const openModels = () => {
    if (!openmodelValidation()) {
      return false;
    }
    // setModalShow(false);
    // setAnotherModalShow(true);
    // setModelEmail(modelEmail);
    var data = {
      email: modelEmail,
    };

    postForgetpassword(data)
      .then(async function (response) {
        // console.log("res", response);
        if (!response.data.status) {
          setModalShow(true);
          setAnotherModalShow(false);
          setModelEmailerror(response.data.message);
        } else {
          Store.addNotification({
            title: "Otp sent to your register email",
            // message: "Otp sent to your register email",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          setModalShow(false);
          setAnotherModalShow(true);
          setModelEmail(modelEmail);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.error);
      });
  };

  const closeModels = () => {
    if (!closemodelValidation()) {
      return false;
    }

    var data = {
      email: modelEmail,
      otp: Otp,
      password: newPassword,
    };

    postResetpassword(data)
      .then(async function (response) {
        setOTPerror(response.data.message);
        // console.log("res", response);
        if (response.data.status) {
          Store.addNotification({
            title: response.data.message,
            // message: "Otp sent to your register email",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          setAnotherModalShow(false);
        }
      })
      .catch(function (error) {
        setmodelPassworderror(error.response.data.error);
        // console.log(error.response.data.error);
      });
  };

  const GoogleLoginApi = (e) => {
    setIsLoading(true);
    var data = {
      email: e.email,
      first_name: e.family_name,
      last_name: e.given_name,
      name: e.name,
    };
    postusergooglelogin(data)
      .then(async function (response) {
        setIsLoading(false);
        if (response.data.status) {
          localStorage.setItem("token", response.data.token);
          await getUserProfile();
          if(props.url){
            history.push(props.url);
          }
          props?.setModalShowLogin(false)
        } else {
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
        }
      })
      .catch(function (error) {
        setIsLoading(false);

        // console.log(error.response.data.error);
        props.setModalShowLogin(false);
        if (error.response.data.error.email) {
          Swal.fire(error.response.data.error.email, "", "error");
        } else {
          Swal.fire(error.response.data.error.password, "", "error");
        }
      });
  };

  const userAppleLoginFun = (token) => {
    setIsLoading(true);
    userAppleLoginAPI({ token }).then(async (response) => {
      if (response.data.status) {
        localStorage.setItem("token", response.data.token);
        await getUserProfile();
        if(props.url){
          history.push(props.url);
        }
      } else {
        Store.addNotification({
          title: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        setIsLoading(false);
      }
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showNewPassword, setShowNewPassword] = useState(false);

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const [passwordErrors, setPasswordErrors] = useState([]);
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    const errors = [];

    if (newPassword.length < 8) {
      errors.push("Password must be at least 8 characters long");
    }
    if (!/[A-Z]/.test(newPassword)) {
      errors.push("Password must contain at least one uppercase letter");
    }
    if (!/[a-z]/.test(newPassword)) {
      errors.push("Password must contain at least one lowercase letter");
    }
    if (!/\d/.test(newPassword)) {
      errors.push("Password must contain at least one number");
    }
    if (!/[@$!%*?&]/.test(newPassword)) {
      errors.push("Password must contain at least one special character");
    }

    setNewPassword(newPassword);
    setPasswordErrors(errors);
    setPasswordError("");
  };


  return (
    <>
      {/* <Row className="rowclass ">
        <Col className="forhight" lg={12}>
          <Container>
          
          </Container>
        </Col>
        <Col className=""></Col>
      </Row> */}
      <Row className="formcenter bg-white border shadow-lg new_width">
        <Col className="">
          <Row>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center mt-2 mb-2 border-top-line"
            >
              <Link to="/">
                {/* <img src={mainimg} className="turn_icon" /> */}
                <img src={logoimg} alt="Turn-To" className="search_icon" />
                {/* <span className="fontclas"> Turn-To</span> */}
              </Link>
              <div className="give_right">
                <img
                  src={historyclose}
                  alt="close"
                  className="close_model closepngiocnwidth"
                  onClick={() => props.setModalShowLogin(false)}
                />
              </div>
              {/* <span className="fontclas"> Turn-To</span> */}
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-center w-100">
                    <Col md={4} className="d-md-flex align-items-center justify-content-center text-center">
                    <h2 className="text-center"> Sign in </h2>
                    </Col>
                    <Col className="border-left-sin" md={8}>
                    <div className="google_login my-2">
                <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
                >
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      var token = credentialResponse.credential;
                      var decoded = jwt_decode(token);
                      GoogleLoginApi(decoded);
                    }}
                    onError={() => {
                      // console.log('Login Failed');
                    }}
                  />
                </GoogleOAuthProvider>
              </div>

              {/* apple login */}
              <div className="google_login mt-2">
                <AppleSignin
                  /** Auth options passed to AppleID.auth.init() */
                  authOptions={{
                    clientId: process.env.REACT_APP_APPLE_LOGIN_CLIENT_ID,
                    scope: "email name",
                    redirectURI: process.env.REACT_APP_APPLE_LOGIN_REDIRECT,
                    state: "",
                    nonce: "",
                    Button: "11",
                    usePopup: true,
                  }}
                  onSuccess={(successResponse) => {
                    // console.log("successResponse", successResponse);
                    userAppleLoginFun(successResponse.authorization.id_token);
                  }}
                  onError={(errorResponse) => {
                    // console.log("errorResponse", errorResponse);
                  }}
                  /** General props */
                  uiType="light"
                  /** Removes default style tag */
                  className="ss"
                  /** Allows to change the button's children, eg: for changing the button text */
                  buttonExtraChildren="Sign in with  Apple"
                  /** Checkout README.md for further customization props. */
                  /** Spread rest props if needed */
                  // {...rest}
                />
              </div>
                    </Col>
                    
                </Row>
          <div className="p-0 p-md-1">
            <Form>
              <Form.Group className="mb-0" controlId="formBasicEmail">
                <Form.Label> Email / Username</Form.Label>
                <Row>
                  <Col style={{ padding: 0 }} xs={12}>
                    <Form.Control
                      type="text"
                      value={emailaddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      placeholder="Enter Email / Username"
                      className="newsearchinputaddborderradius"
                    />
                    <span className="eroorclass">{emailaddresserror}</span>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-0" controlId="formBasicPassword">
                <Form.Label className="mt-2 label_class">Password</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="newsearchinputaddborderradius"
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <img
                        src={passwodshow}
                        className="closepngiocnwidth"
                        alt="show"
                      />
                    ) : (
                      <img
                        src={passwodnotshow}
                        className="closepngiocnwidth"
                        alt="hide"
                      />
                    )}
                  </div>
                </div>
                <span className="eroorclass">{passworderror}</span>
              </Form.Group>
              <div className="text-right mt-2">
                <Form.Group className="mb-0" controlId="formBasicPassword">
                  <Form.Label onClick={() => setModalShow(true)}>
                    Forgot Password ?
                  </Form.Label>
                  {/* open model ------------------------------------------------*/}
                  <Modal
                    show={modalShow}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header
                      onClick={() => {
                        setModalShow(false);
                        setModelEmailerror("");
                      }}
                    >
                      <Modal.Title
                        className="model_side"
                        id="contained-modal-title-vcenter"
                      >
                        Forget Password
                        <img
                          src={historyclose}
                          alt="close"
                          className="close_model set_newlogin reportclosepng"
                          onClick={handleClose}
                        />
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group className="mb-0" controlId="formBasicEmail">
                          <Form.Label>Email address</Form.Label>
                          <Row>
                            <Col style={{ padding: 0 }} xs={12}>
                              <Form.Control
                                type="text"
                                value={modelEmail}
                                onChange={(e) => setModelEmail(e.target.value)}
                                placeholder="Enter email"
                                className="newsearchinputaddborderradius"
                              />
                              <span className="eroorclass">
                                {modelEmailerror}
                              </span>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <MyButton onClick={openModels}>Next</MyButton>
                    </Modal.Footer>
                  </Modal>
                  {/* ---------------------------------------------------------------------------- */}
                  <Modal
                    show={anothermodalShow}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header
                      className="model_side"
                      onClick={() => setAnotherModalShow(false)}
                    >
                      <Modal.Title
                        id="contained-modal-title-vcenter"
                        className="ml-3"
                      >
                        {modelEmail}
                        <img
                          src={historyclose}
                          alt="close"
                          className="close_model set_newlogin reportclosepng"
                          onClick={handleClose}
                        />
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group
                          className="mb-0 "
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="label_password">
                            OTP
                          </Form.Label>
                          <Row>
                            <Col style={{ padding: 0 }} xs={12}>
                              <Form.Control
                                type="text"
                                value={Otp}
                                onChange={(e) => setOTP(e.target.value)}
                                placeholder="Enter OTP"
                                className="newsearchinputaddborderradius"
                              />
                              <span className="eroorclass">
                                {modelOtperror}
                              </span>
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group
                          className="mb-0 top_margin1"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>New Password</Form.Label>
                          <Row>
                            <Col style={{ padding: 0 }} xs={12}>
                              <div style={{ position: "relative" }}>
                                <Form.Control
                                  type={showNewPassword ? "text" : "password"}
                                  value={newPassword}
                                  onChange={handlePasswordChange
                                   
                                  }
                                  placeholder="Enter Password"
                                  className="newsearchinputaddborderradius"
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "10px",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggleNewPasswordVisibility}
                                >
                                  {showNewPassword ? (
                                    <img
                                      src={passwodshow}
                                      className="closepngiocnwidth"
                                      alt="show"
                                    />
                                  ) : (
                                    <img
                                      src={passwodnotshow}
                                      className="closepngiocnwidth"
                                      alt="hide"
                                    />
                                  )}
                                </div>
                              </div>
                              {newPassword.length > 0 && passwordErrors.length > 0 && (
                        <div>
                          <div>
                            {passwordErrors.map((error, index) => (
                              <div key={index} className="eroorclass">
                                {error}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                              <span className="eroorclass">
                                {modelPassworderror}
                              </span>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <MyButton onClick={closeModels}>Submit</MyButton>
                    </Modal.Footer>
                  </Modal>
                  {/* ------------------------------------------------------------------------------- */}
                </Form.Group>
              </div>
              <div className="text-center mt-3">
                <button className="buttonclass" onClick={Signin}>
                  {" "}
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden"></span>
                    </Spinner>
                  ) : (
                    "SIGN IN"
                  )}
                </button>
                <p className="my-2">
                  Don't have an account ?{" "}
                  <b className="purpluecolor">
                    <Link to="/user-register" className="purpluecolor">
                      {" "}
                      Sign Up
                    </Link>
                  </b>
                </p>
                <p className="my-2">
                  Employer Login ?{" "}
                  <b className="purpluecolor">
                    <Link to="/employer-login" className="purpluecolor">
                      Sign In
                    </Link>
                  </b>
                </p>
              </div>
              <div>
                      <div className="text-center">Follow Us</div>
                      <div className="d-flex justify-content-center">
                        <a
                          href={"https://twitter.com/TurnToJobSearch"}
                          className="p-2 loginpageicon"
                          target="_blank"
                        >
                          <img
                            src={logintwitter}
                            alt="twitter"
                            className="closepngiocnwidth"
                          />
                        </a>
                        <a
                          href={"https://www.instagram.com/turn__to/"}
                          className="p-2 loginpageicon"
                          target="_blank"
                        >
                          <img
                            src={logininstagram}
                            alt="instagram"
                            className="closepngiocnwidth"
                          />
                        </a>
                        <a
                          href={"https://www.reddit.com/r/jobmap/"}
                          className="p-2 loginpageicon"
                          target="_blank"
                        >
                          <img
                            src={loginreddit}
                            alt="reddit"
                            className="closepngiocnwidth"
                          />
                        </a>
                        <a
                          href={"https://www.facebook.com/jobsearchmap"}
                          className="p-2 loginpageicon"
                          target="_blank"
                        >
                          <img
                            src={loginfacebook}
                            alt="facebook"
                            className="loginfacebookpng"
                          />
                        </a>
                      </div>
                    </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default NewUserLogin;
