import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MyButton from "../../common/Button/MyButton";
import {
  PostprofileUpdate,
  getLocationData,
  getprofiledata,
  postcheckUserName,
} from "../../../../../Api/Api";
import { useEffect } from "react";
import "./personal.css";
import Autocompletemap from "react-google-autocomplete";
import { useAuth } from "../../../../../context/AuthProvider";
import { Store } from "react-notifications-component";
function PersonalInfo(props) {
  // State
  const { AuthData, setAuthData } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [bio, setBio] = useState("");
  // const [roletitle, setRoletitle] = useState("");
  const [gender, setGender] = useState("Male");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [locationGetAutoComplet, SetLocationGetAutoComplet] = useState("");
  const [isUserNameAvailable, setIsUserNameAvailable] = useState("");
  const [isUserName, setIsUserName] = useState("");
  const [falseUserName, setfalseUserName] = useState("");

  //  validation State
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [bioError, setBioError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [roletitleError, setRoletitleError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [locationGetAutoCompletError, SetLocationGetAutoCompletError] =
    useState("");

  // Api Call Get Location
  // useEffect(() => {
  //   if (
  //     locationGetAutoComplet &&
  //     locationGetAutoComplet != "" &&
  //     locationGetAutoComplet != undefined
  //   ) {
  //     locationgetdata();
  //   }
  // }, [locationGetAutoComplet]);

  const Validation = () => {
    var isValid = true;
    if (firstName.trim() == "") {
      setFirstNameError("Please Enter FirstName ");
      isValid = false;
    }
    if (lastName.trim() == "") {
      setLastNameError("Please Enter LastName ");
      isValid = false;
    }
    // if (isUserName == "false") {
    //   setfalseUserName(falseUserName);
    //   isValid = false;
    //   if(isUserName == "true") {
    //     setfalseUserName("")
    //     // isValid = true;
    //   }
    // }
    if (mobileNumber.trim() == "") {
      setMobileNumberError("Please Enter MobileNumber ");
      isValid = false;
    }
    if (gender == "") {
      setGenderError("Please Select Gender");
      isValid = false;
    }
    if (bio.trim() == "") {
      setBioError("Please Enter Bio ");
      isValid = false;
    }
    // if (roletitle.trim() == "") {
    //   setRoletitleError("Please Enter Roletitle ");
    //   isValid = false;
    // }
    if (city == "") {
      setCityError("Please Enter City ");
      isValid = false;
    }
    if (state.trim() == "") {
      setStateError("Please Enter State ");
      isValid = false;
    }
    if (country.trim() == "") {
      setCountryError("Please Enter Country ");
      isValid = false;
    }
    
    if (pincode.trim() === "") {
      setPincodeError("Please Enter Zipcode");
      isValid = false;
    } else if (!/^\d{4,6}$/.test(pincode.trim())) {
      if (pincode.trim().length < 4) {
        setPincodeError("Zip Code must be at least 4 digits");
      } else if (pincode.trim().length > 6) {
        setPincodeError("Zip Code must be at most 6 digits");
      }
      isValid = false;
    }
    if (locationGetAutoComplet.trim() == "") {
      SetLocationGetAutoCompletError("Please Enter Location ");
      isValid = false;
    }
    return isValid;
  };

  const clearError = () => {
    setfalseUserName("");
    setGenderError("");
  };

  const locationgetdata = (data) => {
    // console.log(data);
    var city = data.address_components.find((ele) =>
      ele.types.includes("locality")
    );
    var State = data.address_components.find((ele) =>
      ele.types.includes("administrative_area_level_1")
    );
    var country = data.address_components.find((ele) =>
      ele.types.includes("country")
    );
    var zipcode = data.address_components.find((ele) =>
      ele.types.includes("postal_code")
    );

    if (city?.long_name) {
      setCity(city.long_name);
    } else {
      setCity("");
    }
    if (State?.long_name) {
      setState(State.long_name);
    } else {
      setState("");
    }

    if (country?.long_name) {
      setCountry(country.long_name);
    } else {
      setCountry("");
    }

    if (zipcode?.long_name) {
      setPincode(zipcode.long_name);
      setPincodeError("");
    } else {
      setPincode("");
      setPincodeError("Please Enter Zip Code");
    }
    if (data.geometry.location.lat()) {
      setLatitude(data.geometry.location.lat());
    }
    if (data.geometry.location.lng()) {
      setLongitude(data.geometry.location.lng());
    }

    // console.log("latitude ", data.geometry.location.lat(), "longitude", data.geometry.location.lng());
  };

  // const locationgetdata = () => {
  //   getLocationData(locationGetAutoComplet)
  //     .then(function (response) {
  //       console.log("pincode", response.data.data);
  //       setCity(response.data.data.city);
  //       setState(response.data.data.state);
  //       setCountry(response.data.data.country);
  //       if (response.data.data?.zipcode) {
  //         setPincode(response.data.data?.zipcode);
  //         // setErrorZipCode("");
  //       } else {
  //         // setErrorZipCode("Please Enter Zip Code");
  //       }
  //       setLongitude(response.data.data.longitude);
  //       setLatitude(response.data.data.latitude);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const SubmitApi = () => {
    clearError();
    if (!Validation()) {
      return false;
    }

    var data = {
      first_name: firstName,
      last_name: lastName,
      mobile_number: mobileNumber,
      username: userName,
      bio: bio,
      // role_title: roletitle,
      gender: gender,
      location: {
        latitude: latitude,
        longitude: longitude,
        address: locationGetAutoComplet,
        city: city,
        state: state,
        country: country,
        zipcode: pincode,
      },
    };
    PostprofileUpdate(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        // console.log(response.data.data);
        props.close();
        // console.log("123");
        setAuthData({
          ...AuthData,
          first_name: firstName,
          last_name: lastName,
        });
        setIsUserNameAvailable(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  useEffect(() => {
    getprofiledata()
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        // handle success
        setFirstName(response.data.data.first_name);
        setLastName(response.data.data.last_name);
        setUserName(response.data.data.username);
        setMobileNumber(response.data.data.mobile_number);
        setBio(response.data.data.info.bio);
        // setRoletitle(response.data.data.info.role_title);
        setGender(response.data.data.info.gender);
        setCity(response.data.data.info.location.city);
        setState(response.data.data.info.location.state);
        setCountry(response.data.data.info.location.country);
        setPincode(response.data.data.info.location.zipcode);
        SetLocationGetAutoComplet(response.data.data.info.location.address);
        setIsUserNameAvailable(response.data.data.username == "");

        // console.log("---------", response.data.data.username == "");
      })
      .catch(function (error) {
        // handle error
      });
  }, []);

  // check username
  const checkUsername = (e) => {
    // console.log(userName, "e");
    var data = {
      username: userName,
    };
    postcheckUserName(data)
      .then(function (response) {
        if(!response.data.status){
          Store.addNotification({
            title: response.data.message,
            // message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false
        }
        if (response.data.status) {
          setfalseUserName("");
        } else {
          setfalseUserName(response.data.message);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  return (
    <Form>
      <Row>
        <Col lg={6} className="mb-3">
          <Form.Group controlId="firstname">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <span className="err_msg">{firstNameError}</span>
          </Form.Group>
        </Col>
        <Col lg={6} className="mb-3">
          <Form.Group controlId="lastname">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
          <span className="err_msg">{lastNameError}</span>
        </Col>
        {isUserNameAvailable ? (
          <Col lg={12} className="mb-3">
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Username"
                onBlur={(e) => checkUsername()}
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </Form.Group>
            <span className="err_msg">{falseUserName}</span>
          </Col>
        ) : (
          <Col lg={12} className="mb-3">
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Username"
                onBlur={(e) => checkUsername()}
                value={userName}
                disabled
              />
            </Form.Group>
          </Col>
        )}
        {/* <Col lg={12} className="mb-3">
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              onBlur={(e)=>checkUsername()}
              value={userName}
              onChange={(e)=>{
              setUserName(e.target.value)}}

            />
          </Form.Group>
          <span className="err_msg">{lastNameError}</span>
        </Col> */}
        <Col lg={6} className="mb-3">
          <Form.Group controlId="mobile">
            <Form.Label>Mobile no.</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </Form.Group>
          <span className="err_msg">{mobileNumberError}</span>
        </Col>

        <Col lg={6} className="mb-3">
          <Form.Group controlId="gender">
            <Form.Label>Gender</Form.Label>
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <div className="form-check ">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="deadline"
                      id="deadline1Male"
                      defaultChecked
                      checked={gender == "Male"}
                      onClick={() => setGender("Male")}
                    />{" "}
                    <i className="input-helper"></i>
                    Male
                  </label>
                </div>
              </div>

              <div className="d-flex align-items-center mx-1">
                <div className="form-check ">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="deadline"
                      id="deadline1Male"
                      checked={gender == "Female"}
                      onClick={() => setGender("Female")}
                    />{" "}
                    <i className="input-helper"></i>
                    Female
                  </label>
                </div>
              </div>
            </div>
            <span className="err_msg">{genderError}</span>
          </Form.Group>
        </Col>
        {/* <Col lg={6} className="mb-3">
          <Form.Group controlId="mobile">
            <Form.Label>Current Role / Unemployed option</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Current Role"
              value={roletitle}
              onChange={(e) => setRoletitle(e.target.value)}
            />
            <span className="err_msg">{roletitleError}</span>
          </Form.Group>
        </Col> */}
        <Col lg={6}>
          <Form.Group>
            <label htmlFor="Location">Location</label>
            <Autocompletemap
              className="form-control"
              apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
              options={{ types: ["geocode"] }}
              value={locationGetAutoComplet}
              onChange={(e) => SetLocationGetAutoComplet(e.target.value)}
              onPlaceSelected={(place) => {
                SetLocationGetAutoComplet(place.formatted_address);
                locationgetdata(place);
              }}
            />
            <span className="err_msg">{locationGetAutoCompletError}</span>

            <span className="eroorclass">
              {/* {errorlocationGetAutoComplet} */}
            </span>
          </Form.Group>
        </Col>
        <Col lg={6} className="mb-3">
          <Form.Group controlId="country">
            <Form.Label>Country</Form.Label>
            <Form.Control type="text" placeholder="Country" value={country} />
            <span className="err_msg">{countryError}</span>
          </Form.Group>
        </Col>
        <Col lg={6} className="mb-3">
          <Form.Group controlId="state">
            <Form.Label>State</Form.Label>
            <Form.Control type="text" placeholder="State" value={state} />
            <span className="err_msg">{stateError}</span>
          </Form.Group>
        </Col>
        <Col lg={6} className="mb-3">
          <Form.Group controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Control type="text" placeholder="City" value={city} />

            <span className="err_msg">{cityError}</span>
          </Form.Group>
        </Col>
        <Col lg={6} className="mb-3">
          <Form.Group controlId="pincode">
            <Form.Label>Zip code</Form.Label>
            <Form.Control
              type="number"
              placeholder="Zipcode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
            <span className="err_msg">{pincodeError}</span>
          </Form.Group>
        </Col>
        {/* <Col lg={12} className="mb-3">
          <Form.Group controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Address"
              as="textarea"
              rows={3}
              value={address}
              onChange={(e)=>setAddress(e.target.value)}
            />
          </Form.Group>
        </Col> */}

        <Col lg={12} className="mb-3">
          <Form.Group controlId="about">
            <Form.Label>About</Form.Label>
            <Form.Control
              type="text"
              placeholder="About"
              as="textarea"
              rows={3}
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
            <span className="err_msg">{bioError}</span>
          </Form.Group>
        </Col>
        <Col sm={12}>
          <div className="text-center">
            <MyButton onClick={SubmitApi}> Save</MyButton>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default PersonalInfo;
