export const setMetatags = (title, description, url = null) => {
  if(!url) {
    url = window.location.href;
  }
  document.title = title;
  var titleTags = document.getElementsByClassName('titleTags');
  for(var i = 0; i < titleTags.length; i++){
    titleTags[i].setAttribute('content', title);
  }
  var descriptionTags = document.getElementsByClassName('descriptionTags');
  for(var i = 0; i < descriptionTags.length; i++){
    descriptionTags[i].setAttribute('content', description);
  }

  var urlTags = document.getElementsByClassName('urlTags');
  for(var i = 0; i < urlTags.length; i++){
    urlTags[i].setAttribute('content', url);
  }
}