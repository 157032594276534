import React from "react";
import "./Productpage.css";

// import "./../../app/Landingpage/LandingPage.css";

import { Col, Container, Row } from "react-bootstrap";
import firstsecimg from "./../../Component/productpageimg/firstsectionimg.png";
import thirdsecimg from "./../../Component/productpageimg/thirdsectionimg.png";
import thirdsecimg1 from "./../../Component/productpageimg/thirdSection1img.webp";
import forthfirstimg from "./../../Component/productpageimg/forthfirstimg.webp";
import forthsecondimg from "./../../Component/productpageimg/forthsecondimg.webp";

import forththirdimg from "./../../Component/productpageimg/forththirdimg.webp";

import forthForthimg from "./../../Component/productpageimg/forthForthimg.webp";

import forthfifthimg from "./../../Component/productpageimg/forthfifthimg.webp";
import forthsixthimg from "./../../Component/productpageimg/forthsixthimg.webp";

import fifthfirstimg from "./../../Component/productpageimg/fifthfirstimg.webp";
import fifthsecimg from "./../../Component/productpageimg/fifthsecondimg.webp";
import fifththirdimg from "./../../Component/productpageimg/fifththirdimg.webp";
import fifthforthimg from "./../../Component/productpageimg/fifthforthimg.webp";
import fifthFifthimg from "./../../Component/productpageimg/fifthFifthimg.webp";
import fifthsiximg from "./../../Component/productpageimg/fifthsixthimg.webp";

import sixtthfirstimg from "./../../Component/productpageimg/sixthfirstimg.webp";
import sixthsecimg from "./../../Component/productpageimg/sixthsecimg.webp";
import sixththirdimg from "./../../Component/productpageimg/sixththirdimg.webp";

import sevenfirstimg from "./../../Component/productpageimg/seventhsecondimg-removebg-preview.png";
import MyButton from "../../app/candidate/components/common/Button/MyButton";
import LandingPageFooter from "../../app/Landingpage/LandingPageFooter";
import firstimg from "./../../Component/productpageimg/product_routes_m02_text-columns_icons-1.png";
import secondimg from "./../../Component/productpageimg/product_routes_m02_text-columns_icons-2.png";
import thirdimg from "./../../Component/productpageimg/product_routes_m02_text-columns_icons-3.png";

const Productpage = () => {
  const cardData = [
    {
      id: 1,
      title: "forthfirstimg",
      image: forthfirstimg,
    },
    {
      id: 2,
      title: "forthsecondimg",
      image: forthsecondimg,
    },
    {
      id: 3,
      title: "forthsecondimg",
      image: forththirdimg,
    },
    {
      id: 4,
      title: "forthsecondimg",
      image: forthForthimg,
    },
    {
      id: 5,
      title: "forthsecondimg",
      image: forthfifthimg,
    },
    {
      id: 6,
      title: "forthsecondimg",
      image: forthsixthimg,
    },
  ];

  const seccardData = [
    {
      id: 1,
      title: "fifthfirstimg",
      image: fifthfirstimg,
    },
    {
      id: 2,
      title: "fifthsecimg",
      image: fifthsecimg,
    },
    {
      id: 3,
      title: "fifththirdimg",
      image: fifththirdimg,
    },
    {
      id: 4,
      title: "fifthforthimg",
      image: fifthforthimg,
    },
    {
      id: 5,
      title: "fifthFifthimg",
      image: fifthFifthimg,
    },
    {
      id: 6,
      title: "fifthsiximg",
      image: fifthsiximg,
    },
  ];

  const thirdcardData = [
    {
      id: 1,
      title: "sixtthfirstimg",
      image: sixtthfirstimg,
    },
    {
      id: 2,
      title: "sixthsecimg",
      image: sixthsecimg,
    },
    {
      id: 3,
      title: "sixththirdimg",
      image: sixththirdimg,
    },
  ];
  return (
    <>
      <div className="product_maindiv ">
        {/* first section */}
        <div className="container">
          <Row className="firstsecbackground align-items-center">
            <Col xl={6} md={12}>
              <div className="firstsecpadding">
                <div className="product_routes">Turn-To Routes</div>
                <h1 className="product_create">
                  {" "}
                  Efficient Journeys to Your Next Opportunity
                </h1>
                <div className="producthelp">
                  {" "}
                  At Turn-To, we understand that the journey to your next job is
                  as important as the destination. Our route planning tools
                  empower you to discover the most efficient and eco-friendly
                  paths to prospective employers, merging real-time traffic
                  updates with global reach.
                </div>
                <div>
                  <button className="product_api">Api</button>
                </div>
              </div>
            </Col>
            <Col xl={6} md={12}>
              <img
                src={firstsecimg}
                alt="firstsecimg"
                className="firstsecimg"
              ></img>
            </Col>
          </Row>
        </div>

        {/* second section */}
        <div className="secondsec_maindiv">
          <div className="container">
            <Row>
              <Col lg={4}>
                <div className="text-center">
                  <img
                    src={firstimg}
                    alt="firstimg"
                    className="firstimg mb-3"
                  ></img>
                  <h3>Real-time traffic</h3>
                  <div className="secsectionfont">
                    Improve business efficiency with up-to-date traffic data.
                  </div>
                </div>
              </Col>
              <Col lg={4} className="secondsec_top">
                <div className="text-center">
                  <img
                    src={secondimg}
                    alt="secondimg"
                    className="firstimg mb-3"
                  ></img>
                  <h3>Global routing</h3>
                  <div className="secsectionfont">
                    Provide comprehensive routes in over 200 countries and
                    territories.
                  </div>
                </div>
              </Col>
              <Col lg={4} className="secondsec_top">
                <div className="text-center">
                  <img
                    src={thirdimg}
                    alt="thirdimg"
                    className="firstimg mb-3"
                  ></img>
                  <h3>Routing at scale</h3>
                  <div className="secsectionfont">
                    Count on infrastructure that serves over one billion users.
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {/* third section */}
        <div className="thirdsec_maindiv">
          <div className="container">
            <div>
              <img
                src={thirdsecimg}
                alt="firstsecimg"
                className="firstsecimg"
              ></img>
            </div>
            <div>
              <h2 className="third_key">Key Features of Turn-To Routes</h2>
            </div>
            <Row>
              <Col lg={4} md={4} className="third_col">
                <div className="third_real">Real-Time Traffic Insights</div>
                <div className="third_detail">
                  Navigate with confidence using live traffic data to avoid
                  delays and optimize your travel time.
                </div>
              </Col>
              <Col lg={4} md={4} className="thirdsec_top">
                <div className="third_real">Comprehensive Coverage</div>
                <div className="third_detail">
                  Our routing solutions span numerous countries and territories,
                  ensuring you can plot your course, no matter where your job
                  search takes you.
                </div>
              </Col>
              <Col lg={4} md={4} className="thirdsec_top">
                <div className="third_real">Scalable Routing Technology</div>
                <div className="third_detail">
                  Rely on robust infrastructure designed to serve a wide
                  audience, from individual job seekers to large enterprises.
                </div>
              </Col>
            </Row>
            <Row className="mt-5 align-items-center">
              <Col lg={7}>
                <div>
                  <img
                    src={thirdsecimg1}
                    alt="firstsecimg"
                    className="firstsecimg"
                  ></img>
                </div>
              </Col>
              <Col lg={5}>
                <div>
                  <h1 className="sustainable"> Sustainable Commutes</h1>
                  <div className="third_col1">
                    <div className="thirdsec_real">Fuel-Efficient Routes</div>
                    <div className="third_choose">
                      Choose paths that are not just quick but also
                      fuel-efficient, aligning with your commitment to
                      sustainability.
                    </div>
                  </div>
                  <div className="third_col mt-4">
                    <div className="thirdsec_real">Accurate Fuel Estimates</div>
                    <div className="third_choose">
                      Calculate fuel usage more precisely with estimates
                      tailored to your vehicle type and current traffic
                      conditions.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {/* forth section */}
        <div className="forthsection_maindiv">
          <div className="container">
            <div>
              <div className="forth_border"></div>
              <div className="forth_optimized"> Optimized route planning</div>
              <Row>
                {cardData.map((card) => (
                  <Col key={card.id} lg={4} md={6} className="my-3">
                    <div className="forth_row">
                      <div>
                        <img
                          src={card.image}
                          alt={card.title}
                          className="firstsecimg"
                        ></img>
                      </div>
                      <div className="forth_subsec">
                        <div className="forth_cardline"> Lorem Ipsum</div>
                        <div className="forth_p">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="forthsubsectionpart">
              <div className="forth_border1"></div>
              <div className="forth_optimized"> Routing</div>
              <Row>
                {seccardData.map((card) => (
                  <Col key={card.id} lg={4} md={6} className="my-3">
                    <div className="forth_row">
                      <div>
                        <img
                          src={card.image}
                          alt={card.title}
                          className="firstsecimg"
                        ></img>
                      </div>
                      <div className="forth_subsec">
                        <div className="forth_cardline1"> Lorem Ipsum</div>
                        <div className="forth_p">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="forthsubthird">
              <div className="forth_optimized"> Product documentation</div>
              <Row>
                {thirdcardData.map((card) => (
                  <Col key={card.id} lg={4} md={6} className="my-3 sixthsec">
                    <div className="forth_row">
                      <div>
                        <img
                          src={card.image}
                          alt={card.title}
                          className="sixthsecimg"
                        ></img>
                      </div>
                      <div className="forth_subsec">
                        <div className="forth_cardline2"> Lorem Ipsum</div>
                        <div className="forth_p">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </div>
                        <div>
                          <button className="product_api">Api</button>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>

        {/* fifth section */}
        <div className="forthsubfinal">
          <div className="container">
            <Row>
              <Col lg={6} className="detailscenter">
                <div>
                  <img
                    src={sevenfirstimg}
                    alt="firstsecimg"
                    className="sevenfirstimg"
                  ></img>
                </div>
                <div className="fifthsec_p">
                  Embrace a smarter way to navigate your career path with
                  Turn-To Routes. Let's drive forward to success and
                  sustainability together.
                </div>
                <div className="mt-3">
                  {" "}
                  <MyButton>Get Started</MyButton>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {/* footer */}
        <LandingPageFooter />
      </div>
    </>
  );
};

export default Productpage;
