import Reaact from "react";
import "./NewAddPage.css";
import { Accordion, Col, Nav, Navbar } from "react-bootstrap";
import footerlogo from "../../assets/images/loginpagenewlogo.png";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";

const OfferingPage = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  return (
    <>
      <div>
        <HeaderNew />
        {/* first section */}
        <div className="container new_pagesmargin" onClick={RemoveSlider}>
          <h3 className="text-center">
            <b>Turn-To Hiring Solutions: Revolutionize Your Recruitment</b>
          </h3>
          <hr></hr>

          <div>
            <h5>
              <b>Main Navigation:</b>
            </h5>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Post a Job:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Simplify job posting with Turn-To’s streamlined interface.
                      Whether you're posting one job or bulk-loading, set your
                      budget effortlessly and gain immediate access to qualified
                      candidates.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Find Candidates:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Utilize Turn-To’s innovative job map search engine to
                      connect with candidates effectively. Benefit from both
                      internal insights and external exposure.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Products:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Explore Turn-To's cutting-edge hiring products, including
                      Eco Routes, Pay As You Go, and the ATS Resume Builder,
                      designed for maximum efficiency.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  4. Pricing:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Embrace the flexibility of Turn-To's Pay As You Go model,
                      ensuring cost-effective recruitment with no subscription
                      fees.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  5. Enterprise:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Tailored solutions for large-scale hiring needs, offering
                      bespoke features and support.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  5. Help Center:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Your go-to resource for all queries and guidance on making
                      the most of Turn-To’s platform.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div>
            <h5>
              <b>Pricing Section: Take Control of Your Hiring Budget</b>
            </h5>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Flexible Budgeting:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Enjoy the benefits of Turn-To's unique Pay As You Go
                      pricing. Set budgets per job post and manage your funds
                      with the One Wallet system.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Visibility Boosting Options:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Leverage Turn-To's job map search engine for unprecedented
                      job visibility. Choose how to amplify your job posts with
                      intuitive budget control.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Sponsorship Choices:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Decide on the best sponsorship level for your job posts,
                      with options designed to fit any budget and hiring
                      workflow.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div>
            <h5>
              <b>Why Choose Turn-To?</b>
            </h5>
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  1. Streamlined Job Posting:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Post jobs individually or in bulk with ease. Set your
                      budget, and your job goes live on Turn-To's job map search
                      engine, offering comprehensive insights and widespread
                      visibility.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  2. Innovative Job Search for Candidates:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Candidates enjoy a unique job search experience with the
                      job map, AI-assisted search functionalities, and
                      personalized job recommendations, all available for free.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  3. Eco-Friendly Solutions:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Embrace sustainability in hiring with Eco Routes,
                      promoting environmentally friendly recruitment practices.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="my-2">
                <Accordion.Header className="fontsetAccordion">
                  4. Advanced Candidate Matching:
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-justify">
                      Look forward to AI-driven talent matching, enhancing
                      connections between employers and the perfect candidates.
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="row mt-3 howtoworkemp">
            <h4>
              <b>Get Started with Turn-To Today</b>
            </h4>
            <div className="text-justify">
              Ready to streamline your recruitment process and connect with the
              right candidates more effectively than ever before? Turn-To is
              here to transform your hiring journey with innovative solutions
              and a commitment to efficiency and sustainability.
            </div>
          </div>
        </div>
        <div>
          <LandingPageFooter />
        </div>
      </div>
    </>
  );
};

export default OfferingPage;
