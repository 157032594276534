import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import api from "../Api/url";
import Spinner from "../app/shared/Spinner";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [AuthData, setAuthData] = useState(null);
  const [profileScores, setProfileScores] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getUserProfile = async () => {
    const Token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        api.profile,
        {
          headers: {
            Authorization: "Bearer " + Token,
          },
        }
      );

      if (response.status === 200) {
        setAuthData(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAuthData(null);
      setProfileScores({})
    }
  }

  useEffect(() => {
    async function loadProfile() {
      const Token = localStorage.getItem("token");
      if (Token) {
        try {
          const response = await axios.get(
            api.profile,
            {
              headers: {
                Authorization: "Bearer " + Token,
              },
            }
          );

          if (response.status === 200) {
            setAuthData(response.data.data);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          setAuthData(null);
          setProfileScores({})
        }
      } else {
        setIsLoading(false);
        setAuthData(null);
        setProfileScores({})
      }
    }
    loadProfile();

    return () => {
      setAuthData(null);
      setProfileScores({})
      setIsLoading(false);
    };
  }, []);

  if (isLoading) {
    return <><Spinner /></>;
  }
  else {
    return (
      <AuthContext.Provider
        value={{
          isAuthenticated: !!AuthData,
          AuthData,
          setAuthData,
          isLoading,
          getUserProfile,
          profileScores,
          setProfileScores
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

};

export const useAuth = () => useContext(AuthContext);
