import Reaact, { useState } from "react";
import "./NewAddPage.css";
import { Accordion, Col, Modal, Nav, Navbar, Row } from "react-bootstrap";
import footerlogo from "../../assets/images/loginpagenewlogo.png";
import { useAuth } from "../../context/AuthProvider";
import LandingPageFooter from "./LandingPageFooter";
import HeaderNew from "./HeaderNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewEmployerLogin from "../candidate/components/common/CompanyDetail/NewEmployerLogin";
import turntologo from "../../assets/images/loginpagenewlogo.png";
import firstsecimg from "../../Component/AboutPageimg/firstimgjobs.png";
import forthsecimg from "../../Component/AboutPageimg/jobadsclear.png";

import firstthirdimg from "../../Component/AboutPageimg/secongimgjobs.png";
import fisthforthimg from "./../../Component/AboutPageimg/thirdimgjobs.png";
import NewUserLogin from "../candidate/components/common/CompanyDetail/NewUserLogin";
import { BsDot } from "react-icons/bs";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";


const JobAds = () => {
  const { AuthData, setAuthData, isAuthenticated } = useAuth();
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };
  const [employerloginmodal, setEmployerloginmodal] = useState(false);
  const [modalShowLogin, setModalShowLogin] = useState(false);
  const history = useHistory();
  const postjobbtn = () => {
    if (isAuthenticated && AuthData.role == "employer") {
      history.push("/employer/jobs");
    } else {
      setEmployerloginmodal(true);
    }
  };
  return (
    <>
      <div>
        {/* first section */}
        <div className=" new_pagesmargin" onClick={RemoveSlider}>
          <div className="text-center mb-3">
            <img
              src={turntologo}
              alt="turn-to"
              className="text-center turntologocssnew"
            />
          </div>
          <div className="about_second py-5">
            <div className="container">
              <div className="row">
                <Col md={7} className="d-flex align-items-center">
                  <div >
              <h2 className="text-left">
                <b>
                Unleash the Power of Recruitment with Turn-To's AI-Driven Job Map

                </b>
              </h2>

              <div className="mt-3 mb-3 text-left">
                <div>
                 
                  Stop sifting through countless resumes and struggling to find the
            perfect fit. Turn-To transforms recruitment with a unique AI-powered
            job map and streamlined pricing, empowering you to reach top talent
            efficiently and cost-effectively.
                  
                </div>
              </div>
              </div>
              </Col>
              <Col md={5}>
              <div>
                    <img
                      src={forthsecimg}
                      alt="forthsecimg"
                      className="setnewpageimg"
                    ></img>
                  </div>
              </Col>
              </div>
              
            </div>
          </div>

          <div className="py-5">
            <div className="container">
             
              <Row>
                <Col lg={5} md={5} className="setnewpageflex">
                  <div>
                    <img
                      src={firstsecimg}
                      alt="firstsecimg"
                      className="setnewpageimg"
                    ></img>
                  </div>
                </Col>
                <Col lg={7} md={7}>
                  <div class="about_colfive">
                    <h4><b>Turn-To streamlines your hiring process</b></h4>
                    <div className="mt-2"></div>
                  <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Effortless Job Posting</b>
                    <div className="text-justify">
                    Ditch the tedious process of individual postings. Bulk
                      upload your jobs from a simple spreadsheet template,
                      saving you precious time.
                    </div>
                    <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Targeted Visibility</b>
                    </div>
                    <div className="text-justify">
                    Turn-To's magic lies in its intelligent job map. Our AI
                      analyzes your job descriptions and cultural indicators to
                      pinpoint the ideal candidate profile. This goes beyond
                      basic keyword matching, ensuring your listings reach the
                      most relevant talent pool.
                    </div>
                    <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Seamless Social Sharing</b>
                    </div>
                    <div className="text-justify">
                    Amplify your reach by effortlessly sharing your Turn-To
                      job postings directly on social media platforms.
                    </div>
                    <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Effortless Employer Branding</b>
                    </div>
                    <div className="text-justify">
                    Showcase your innovative approach to recruitment by
                      integrating our free iFrame directly on your career page.
                      This allows candidates to seamlessly search for
                      opportunities within your organization, enhancing your
                      employer brand.
                    </div>


                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="about_second py-5">
            <div className="container">
              
              <Row>
                <Col lg={7} md={7}>
                  <div class="about_colfive1">
                    <h4><b>Turn-To doesn't stop at just job placement. We empower you with
                advanced features as well</b></h4>
                <div className="mt-2">
                  <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Flat-Rate Pricing</b>
                    </div>
                    <div className="text-justify">
                    Predictability is key! Unlike traditional job boards with
                      complex pricing models, Turn-To offers a flat daily rate
                      for showcasing your jobs on the map. Choose between 15-day
                      or 30-day placements, giving you flexibility based on your
                      hiring needs.
                    </div>
                    <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b> AI-Powered Matching</b>
                    </div>
                    <div className="text-justify">
                    Our intelligent technology goes beyond just keywords. It
                      analyzes the essence of your job descriptions, ensuring a
                      higher probability of finding candidates who align not
                      only with the required skills, but also with your company
                      culture.
                    </div>
                    <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Seamless Integration</b>
                    </div>
                    <div className="text-justify">
                    The Turn-To platform integrates effortlessly with your
                      existing workflows. Bulk upload your jobs, easily select
                      placement duration, and manage everything from a
                      centralized employer dashboard.
                    </div>
                    
                  </div>
                </Col>
                <Col lg={5} md={5} className="setnewpageimg d-flex align-items-center">
                  <div>
                    <img
                      src={firstthirdimg}
                      alt="firstthirdimg"
                      className="firstsecimg"
                    ></img>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className=" aboutnewdivspace py-5">
            <div className="container">
              
              <Row>
                <Col lg={5} md={5} className="setnewpageflex d-flex align-items-center">
                  <div className="about_colseven">
                    <img
                      src={fisthforthimg}
                      alt="firthforthimg"
                      className="setnewpageimg"
                    ></img>
                  </div>
                </Col>
                <Col lg={7} md={7}>
                  <div class="about_colfive">
                    <h4>
                      <b>
                      Benefits for Employers
                      </b>
                    </h4>
                    <div className="mt-2">
                  <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Cost-Effective Recruitment</b>
                    </div>
                    <div className="text-justify">
                    Flat daily rates ensure you know exactly what your budget
                      is upfront. No hidden fees, no surprises.
                    </div>
                    <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Targeted Visibility</b>
                    </div>
                    <div className="text-justify">
                      {" "}
                      Reach the right candidates with our AI-powered matching
                      and job map search.
                    </div>
                    <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Time Savings</b>
                    </div>
                    <div className="text-justify">
                    Bulk upload your jobs and easily select your preferred
                      duration, streamlining the posting process.
                    </div>
                    <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Boosted Employer Brand</b>
                    </div>
                    <div className="text-justify">
                    Showcase your commitment to innovation with our unique job
                      map integration.
                    </div>
                    <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Increased Visibility</b>
                    </div>
                    <div className="text-justify">
                    Effortlessly share your job listings on social media,
                      expanding your reach even further.
                    </div>
                    <div className="mt-2">
                    <IoIosCheckmarkCircleOutline size={20} fill="#5932EA"/>{" "}
                    <b>Improved Candidate Quality</b>
                    </div>
                    <div className="text-justify">
                    Our focus on semantic search helps reduce applications
                      from unqualified candidates.
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className=" addhowitsfirstsectionbackcolorsetsec">
        <div className="container">
          <div className=" row text-left">
            <Col lg={9} md={9}>
            <h5 className="">
           <b> Ready to transform your recruitment strategy and attract top
                  talent? </b>
            </h5>
            <h5 className="">Get started with Turn-To today! Simply sign up, bulk upload your
                jobs, and choose your desired placement duration. Our AI will
                take care of the rest, ensuring your open positions reach the
                perfect candidates.</h5>
                </Col>
                <Col lg={3} md={3} className="jobadsbtnflex">
                  <div>
              <button class="about_ads mb-4 mt-2" onClick={(e) => postjobbtn()}>
              Job Ads
              </button>
              </div>
              </Col>
          </div>
        </div>
      </div>
          
         
          
          
        </div>
        <div>
          <LandingPageFooter />
        </div>
        {!isAuthenticated ? (
          <Modal
            show={employerloginmodal}
            onHide={() => setEmployerloginmodal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="remove_modelback"
          >
            <Modal.Body className="p-0">
              <div className="">
                <NewEmployerLogin
                  setEmployerloginmodal={setEmployerloginmodal}
                />
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          AuthData?.role === "user" && (
            <Modal
            show={modalShowLogin}
            onHide={() => setModalShowLogin(false)}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="remove_modelback"
            >
              <Modal.Body className="p-0">
                <div className="">
                <NewUserLogin setModalShowLogin={setModalShowLogin} />
                </div>
              </Modal.Body>
            </Modal>
          )
        )}
      </div>
    </>
  );
};

export default JobAds;
