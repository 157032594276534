import React from "react";
import "./NewAddPage.css";
import { Accordion, Col, Nav, Navbar } from "react-bootstrap";
import HeaderNew from "./HeaderNew";
import LandingPageFooter from "./LandingPageFooter";
import firstsecimg from "../../Component/AboutPageimg/faqing.png";
import footerpagepng from "../../Component/icontopng/footerpagepng.png";



const FaqEmployer = () => {
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };

  const accordionDataJob = [
    {
      question: "What is Turn-To's Job Map Search Engine?",
      answer:
        "Turn-To's Job Map Search Engine is an innovative platform that allows employers to post job vacancies on an interactive map. This visually represents job opportunities, making it easier for candidates to discover jobs in specific geographical areas.",
    },
    {
      question:
        "How can Turn-To's icon and URL be used as a media tool on my website?",
      answer:
        "Turn-To provides a unique icon that includes your URL address, which can be used as a media tool on your website. This directs job seekers to view jobs on our map, offering a dynamic alternative to traditional career pages.",
    },
  ];
  const accordionDataJobPosting = [
    {
      question: "How does the CPA and CPC model work on Turn-To?",
      answer:
        "Cost Per Action (CPA): You're charged $75 only when a candidate applies to your job posting. Cost Per Click (CPC): You're charged $0.75 per click, focusing on increasing traffic and visibility to your job listings.",
    },
    {
      question: "Are job posts free on Turn-To?",
      answer:
        "Yes, posting jobs on Turn-To is entirely free. You only incur costs when candidates take specific actions, such as submitting an application (CPA) or clicking on your job post (CPC), based on your chosen pricing model.",
    },
    {
      question: "How can employers post a job on Turn-To?",
      answer:
        "Employers can post a job by logging into their dashboard, filling out the job details, and submitting the listing. The process is designed to be straightforward and user-friendly.",
    },
  ];
  const accordionDataJobCnadiddates = [
    {
      question:
        "How does Turn-To ensure a match between candidates and company culture?",
      answer:
        "Turn-To emphasizes cultural fit by allowing employers to include company culture questions in job posts. This ensures candidates understand the job role, working environment, and company values.",
    },
    {
      question: "What benefits does Turn-To offer for targeting local talent?",
      answer:
        "Turn-To's Job Map and geographical precision allow you to target local talent effectively, showcasing your vacancies to candidates searching in your area. This increases the relevance and efficiency of your recruitment efforts.",
    },
    {
      question: "How does the ATS Resume Builder enhance candidate matching?",
      answer:
        "The ATS Resume Builder structures candidate data in a way that makes it easy for Turn-To's AI to match job postings with the most qualified applicants, enhancing the effectiveness of the recruitment process.",
    },
  ];
  const accordionDataJobSupport = [
    {
      question: "What kind of support does Turn-To offer to employers?",
      answer:
        "Turn-To offers support through email and a feedback loop system. Response times are typically between 12-24 hours, with an emphasis on resolving issues quickly and effectively.",
    },
    {
      question: "How can employers provide feedback on Turn-To's services?",
      answer:
        "Employers can provide feedback through the dedicated feedback loop system on the platform, which is monitored closely to continually improve Turn-To's services.",
    },
    {
      question: "What type of analytics and reporting does Turn-To provide?",
      answer:
        "The employer dashboard includes analytics and reporting tools that offer insights into job listing performance, applicant flow, and the effectiveness of different job postings.",
    },
  ];

  const accordionData = [
    {
      question: "Can I see my job posts on a map?",
      answer:
        "Absolutely. Once you post a job on Turn-To, it becomes visible on our dynamic job map, offering a geographical visualization of your vacancies. This feature simplifies hiring by connecting you directly with the local workforce.",
    },
    {
      question: "What are the benefits of using Turn-To’s job posting service?",
      answer:
        "Turn-To provides an AI-driven job matching system that connects employers with suitable candidates effectively. Employers benefit from an intuitive dashboard that tracks applications and performance.",
    },
    {
      question: "Is there a cost for posting jobs on Turn-To?",
      answer:
        "Posting jobs on Turn-To is based on a pay-as-you-go system. There are no recurring charges, providing flexibility and control over the hiring budget.",
    },
    {
      question: "How can Turn-To’s platform improve the recruitment process?",
      answer:
        "The platform's AI-driven tools and analytics help employers optimize their job listings, track applicant flow, and manage applications more efficiently, ultimately improving the recruitment process.",
    },
    {
      question:
        "Can employers manage and track their recruitment budget on Turn-To?",
      answer:
        "Employers can manage and track their recruitment budget through the dashboard, which includes features for setting job posting budgets and viewing wallet funds.",
    },
    {
      question: "How does Turn-To ensure the visibility of job postings?",
      answer:
        "Turn-To uses an AI-driven job and map search engine to display jobs effectively to candidates based on their location, search behavior, and profile preferences.",
    },
    {
      question:
        "What measures are in place to ensure fair and inclusive hiring practices?",
      answer:
        "While specifics on fair and inclusive hiring practices were not discussed, it can be addressed that Turn-To is committed to providing a platform that promotes diversity and equal opportunity.",
    },
    {
      question: "How can employers get more exposure for their job listings?",
      answer:
        "Employers can enhance job listing visibility through various platform features, such as setting a competitive salary and benefits package, ensuring job listing completeness, and utilizing the platform's AI to target suitable candidates.",
    },
    {
      question: "Can employers customize their job postings?",
      answer:
        "Yes, employers have the ability to customize job postings with details specific to the role, company culture, and other unique benefits to attract the right candidates.",
    },
    {
      question: "How secure is employer data on Turn-To?",
      answer:
        "Turn-To takes data security seriously, employing robust internal security measures to protect all employer data, including job listings and candidate interactions.",
    },
    {
      question:
        "Are there options for promoting job postings to reach a wider audience?",
      answer:
        "While the specifics haven’t been discussed, if Turn-To has such features, this could include sponsored listings or featured job posts that increase visibility beyond the standard listings.",
    },
    {
      question: "How can employers provide feedback on Turn-To’s services?",
      answer:
        "Employers can provide feedback through the dedicated feedback loop system on the platform, which is monitored closely to continually improve Turn-To’s services.",
    },
    {
      question:
        "What is Turn-To’s policy on job listing content and employer conduct?",
      answer:
        "Turn-To expects all employers to adhere to a set of guidelines that ensure job listings are legal, non-discriminatory, and comply with ethical hiring practices.",
    },
    {
      question:
        "How can employers track the performance of their recruitment campaigns?",
      answer:
        "The employer dashboard provides analytics on recruitment campaign performance, including metrics such as application rates, candidate engagement, and cost-effectiveness.",
    },
    {
      question: "Does Turn-To offer any integrations with other HR systems?",
      answer:
        "If Turn-To provides integrations with HR software or systems, this can be a key feature that allows for a more streamlined recruitment process by synchronizing data across platforms.",
    },
    {
      question: "How are technical issues addressed and resolved on Turn-To?",
      answer:
        "Turn-To addresses technical issues promptly, with employers encouraged to report such issues via the feedback loop, providing relevant screenshots and descriptions for a quicker resolution.",
    },
    {
      question:
        "What training or resources does Turn-To offer to new employer users?",
      answer:
        "Turn-To may offer training sessions, webinars, user guides, and a knowledge base to help new employers familiarize themselves with the platform’s features and best practices.",
    },
    {
      question: "Can employers set alerts for when specific candidates apply?",
      answer:
        "This feature allows employers to receive notifications for applications from candidates who meet certain predetermined criteria, streamlining the recruitment process.",
    },
    {
      question:
        "What strategies does Turn-To suggest for competitive job listing creation?",
      answer:
        "Turn-To  provides insights and tips on crafting job listings that stand out, including the importance of clear job descriptions, showcasing company culture, and highlighting unique offerings.",
    },
    {
      question:
        "How can employers leverage Turn-To’s AI for better recruitment outcomes?",
      answer:
        "Employers can leverage the AI for tasks such as screening candidates, predicting candidate suitability, and analyzing job market trends to refine their recruitment strategies.",
    },
  ];

  return (
    <>
      <div className="user-profile">
        {/* <HeaderNew /> */}

        <div
          className="terms-container2 container new_pagesmargin"
          onClick={RemoveSlider}
        >
         
          <div className="d-flex justify-content-center">
           <img
                  src={firstsecimg}
                  className="faqimgcss"
                  alt="faq"
                />
                </div>
         
          <div>
            <h5 className="mt-4 text-center">
              <b> Turn-To Basics</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
              {accordionDataJob.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion mycustomaccrodationclass">
                    <div>
                     {item.question}
                     </div>
                     <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h5 className="mt-4 text-center">
              <b> Pricing and Job Posting</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
              {accordionDataJobPosting.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion mycustomaccrodationclass">
                  <div>
                     {item.question}
                     </div>
                     <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h5 className="mt-4 text-center">
              <b> Finding the Right Candidates</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
              {accordionDataJobCnadiddates.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion mycustomaccrodationclass">
                  <div>
                     {item.question}
                     </div>
                     <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h5 className="mt-4 text-center">
              <b> Platform Features and Support</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
              {accordionDataJobSupport.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion mycustomaccrodationclass">
                  <div>
                     {item.question}
                     </div>
                     <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h5 className="mt-4 text-center">
              <b> Additional Questions</b>
            </h5>
            <Accordion
              defaultActiveKey={[
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
              ]}
              alwaysOpen
            >
              {accordionData.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion mycustomaccrodationclass">
                  <div>
                     {item.question}
                     </div>
                     <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
        <div className="">
          <LandingPageFooter />
        </div>
      </div>
    </>
  );
};

export default FaqEmployer;
