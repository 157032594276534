import React, { useEffect } from "react";
import "./footerpage.css";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import MyButton from "../../app/candidate/components/common/Button/MyButton";

const ProfileSetting = () => {
  return (
    <>
      <div className="terms-container" style={{ height: "100vh" }}>
        <h1 className="termofuse text-center">Profile Setting</h1>
        <hr></hr>
      </div>
    </>
  );
};

export default ProfileSetting;
