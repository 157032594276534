import React from "react";
import "./NewAddPage.css";
import { Accordion, Col, Nav, Navbar } from "react-bootstrap";
import LandingPageFooter from "./LandingPageFooter";
import setfaqbackimg from "../../Component/newwithoutloginpageimages/faqimges.png";
import footerpagepng from "../../Component/icontopng/footerpagepng.png";

const FaqCandidate = () => {
  const RemoveSlider = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };

  const accordionDataJob = [
    {
      question: "How can I use Turn-To to find jobs near me?",
      answer:
        "Turn-To's job map visually allows you to search for job opportunities in your preferred geographical area. Navigate the map to discover vacancies near you, streamlining your job search process.",
    },
    {
      question:
        "How can I fine-tune my job search on Turn-To to match specific needs?",
      answer:
        "Modify your search parameters by including specific keywords, desired salary ranges, company culture preferences, and job types (full-time, part-time, remote). Turn-To's advanced search allows you to personalize these settings for more accurate results.",
    },
    {
      question: "Does Turn-To list remote or international job opportunities?",
      answer:
        "Yes, you can filter your job search to include remote positions or explore opportunities across the globe, provided they are listed by employers on the platform.",
    },
  ];

  const accordionDataJobApplication = [
    {
      question: "What is the ATS Resume Builder on Turn-To?",
      answer:
        "Turn-To's ATS Resume Builder enables you to create resumes optimized for Applicant Tracking Systems, enhancing your chances of getting noticed by employers. You can also download and save your resume for future applications.",
    },
    {
      question: "How does the ATS Resume Builder enhance my resume?",
      answer:
        "Fill in your details to automatically generate an ATS-friendly resume, ready for download and distribution. An upcoming AI feature will further tailor your resume for keyword optimization, aligning it with industry standards and job requirements.",
    },
    {
      question: "How do I apply for jobs on Turn-To?",
      answer:
        "Applying for jobs on Turn-To is straightforward. Once you find a job of interest on the map or through search, click on the listing to view more details and follow the application instructions provided by the employer.",
    },
  ];

  const accordionDataJobSupport = [
    {
      question: "Are there any costs involved in using Turn-To as a candidate?",
      answer:
        "No, Turn-To is completely free for candidates. You can search for jobs, bookmark listings, and use the ATS Resume Builder at no cost.",
    },
    {
      question: "What services does Turn-To offer candidates for free?",
      answer:
        "Access the Job Map search engine to view job locations relative to your area and plan your commute.Bookmark and share favorite jobs, report issues with listings, and track job application metrics like clicks and applications in the 'My Saved Jobs' map.",
    },
    {
      question: "How does Turn-To's AI improve my job matches?",
      answer:
        "Our AI system learns from your profile and job search behavior to suggest opportunities that align with your skills and career aspirations. Over time, it personalizes your job feed to increase the relevance of the positions you see.",
    },
    {
      question: "How can I refine my job search using Turn-To's AI features?",
      answer:
        "Use AI-driven semantic search to go beyond keywords and match with jobs that align with your aspirations and goals. Engage with the system conversationally to find opportunities that suit your needs on a deeper level than traditional search parameters.",
    },
  ];

  const accordionDataJobRights = [
    {
      question:
        "How does Turn-To help me find a job that aligns with my values?",
      answer:
        "Our upcoming AI-driven 'Culture and Fit' feature will match candidates with companies that align with their personal values and professional aspirations.It offers a holistic view, pairing you with roles where you can thrive and aligning with your capabilities.",
    },
    {
      question: "Can I access company reviews and ratings on Turn-To?",
      answer:
        "Yes, Turn-To provides candid reviews and ratings from current and former employees to help you gauge company culture and expectations before applying.",
    },
  ];

  const accordionDataJobFeauters = [
    {
      question:
        "What makes a candidate profile stand out to potential employers on Turn-To?",
      answer:
        "A complete profile with detailed work experience, skills, and a professional photo can attract more attention from employers. Regular updates and an active engagement with job listings also indicate to employers that you're a serious and current job seeker.",
    },
    {
      question:
        "How can I organize my job search with the bookmarking feature?",
      answer:
        "'My Saved Jobs' lets you compile a list of preferred jobs, extending beyond your immediate area to country-wide or global opportunities. This feature helps you maintain organization in your job search and provides reminders of potential opportunities.",
    },
  ];

  const accordionData = [
    {
      question: "Can I bookmark jobs and view them later?",
      answer:
        "Yes, Turn-To allows you to bookmark job listings for easy access later. This feature helps you organize your job search and follow up on opportunities of interest.",
    },
    {
      question: "How does Turn-To help with cultural fit?",
      answer:
        "Turn-To includes a culture and fit category, providing insights into the company's environment and values. This objective versus subjective approach helps you find jobs that match your skills and align with your values and work preferences.",
    },

    {
      question:
        "How does Turn-To assist with finding a job that matches my values?",
      answer:
        "Our upcoming AI-driven 'Culture and Fit' feature will match candidates with companies that align with their personal values and professional aspirations. It offers a holistic view, pairing you with roles where you can thrive and aligning with your capabilities.",
    },
    {
      question: "What is the process for applying to jobs on Turn-To?",
      answer:
        "Build your resume with the ATS Resume Builder and then apply directly to jobs, with the system tracking your applications under 'Applied Jobs'. While Turn-To currently focuses on resume building, our blogs provide tips for cover letter writing and interview preparation, addressing key questions to help you stand out.",
    },
    {
      question:
        "How can I effectively use the job map to find local opportunities?",
      answer:
        "Utilize the interactive map to pinpoint job openings in your desired locale. Zoom in/out and filter by criteria such as job title, industry, or company size to find your ideal role. The map is updated in real-time to provide the latest job market information.",
    },

    {
      question: "How does Turn-To protect my personal information?",
      answer:
        "We prioritize your privacy with robust security measures. Your data is encrypted and we only share your profile information with your consent. You control what information is visible to employers and can update your privacy settings at any time.",
    },
    {
      question:
        "How can I receive notifications for jobs that fit my criteria?",
      answer:
        "You can set up alerts for new job postings that match your saved search criteria. Choose to receive notifications via email or SMS to ensure you never miss out on an opportunity that suits your qualifications and interests.",
    },
    {
      question:
        "Can I provide feedback on job listings or features on Turn-To?",
      answer:
        "Yes, we welcome your feedback to continually improve the Turn-To platform. Use the feedback button on job listings or in your dashboard to send us your thoughts or report any issues.",
    },
    {
      question: "How can I fine-tune my job search to match specific needs?",
      answer:
        "Modify your search parameters by including specific keywords, desired salary ranges, company culture preferences, and job types (full-time, part-time, remote). Turn-To’s advanced search allows you to personalize these settings for more accurate results.",
    },

    {
      question:
        "How does Turn-To keep me informed about trends in my industry?",
      answer:
        "Stay up-to-date with industry trends by subscribing to Turn-To’s newsletter, following relevant job categories, and engaging with our industry-specific blogs and articles.",
    },
    {
      question:
        "What resources does Turn-To offer for career growth and advancement?",
      answer:
        "Turn-To offers career advice, professional development tips, and tools like the ATS Resume Builder. Additionally, we host webinars and interactive sessions with career coaches and industry experts.",
    },

    {
      question: "Is Turn-To accessible on different devices and platforms?",
      answer:
        "Yes, Turn-To is designed for cross-platform accessibility. You can seamlessly switch between devices and continue your job search on mobile, tablet, or desktop.",
    },
    {
      question: "How can I refine my job search using Turn-To’s AI features?",
      answer:
        "Use AI-driven semantic search to go beyond keywords and match with jobs that align with your aspirations and goals. Engage with the system conversationally to find opportunities that suit your needs on a deeper level than traditional search parameters.",
    },
  ];

  return (
    <>
      <div className="user-profile">
        {/* <HeaderNew /> */}

        <div
          className="terms-container2 container new_pagesmargin"
          onClick={RemoveSlider}
        >
          
          <div className="text-center">
           <img src ={setfaqbackimg} alt="img" className="setimgfaqimguser"/>
          </div>
          <div>
            <h5 className="mt-4 text-center">
              <b> Job Search Basics</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
              {accordionDataJob.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion faqqutionuserside">
                   {item.question}
                   <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h5 className="mt-4 text-center">
              <b> ATS Resume and Job Applications</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
              {accordionDataJobApplication.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion faqqutionuserside">
                   {item.question}
                   <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h5 className="mt-4 text-center">
              <b> Finding the Right Fit</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
              {accordionDataJobRights.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion faqqutionuserside">
                   {item.question}
                   <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h5 className="mt-4 text-center">
              <b> Candidate Profile and Features</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
              {accordionDataJobFeauters.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion faqqutionuserside">
                   {item.question}
                   <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h5 className="mt-4 text-center">
              <b> Additional Features and Support</b>
            </h5>
            <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
              {accordionDataJobSupport.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion faqqutionuserside">
                   {item.question}
                   <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h5 className="mt-4 text-center">
              <b> More Questions?</b>
            </h5>
            <Accordion
              defaultActiveKey={[
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
              ]}
              alwaysOpen
            >
              {accordionData.map((item, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  className="my-2"
                  key={index}
                >
                  <Accordion.Header className="fontsetAccordion faqqutionuserside">
                     {item.question}
                     <img
                        className="mx-2 "
                        src={footerpagepng}
                        alt="open"
                        width="20px"
                        height="20px"
                      />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="text-justify">{item.answer}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
        <div className="">
          <LandingPageFooter />
        </div>
      </div>
    </>
  );
};

export default FaqCandidate;
