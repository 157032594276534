import React, { useEffect, useState } from "react";
import "./UserprofileInfo.css";
import {  Col, Container, Row } from "react-bootstrap";
import { getUserProfileResumeInfo } from "../../Api/Api";
import { useParams } from "react-router";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import comapanynumber from "../icontopng/PhoneiconBlack.png";
import LocationIcon from "../icontopng/loaction.png";
import linkedins from "../icontopng/footerlinkedin.png";
import companymail from "../icontopng/jobcardmail.png";
import userinfodownlaod from "../icontopng/userinfodownload.png";
import calandersicon from "../icontopng/calandersicon.png";
import searchlocation from "../icontopng/searchlocation.png";
import { useRef } from "react";
import hoverdatashowpng from "../icontopng/pnotshow.png";
import html2pdf from "html2pdf.js";
import InfoCvDownlord from "../../app/candidate/components/profile/InfoPop/infoCvDownlord";

import { Store } from "react-notifications-component";
import Documentview from "../DocumentView/Documentview";

function UserProfileResumeInfo(props) {
  const contentRef = useRef();
  var { username, profile_slug } = useParams();

  if (!username) {
    username = props.username;
  }

  if (!profile_slug) {
    profile_slug = props.profile_slug;
  }
  const [profileDetalis, setProfileData] = useState({});
  const [urlDocumnet, setUrlDocumnet] = useState("");
  const [isDocumnetModalView, setIsdocumnetModalView] = useState(false);
  const [skeletonscheduleUserData, setSkeletonscheduleUserData] =
    useState(true);
  const month = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  //------------------------------------------------------------------
  useEffect(() => {
    getUserProfileResumeInfo(username, profile_slug)
      .then(async function (response) {
        console.log("res", response.data.data);
        if (response.data.status) {
          setProfileData(response.data.data);
          localStorage.setItem("resumedata", response.data.data);
        } else {
          Store.addNotification({
            title: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          if (!props.usernamestate) {
            history.goBack();
          }
        }
        setSkeletonscheduleUserData(false);
        if (props?.setIsLoading) {
          props.setIsLoading(false);
        }
      })
      .catch(function (error) {
      });
  }, []);
  //------------------------------------------------------------------
  const DownlordPdf = (url) => {
    window.open(url);
  };

  var history = useHistory();

  const moveDashboard = () => {
    history.goBack();
  };

  // ModalCloseDocumnet
  const ModalCloseDocumnet = () => {
    setIsdocumnetModalView(false);
  };
  // handleGeneratePdf
  const handleGeneratePdf = () => {
    const content = contentRef.current;

    if (content) {
      const options = {
        margin: 5,
        filename: `${
          profileDetalis.first_name + " " + profileDetalis.last_name
        } Ats Resume.pdf`,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf(content, options);
    }
  };

  // DataSetDoucument
  const DataSetDoucument = (e) => {
    console.log(e);
    setUrlDocumnet("");
    setIsdocumnetModalView(true);
    setUrlDocumnet(e.document);
  };

  return (
    <>
      <div
        className={
          props.usernamestate == undefined
            ? " mb-5"
            : "user-profile"
        }
      >
          {!props.username && (
          <Row>
            <Col md={6} className="p-0 mb-2 mt-1">
              <h4>Resume / CV Download</h4>
            </Col>
            <Col md={6} className="text-end">
             
            </Col>
          </Row>
          ) }
        <Container
          className={
            props.usernamestate == undefined
              ? "bg-white cardnews radiusset new_userspace px-0"
              : "bg-white cardnews radiusset new_userspace px-0"
          }
        >
          <Row ref={contentRef} className="mb-3 ">
            {skeletonscheduleUserData ? (
              <Col md={12}>
                <div className="">
                  <Skeleton
                    containerClassName="username-skeleton"
                    height={20}
                    count={20}
                  />
                </div>
              </Col>
            ) : (
              <Col md={12}>
                <div className="mt-2">
                  <h4 className="setnewresumename mb-0">
                    {profileDetalis?.first_name +
                      " " +
                      profileDetalis?.last_name}
                  </h4>
                </div>
              </Col>
            )}

            <Col xs={12}>
              <div className="d-flex flex-wrap cmpinfo_sideflex1  my-0">
                {skeletonscheduleUserData ? (
                  <div className="">
                    <Skeleton containerClassName="username-skeleton" />
                  </div>
                ) : (
                  <>
                    {profileDetalis?.mobile_number ? (
                      <div className="d-flex align-items-center my-1">
                        <img
                          src={comapanynumber}
                          alt="number"
                          className="social_color1 closepngiocnwidth"
                        />
                        <span className="mx-2 allsamedatafontsize">
                          {profileDetalis?.mobile_number}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {skeletonscheduleUserData ? (
                  <div className="">
                    <Skeleton containerClassName="username-skeleton" />
                  </div>
                ) : (
                  <>
                    {profileDetalis?.email ? (
                      <div className="d-flex align-items-center my-0">
                        <img
                          src={companymail}
                          alt="mail"
                          className="social_color1 closepngiocnwidth"
                        />{" "}
                        <span className="mx-2 text-break allsamedatafontsize">
                          {profileDetalis?.email}{" "}
                        </span>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {skeletonscheduleUserData ? (
                  <div className="">
                    <Skeleton containerClassName="username-skeleton" />
                  </div>
                ) : (
                  <>
                    {profileDetalis?.info?.linkedin ? (
                      <div className="d-flex align-items-center my-0">
                        <img
                          src={linkedins}
                          alt="link"
                          className="social_color1 closepngiocnwidth"
                        />
                        <span className="mx-2 text-break allsamedatafontsize">
                          {profileDetalis?.info?.linkedin}
                        </span>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {skeletonscheduleUserData ? (
                  <div className="">
                    <Skeleton
                      containerClassName="username-skeleton"
                      height={20}
                    />
                  </div>
                ) : (
                  <>
                    {profileDetalis?.info?.location?.address ? (
                      <div className="d-flex align-items-center my-0">
                        <img
                          src={LocationIcon}
                          alt="location"
                          className="social_color1 closepngiocnwidth"
                        />
                        <span className="mx-2 allsamedatafontsize">
                          {profileDetalis?.info?.location?.address}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </Col>

            {skeletonscheduleUserData ? (
              <Col md={12}>
                <div className="">
                  <Skeleton
                    containerClassName="username-skeleton"
                    height={20}
                    count={20}
                  />
                </div>
              </Col>
            ) : (
              <Col md={12}>
                <div className="mt-1 borderSetpv ">
                  <p className="mb-0 mt-1 userprofileinfonewcss">
                    {profileDetalis?.profile?.header_title}
                  </p>
                </div>
              </Col>
            )}

            {profileDetalis?.profile?.bio ? (
              <Col lg={12} className="mt-0">
                <b className="sametitlename"> Professional Summary </b>
                <Row className="p-0 borderSetpv">
                  {skeletonscheduleUserData ? (
                    <div className="">
                      <Skeleton
                        containerClassName="username-skeleton"
                        height={50}
                        count={2}
                      />
                    </div>
                  ) : (
                    <>
                      <Col md={12} className="date_span p-0 pt-1 d-flex">
                        <div className="allsamedatafontsize userprofileinfonewcss">
                          {profileDetalis?.profile?.bio}
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            ) : (
              ""
            )}

            {profileDetalis?.experiences?.length > 0 ? (
              <Col lg={12} className="mt-0">
                <b className="sametitlename"> Experience</b>

                <Row className="p-0 borderSetpv">
                  {skeletonscheduleUserData ? (
                    <div className="">
                      <Skeleton
                        containerClassName="username-skeleton"
                        height={50}
                        count={3}
                      />
                    </div>
                  ) : (
                    <>
                      {profileDetalis?.experiences?.map((e, index) => {
                        return (
                          <>
                            <Col md={12} className="date_span p-0 pt-1 d-flex">
                              <Col md={12} className="p-0">
                                <div className="d-flex flex-wrap">
                                  <span className="mr-2 allsamedatafontsize">
                                    {e.company_name}
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={searchlocation}
                                      width={"15px"}
                                      height={"15px"}
                                    />
                                    <span className="ml-1 allsamedatafontsize">
                                      {e.location}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap">
                                  <span className="mr-2 allsamedatafontsize">
                                    {e.job_title}
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={calandersicon}
                                      width={"15px"}
                                      height={"15px"}
                                    />
                                    <span className="ml-1 allsamedatafontsize">
                                      {month[e.start_month]}, {e.start_year} -{" "}
                                      {e.currently_worked
                                        ? "Present "
                                        : month[e.start_month] +
                                          ", " +
                                          e.start_year}
                                    </span>
                                  </div>
                                </div>
                                {e.descriptionArray.map((e) => {
                                  return (
                                    <div className="allsamedatafontsize">
                                      • {e}
                                    </div>
                                  );
                                })}
                               
                              </Col>
                            </Col>
                          </>
                        );
                      })}
                    </>
                  )}
                </Row>
              </Col>
            ) : (
              ""
            )}

            {profileDetalis?.educations?.length > 0 ? (
              <Col md={12} className="mt-0">
                <b className="sametitlename"> Education</b>
                <Row className="borderSetpv">
                  {skeletonscheduleUserData ? (
                    <div className="user_details">
                      <Skeleton
                        containerClassName="username-skeleton"
                        height={50}
                        count={2}
                      />
                    </div>
                  ) : (
                    <>
                      {profileDetalis?.educations?.map((e, index) => {
                        return (
                          <>
                            <Col md={12} className="p-0 d-flex my-1">
                              <Col md={12} className="p-0">
                                <div className="d-flex flex-wrap">
                                  <span className="mr-2 allsamedatafontsize">
                                    {e.institude_name}
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={searchlocation}
                                      width={"15px"}
                                      height={"15px"}
                                    />
                                    <span className="ml-1 allsamedatafontsize">
                                      {e.location}
                                    </span>
                                  </div>
                                </div>

                                <div className="d-flex flex-wrap">
                                  <span className="mr-2 allsamedatafontsize">
                                    {e.field_of_study}
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={calandersicon}
                                      width={"15px"}
                                      height={"15px"}
                                    />
                                    <span className="ml-1 allsamedatafontsize">
                                      {month[e.start_month]}, {e.start_year} -{" "}
                                      {e.currently_worked
                                        ? "Present "
                                        : month[e.start_month] +
                                          ", " +
                                          e.start_year}
                                    </span>
                                  </div>
                                </div>
                                {e.descriptionArray.map((e) => {
                                  return (
                                    <div className="allsamedatafontsize">
                                      • {e}
                                    </div>
                                  );
                                })}
                                 {props?.IconShow && e.document !== "" && (
                                  <div>
                                    <span className="allsamedatafontsize">
                                      {" "}
                                      Degree
                                    </span>
                                    <img
                                      className="closepngiocnwidth mx-2"
                                      src={hoverdatashowpng}
                                      onClick={() => DataSetDoucument(e)}
                                    />
                                  </div>
                                )}
                              </Col>
                            </Col>
                          </>
                        );
                      })}
                    </>
                  )}
                </Row>
              </Col>
            ) : (
              ""
            )}

            {profileDetalis?.profile?.skills.length > 0 ? (
              <Col lg={12} className="">
                <b> Skills</b>

                <Row className="borderSetpv">
                  {skeletonscheduleUserData ? (
                    <div className="">
                      <Skeleton
                        containerClassName="username-skeleton"
                        height={20}
                      />
                    </div>
                  ) : (
                    <>
                      <Col xs={12} className="date_span p-0 ">
                        {profileDetalis?.profile?.skills?.map((e) => {
                          return (
                            <span className="lablebgblack wordspaceclass">
                              <p className="text-left m-0">{e}</p>
                            </span>
                          );
                        })}
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            ) : (
              ""
            )}

            {profileDetalis?.languages?.length > 0 ? (
              <Col lg={12} className="mb-1">
                <b className=""> Languages</b>

                <Row className="borderSetpv">
                  {skeletonscheduleUserData ? (
                    <div className="">
                      <Skeleton
                        containerClassName="username-skeleton"
                        height={20}
                      />
                    </div>
                  ) : (
                    <>
                      <Col xs={12} className="date_span p-0 ">
                        {profileDetalis?.languages?.map((e) => {
                          return (
                            <span className="lablebgblack wordspaceclass">
                              <p className="text-left m-0">
                                {e.name + " " + "(" + e.level + ")"}
                              </p>
                            </span>
                          );
                        })}
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            ) : (
              ""
            )}

            {profileDetalis?.documents?.length > 0 ? (
              <Col lg={12} className="mb-1">
                <b className=""> Certificates</b>

                <Row className="borderSetpv">
                  {skeletonscheduleUserData ? (
                    <div className="">
                      <Skeleton
                        containerClassName="username-skeleton"
                        height={20}
                      />
                    </div>
                  ) : (
                    <>
                      <Col xs={12} className="date_span p-0 ">
                        {profileDetalis?.documents?.map((e) => {
                          return (
                            <span className="lablebgblack wordspaceclass">
                               <div className="d-flex">
                              <p className="text-left m-0">
                                {e.document_title}
                              </p>
                              {props?.IconShow && e.document !== "" && (
                                  <img
                                    className="closepngiocnwidth mx-2"
                                    src={hoverdatashowpng}
                                    onClick={() => DataSetDoucument(e)}
                                  />
                                )}
                              </div>
                            </span>
                          );
                        })}
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            ) : (
              ""
            )}

            {profileDetalis?.professionalAffiliations?.length > 0 ? (
              <Col lg={12} className="mb-1">
                <b className=""> Professional Affiliations</b>

                <Row className="borderSetpv">
                  {skeletonscheduleUserData ? (
                    <div className="">
                      <Skeleton
                        containerClassName="username-skeleton"
                        height={20}
                      />
                    </div>
                  ) : (
                    <>
                      <Col xs={12} className="date_span p-0 ">
                        {profileDetalis?.professionalAffiliations?.map((e) => {
                          return (
                            <span className="lablebgblack wordspaceclass">
                              <p className="text-left m-0">
                                {e.name + " " + "(" + e.level + ")"}
                              </p>
                            </span>
                          );
                        })}
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            ) : (
              ""
            )}

            {profileDetalis?.awards?.length > 0 ? (
              <Col lg={12} className="mb-1">
                <b className=""> Awards</b>

                <Row className="borderSetpv">
                  {skeletonscheduleUserData ? (
                    <div className="">
                      <Skeleton
                        containerClassName="username-skeleton"
                        height={20}
                      />
                    </div>
                  ) : (
                    <>
                      <Col xs={12} className="date_span p-0 ">
                        {profileDetalis?.awards?.map((e) => {
                          return (
                            <span className="lablebgblack wordspaceclass">
                              <p className="text-left m-0">{e.award}</p>
                            </span>
                          );
                        })}
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col className="text-center">
              {skeletonscheduleUserData ? (
                ""
              ) : (
                <Col className="d-flex justify-content-center" md={12}>
                  <div className="btndownloadpdf mb-2" onClick={handleGeneratePdf}>
                    <img
                      src={userinfodownlaod}
                      alt="download"
                      className="closepngiocnwidth"
                    />
                    <div className="">Download PDF</div>
                  </div>
                </Col>
              )}
            </Col>
          </Row>
        </Container>
        <InfoCvDownlord />

        <Documentview
          open={isDocumnetModalView}
          CloseModal={ModalCloseDocumnet}
          url={urlDocumnet}
        />
      </div>
    </>
  );
}

export default UserProfileResumeInfo;
