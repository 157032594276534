import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { DocumentViewer } from "react-documents";

const Documentview = (props) => {
  console.log(props.url);
  return (
    <>
      <Modal
        show={props.open}
        onHide={props.CloseModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modalover"
        centered
      >
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DocumentViewer
            viewerUrl={props.url}
            // viewerUrl={"https://docs.google.com/gview?url=%URL%&embedded=true"}
            url={props.url}
            viewer="url"
            style={{ height: "700px", width: "100%" }}
          ></DocumentViewer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.CloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Documentview;
